const Menu = () => import(/* webpackChunkName: "newsletters" */ '@/apps/newsletters/menu');
const GroupOfNewslettersEdit = () => import(/* webpackChunkName: "newsletters" */ '@/apps/newsletters/group_of_newsletters_edit');
const GroupOfNewslettersList = () => import(/* webpackChunkName: "newsletters" */ '@/apps/newsletters/group_of_newsletters_list');
const NewsletterEdit = () => import(/* webpackChunkName: "newsletters" */ '@/apps/newsletters/newsletter_edit');
const NewsletterList = () => import(/* webpackChunkName: "newsletters" */ '@/apps/newsletters/newsletter_list');
const PublicNewslettersHome = () => import(/* webpackChunkName: "newsletters" */ '@/apps/newsletters/public_newsletters_home');
const PublicNewslettersList = () => import(/* webpackChunkName: "newsletters" */ '@/apps/newsletters/public_newsletters_list');
const PublicNewsletterView = () => import(/* webpackChunkName: "newsletters" */ '@/apps/newsletters/public_newsletter_view');

const routes = [
    {
        path: '/newsletters-public/',
        name: 'PublicNewslettersHome',
        component: PublicNewslettersHome,
        props: true,
        meta: {
            requiresAuth: false,
        },
        children: [
            {
                path: ':groupSlug',
                name: 'PublicNewslettersList',
                component: PublicNewslettersList,
                props: true,
                meta: {
                    requiresAuth: false,
                },
            },
            {
                path: ':groupSlug/:gonSlug/:slug/:pk',
                name: 'PublicNewsletterView',
                component: PublicNewsletterView,
                props: true,
                meta: {
                    requiresAuth: false,
                },
            },
        ],
    },
    {
        path: '/newsletters/',
        name: 'NewslettersHome',
        component: Menu,
        meta: {
            requiresAuth: true,
            requiresUserType: ['superadvisor'],
        },
        children: [
            {
                path: 'groupe/',
                name: 'GroupOfNewslettersList',
                component: GroupOfNewslettersList,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['superadvisor'],
                },
            },
            {
                path: 'groupe/:groupSlug/:pk',
                name: 'GroupOfNewslettersEdit',
                component: GroupOfNewslettersEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['superadvisor'],
                },
            },
            {
                path: 'groupe/creation',
                name: 'GroupOfNewslettersCreate',
                component: GroupOfNewslettersEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['superadvisor'],
                },
            },
            {
                path: 'newsletter/:groupSlug/:groupPk',
                name: 'NewsletterList',
                component: NewsletterList,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['superadvisor'],
                },
            },
            {
                path: 'newsletter/:groupSlug/:groupPk/:slug/:pk',
                name: 'NewsletterEdit',
                component: NewsletterEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['superadvisor'],
                },
            },
            {
                path: 'newsletter/:groupSlug/:groupPk/creation',
                name: 'NewsletterCreate',
                component: NewsletterEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['superadvisor'],
                },
            },
        ],
    },
];

export default routes;
