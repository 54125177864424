<template>
<div class="select-value">
  <multiselect
     v-model="localValue"
     :ref="id"
     :name="id"
     :id="id"
     :placeholder="placeholder"
     select-label=""
     deselect-label=""
     selectedLabel="sélection"
     :options="options"
     :track-by="trackBy"
     :optionLabel="optionLabel"
     :label="label"
     :allowEmpty="allowEmpty"
     :searchable="false"
     :multiple="multiple"
     :hideSelected="hideSelected"
     :mandatory="mandatory"
     >
    

      <!-- Pass all slots to child component, erasing default content -->
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      <slot name="noOptions">Désolé, il n'y a pas de données.</slot>
  </multiselect>
  <div class="error" v-show="isError">Erreur, cette valeur n'est pas valide</div>
</div>
</template>

<script>
import Multiselect from '@/components/multiselect_field';
// import Multiselect from 'vue-multiselect';
// console.warn('TBD: Change Multiselect to component in SelectValue');
// Idea from : https://github.com/shentao/vue-multiselect/issues/385#issuecomment-374591183

export default {
    name: 'SelectValue',
    components: {
        Multiselect,
    },
    props: {
        value: { default: '' },
        id: {
            default: x => {
                return (
                    '_' +
                    Math.random()
                        .toString(36)
                        .substr(2, 9)
                );
            },
            type: String,
        },
        placeholder: { default: '', type: String },
        options: { default: () => { return []; }, type: Array },
        trackBy: { default: 'value', type: String },
        optionLabel: { default: 'label', type: String },
        label: { default: 'label', type: String },
        mandatory: { default: false, type: Boolean },
        multiple: { default: false, type: Boolean },
        allowEmpty: { default: false, type: Boolean },
        isError: { default: false, type: Boolean },
        hideSelected: { default: true, type: Boolean },
        scope: { type: String },
    },
    data: function() {
        return {
            localValue: [], // this.multiple ? [] : {},
        };
    },
    computed: {
        scopedId() {
            return this.scope ? `${this.scope}.${this.id}` : this.id;
        },
    },
    watch: {
        options: {
            handler(options) {
                this.setLocalValue(this.value);
            },
            immediate: true,
        },
        value: {
            handler(value) {
                this.setLocalValue(value);
            },
            immediate: true,
        },
        localValue: {
            handler(localValue) {
                if (this.localValue) {
                    if (!this.multiple) {
                        this.$emit('input', this.localValue[this.trackBy]);
                    } else {
                        const values = this.localValue.map(option => option[this.trackBy]);
                        if (JSON.stringify(values) !== JSON.stringify(this.value)) {
                            this.$emit('input', this.localValue.map(option => option[this.trackBy]));
                        }
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        setLocalValue(value) {
            if (!this.multiple) {
                this.localValue = this.options.find(option => option[this.trackBy] === value);
            } else {
                const values = this.options.filter(option => this.value.includes(option[this.trackBy]));
                if (JSON.stringify(values) !== JSON.stringify(this.localValue)) {
                    this.localValue = this.options.filter(option => this.value.includes(option[this.trackBy]));
                }
            }
        },
    },
};
</script>
