<template>    
    <div>
        <div class="content-white" >
            <header class="header">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-10">
                        <i class="ico_pie69 icon-logo-pie69 logo-header"></i>
                        <span class="global-title">Professionnels rénovation performante 69<br>
                        <span class="title">Votre annuaire des professionnels du bâtiment</span></span>
                    </div>
                    <div class="cell small-2">
                        <a @click="hideModal"><font-awesome-icon class="close-modal" :icon="['fal', 'times-circle']" /></a>
                    </div>
                </div>
            </header>
            <layout-container>
                <div class="grid-x grid-padding-x">
                    <div class="cell">
                        <h2 class="title-helper">Bienvenue sur l'annuaire des professionnels rénovation performante 69</h2>
                    </div>
                </div>
            </layout-container>
        </div>
        <div class="content-gray" >
          <layout-container>
            <div v-show="!editionMode" v-html="helpContent"></div>

            <div v-show="editionMode" class="tiptap">
              <tiptap-editor
                id="help-content"
                v-model="helpContent"
                />
            </div>

            <regular-button class="right bt-close" @click="hideModal">
              Fermer
            </regular-button>
            <regular-button v-show="isAdmin && editionMode" class="right bt-save" @click="save">
              Sauvegarder
            </regular-button>
            <regular-button v-show="isAdmin && !editionMode" class="right bt-edit" @click="edit">
              Éditer le message
            </regular-button>
          </layout-container>
        </div>
    </div>
</template>

<script>

import { userMixin } from '@/mixins/user';
import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';
import RegularButton from '@/components/regular_button';
import TiptapEditor from '@/components/tiptap_editor';
import { HelperClass, HelperStoreCollection } from '@/models/listepro/helper';

export default {
    name: 'ModalHelpUserAndPro',
    mixins: [ handleFormErrorsMixin, userMixin ],

    data() {
        return {
            helpContent: "",
            helpPk: null,
            helpers: new HelperStoreCollection(),
            editionMode: false,
        };
    },
    created: function() {},
    mounted: function() {
        this.helpers.load().then(() => {
            let name = null;
            switch (this.helpType) {
                case 'professional':
                    name = "acces-professionnel";
                    break;
                case 'user': 
                    name = "aide-utilisateur";
                    break;
            } 
            if (name) {
                const help = this.helpers.asList().find(x => x.name === name);
                if (help) {
                    this.helpPk = help.pk;
                    this.helpContent=  help.helpText;
                }
            }
            
        });  
    },
    methods: {
        hideModal() {
            this.$emit('hide-modal');
        },
        edit() {
            this.editionMode = true;
        },
        save() {
            const currentHelper=new HelperClass();
            currentHelper.load(this.helpPk)
                .then( () => {
                    currentHelper.helpText = this.helpContent;
                    currentHelper.save()
                        .then(helpSaved => {
                            this.$notify({
                                group: 'root',
                                type: 'success',
                                title: 'Succès',
                                text: "L'aide a bien été sauvegardée.",
                            });
                            this.editionMode = false;
                        })
                        .catch(error => {
                            this.handleFormErrors(error);
                        });
                })
                .catch(error => {
                    this.handleFormErrors(error);
                });
            
        },
    },
    components: {
        RegularButton,
        TiptapEditor,
    },
    computed: {
        isAdmin() {
            return this.userUtilities.isListeProAdmin;
        },
    },
    props: {
        helpType:String,
    },
};


</script>
<style lang='stylus' scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/listepro_font'

.header
    padding: 1.5rem 0.5rem 1.5rem 0.5rem
    background-color: primary-color-500
    margin-bottom: 1rem

.close-modal
    color: #fff
    float: right
    font-size: 1.5rem

.bt-close
.bt-edit
.bt-save
    margin-top: 2rem

.bt-edit
.bt-save
    margin-right: 1rem

.logo-header
    font-size: 6rem
    padding: 0 20px 0 0
    vertical-align: middle
    color: #fff
    margin-left: 1rem

.logo
    font-size: 10rem
    padding: 0 20px 0 0
    color: #fff
    position: absolute
    top: 1.5rem
    left: 5.5rem

.logo-container
    background-color: secondary-color-400
    width: 25rem
    height: 15rem
    margin: 2rem
    position: relative
    border: solid 1rem #fff

.global-title
    text-transform: uppercase
    font-size: 2.2rem
    line-height: 0.7
    font-weight: 700
    color: #fff
    margin-bottom: -15px
    vertical-align: middle
    display: inline-block

.title
    font-size: 1.25rem
    font-weight: normal
    text-transform: uppercase

.title-helper
    text-transform: uppercase
    font-weight: bold

.help-container
    margin-top: 1rem
    margin-bottom: 1rem

.content-gray
    background-color: gray-100

.content-white
    background-color: white

.right
    float: right
</style>
