import apiClient from '@/api/api';

const actions = {
    /**
     * Load report data and save into the store
     * If report does not exists, empty report is savec into the store
     */
    loadReport({ commit }, { routeParamReportPk }) {
        return new Promise(function(resolve, reject) {
            let loadedReport;
            return apiClient
                .genericDetail('/visit-report/report/', routeParamReportPk)
                .then(report => {
                    loadedReport = report;
                    commit('updateField', {
                        path: 'report',
                        value: Object.assign({}, report, {
                            advisor: report.advisor.pk,
                            group: report.group.pk,
                            housing: report.housing.pk,
                        }),
                    });
                })
                .then(() => resolve(loadedReport))
                .catch(error => {
                    reject(error);
                });
        });
    },
    loadContactEntity({ commit }, { contactEntity }) {
        return new Promise(function(resolve, reject) {
            let loadContactEntityPromise;
            switch (contactEntity.type) {
                case 'contact':
                    loadContactEntityPromise = apiClient.genericDetail('/fac/contact/', contactEntity.pk);
                    break;
                case 'organization':
                    loadContactEntityPromise = apiClient.genericDetail('/fac/organization/', contactEntity.pk);
                    break;
                default:
                    reject(new Error('Contact associé au logement est erroné'));
                    break;
            }
            return loadContactEntityPromise
                .then(contact => {
                    commit('updateField', {
                        path: 'contactEntity',
                        value: contact,
                    });
                })
                .then(() => resolve())
                .catch(error => {
                    reject(error);
                });
        });
    },
    /**
     * Load the complete data report
     * First the housing, then the contactEntity and then the report
     */
    loadFullReport({ state, commit, dispatch, rootState }, { routeParamReportPk }) {
        return new Promise((resolve, reject) => {
            return dispatch('loadReport', { routeParamReportPk })
                .then(report => {
                    commit('updateField', {
                        path: 'housing',
                        value: report.housing,
                    });
                    commit('updateField', {
                        path: 'contactEntity',
                        value: report.housing.contactEntity,
                    });
                    return report;
                })
                .then(report => dispatch('loadContactEntity', { contactEntity: report.housing.contactEntity }))
                .then(() => resolve())
                .catch(error => {
                    reject(error);
                });
        });
    },
    /**
     * Save an empty report into database to prevent bad saves
     */
    initReport({ rootState, getters, dispatch }, { housingPk, groupPk }) {
        return new Promise((resolve, reject) => {
            const emptyReport = getters.emptyReport(housingPk, rootState.user.pk, groupPk);
            return apiClient
                .genericSave('/visit-report/report/', emptyReport)
                .then(savedReport => resolve(savedReport))
                .catch(error => reject(error));
        });
    },
    /**
     * Returns a fully loaded report with errors handling
     */
    getReport({ state, dispatch, commit }, { routeParamReportPk }) {
        return new Promise((resolve, reject) => {
            if (state.isReportLoaded && Number(routeParamReportPk) === Number(state.report.pk)) {
                return resolve(state.report);
            } else {
                return dispatch('loadFullReport', {
                    routeParamReportPk,
                })
                    .then(() =>
                        commit('updateField', {
                            path: 'isReportLoaded',
                            value: true,
                        }),
                    )
                    .then(() => resolve(state.report));
            }
        });
    },
    /**
     * Save report into database
     */
    saveReport({ state, dispatch, commit }) {
        return new Promise(function(resolve, reject) {
            apiClient
                .saveReport(state.report, state.housing)
                .then(() =>
                    dispatch('loadReport', {
                        routeParamReportPk: state.report.pk,
                    }),
                )
                .then(() =>
                    commit('updateField', {
                        path: 'isReportLoaded',
                        value: true,
                    }),
                )
                .then(() => resolve(state.report))
                .catch(error => {
                    reject(error);
                });
        });
    },
    /**
     * Adding a subobject (like face, reco, system) into the store (and save it into database immediately)
     */
    addSubobject({ state, dispatch }, { subobject, reportPk, url }) {
        return new Promise(function(resolve, reject) {
            Object.assign(subobject, { report: reportPk });
            apiClient
                .genericCreate(url, subobject)
                .then(newSubobject => resolve(newSubobject))
                .catch(error => {
                    reject(error);
                });
        });
    },
    /**
     * Removing a subobject (reco, face or system) from the store (and from database immediately)
     */
    removeSubobject({ state, dispatch }, { subobject, url }) {
        return new Promise(function(resolve, reject) {
            apiClient
                .genericDelete(url, subobject.pk)
                .then(() => resolve(true))
                .catch(error => {
                    reject(error);
                });
        });
    },
    /**
     * Launch PDF report generation
     */
    getPdfReport({ state, rootState }) {
        const data = {
            report: state.report,
            housing: state.housing,
            contactEntity: state.contactEntity,
            pdfParams: state.pdfParams,
            advisor: {},
            group: {},
        };

        return new Promise(function(resolve, reject) {
            apiClient
                .userDetail(state.report.advisor)
                .then(advisor => {
                    data.advisor = advisor;
                })
                .then(() => apiClient.groupDetail(state.report.group))
                .then(group => {
                    data.group = group;
                })
                .then(() =>
                    apiClient.getVisitReportPdf({
                        data: data,
                        domain: rootState.whiteLabelling.domain,
                    }),
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    /**
     * Load temporary store to pass application data to PDF generation
     */
    getPdfTempStore({ commit }, uuid) {
        return new Promise(function(resolve, reject) {
            apiClient
                .getPdfTempStore(uuid)
                .then(response => {
                    const data = response.data;
                    commit('updateField', { path: 'report', value: data.report });
                    commit('updateField', { path: 'housing', value: data.housing });
                    commit('updateField', { path: 'contactEntity', value: data.contactEntity });
                    commit('updateField', { path: 'pdfParams', value: data.pdfParams });
                    commit('updateField', { path: 'advisor', value: data.advisor });
                    commit('updateField', { path: 'group', value: data.group });
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export default actions;
