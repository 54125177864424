
const bapMenu = () => import('@/apps/bap/bap_menu');
const bapProMenu = () => import('@/apps/bap/bap_pro_menu');
const projectEdit = () => import('@/apps/bap/projects/project_edit');
const projectList = () => import('@/apps/bap/projects/project_list');
const registration = () => import('@/apps/bap/professional/registration');
const statistics = () => import('@/apps/bap/statistics/statistics');

const proProjectList = () => import('@/apps/bap/professional/pro_project_list');
const proProjectDetail = () => import('@/apps/bap/professional/pro_project_detail');

const routes = [
    {
        path: '/bourse-aux-projets',
        name: 'BAP',
        component: bapMenu,
        meta: {
            requiresAuth: true,
            requiresUserType: ['listeproadmin', 'professional', 'advisor', 'superadvisor'],
        },
        children: [
            {
                name: 'BapProjects',
                path: 'projets',
                component: projectList,
                props: false,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['listeproadmin', 'advisor', 'superadvisor'],
                },
            },
            {
                name: 'BapProjectNew',
                path: 'projets/creation',
                component: projectEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['listeproadmin', 'advisor', 'superadvisor'],
                },
            },
            {
                name: 'BapProjectEdit',
                path: 'projets/edition/:pk',
                component: projectEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['listeproadmin', 'advisor', 'superadvisor'],
                },
            },
            {
                name: 'BapStatistics',
                path: 'statistics',
                component: statistics,
                props: false,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['listeproadmin', 'advisor', 'superadvisor'],
                },
            },
            {
                name: 'BapProMenu',
                path: 'professionnels',
                component: bapProMenu,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['professional'],
                },
                children: [
                    {
                        name: 'BapProProjects',
                        path: 'projets',
                        component: proProjectList,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            requiresUserType: ['professional'],
                        },
                    },
                    {
                        name: 'BapProProjectDetail',
                        path: 'projets/:pk',
                        component: proProjectDetail,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            requiresUserType: ['professional'],
                        },
                    },
                    {
                        name: 'BapProRegister',
                        path: 'adhesion',
                        component: registration,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            requiresUserType: ['professional'],
                        },
                    },
                ],
            },
        ],
    },
];

export default routes;
