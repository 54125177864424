import OldThermix from '@/apps/old_thermix/old_thermix';

const routes = [
    {
        path: '/thermix-v2',
        name: 'OldThermix',
        component: OldThermix,
    },
];

export default routes;
