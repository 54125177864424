import { getField, updateField } from 'vuex-map-fields';

const USER_ICONS_LIST = {
    client: 'user-circle',
    professional: 'user-circle',
    advisor: 'user-tie',
    manager: 'chalkboard-teacher',
    administrator: 'user-cog',
    anonymous: 'user-circle',
};

const state = {
    // user: null,
    // users: [],
    iconsList: USER_ICONS_LIST,
};

const getters = {
    getField,

    userUtilities: function(state, getters, rootState) {
        return {
            existsInStore: getters.existsInStore,
            fullName: getters.fullName,
            hasPicProfile: getters.userHasPicProfile,
            homeRoute: getters.userHomeRoute,
            icon: getters.userIcon,
            isAdministrator: getters.isAdministrator,
            isAdministratorOrManager: getters.isAdministratorOrManager,
            isAdvisor: getters.isAdvisor,
            isSuperAdvisor: getters.isSuperAdvisor,
            isListeProAdmin: getters.isListeProAdmin,
            isAnonymous: getters.isAnonymous,
            isAuthenticated: getters.isAuthenticated,
            isManager: getters.isManager,
            isProfessional: getters.isProfessional,
            isStaff: getters.isStaff,
            picProfileUrl: getters.userPicProfileUrl,
            allowedToSeeBapMenu: getters.allowedToSeeBapMenu,
        };
    },

    fullName: function(state, getters, rootState) {
        if (rootState.user !== null) {
            if (rootState.user.firstName !== null) {
                return `${rootState.user.firstName} ${rootState.user.lastName}`;
            } else {
                return `${rootState.user.lastName}`;
            }
        } else {
            return '';
        }
    },
    existsInStore: function(state, getters, rootState) {
        if (rootState.user !== null) {
            return true;
        }
        return false;
    },
    allowedToSeeBapMenu: function(state, getters, rootState) {
        return rootState.user !== null && rootState.user.allowedToSeeBapMenu;
    },
    isAdministrator: function(state, getters, rootState) {
        return rootState.user !== null && rootState.user.userType === 'administrator';
    },
    isManager: function(state, getters, rootState) {
        return rootState.user !== null && rootState.user.userType === 'manager';
    },
    isAdvisor: function(state, getters, rootState) {
        return (
            rootState.user !== null &&
            (rootState.user.userType === 'advisor' || rootState.user.userType === 'superadvisor' || rootState.user.userType === 'listeproadmin')
        );
    },
    isListeProAdmin: function(state, getters, rootState) {
        return rootState.user !== null && rootState.user.userType === 'listeproadmin';
    },
    isSuperAdvisor: function(state, getters, rootState) {
        return rootState.user !== null && (rootState.user.userType === 'superadvisor' || rootState.user.userType === 'listeproadmin');
    },
    isAdministratorOrManager: function(state, getters, rootState) {
        return getters.isAdministrator || getters.isManager;
    },
    isStaff: function(state, getters, rootState) {
        return getters.isAdministrator || getters.isManager || getters.isAdvisor;
    },
    isProfessional: function(state, getters, rootState) {
        return rootState.user !== null && rootState.user.userType === 'professional';
    },
    isAnonymous: function(state, getters, rootState) {
        return !(getters.isStaff || getters.isProfessional);
    },
    isAuthenticated: function(state, getters, rootState) {
        return getters.isStaff || getters.isProfessional;
    },
    // userIconFor: state => profile => state.iconsList[profile],
    userIconFor: function(state, getters, rootState) {
        // return rootState.iconsList[profile];
        return state.iconsList;
    },
    userIcon: function(state, getters, rootState) {
        if (getters.isProfessional) {
            return state.iconsList.professional;
        } else if (getters.isAdvisor) {
            return state.iconsList.advisor;
        } else if (getters.isManager) {
            return state.iconsList.manager;
        } else if (getters.isAdministrator) {
            return state.iconsList.administrator;
        } else if (getters.isAnonymous) {
            return state.iconsList.anonymous;
        }
    },
    userHasPicProfile: function(state, getters, rootState) {
        if (rootState.user !== null && rootState.user.profilePic !== null) {
            return true;
        }
        return false;
    },
    userPicProfileUrl: function(state, getters, rootState) {
        if (rootState.user !== null && rootState.user.profilePic !== null) {
            return rootState.user.profilePic;
        }
        return '/static/img/empty_avatar.png';
    },
    userHomeRoute: function(state, getters, rootState) {
        let homeRoute = rootState.whiteLabelling.homeRoute || 'Home';
        if (getters.isAnonymous) {
            homeRoute = rootState.whiteLabelling.homeRoute || 'Home';
        } else if (getters.isProfessional) {
            homeRoute = rootState.whiteLabelling.homeRouteForProfessional || 'Home';
        } else if (getters.isAdvisor) {
            homeRoute = rootState.whiteLabelling.homeRouteForAdvisor || 'Home';
        } else if (getters.isManager) {
            homeRoute = rootState.whiteLabelling.homeRouteForManager || 'Home';
        } else if (getters.isAdministrator) {
            homeRoute = rootState.whiteLabelling.homeRouteForAdministrator || 'Home';
        }
        return homeRoute;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations: {
        updateField,
    },
    actions: {
        getUser({ state, commit, rootState }) {
            commit('updateField', { path: 'user', value: rootState.user });
        },
    },
};
