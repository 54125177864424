<template>
  <field-wrapper
    class="wysiwyg-field"
    :field-data="fieldData"
    ref="wrapper"
  >
    <div class="wrapper">
      <div class="input-group">
        <tiptap-editor
          class="input-group-field tiptap"
          v-model="localValue"
          :editable="!readonly"
          :ref="id"
          :name="id"
          :label="label"
          :id="id"
          @onBlur="emitBlur"
          :disabled-extensions="disabledExtensions"
          :allowRawEdit="allowRawEdit"
        />
      </div>
    </div>
  </field-wrapper>
</template>

<script>
import fieldMixin from './field_mixin';
import TiptapEditor from './tiptap_editor';

export default {
    name: 'WysiwygField',
    mixins: [fieldMixin],
    components: { TiptapEditor },
    props: {
        value: { default: '' },
        readonly: { default: false, type: Boolean },
        disabledExtensions: { default: () => ({}), type: Object },
        allowRawEdit: { default: true, type: Boolean },
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    methods: {
        setFocus: function() {
            this.$refs[this.id].focus();
        },
        emitBlur: function() {
            this.$emit('blur', this.value);
        },
    },
};
</script>

<style lang="stylus">
.wysiwyg-field
    width: 100%
</style>
