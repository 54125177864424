import { store } from '@/store/store';

export function loadThenStoreByIdentifier(collectionInstance, originalLoad, identifierField = 'identifier', filter = null) {
    return new Promise((resolve, reject) => {
        originalLoad.call(collectionInstance, filter).then((collection) => {
            const dataSet = Object.values(collection.getCollection()).reduce(
                (acc, item) => { acc[item[identifierField]] = item; return acc; },
                {}
            );
            store.set(collection.getStorePath() + 'ByIdentifier', dataSet);
            resolve(collection);
        }, (error) => {
            reject(error);
        });
    });
}
