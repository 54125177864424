import { getField } from 'vuex-map-fields';
import _ from 'lodash';

export default {
    getField,

    systemsRenovationInvoiceEuroConcernedEnergyTypes(state, getters) {
        return getters.systemsRenovationInvoiceConcernedEnergyTypes([
            state.systemsRenovationInvoiceEuro.heatingSystems[0],
            state.systemsRenovationInvoiceEuro.heatingSystems[1],
            state.systemsRenovationInvoiceEuro.hotWaterSystems[0],
            state.systemsRenovationInvoiceEuro.hotWaterSystems[1],
        ]);
    },

    systemsRenovationInvoiceQuantityConcernedEnergyTypes(state, getters) {
        return getters.systemsRenovationInvoiceConcernedEnergyTypes([
            state.systemsRenovationInvoiceQuantity.heatingSystems[0],
            state.systemsRenovationInvoiceQuantity.heatingSystems[1],
            state.systemsRenovationInvoiceQuantity.hotWaterSystems[0],
            state.systemsRenovationInvoiceQuantity.hotWaterSystems[1],
        ]);
    },

    systemsRenovationInvoiceConcernedEnergyTypes(state, getters, rootState, rootGetters) {
        return (systemDefs) => {
            const energyTypes = {};

            systemDefs.forEach((defId) => {
                if (!defId) return;

                const productionSystemDef = rootGetters['thermix/productionSystemDefs/get'](defId);
                let productionSystem = rootState.energies.productionSystemCollectionByIdentifier[productionSystemDef.identifier];

                if (productionSystemDef.identifier === 'synced-with-heating') {
                    const syncedProductionSystemDef = rootGetters['thermix/productionSystemDefs/get'](productionSystemDef.syncedId);
                    productionSystem = rootState.energies.productionSystemCollectionByIdentifier[syncedProductionSystemDef.identifier];
                }
                if (!productionSystem) return;
                const energyType = rootState.energies.energyCollectionByIdentifier[productionSystem.energyIdentifier];

                if (!energyType.currentPrice) return;

                if (!energyTypes[energyType.identifier]) {
                    energyTypes[energyType.identifier] = {
                        ...energyType,
                        heatingSystems: [],
                        hotWaterSystems: [],
                    };
                }

                if (productionSystemDef.type === 'heating') energyTypes[energyType.identifier].heatingSystems.push(defId);
                else energyTypes[energyType.identifier].hotWaterSystems.push(defId);
            });

            return Object.values(energyTypes);
        };
    },

    systemsRenovationInvoiceEuroProductionSystemIdentifiers(state, getters) {
        return getters.systemsRenovationInvoiceProductionSystemIdentifiers([
            state.systemsRenovationInvoiceEuro.heatingSystems[0],
            state.systemsRenovationInvoiceEuro.heatingSystems[1],
            state.systemsRenovationInvoiceEuro.hotWaterSystems[0],
            state.systemsRenovationInvoiceEuro.hotWaterSystems[1],
        ]);
    },

    systemsRenovationInvoiceQuantityProductionSystemIdentifiers(state, getters) {
        return getters.systemsRenovationInvoiceProductionSystemIdentifiers([
            state.systemsRenovationInvoiceQuantity.heatingSystems[0],
            state.systemsRenovationInvoiceQuantity.heatingSystems[1],
            state.systemsRenovationInvoiceQuantity.hotWaterSystems[0],
            state.systemsRenovationInvoiceQuantity.hotWaterSystems[1],
        ]);
    },

    systemsRenovationInvoiceProductionSystemIdentifiers(state, getters, rootState, rootGetters) {
        return (systemDefs) => {
            return _.compact(systemDefs.map((defId) => {
                const productionSystemDef = rootGetters['thermix/productionSystemDefs/get'](defId);
                return productionSystemDef ? productionSystemDef.identifier : null;
            }));
        };
    },

    altitudePerformanceBonusOptions(state) {
        return Object.keys(state.altitudePerformanceBonus).map((key) => {
            return { code: key, ...state.altitudePerformanceBonus[key] };
        });
    },
};
