/*
    Autogenerated class model from django SecondaryEfficiency
    -- 2020-02-10 10:50:39.385674
*/
import _ from 'lodash';
import Vue from 'vue';
import { store } from '@/store/store';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class SecondaryEfficiencyClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'isHeating', rawData.isHeating);
        Vue.set(this, 'isMultiUnit', rawData.isMultiUnit);
        Vue.set(this, 'isHydro', rawData.isHydro);
        Vue.set(this, 'ratio', rawData.ratio);
    }

    getBaseUrl() {
        return '/energies/secondary-efficiency/';
    }

    get isValid() {
        return true;
    }
}

class SecondaryEfficiencyCollection extends BaseCollection {
    initModel(rawData) {
        return new SecondaryEfficiencyClass(rawData);
    }

    getBaseUrl() {
        return '/energies/secondary-efficiency/';
    }
}

class SecondaryEfficiencyStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new SecondaryEfficiencyClass(rawData);
    }

    getBaseUrl() {
        return '/energies/secondary-efficiency/';
    }

    getStorePath() {
        return 'energies/secondaryEfficiencyCollection';
    }

    load() {
        super.load().then(() => {
            const data = _.cloneDeep(store.get('energies/defaultStateCopy').secondaryEfficiency);
            for (const secondaryEfficiency of this.asList()) {
                const systemType = secondaryEfficiency.isHeating ? 'heating' : 'hot_water';
                const ratio = secondaryEfficiency.ratio;
                if (secondaryEfficiency.isMultiUnit) {
                    data[systemType].multi_unit = ratio;
                } else {
                    data[systemType].individual.hydro = ratio;
                    data[systemType].individual.no_hydro = secondaryEfficiency.isHeating ? 1 : null;
                }
            }

            store.set('energies/secondaryEfficiency', data);
        });
    }
}

export {
    SecondaryEfficiencyClass,
    SecondaryEfficiencyCollection,
    SecondaryEfficiencyStoreCollection,
};
