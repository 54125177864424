import defaultState from './_defaultState';
import { updateField } from 'vuex-map-fields';
import Vue from 'vue';

export default {
    RESET_STATE(state) {
        Object.assign(state, defaultState);
    },

    SET_USER_ENERGY_PRICE(state, value) {
        const groupValues = state.userEnergyPrices[value.group] || {};
        Vue.set(groupValues, value.identifier, value);
        Vue.set(state.userEnergyPrices, value.group, groupValues);
    },
    RESET_USER_ENERGY_PRICE(state, value) {
        const groupValues = state.userEnergyPrices[value.group] || {};
        Vue.set(groupValues, value.identifier, undefined);
        Vue.set(state.userEnergyPrices, value.group, groupValues);
    },

    updateField,
};
