/*
    Autogenerated class model from django SubMission
    -- 2023-03-13 18:02:20.830444
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class SubMissionClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'order', rawData.order);
        Vue.set(this, 'mission', rawData.mission);
        Vue.set(this, 'name', rawData.name);
        Vue.set(this, 'helpText', rawData.helpText);
    }

    getBaseUrl() {
        return '/listepro/sub-mission/';
    }

    get isValid() {
        return true;
    }
}

class SubMissionCollection extends BaseCollection {
    initModel(rawData) {
        return new SubMissionClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/sub-mission/';
    }
}

class SubMissionStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new SubMissionClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/sub-mission/';
    }

    getStorePath() {
        return 'listepro/subMissionCollection';
    }
}

export {
    SubMissionClass,
    SubMissionCollection,
    SubMissionStoreCollection,
};
