const api = {
    groupList(inseecode = null) {
        let url = '/accounts/group/';
        if (inseecode) {
            url = `/accounts/group/?inseecode=${inseecode}`;
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    groupAdminList() {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get('/accounts/group-admin/')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    groupDetail(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/accounts/group/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    groupDelete(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .delete(`/accounts/group/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    groupUpdate(data) {
        const pk = data.pk;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`/accounts/group/${pk}/`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    groupCreate(data) {
        data.pk = null;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/accounts/group/', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
