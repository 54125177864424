<template>
<div class="content no-href-link-reveal">
  <page class="grid-y grid-padding-x">
    <header class="header">
      <i class="ico_pie69 icon-logo-pie69 header-logo"></i>
      <span class="header-global-title">professionnels-renoperformante69.org<br>
        <span class="header-title">Votre annuaire de professionnels du bâtiment</span></span>
    </header>
    <div class="sub-header skip-break cell shrink">
      <!--Annuaire des Professionnels-->
    </div>
    <div class="cell auto grid-x">
      <div :class="{'cell' : true, 'text-center': true, 'title-with-comment':commentPdf.value, 'title-without-comment':!commentPdf.value}">
        <h1 class="pdf-title">Sélection de professionnels du bâtiment</h1>
      </div>
      <div class="cell comment-container">
        <div class ="content-container" v-if="commentPdf.value">
          <div class="text-center">
            <span class="title-comment text-center">Commentaire : </span><br>
          </div>
          <div class="cell frame">
            <div v-html="commentPdf.value"></div>
          </div>
        </div>
      </div>
    </div>
  </page>
  <div v-if="downloadAll.value==='all'">
    <div class="cell text-center all-container">
      <span class="title-criteria">Critères de recherche : </span>
      <br>
      <div class="cell frame frame-criteria">
        <div class="pave-1">
          <i class="ico_pie69" :class="activity.value.icon"></i>
          <div>{{activity.value.name}}</div>
        </div>
        <br><br>
        <div class="pave-2">
          {{mission.value.name}}
        </div>
        <div class="arrow">
          <font-awesome-icon class="chevron" icon="long-arrow-alt-right"/>
        </div>
        <div class="pave-2">
          {{subMission.value.name}}
        </div>
      </div>
      <div>
        <a :href="link">Retrouvez les résultats ici</a> 
      </div>
    </div>
  </div>
  <div>
    <page class="result-page">
      <div class="liste-result">
        <professional-card-in-list
          v-for="professional in favoritePro.value"
          v-bind:key="professional.pk"  
          class="liste-card"
          :professional="professional"
          :printMode="true"
          />
      </div>
    </page>
  </div>
</div>
</template>

<script>

import ProfessionalCardInList from '@/apps/listepro/public/search/professional_card_in_list';
import Page from '@/components/page';
import {get} from 'vuex-pathify';


export default {
    name: 'PdfReport',
    mixins: [ ],

    data() {
        return {
        };
    },
    created: function() {},
    mounted: function() {
    },
    methods: {
        // parseProfessional(professional){
        //     console.log(JSON.stringify(professional).substring(1,JSON.stringify(professional).lenght));
        //     return JSON.stringify(professional).substring(1,JSON.stringify(professional).lenght);
        // },
    },
    components: {
        Page,
        ProfessionalCardInList,
    },
    computed: {
        mission:get("listepro/mission"),
        subMission:get("listepro/subMission"),
        activity:get("listepro/activity"),
        favoritePro:get("listepro/favoritePro"),
        commentPdf:get("listepro/commentPdf"),
        downloadAll:get("listepro/downloadAll"),
        link() {
            return `https://www.professionnels-renoperformante69.org/#/annuaire-des-pros/resultats/${this.activity.value.pk}/${this.mission.value.pk}/${this.subMission.value.pk}`;
        },
    },
    props: {
    },
};


</script>
<style lang='stylus' scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/listepro_font'

.top-page
    margin-top: 4rem

.middle-page
    margin-top: 0.5rem

.result-page
    padding-top: 3rem

.content
    background-color: #fff

.header
    padding: 1rem 0rem 1.5rem 1rem
    background-color: primary-color-500
    text-align: center

.header-global-title
    text-transform: uppercase
    font-size: 1.7rem
    line-height: 0.7
    font-weight: 700
    color: #fff
    margin-bottom: -15px
    vertical-align: middle
    display: inline-block

.header-logo
    font-size: 4.5rem
    padding: 0 20px 0 0
    vertical-align: middle
    color: #fff

.header-title
    font-size: 1rem
    font-weight: normal
    text-transform: uppercase

.sub-header
    margin-bottom: 0.5rem
    padding: 0.5rem
    font-size: 1rem
    color: white
    background-color: #3a3832
    font-weight: bold
    text-align: center
    text-transform: uppercase

.pdf-title
    color: #3a3832

.title-with-comment
    padding-top: 7rem
    padding-bottom: 5rem

.title-without-comment
    padding-top: 20rem
    padding-bottom: 5rem

.comment-title
    text-align: center
    float: middle

.title-comment
    color: primary-color-500
    text-transform: uppercase

.title-criteria
    color: primary-color-500
    text-transform: uppercase
    margin-top: 2rem

.frame
    border: 0.1rem solid primary-color-500
    padding: 2rem
    display: inline-block
    margin-top: 1rem
    max-height: 35rem

.frame-criteria
    width: 70%
    margin-bottom: 1rem

.comment-container
    position: relative

.content-container
    width: 95%
    margin-right: 2.5%
    margin-left: 2.5%

.comment-container .content-container
    position: absolute
    bottom: 4rem

.all-container
    padding-top: 4rem

.pave-1
    margin: 0 1rem
    text-align: center
    display: inline-block

    i
        text-align: center
        line-height: 2
        padding: 1.5rem 1.5rem
        font-size: 3rem
        color: #fff
        background-color: primary-color-500

    div
        text-align: center
        font-weight: 600
        font-size: 1.2rem
        color: primary-color-500

.pave-2
    border-radius: 0.2rem
    border: 0.2rem solid primary-color-500
    background-color: primary-color-500
    display: inline-block
    padding: 0.4rem 0.6rem
    text-transform: uppercase
    color: #fff
    font-size: 0.8rem

.arrow
    color: primary-color-500
    font-size: 1.5rem
    display: inline-block
    vertical-align: middle

.card
    position: relative
    display: inline-block
    vertical-align: top
    margin-left: 0.4rem
    margin-bottom: 0.5rem
    margin-right: 0.5rem
    width: 19rem
    height: 20rem
    padding: 0rem 0.8rem 0rem 0.8rem
    border: 1px solid #c6c6c6

.pro-list
    margin: 1rem 4.8rem 1rem 4.8rem

.pro-line
    margin-top: 0.8rem
    margin-bottom: 0.8rem

.pro-title
    font-size: 0.9rem
    font-weight: 700
    text-transform: uppercase
    line-height: 0%

.pro-link
    font-size: 0.8rem
    line-height: 0%

.pro-job
    font-size: 0.6rem
    text-transform: uppercase
    background-color: #000
    padding: 0.2rem
    color: #fff
    line-height: 0%

.pro-address
    font-size: 0.7rem

.pro-kw
    display: inline-block

.kw
    padding: 3px
    display: inline-block
    background-color: #fff
    border: 1px solid gray
    color: gray
    font-size: 0.7rem
    margin: 0.1rem 0.1rem 0.1rem 0.1rem

.logo
    width: 100px
    height: 85px
    margin-bottom: 1rem
    margin-top: 1.6rem

.icon
    font-size: 0.8rem

.basic-info-without-logo
    line-height: 1.4rem

.card .basic-info-with-logo
    position: absolute
    left: 8.5rem
    top: 0.5rem

.liste-result
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    align-items: flex-start
    align-content: flex-start
    gap: 20px

    .liste-card
        min-width: 370px
        margin-bottom: 100px // coz page-break do not works
        -webkit-column-break-inside: avoid-page
        page-break-inside: avoid-page
        break-inside: avoid-page
</style>
