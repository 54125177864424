export default {
    // results: [],
    // heatingProposition: {
    //     currentCost: null,
    //     productionSystem: null,
    //     estimatedCost: null,
    // },
    // hotWaterProposition: {
    //     currentCost: null,
    //     productionSystem: null,
    //     estimatedCost: null,
    // },
};
