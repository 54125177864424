export default {
    1: {
        needs: {
            housingCategory: 'individual',
            heating: 15000,
            hotWater: 3000,
        },
        solutions: [
            {
                heatingSystems: [{ identifier: 'oil_boiler_condensing', share: 0.8 }, { identifier: 'granulated_wood_stove', share: 0.2 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: false,
                financialSupport: false,
            }, {
                heatingSystems: [{ identifier: 'gaz_boiler_condensing', share: 1 }, { identifier: 'solar_system_combined', share: 0 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }, { identifier: 'synced-with-heating', share: 0 }],
                renewableElectricity: false,
                financialSupport: false,
            }, {
                heatingSystems: [{ identifier: 'heat_pump_air_water', share: 1 }],
                hotWaterSystems: [{ identifier: 'ceti_extracted_air', share: 1 }],
                renewableElectricity: false,
                financialSupport: false,
            }, {
                heatingSystems: [{ identifier: 'granulated_wood_boiler', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: false,
                financialSupport: false,
            },
        ],
    },
    2: {
        needs: {
            housingCategory: 'multi_unit',
            heating: 185000, // 15*80*180*0.85
            hotWater: 35000, // 650*3.5*15
        },
        solutions: [
            {
                heatingSystems: [{ identifier: 'gaz_boiler_standard', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: false,
            }, {
                heatingSystems: [{ identifier: 'gaz_boiler_condensing', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }, { identifier: 'solar_water_heater', share: 0 }],
                renewableElectricity: false,
                financialSupport: true,
                financialSupportAmount: 15000,
            }, {
                heatingSystems: [{ identifier: 'heat_pump_geothermal_lte', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: false,
            }, {
                heatingSystems: [{ identifier: '' }],
                hotWaterSystems: [{ identifier: '' }],
                renewableElectricity: false,
            },
        ],
    },
    3: {
        needs: {
            heating: 15000,
            hotWater: 3000,
        },
        solutions: [
            {
                heatingSystems: [{ identifier: 'solar_system_combined', share: 0 }, { identifier: 'gaz_boiler_standard', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 0 }, { identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: false,
                renewableElectricityProd: 0,
            }, {
                heatingSystems: [{ identifier: 'gaz_boiler_standard' }],
                hotWaterSystems: [{ identifier: 'synced-with-heating' }],
                renewableElectricity: false,
                renewableElectricityProd: 0,
            }, {
                heatingSystems: [{ identifier: '' }],
                hotWaterSystems: [{ identifier: '' }],
                renewableElectricity: false,
                renewableElectricityProd: 0,
            }, {
                heatingSystems: [{ identifier: '' }],
                hotWaterSystems: [{ identifier: '' }],
                renewableElectricity: false,
                renewableElectricityProd: 0,
            },
        ],
    },
    4: {
        needs: {
            heating: 15000,
            hotWater: 3000,
        },
        solutions: [
            {
                heatingSystems: [{ identifier: 'solar_system_combined', share: 0 }, { identifier: 'gaz_boiler_standard', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 0 }, { identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: false,
                renewableElectricityProd: 0,
            }, {
                heatingSystems: [{ identifier: 'gaz_boiler_standard', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: false,
                renewableElectricityProd: 0,
            }, {
                heatingSystems: [{ identifier: 'oil_boiler_condensing', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: true,
                renewableElectricityProd: 1000,
            }, {
                heatingSystems: [{ identifier: 'oil_boiler_condensing', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: false,
                renewableElectricityProd: 0,
            },
        ],
    },
    5: {
        needs: {
            heating: 15000,
            hotWater: 3000,
        },
        solutions: [
            {
                heatingSystems: [{ identifier: 'oil_boiler_condensing', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: false,
                renewableElectricityProd: 0,
            }, {
                heatingSystems: [{ identifier: 'gaz_boiler_condensing', share: 1 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }],
                renewableElectricity: false,
                renewableElectricityProd: 0,
            }, {
                heatingSystems: [{ identifier: 'gaz_boiler_condensing', share: 1, useGreenEnergy: true, greenEnergyShare: 0.4 }],
                hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1, useGreenEnergy: true, greenEnergyShare: 0.4 }],
                renewableElectricity: true,
                renewableElectricityProd: 1000,
            },
        ],
    },
};
