const Menu = () => import(/* webpackChunkName: "experiences" */ '@/apps/experiences/menu');
const DocTab = () => import(/* webpackChunkName: "experiences" */ '@/apps/experiences/doc_tab');
const ExperienceTab = () => import(/* webpackChunkName: "experiences" */ '@/apps/experiences/experience_tab');
const ExperienceView = () => import(/* webpackChunkName: "experiences" */ '@/apps/experiences/experience_view');
const ExperienceEdit = () => import(/* webpackChunkName: "experiences" */ '@/apps/experiences/experience_edit');
const ExperienceList = () => import(/* webpackChunkName: "experiences" */ '@/apps/experiences/experience_list');

const routes = [
    {
        path: '/references',
        name: 'ExperiencesHome',
        component: Menu,
        meta: {
            requiresAuth: true,
            requiresUserType: ['superadvisor', 'advisor'],
        },
        children: [
            {
                path: 'liste-references',
                name: 'ExperienceMenu',
                component: ExperienceTab,
                redirect: { name: 'ExperienceList' },
                meta: {
                    tabLabel: 'Références',
                    requiresAuth: true,
                    requiresUserType: ['superadvisor', 'advisor'],
                },
                children: [
                    {
                        path: '/',
                        name: 'ExperienceList',
                        component: ExperienceList,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            requiresUserType: ['superadvisor', 'advisor'],
                        },
                    },
                    {
                        path: 'fiche/:pk',
                        name: 'ExperienceView',
                        component: ExperienceView,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            requiresUserType: ['superadvisor', 'advisor'],
                        },
                    },
                    {
                        path: 'edition/:pk',
                        name: 'ExperienceEdit',
                        component: ExperienceEdit,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            requiresUserType: ['superadvisor', 'advisor'],
                        },
                    },
                    {
                        path: 'creation',
                        name: 'ExperienceCreate',
                        component: ExperienceEdit,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            requiresUserType: ['superadvisor', 'advisor'],
                        },
                    },
                ],
            },
            {
                path: 'lancer-un-projet',
                name: 'ExperienceStartAProject',
                component: DocTab,
                props: { editor: 'start-a-project' },
                meta: {
                    tabLabel: 'Lancer un projet',
                    requiresAuth: true,
                    requiresUserType: ['superadvisor', 'advisor'],
                },
            },
            {
                path: 'documents-utiles',
                name: 'ExperienceUsefullDocs',
                component: DocTab,
                props: { editor: 'usefull-docs' },
                meta: {
                    tabLabel: 'Documents utiles',
                    requiresAuth: true,
                    requiresUserType: ['superadvisor', 'advisor'],
                },
            },
        ],
    },
];

export default routes;
