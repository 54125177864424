import { store } from '@/store/store';

export function initNeedsEstimationSystemRenovationInvoiceEuroWatchers() {
    // Fire when a production system change in "system renovation invoice euro" needs estimation module
    return store.watch(
        (state, getters) => getters['thermix/needsEstimation/systemsRenovationInvoiceEuroProductionSystemIdentifiers'],
        () => { store.dispatch('thermix/needsEstimation/refreshSystemsRenovationInvoiceEuroInvoices'); },
    );
};
export function initNeedsEstimationSystemRenovationInvoiceQuantityWatchers() {
    // Fire when a production system change in "system renovation invoice quantity" needs estimation module
    return store.watch(
        (state, getters) => getters['thermix/needsEstimation/systemsRenovationInvoiceQuantityProductionSystemIdentifiers'],
        () => { store.dispatch('thermix/needsEstimation/refreshSystemsRenovationInvoiceQuantityInvoices'); },
    );
};

export function initNeedsEstimationWatchers() {
    const unwatchFunctions = [
        initNeedsEstimationSystemRenovationInvoiceEuroWatchers(),
        initNeedsEstimationSystemRenovationInvoiceQuantityWatchers(),
    ];
    return unwatchFunctions;
};
