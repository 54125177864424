 <template>
<div class="select-jobs">
  <spinner
    :active="isLoading"
    />
  
  <multiselect-field
    v-model="localValue"
    :label="label"
    :options="jobsOptions"
    :reduce="x => x.value"
    :multiple="multiple"
    class="jobs-list"
    />

  <span @click="clearSelection" class="reset-button">
    <font-awesome-icon icon="times-circle" />
  </span>
</div>
</template>

<script>
import apiClient from '@/api/api';
import MultiselectField from '@/components/multiselect_field';
import Spinner from '@/components/spinner';

import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';
        
export default {
    name: 'SelectJobs',
    mixins: [handleFormErrorsMixin],
    components: {
        MultiselectField,
        Spinner,
    },
    props: {
        'label': {type: String, default: 'Référents :'},
        'multiple': {type: Boolean, default: true},
        'value': {type: [Array, Number], default: () => []},
    },
    data: function() {
        return {
            isLoading: false,
            jobs: [],
            localValue: undefined,
        };
    },
    watch: {
        value() {
            this.localValue = this.value;
        },
        localValue() {
            this.$emit('input', this.localValue);
        },
    },
    computed: {
        jobsOptions() {
            const options = this.jobs.map(({ name, pk }) => ({
                label: name,
                value: pk,
                pk: pk,
            }));
            options.sort();
            return options;
        },
    },
    mounted: function() {
        this.load();
    },
    methods: {
        clearSelection() {
            this.localValue = null;
        },
        load() {
            this.isLoading = true;
            apiClient.genericList('listepro/job/')
                .then(jobs => {
                    this.jobs = jobs;
                    this.localValue = this.value;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../stylesheet/typography'
@require '../../stylesheet/variables'

.jobs-list
    display: inline-block
    min-width: 400px
    max-width: 490px

.reset-button
    cursor: pointer
    color: primary-color
</style>
