class Storage {
    get(key, defaultValue = null) {
        const cached = JSON.parse(
            localStorage.getItem(key)
        );

        if (!cached) {
            return defaultValue;
        }

        return cached.value;
    }

    set(key, value) {
        localStorage.setItem(key, JSON.stringify({ value }));
    }

    remove(key) {
        localStorage.removeItem(key);
    }
}

export default new Storage();
