import _ from 'lodash';

import groups from '@/apps/groups_and_users/api/groups';
import users from '@/apps/groups_and_users/api/users';
import housing from '@/apps/groups_and_users/api/housing';

const api = {};

_.assign(api, groups, users, housing);

export default api;
