<template>
  <dialog
    ref="dialog"
    @close="hide"
  >
    <slot
      v-if="open"
    />
  </dialog>
</template>

<script>
import { ref } from 'vue';

export default {
    setup(props, { emit }) {
        const dialog = ref();
        const open = ref(false);

        return {
            dialog,
            open,
            show() {
                emit('before-open');
                open.value = true;
                dialog.value.showModal();
                emit('opened');
            },
            hide() {
                emit('before-close');
                dialog.value.close();
                open.value = false;
                emit('closed');
            },
        };
    },
};
</script>
