<template>
  <div class="editor">
    <div class="menubar grid-x" v-show="!rawMode">
      
    <editor-content :editor="editor" />
      <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click.prevent="editor.chain().toggleBold().focus().run()"
          >
        <font-awesome-icon icon="bold" title="Gras" />
      </button>

      <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click.prevent="editor.chain().toggleItalic().focus().run()"
          >
        <font-awesome-icon icon="italic" title="Italique" />
      </button>

      <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click.prevent="editor.chain().toggleStrike().focus().run()"
          >
        <font-awesome-icon icon="strikethrough" title="Barré" />
      </button>

      <button
        class="menubar__button cell shrink"
        :class="{ 'is-active': editor.isActive('underline') }"
        @click.prevent="editor.chain().toggleUnderline().focus().run()"
      >
        <font-awesome-icon icon="underline" title="Souligné" />
      </button>

      
      <template v-if="!disabledExtensions.headings">
        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('paragraph') }"
          @click.prevent="editor.chain().setParagraph().focus().run()"
        >
          <font-awesome-icon icon="paragraph" title="Paragraphe" />
        </button>

        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          @click.prevent="editor.chain().toggleHeading({ level: 1 }).focus().run()"
          >
          <font-awesome-icon icon="h1" title="Titre 1" />
        </button>

        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          @click.prevent="editor.chain().toggleHeading({ level: 2 }).focus().run()"
        >
          <font-awesome-icon icon="h2" title="Titre 2" />
        </button>

        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          @click.prevent="editor.chain().toggleHeading({ level: 3 }).focus().run()"
        >
          <font-awesome-icon icon="h3" title="Titre 3" />
        </button>
      </template>

      <template v-if="!disabledExtensions.lists">
        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('bullet_list') }"
          @click.prevent="editor.chain().toggleBulletList().focus().run()"
        >
          <font-awesome-icon icon="list-ul" title="Liste" />
        </button>

        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('ordered_list') }"
          @click.prevent="editor.chain().toggleOrderedList().focus().run()"
        >
          <font-awesome-icon icon="list-ol" title="Liste numérotée" />
        </button>
      </template>

      <template v-if="!disabledExtensions.tables">
        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('blockquote') }"
          @click.prevent="editor.chain().toggleBlockquote().focus().run()"
        >
          <font-awesome-icon icon="quote-right" title="Citation" />
        </button>

        <button
          class="menubar__button cell shrink"
          @click.prevent="editor.chain().setHorizontalRule().focus().run()"
          v-if="!disabledExtensions.blocks"
        >
          <font-awesome-icon icon="horizontal-rule" title="Ligne horizontale" />
        </button>
      </template>


      
      <template>
        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          @click.prevent="editor.chain().focus().setTextAlign('left').run()"
          >
          <font-awesome-icon icon="align-left" title="À gauche" />
        </button>
        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          @click.prevent="editor.chain().focus().setTextAlign('center').run()"
          >
          <font-awesome-icon icon="align-center" title="Centrer" />
        </button>
        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          @click.prevent="editor.chain().focus().setTextAlign('right').run()"
          >
          <font-awesome-icon icon="align-right" title="À droite" />
        </button>
        <!-- // Does not work with Firefox https://bugzilla.mozilla.org/show_bug.cgi?id=1253840
        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
          @click.prevent="editor.chain().focus().setTextAlign('justify').run()"
          >
          <font-awesome-icon icon="align-justify" title="Justifier" />
        </button>
        -->
      </template>

      
      <button
          class="menubar__button cell shrink"
          @click.prevent="editor.chain().undo().focus().run()"
          >
        <font-awesome-icon icon="undo" title="Annuler" />
      </button>

      <button
          class="menubar__button cell shrink"
          @click.prevent="editor.chain().redo().focus().run()"
          >
        <font-awesome-icon icon="redo" title="Refaire" />
      </button>

      <template v-if="!disabledExtensions.links">
        <button
          class="menubar__button cell shrink"
          :class="{ 'is-active': editor.isActive('link') }"
          @click.prevent="showLinkMenu"
        >
          <font-awesome-icon icon="link" title="Lien" />
        </button>
      </template>

      
      <button
        v-if="allowRawEdit"
        class="menubar__button cell shrink"
        @click.prevent="toggleRawMode"
      >
        <font-awesome-icon icon="code" title="Source HTML" />
      </button>

      <template v-if="!disabledExtensions.tables">
        <button
          class="menubar__button cell shrink"
          @click.prevent="editor.chain().insertTable({ rowsCount: 3, colsCount: 3, withHeaderRow: false }).focus().run()"
        >
          <font-awesome-icon icon="table" title="Tableau" />
        </button>

        <div
          v-if="editor.isActive('table')"
          class="cell shrink grid-x"
        >
          <button
            class="menubar__button cell shrink"
            @click.prevent="editor.chain().deleteTable().focus().run()"
          >
            <font-awesome-icon icon="trash-alt" title="Supprimer le tableau" />
          </button>
          <button
            class="menubar__button cell shrink"
            @click.prevent="editor.chain().addColumnBefore().focus().run()"
          >
            <font-awesome-icon icon="arrow-to-left" title="Ajouter une colonne avant" />
          </button>
          <button
            class="menubar__button cell shrink"
            @click.prevent="editor.chain().addColumnAfter().focus().run()"
          >
            <font-awesome-icon icon="arrow-to-right" title="Ajouter une colonne après" />
          </button>
          <button
            class="menubar__button cell shrink"
            @click.prevent="editor.chain().deleteColumn().focus().run()"
          >
            <font-awesome-icon icon="backspace" title="Supprimer la colonne" />
          </button>
          <button
            class="menubar__button cell shrink"
            @click.prevent="editor.chain().addRowBefore().focus().run()"
          >
            <font-awesome-icon icon="sort-amount-up-alt" title="Ajouter une colonne avant" />
          </button>
          <button
            class="menubar__button cell shrink"
            @click.prevent="editor.chain().addRowAfter().focus().run()"
          >
            <font-awesome-icon icon="sort-amount-down-alt" title="Ajouter une colonne après" />
          </button>
          <button
            class="menubar__button cell shrink"
            @click.prevent="editor.chain().deleteRow().focus().run()"
          >
            <font-awesome-icon icon="minus-square" title="Supprimer la ligne" />
          </button>
          <button
            class="menubar__button cell shrink"
            @click.prevent="editor.chain().mergeCells().focus().run()"
          >
            <font-awesome-icon icon="object-group" title="Fusionner des cellules (les sélectionner avec shift)" />
          </button>
        </div>
      </template>

      <form
        class="link-form cell grid-x"
        v-if="linkMenuIsActive"
        @submit.prevent="setLinkUrl"
      >
        <input
          type="text"
          v-model="linkUrl"
          placeholder="https://"
          ref="linkInput"
          @keydown.esc="hideLinkMenu"
        />
        <button @click.prevent="setLinkUrl()" type="button">
          <font-awesome-icon icon="check" title="Ok" />
        </button>
      </form>
    </div>

    <EditorContent class="editor__content" :editor="editor" v-if="!rawMode" />

    <div  v-if="rawMode">
      <div class="menubar">
        <button
          class="menubar__button cell shrink"
          @click.prevent="toggleRawMode"
        >
          <font-awesome-icon icon="code" title="Éditeur HTML" />
        </button>
      </div>

      <textarea
        v-if="rawMode"
        v-model="localValue"
        rows="10"
      />

    </div>

  </div>
</template>

<script>
import { computed, nextTick, ref, watch } from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-2';

import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';


Heading.configure({ levels: [1, 2, 3] });
Table.configure({ resizable: true });


export default {
    name: 'TiptapEditor',
    props: {
        value: { default: '', type: String },
        disabledExtensions: { default: () => ({}), type: Object },
        allowRawEdit: { default: true, type: Boolean },
    },
    components: { EditorContent },

    setup(props, { emit }) {
        const extensions = [
            Bold,
            Code,
            Document,
            History,
            Italic,
            Link,
            Paragraph,
            Strike,
            Text,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Underline,
        ];
        props.disabledExtensions.headings || extensions.push(Heading);
        props.disabledExtensions.lists || extensions.push(
            BulletList,
            ListItem,
            OrderedList,
        );
        props.disabledExtensions.blocks || extensions.push(
            Blockquote,
            CodeBlock,
            HardBreak,
            HorizontalRule,
        );
        props.disabledExtensions.tables || extensions.push(
            Table,
            TableCell,
            TableHeader,
            TableRow,
        );

        const localValue = ref('');
        const rawMode = ref(false);
        const editorChange = ref(false);

        const editor = new Editor({
            content: props.value,
            extensions,
            onUpdate() {
                editorChange.value = true;
                emit('input', editor.getHTML());
            },
        });

        watch(rawMode, raw => {
            if (raw) {
                localValue.value = editor.getHTML();
            } else {
                editor.commands.setContent(localValue.value, true);
            }
        });

        watch(localValue, value => {
            if (rawMode.value) {
                emit('input', value);
            }
        });

        const value = computed(() => props.value);
        watch(value, value => {
            if (rawMode.value) {
                localValue.value = value;
            } else {
                if (!editorChange.value) {
                    editor.commands.setContent(value, true);
                }
                editorChange.value = false;
            }
        });

        const linkUrl = ref(null);
        const linkMenuIsActive = ref(null);
        const linkInput = ref(null);

        function showLinkMenu() {
            const attrs = editor.getAttributes('link');
            linkUrl.value = attrs.href;
            linkMenuIsActive.value = true;
            nextTick().then(() => linkInput.value.focus());
        }

        function hideLinkMenu() {
            linkUrl.value = null;
            linkMenuIsActive.value = false;
        }

        function setLinkUrl() {
            editor.commands.setLink({ href: linkUrl.value });
            hideLinkMenu();
        }

        function toggleRawMode() {
            rawMode.value = !rawMode.value;
        }

        return {
            editor: ref(editor),
            localValue,
            linkInput,
            linkMenuIsActive,
            linkUrl,
            rawMode,
            showLinkMenu,
            hideLinkMenu,
            setLinkUrl,
            toggleRawMode,
        };
    },
};
</script>

<style lang="stylus" scoped>
@require '../stylesheet/typography'
@require '../stylesheet/variables'

.grid-x.menubar
    margin-bottom: space-md
    background-color: gray-400
    line-height: 10px
    padding: 3px 10px 3px 10px
    margin-bottom: 0

.menubar__button
    font-size: 10px
    background-color: gray-400
    color: white
    padding: 4px 0 4px 0

.menubar__button:hover
.is-active
    background-color: gray-600

.link-form
    padding-top: 2px

    input
        width: 80%

    button
        padding: 10px
        font-size: 1rem

.editor__content
    border: solid 1px gray-300
    padding: 10px
    max-height: 30rem
    overflow-y: auto
    overflow-x: hidden
    cursor: text !important

    :deep(.ProseMirror)
    :deep(.ProseMirror-focused)
        outline: none

    :deep(.ProseMirror)
        table
        tr
        td
            border: 1px solid gray-300

    :deep(.selectedCell)
        background-color: #ffc

.menubar .svg-inline--fa
    height: 0.8rem
</style>
