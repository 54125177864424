<template>
  <div class="accordion">
    <slot />
  </div>
</template>

<script>
export default {
    name: 'Accordion',
    components: {
    },
    props: [],
    data() {
        return {};
    },
    computed: {
    },
    created: function() {
    },
    mounted: function() {
    },
    methods: {
    },
};
</script>

<style scoped></style>
