/*
    Autogenerated class model from django
    -- 2020-02-10 10:48:33.488860
*/

import { make } from 'vuex-pathify';
import _ from 'lodash';
import { ProductionSystemStoreCollection } from '@/models/energies/production_system';
import { EnergyStoreCollection } from '@/models/energies/energy';
import { BuildingHeatingConsumptionStoreCollection } from '@/models/energies/building_heating_consumption';
import { EnergyVectorStoreCollection } from '@/models/energies/energy_vector';
import { SecondaryEfficiencyStoreCollection } from '@/models/energies/secondary_efficiency';

const defaultState = {
    isLoading: 0,
    buildingHeatingConsumptionCollection: {},
    buildingHeatingConsumptionCollectionByIdentifier: {},
    buildingHeatingConsumptionCollectionFilter: null,
    buildingHeatingConsumptionCollectionLastUpdate: 0,
    carbonTaxCollection: {},
    carbonTaxCollectionFilter: null,
    carbonTaxCollectionLastUpdate: 0,
    energyCollection: {},
    energyCollectionByIdentifier: {},
    energyCollectionFilter: null,
    energyCollectionLastUpdate: 0,
    energyVectorCollection: {},
    energyVectorCollectionByIdentifier: {},
    energyVectorCollectionFilter: null,
    energyVectorCollectionLastUpdate: 0,
    productionSystemCollection: {},
    productionSystemCollectionByIdentifier: {},
    productionSystemCollectionFilter: null,
    productionSystemCollectionLastUpdate: 0,
    /* eslint-disable quote-props */
    secondaryEfficiency: {
        'heating': {
            'multi_unit': null,
            'individual': {
                'hydro': null,
                'no_hydro': null,
            },
        },
        'hot_water': {
            'multi_unit': null,
            'individual': {
                'hydro': null,
                'no_hydro': null,
            },
        },
    },
    /* eslint-enable quote-props */
    secondaryEfficiencyCollection: {},
    secondaryEfficiencyCollectionFilter: null,
    secondaryEfficiencyCollectionLastUpdate: 0,
    yearlyEnergyPriceCollection: {},
    yearlyEnergyPriceCollectionFilter: null,
    yearlyEnergyPriceCollectionLastUpdate: 0,

    thermalSolarIdentifiers: ['solar_system_combined', 'solar_water_heater'],
    gazIdentifiers: ['gaz_boiler_standard', 'gaz_boiler_condensing'],
    oilIdentifiers: ['oil_boiler_standard', 'oil_boiler_condensing'],

};

const state = Object.assign({}, (defaultState));
const getters = {
    ...make.getters(state),

    filteredProductionSystems(state) {
        return (housingCategory = null, systemType = null) => {
            let systems = Object.values(state.productionSystemCollection) || [];
            if (systemType === 'hot_water') systems = systems.filter(ps => ps.isHotWater);
            else if (systemType === 'heating') systems = systems.filter(ps => ps.isHeating);
            if (housingCategory === 'individual') systems = systems.filter(ps => ps.isIndividual);
            else if (housingCategory === 'multi_unit') systems = systems.filter(ps => ps.isMultiUnit);
            return systems;
        };
    },

    possibleProductionSystems(state, getters) {
        return (housingCategory = null, systemType = null, limitedChoices = [], excludedChoices = []) => {
            let systems = getters.filteredProductionSystems(housingCategory, systemType);

            // Apply limited choices
            if (limitedChoices.length) {
                systems = systems.filter(ps => limitedChoices.includes(ps.identifier));
            }

            // Remove excluded choices
            if (excludedChoices.length) {
                systems = systems.filter(ps => !excludedChoices.includes(ps.identifier));
            }
            systems = _.sortBy(systems, ['name']);
            return systems;
        };
    },

    energyVectorsForEnergy(state) {
        return (energyIdentifier) => {
            const energyVectors = Object.values(state.energyVectorCollection) || [];
            if (!energyIdentifier) return energyVectors;
            return energyVectors.filter(ev => ev.energyIdentifier === energyIdentifier);
        };
    },

    defaultStateCopy() {
        return _.cloneDeep(defaultState);
    },

    secondaryEnergyRatio(state) {
        return (systemType, housingCategory, withHydro) => {
            if (housingCategory === 'individual') {
                withHydro = withHydro ? 'hydro' : 'no_hydro';
                return state.secondaryEfficiency[systemType][housingCategory][withHydro];
            } else {
                return state.secondaryEfficiency[systemType][housingCategory];
            }
        };
    },

};

const mutations = {
    ...make.mutations(state),

    resetState(state) {
        Object.assign(state, defaultState);
    },

    incrementLoading(state) {
        state.isLoading++;
    },

    decrementLoading(state) {
        state.isLoading--;
    },
};

const actions = {
    loadEnergies() {
        return (new EnergyStoreCollection()).load();
    },
    loadProductionSystems() {
        return (new ProductionSystemStoreCollection()).load();
    },
    loadBuildingHeatingConsumptions() {
        return (new BuildingHeatingConsumptionStoreCollection().load());
    },
    loadEnergyVectors() {
        return (new EnergyVectorStoreCollection().load());
    },
    loadSecondaryEfficiencies() {
        return (new SecondaryEfficiencyStoreCollection().load());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
