export const userMixin = {
    computed: {
        userUtilities: function() {
            return this.$store.getters['userUtilities/userUtilities'];
        },
        // TODO Deprecated -> remove from code

        /*
for i in $(grep -rl userMixin *|grep .vue$); do
sed -e s/this\\.fullName/this.userUtilities.fullName/g -ibac $i;
sed -e s/this\\.isAnonymous/this.userUtilities.isAnonymous/g -ibac $i;
sed -e s/this\\.isAuthenticated/this.userUtilities.isAuthenticated/g -ibac $i;
sed -e s/this\\.isAdministrator/this.userUtilities.isAdministrator/g -ibac $i;
sed -e s/this\\.isManager/this.userUtilities.isManager/g -ibac $i;
sed -e s/this\\.isAdministratorOrManager/this.userUtilities.isAdministratorOrManager/g -ibac $i;
sed -e s/this\\.isAdvisor/this.userUtilities.isAdvisor/g -ibac $i;
sed -e s/this\\.isStaff/this.userUtilities.isStaff/g -ibac $i;
sed -e s/this\\.userIcon/this.userUtilities.icon/g -ibac $i;
sed -e s/this\\.userHasPicProfile/this.userUtilities.hasPicProfile/g -ibac $i;
sed -e s/this\\.userPicProfileUrl/this.userUtilities.picProfileUrl/g -ibac $i;
sed -e s/this\\.userHomeRoute/this.userUtilities.homeRoute/g -ibac $i;

sed -e s/fullName/userUtilities.fullName/g -ibac $i;
sed -e s/isAnonymous/userUtilities.isAnonymous/g -ibac $i;
sed -e s/isAuthenticated/userUtilities.isAuthenticated/g -ibac $i;
sed -e s/isAdministrator/userUtilities.isAdministrator/g -ibac $i;
sed -e s/isManager/userUtilities.isManager/g -ibac $i;
sed -e s/isAdministratorOrManager/userUtilities.isAdministratorOrManager/g -ibac $i;
sed -e s/isAdvisor/userUtilities.isAdvisor/g -ibac $i;
sed -e s/isStaff/userUtilities.isStaff/g -ibac $i;
sed -e s/userIcon/userUtilities.icon/g -ibac $i;
sed -e s/userHasPicProfile/userUtilities.hasPicProfile/g -ibac $i;
sed -e s/userPicProfileUrl/userUtilities.picProfileUrl/g -ibac $i;
sed -e s/userHomeRoute/userUtilities.homeRoute/g -ibac $i;

sed -e s/userUtilities\\.userUtilities./userUtilities./g -ibac $i;
done
         */
        fullName: function() {
            console.error('Deprecated: use userUtilities.fullName');
            return this.userUtilities.fullName;
        },
        isAnonymous: function() {
            console.error('Deprecated: use userUtilities.isAnonymous');
            return this.userUtilities.isAnonymous;
        },
        isAuthenticated: function() {
            console.error('Deprecated: use userUtilities.isAuthenticated');
            return this.userUtilities.isAuthenticated;
        },
        isAdministrator: function() {
            console.error('Deprecated: use userUtilities.isAdministrator');
            return this.userUtilities.isAdministrator;
        },
        isManager: function() {
            console.error('Deprecated: use userUtilities.isManager');
            return this.userUtilities.isManager;
        },
        isAdministratorOrManager: function() {
            console.error('Deprecated: use userUtilities.isAdministratorOrManager');
            return this.userUtilities.isAdministratorOrManager;
        },
        isAdvisor: function() {
            console.error('Deprecated: use userUtilities.isAdvisor');
            return this.userUtilities.isAdvisor;
        },
        isListeProAdmin: function() {
            console.error('Deprecated: use userUtilities.isListeProAdmin');
            return this.userUtilities.isListeProAdmin;
        },
        isProfessional: function() {
            console.error('Deprecated: use userUtilities.isProfessional');
            return this.userUtilities.isProfessional;
        },
        isStaff: function() {
            console.error('Deprecated: use userUtilities.isStaff');
            return this.userUtilities.isStaff;
        },
        userIcon: function() {
            console.error('Deprecated: use userUtilities.icon');
            return this.userUtilities.icon;
        },
        userHasPicProfile: function() {
            console.error('Deprecated: use userUtilities.hasPicProfile');
            return this.userUtilities.hasPicProfile;
        },
        userPicProfileUrl: function() {
            console.error('Deprecated: use userUtilities.picProfileUrl');
            return this.userUtilities.picProfileUrl;
        },
        userHomeRoute: function() {
            console.error('Deprecated: use userUtilities.homeRoute');
            return this.userUtilities.homeRoute;
        },
    },
};
