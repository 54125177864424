/*
    Autogenerated class model from django Job
    -- 2023-03-13 18:02:20.827266
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class JobClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'order', rawData.order);
        Vue.set(this, 'name', rawData.name);
        Vue.set(this, 'form', rawData.form);
        Vue.set(this, 'allowToUseBap', rawData.allowToUseBap || true);
    }

    getBaseUrl() {
        return '/listepro/job/';
    }

    get isValid() {
        return true;
    }
}

class JobCollection extends BaseCollection {
    initModel(rawData) {
        return new JobClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/job/';
    }
}

class JobStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new JobClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/job/';
    }

    getStorePath() {
        return 'listepro/jobCollection';
    }
}

export {
    JobClass,
    JobCollection,
    JobStoreCollection,
};
