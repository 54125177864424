<template>
  <field-wrapper
    class="select-field"
    :class="{'inline': inline}"
    :field-data="fieldData"
    ref="wrapper"
  >
    <div class="wrapper">
      <v-select
        :ref="id"
        :id="id"
        :name="id"
        :options="options"
        v-model="localValue"
        :reduce="reduce"
        :label="optionLabel"
        :append-to-body="appendToBody"
        :clearable="clearable"
        :selectable="selectable"
        :disabled="disabled"
        :multiple="multiple"
        :placeholder="placeholder"
        :searchable="searchable"
        :clearSearchOnSelect="clearSearchOnSelect"
        :closeOnSelect="closeOnSelect"
        :taggable="taggable"
        :tabindex="tabindex"
        :pushTags="pushTags"
        :filterable="filterable"
        :filterBy="filterBy"
        :loading="loading"
        v-on:search="search"
      >
        <span slot="no-options">Désolé, il n'y a pas de données.</span>
        <!--
            Pass all slots to v-select (erase defaults defined above)
            https://stackoverflow.com/a/50892881/1216183
        -->
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </v-select>
    </div>
  </field-wrapper>
</template>

<script>
import _ from 'lodash';
import vSelect from 'vue-select';

import fieldMixin from './field_mixin';

export default {
    name: 'SelectField',
    mixins: [fieldMixin],
    components: {
        'v-select': vSelect,
    },
    props: {
        value: { default: null },
        options: { type: Array, default: () => [] },
        optionLabel: { default: 'label', type: String },
        helptext: { default: '', type: String },
        inline: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        appendToBody: { type: Boolean, default: false },
        clearable: { type: Boolean, default: true },
        selectable: { type: Function, default: (option) => true },
        searchable: { type: Boolean, default: true },
        multiple: { type: Boolean, default: false },
        placeholder: { type: String, default: '' },
        clearSearchOnSelect: { type: Boolean, default: true },
        closeOnSelect: { type: Boolean, default: true },
        taggable: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        tabindex: { type: Number, default: null },
        pushTags: { type: Boolean, default: false },
        filterable: { type: Boolean, default: true },
        filterBy: { type: Function },
        reduce: { type: Function, default: (option) => option },
        // linked only basic options of vue-select for more see this -> https://vue-select.org/api/props.html#value
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    mounted() {
        this.localValue = this.value;
    },
    watch: {
        value() {
            // Update local value to allow reactivity on prop value
            this.localValue = this.value;
            if (this.value !== null) {
                this.forceUpdate();
            }
        },
    },
    methods: {
        search: function(text) {
            this.$emit('search', text);
        },
        forceUpdate: function() {
            // Update v-select value
            for (const option of this.options) {
                if (_.eq(this.$refs[this.id].reduce(option), this.value)) {
                    this.$refs[this.id].updateValue(option);
                    break;
                }
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
.inline
    display: flex
    flex-direction: row
    align-items: flex-top
    margin-bottom: 0.6rem

    .wrapper
        display: flex
        flex-direction: column
        flex-grow: 1

        > .input-group
            margin: 0

:deep(.vs__search:focus[readonly])
    color: white

:deep(.vs__selected)
:deep(.vs__selected-options)
    white-space: nowrap
    overflow-x: hidden
    text-overflow: ellipsis
    flex-wrap: nowrap

:deep(.vs__dropdown-option)
    white-space: normal
</style>
