const EventMenu = () => import(/* webpackChunkName: "events" */ '@/apps/events/menu');
const EventEdit = () => import(/* webpackChunkName: "events" */ '@/apps/events/event_edit');
const EventList = () => import(/* webpackChunkName: "events" */ '@/apps/events/event_list');
const AttendeesPublicView = () => import(/* webpackChunkName: "attendees" */ '@/apps/events/attendees_public_view');

const routes = [
    {
        path: '/evenements/',
        name: 'EventsHome',
        component: EventMenu,
        meta: {
            requiresAuth: true,
            requiresUserType: ['superadvisor', 'advisor'],
        },
        children: [
            {
                path: '/',
                name: 'EventList',
                component: EventList,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['superadvisor', 'advisor'],
                },
            },
            {
                path: 'edition/:pk',
                name: 'EventEdit',
                component: EventEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['superadvisor', 'advisor'],
                },
            },
            {
                path: 'creation/',
                name: 'EventCreate',
                component: EventEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['superadvisor', 'advisor'],
                },
            },
        ],
    },
    {
        path: '/inscriptions/:id',
        name: 'AttendeesPublicView',
        props: true,
        component: AttendeesPublicView,
        meta: {
            requiresAuth: true,
        },
    },
];

export default routes;
