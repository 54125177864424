export default {

    reset({ commit, state, dispatch, rootState }) {
        commit('RESET_STATE');

        state.solutions.forEach((_, solutionIndex) => {
            dispatch('thermix/productionSystemDefs/createCouplesSet', null, { root: true }).then(({ heatingIds, hotWaterIds }) => {
                commit('updateField', { path: `solutions[${solutionIndex}].heatingSystems`, value: heatingIds });
                commit('updateField', { path: `solutions[${solutionIndex}].hotWaterSystems`, value: hotWaterIds });
            });
        });
    },

    enableHeatingNetwork({ state, getters, rootState, commit }) {
        getters.solutionsSystemsDefs.forEach((def) => {
            const excludedChoices = def.excludedChoices.filter((choice) => choice !== 'heating_network');
            if (excludedChoices.length !== def.excludedChoices.length) {
                commit('thermix/productionSystemDefs/UPDATE', { defId: def.id, values: { excludedChoices } }, { root: true });
            }
        });
    },

    cleanSolutions({ commit, state, getters }) {
        const emptyIndexes = [];
        state.solutions.forEach((solution, index) => {
            const isValidSolution = getters.isValidSolution(solution);
            if (!isValidSolution) {
                emptyIndexes.push(index);
                return;
            }
            if (emptyIndexes.length && isValidSolution) {
                commit('SWAP_SOLUTIONS', { fromIndex: index, toIndex: emptyIndexes.pop() });
            }
        });
    },

    async deleteSolution({ dispatch, getters }, solutionIndex) {
        if (!getters.deletableSolutions[solutionIndex]) return;
        await dispatch('updateSolution', {
            solutionIndex,
            values: {
                heatingSystems: [{ identifier: '', share: 1 }, { identifier: '', share: 0 }],
                hotWaterSystems: [{ identifier: '', share: 1 }, { identifier: '', share: 0 }],
                renewableElectricity: false,
                financialSupport: false,
            },
        });
        await dispatch('cleanSolutions');
    },

    updateSolutions({ dispatch }, solutions) {
        for (let solutionIndex = 0; solutionIndex < solutions.length; solutionIndex++) {
            dispatch('updateSolution', { solutionIndex, values: solutions[solutionIndex] });
        }
    },

    updateSolution({ commit, state, rootGetters }, { solutionIndex, values }) {
        const solution = state.solutions[solutionIndex];

        ['heatingSystems', 'hotWaterSystems'].forEach((systemsType) => {
            if (!solution[systemsType] || !values[systemsType]) return;

            solution[systemsType].forEach((defId, systemIndex) => {
                const system = values[systemsType][systemIndex];
                if (system) {
                    // If not managing heating, we replace synced hot water with original system identifier
                    if (!rootGetters['thermix/needs/isManagingHeating'] && systemsType === 'hotWaterSystems' && system.identifier === 'synced-with-heating') {
                        system.identifier = values.heatingSystems[systemIndex].identifier;
                    }
                    commit('thermix/productionSystemDefs/UPDATE', { defId, values: system }, { root: true });
                } else {
                    commit('thermix/productionSystemDefs/UPDATE', { defId, values: { identifier: '' } }, { root: true });
                }
            });
        });

        if (values.renewableElectricity !== undefined) {
            commit('updateField', { path: `solutions[${solutionIndex}].renewableElectricity`, value: values.renewableElectricity });
            commit('updateField', { path: `solutions[${solutionIndex}].renewableElectricityProd`, value: values.renewableElectricityProd });
        }
        if (values.financialSupport !== undefined) {
            commit('updateField', { path: `solutions[${solutionIndex}].financialSupport`, value: values.financialSupport });
            commit('updateField', { path: `solutions[${solutionIndex}].financialSupportAmount`, value: values.financialSupportAmount });
        }
        commit('updateField', { path: `solutions[${solutionIndex}].isMetroGrenoble`, value: values.isMetroGrenoble });
    },

};
