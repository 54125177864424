const api = {
    findAppointment(query) {
        let url = '/dialogwatt/find-appointment/';
        if (query) {
            url += '?';
            for (const item in query) {
                const sep = (url.substring(url.length - 1) !== '?') ? '&' : '';
                url += sep + item + '=' + query[item];
            }
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
