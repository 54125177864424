import defaultState from './_defaultState';
// import { updateField } from 'vuex-map-fields';
import { make } from 'vuex-pathify';

export default {
    RESET_STATE(state) {
        Object.assign(state, defaultState);
    },
    ...make.mutations(defaultState),

    // updateField,
};
