import { store } from '@/store/store';

const api = {
    authenticate(email, password) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/auth/obtain_token/', {
                    email: email,
                    password: password,
                })
                .then(response => {
                    store.dispatch('resetStore');
                    store.commit('updateToken', response.data.token);
                    this.getProfileAndConsent()
                        .then(response => {
                            resolve(response);
                        })
                        .catch(error => {
                            console.debug(error);
                            reject(error);
                        });
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },

    refreshToken(token) {
        let tokenToUse = token;
        if (token == null) {
            tokenToUse = store.state.jwt;
        }
        this.apiClient()
            .post('/auth/refresh_token/', {
                token: tokenToUse,
            })
            .then(response => {
                store.commit('updateToken', response.data.token);
            })
            .catch(error => {
                console.debug(error);
                store.commit('removeToken');
                this.$router.push('AccountLogin');
            });
    },

    refreshTokenWithPromise(token) {
        let tokenToUse = token;
        if (token == null) {
            tokenToUse = store.state.jwt;
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/auth/refresh_token/', {
                    token: tokenToUse,
                })
                .then(response => {
                    store.commit('updateToken', response.data.token);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    logout() {
        store.commit('removeToken');
    },
};

export default api;
