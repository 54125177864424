<template>
<div>
  
  <div class="first-plan" v-if="dataAvailable">
    
    <div class="breadcrumbs first-plan">
      <!-- PDF -->
      <div  @blur="pdfDropDown=false;" class="bt-pdf">
        <div>
          <spinner :active="downloadPdfInProgress" />
          <a @click="downloadIfNoSelectionElseShowMenu();" class="bc-pdf bc-title">Télécharger en pdf<font-awesome-icon icon="chevron-circle-down"/></a>
          <div v-show="pdfDropDown" class="bc-dropdown bc-dropdown-pdf pdf-menu">
            <a @click="pdfDropDown=false;downloadType='all';showModalComment();" class="bc-dropdown-element">Tous les professionnels</a>
            <a v-if="favoritePro && favoritePro.length" @click="pdfDropDown=false;downloadType='selection';showModalComment();" class="bc-dropdown-element">La sélection uniquement</a>
          </div>
        </div>
      </div>
      
      <div class="bt-menu">      
        <ul>
          <li><a @click="goHome" title="Modifier la recherche"><font-awesome-icon icon="home"/></a></li>
          
          <!-- Activities -->
          <li tabindex=0 @blur="activityDropDown=false">
            <a @click="activityDropDown=!activityDropDown" class="bc-title">{{ activity.name }}<font-awesome-icon icon="chevron-circle-down"/></a>
            <div v-show="activityDropDown" class="bc-dropdown">
              <a :key=item.id v-for="item in activities" class="bc-dropdown-element" @click="changeSelection(item.id, 1);activityDropDown=false">{{item.name}}</a>
            </div>
          </li>
          
          <!-- Missions -->
          <li tabindex=0 @blur="missionDropDown=false">
            <a @click="missionDropDown=!missionDropDown" class="bc-title">{{ mission.name }}<font-awesome-icon icon="chevron-circle-down"/></a>
            <div v-show="missionDropDown" class="bc-dropdown">
              <a :key=item.id v-for="item in missions" class="bc-dropdown-element" @click="changeSelection(item.id, 2);missionDropDown=false">{{item.name}}</a>
            </div>
          </li>
          
          <!-- SubMissions -->      
          <li tabindex=0 @blur="subMissionDropDown=false">
            <a @click="subMissionDropDown=!subMissionDropDown" class="bc-title">{{ subMission.name }}<font-awesome-icon icon="chevron-circle-down"/></a>
            <div v-show="subMissionDropDown" class="bc-dropdown">
              <a :key=item.id v-for="item in subMissions" class="bc-dropdown-element" @click="changeSelection(item.id, 3);subMissionDropDown=false">{{item.name}}</a>
            </div>
          </li>
          
        </ul>
      </div> 
    </div> 
  </div> 
  
  <div class="content second-plan">
    <spinner :active="professionalsLoading || searchTreeLoading" />
    
    
    <div class="content result-container">
      
      
      <div class="result-cards" v-show="!fullscreenMap">
        
        <div class="go-home-button">
          <a @click="goHome">
            <font-awesome-icon icon="search" />
            Modifier la recherche
          </a>
        </div>
        
        <liste-results
          :professionals="professionals"
          @locate-professional="setFocusOnProfessional"
          />
      </div>
      <div :class="{ 'result-map': !fullscreenMap, 'result-map-fullscreen': fullscreenMap }">
        <liste-results-map
          class="map-object"
          :focusOnProfessional="focusOnProfessional"
          :professionals="professionals"
          @resize-map="resizeMap"
          />
        
      </div>
    </div>
    
  </div>
  
  <DialogWrapper
    ref="modalHelpResult"
  >
    <div> 
      <help-search
        @hide-modal="hideModalHelpResult"
        >
      </help-search>
    </div>
  </DialogWrapper>
  
  <DialogWrapper
    ref="modalComment"
    :adaptive="false"
    :width="1000"
    height="auto"
    clickToClose
    :scrollable="true"
    >   
    <div> 
      <modal-comment
        @hide-modal="hideModalComment"
        @start-download="startDownload"
        >
      </modal-comment>
    </div>
  </DialogWrapper>
  
</div>
</template>

<script>
import apiClient from '@/api/api';

import Spinner from '@/components/spinner';

import HelpSearch from '@/apps/listepro/modal_help/help_search';
import ListeResults from '@/apps/listepro/public/search/liste_results';
import ListeResultsMap from '@/apps/listepro/public/search/liste_results_map';
import ModalComment from '@/apps/listepro/public/search/modal_comment';
import { downloadPdf } from "@/helpers/pdf";
import { searchMixin } from '@/apps/listepro/public/search/search_mixin';

import { sync } from 'vuex-pathify';

export default {
    name: 'Results',
    mixins: [ searchMixin ],
    components: {
        HelpSearch,
        ListeResults,
        ListeResultsMap,
        ModalComment,
        Spinner,
    },
    props: {
        givenSearchTree: { default: () => [], type: Array },
        givenActivity: { default: null, type: [Number, String] },
        givenMission: { default: null, type: [Number, String] },
        givenSubMission: { default: null, type: [Number, String] },
    },
    data() {
        return {
            searchTree: [],
            selectedActivity: null,
            selectedMission: null,
            selectedSubMission: null,
            professionalsLoading: false,
            professionals: [],
            to:"",
            activityDropDown: false,
            missionDropDown: false,
            subMissionDropDown: false,
            pdfDropDown: false,
            downloadType:"",
            focusOnProfessional: null,
            fullscreenMap: false,
            downloadPdfInProgress: false,
        };
    },
    provide() {
        return { parentValidator: this.$validator };
    },
    computed: {
        favoritePro: sync("listepro/favoritePro"),
        downloadAll: sync("listepro/downloadAll"),
        commentPdf: sync("listepro/commentPdf"),
        whiteLabelling: sync("whiteLabelling"),
        dataAvailable() {
            return (
                this.searchTree.length > 0
                    && this.selectedActivity !== null
                    && this.selectedMission !== null
                    && this.selectedSubMission !== null
            );
        },
    },
    watch: {
        matchingProfessionalValidationWorkflows: {
            handler: function() {
                if (this.dataAvailable) {
                    this.changeResults();
                }
            },
            immediate: true,
            deep: true,
        },
        givenActivity() {
            this.updateSearchParams();
        },
        givenMissions() {
            this.updateSearchParams();
        },
        givenSubMissions() {
            this.updateSearchParams();
        },
    },
    mounted: function() {
        this.loadSearchTreeIfEmpty();
        this.updateSearchParams();
    },
    methods: {
        updateSearchParams() {
            this.selectedActivity = parseInt(this.givenActivity);
            this.selectedMission = parseInt(this.givenMission);
            this.selectedSubMission = parseInt(this.givenSubMission);
        },
        loadSearchTreeIfEmpty() {
            if (this.givenSearchTree.length === 0) {
                this.loadSearchTree();
            } else {
                this.searchTree = this.givenSearchTree;
            }
            
        },
        goHome() {
            this.$router.push({ name: 'ListeProPublicSearch' });
        },
        changeSelection(choice, step) {
            this.favoritePro = [];
            this.setSelection(choice, step, true);
        },
        changeResults() {
            if (
                this.selectedActivity !== parseInt(this.givenActivity)
                || this.selectedMission !== parseInt(this.givenMission)
                || this.selectedSubMission !== parseInt(this.givenSubMission)
            ) {
                this.$router.replace(
                    {
                        name: 'ListeProPublicShowResults',
                        params: {
                            givenActivity: this.selectedActivity,
                            givenMission: this.selectedMission,
                            givenSubMission: this.selectedSubMission,
                        },
                    }
                );
            } else {
                this.loadProfessionals();
            }
        },
        loadProfessionals() {
            this.professionalsLoading = true;
            apiClient.genericList(
                `/listepro/public/professional/`,
                {
                    pks: this.matchingProfessionalValidationWorkflows,
                })
                .then(response => {
                    this.professionals = response;
                })
                .catch(error => {
                    console.debug(error);
                    this.$notify({
                        group: 'root',
                        type: 'error',
                        title: 'Erreur serveur',
                        text: 'La liste des professionnels n\'a pas pu être chargée.',
                    });
                })
                .finally( () => {
                    this.professionalsLoading = false;
                });
            
        },

        resizeMap(fullscreen) {
            this.fullscreenMap = fullscreen;
        },
        
        setFocusOnProfessional(professionalPk) {
            this.focusOnProfessional = null;
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.focusOnProfessional = professionalPk;
                });
            });
        },

        showModalHelpResult() {
            this.$refs.modalHelpResult.show();
        },
        hideModalHelpResult() {
            this.$refs.modalHelpResult.hide();
        },
        showModalComment() {
            this.commentPdf="";
            this.$refs.modalComment.show();
        },
        hideModalComment() {
            this.$refs.modalComment.hide();
        },
        downloadPdf() {
            this.downloadPdfInProgress = true;

            const data = {
                mission: this.mission,
                subMission: this.subMission,
                activity: this.activity,
                favoritePro: [],
                commentPdf: this.commentPdf,
                downloadAll: this.downloadAll,
            };

            if (this.downloadAll === 'selection') {
                data.favoritePro = this.professionals.filter(x => this.favoritePro.includes(x.pk));
            } else {
                data.favoritePro = this.professionals;
            }

            apiClient
                .getListeProPdf({
                    data: data,
                    domain: this.whiteLabelling.domain,
                })
                .then(response => {
                    downloadPdf(response, "rapport-liste-professionnels.pdf");
                    this.downloadAll="nothing";
                    this.commentPdf="";
                })
                .catch(error => {
                    this.handleFormErrors(error);
                })
                .finally(() => {             
                    this.downloadPdfInProgress = false;
                });
        },
        startDownload() {
            this.hideModalComment();
            this.downloadAll=this.downloadType;
            this.downloadPdf();
        },
        downloadIfNoSelectionElseShowMenu() {
            console.log(this.favoritePro && this.favoritePro.length, this.favoritePro, this.favoritePro.length);
            if (this.favoritePro && this.favoritePro.length) {
                this.pdfDropDown = !this.pdfDropDown;
                this.downloadType = 'nothing';
            } else {
                this.downloadType = 'all';
                this.showModalComment();
            }
        },
    },
};

</script>

<style lang="stylus" scoped>
@require '../../../../stylesheet/variables'
@require '../../../../stylesheet/typography'
@require '../../../../stylesheet/listepro_font'

.result-container
    padding: 0 space-md 0 space-md
    // background-color: red !important
    display: grid
    grid-template-columns: minmax(365px, 780px) auto
    grid-gap: 20px

    .result-cards
        padding-top: 27px
        padding-bottom: space-xl
        grid-column: 1
        grid-row: 1

        // min-width: 362px
        // width: 362px
        .go-home-button
            padding-left: 10px
            margin-top: -26px
            // text-transform: uppercase
            // font-size: 0.6875rem
            // a
            // color: red

    .result-map
        height: 100%
        grid-column: 2
        grid-row: 1

        :deep(.liste-results-map)
            position: sticky
            top: 0

    .result-map-fullscreen
        height: 100%
        grid-column: 1 / 3
        grid-row: 1

    @media (max-width: 600px)
        .result-map
            display: none

.content
    background-color: gray-100
    padding-top: 0.5rem

.first-plan
    z-index: 1000

.second-plan
    z-index: 1

.breadcrumbs
    padding-bottom: 0.7rem
    padding-top: 0.7rem
    margin-bottom: 0rem
    background-color: gray-100
    position: relative
    border: 1px solid gray-300
    display: grid

    .bt-menu
        grid-row: 1
        grid-column: 1 -3
        min-width: 300px

        ul
            list-style-type: none

    .bt-pdf
        grid-row: 1
        grid-column: 4
        text-align: right
        margin-right: 2rem
        margin-top: -5px

    .bc-dropdown
        position: absolute

    .bc-dropdown.pdf-menu
        right: 0rem

.bc-title
    color: black

.bc-pdf
    text-transform: uppercase
    font-size: 0.6875rem

.bc-title:hover
    color: primary-color-500
    text-decoration: none

.bc-dropdown
    padding: 1px
    background: #fff
    border: 1px solid gray-500

.bc-dropdown-pdf
    width: 10rem

a.bc-dropdown-element
    display: block
    padding: 0.3rem 1rem 0.3rem 0.3rem
    text-transform: none
    font-size: 0.8rem

a.bc-dropdown-element:hover
    background-color: gray-200
    color: #000
    text-decoration: none

.bt-nav
    padding-top: 0.75rem
    padding-bottom: 0.75rem
    padding-left: 1rem
    padding-right: 1rem
    font-size: 1.1rem
    text-transform: uppercase
    background-color: primary-color-400
    border-radius: 0
    margin: 0.5rem 0.25rem 0.5rem 0.25rem

.active
    color: primary-color-500
    background-color: #fff
    margin-bottom: 0rem
    margin-top: 1rem

.li
    margin: 0rem

.nav-bar
    background-color: primary-color-500
    margin-bottom: 1rem

.subMission
    margin: 0rem

.choices
    text-align: center

.center
    align: center
    justify-content: center
    text-align: center
    float: center
    display: center

.inline
    display: inline-block

.button-choice
    border-radius: 0.2rem
    border: 0.2rem solid primary-color-500
    margin-left: 0.5rem
    margin-right: 0.5rem
    color: #fff

.button-white
    background-color: #fff
    border: 0.2rem solid primary-color-500
    color: primary-color-500

.bt-help
    margin: 0.75rem

.help
    color: primary-color-200
    font-size: 2rem
    vertical-align: middle

.help:hover
    color: #fff

.map-cell
    height: 100%

    .map-object
        position: sticky
        top: 0
</style>
