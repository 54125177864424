<template>
  <div class="ecorenover">
    <iframe
      src="https://mixeur.ecorenover.org/"
      title="Écorénover"
      width="100%"
      height="800px"
      style="overflow-y:visible;"
    ></iframe>
  </div>
</template>

<script>
export default {
    name: 'EcorenoverIframe',
    data: function() {
        return {};
    },
};
</script>

<style lang="stylus" scoped>
iframe
    border: none
</style>
