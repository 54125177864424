<template>
  <div class="map-field" lazy>
    <spinner :active="isLoading" />
    <l-map
      ref="map"
      :zoom="localZoom"
      :bounds="bounds"
      :center="center"
      :options="options"
      @update:center="updatedCenter"
      @update:bounds="updatedBounds"
      @update:zoom="updatedZoom"
      :height="height"
      width="100%"
      :key="key"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <v-marker-cluster :options="clusterOptions">
        <l-marker
          v-for="m in markers"
          v-bind:key="m.id"
          :lat-lng="m.latlng"
          :icon="customMarker ? customMarker(m) : markerIcons[m.icon]"
          :visible="m.visible"
          @click="markerSelected(m.id);"
        >
          <l-popup v-if="m.popup" :content="m.popup" />
          <l-tooltip v-if="m.tooltip" :content="m.tooltip" />
        </l-marker>
      </v-marker-cluster>
      <l-rectangle
        v-for="r in rectangles"
        v-bind:key="r.id"
        :bounds="r.bounds"
        :l-style="r.style"
        :visible="r.visible"
        @click="rectangleSelected(r.id);"
      >
        <l-popup v-if="r.popup" :content="r.popup" />
        <l-tooltip v-if="r.tooltip" :content="r.tooltip" />
      </l-rectangle>
      <l-polygon
        v-for="p in polygons"
        v-bind:key="p.id"
        :lat-lngs="p.latlngs"
        :l-style="p.style"
        :visible="p.visible"
        @click="polygonSelected(p.id);"
      >
        <l-popup v-if="p.popup" :content="p.popup" />
        <l-tooltip v-if="p.tooltip" :content="p.tooltip" />
      </l-polygon>
      <l-polyline
        v-for="p in polylines"
        v-bind:key="p.id"
        :lat-lngs="p.latlngs"
        :l-style="p.style"
        :visible="p.visible"
        @click="polylineSelected(p.id);"
      >
        <l-popup v-if="p.popup" :content="p.popup" />
        <l-tooltip v-if="p.tooltip" :content="p.tooltip" />
      </l-polyline>
    </l-map>
  </div>
</template>

<script>
import _ from 'lodash';
  
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LRectangle, LPolygon, LPolyline } from 'vue2-leaflet';
import L from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw/dist/images/layers-2x.png';
import 'leaflet-draw/dist/images/layers.png';
import 'leaflet-draw/dist/images/marker-icon-2x.png';
import 'leaflet-draw/dist/images/marker-icon.png';
import 'leaflet-draw/dist/images/marker-shadow.png';
import 'leaflet-draw/dist/images/spritesheet-2x.png';
import 'leaflet-draw/dist/images/spritesheet.png';
import 'leaflet-draw/dist/images/spritesheet.svg';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.min';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

import Spinner from '@/components/spinner';


export default {
    name: 'MapField',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LTooltip,
        LRectangle,
        LPolygon,
        LPolyline,
        Spinner,
        'v-marker-cluster': Vue2LeafletMarkerCluster,
    },
    props: {
        isLoading: { default: false, type: Boolean },
        fitMarkers: { default: false, type: Boolean },
        lat: { default: 0, type: Number },
        lon: { default: 0, type: Number },
        zoom: { default: 10, type: Number },
        height: { default: '300px', type: String },
        markers: { default: () => [], type: Array },
        rectangles: { default: () => [], type: Array },
        polygons: { default: () => [], type: Array },
        polylines: { default: () => [], type: Array },
        options: { default: () => {}, type: Object },
        drawAllow: { default: false, type: Boolean },
        fullScreenButton: { default: true, type: Boolean },
        drawControlPosition: { default: 'topright', type: String },
        drawPolyline: { default: true, type: Boolean },
        drawPolygon: { default: true, type: Boolean },
        drawRectangle: { default: true, type: Boolean },
        drawCircle: { default: true, type: Boolean },
        drawCircleMarker: { default: true, type: Boolean },
        drawMarker: { default: true, type: Boolean },
        clusterOptions: { default: () => {}, type: Object },
        enableZoomOnScroll: { default: false, type: Boolean },
        customMarker: {default: null, type: Function},
    },
    data() {
        return {
            localLat: 0,
            localLon: 0,
            localZoom: 10,
            url: 'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            key: 0,
        };
    },
    watch: {
        lat: function() {
            this.localLat = this.lat;
        },
        lon: function() {
            this.localLon = this.lon;
        },
        zoom: function() {
            this.localZoom = this.zoom;
            this.$refs.map.setZoom(this.zoom);
        },
        localLat() {
            this.changeCenter();
        },
        localLon() {
            this.changeCenter();
        },
    },
    computed: {
        bounds() {
            if (this.markers?.length > 0 && this.fitMarkers) {
                return new L.LatLngBounds(this.markers.map(marker => marker.latlng));
            }
            return null;
        },
        center: function() {
            return L.latLng(this.localLat, this.localLon);
        },
        markerIcons: function() {
            return {
                blue: new L.Icon({
                    iconUrl: '/static/img/marker-icon-2x-blue.png',
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                }),

                bike: new L.Icon({
                    iconUrl: '/static/img/marker-icon-2x-bike.png',
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                }),

                parking: new L.Icon({
                    iconUrl: '/static/img/marker-icon-2x-parking.png',
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                }),

                purple: new L.Icon({
                    iconUrl: '/static/img/marker-icon-2x-purple.png',
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                }),
                blackflag: new L.Icon({
                    iconUrl: '/static/img/marker-black-flag.png',
                    iconSize: [41, 41],
                    iconAnchor: [14, 36],
                    popupAnchor: [1, -34],
                }),
                circle: new L.Icon({
                    iconUrl: '/static/img/marker-icon-2x-circle-purple.png',
                    iconSize: [12, 12],
                    iconAnchor: [6, 6],
                    popupAnchor: [1, 1],
                }),
                pin: new L.Icon({
                    iconUrl: '/static/img/marker-pin-orange.png',
                    iconSize: [24, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                }),
                green: new L.Icon({
                    iconUrl: '/static/img/marker-icon-2x-green.png',
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],

                    // shadowUrl: 'static/img/marker-icon-2x-green.pngleaf-shadow.png',
                    // shadowSize:   [50, 64], // size of the shadow
                    // shadowAnchor: [4, 62],  // the same for the shadow
                }),
            }; 
        },
    },
    created: function() {
        this.localZoom = this.zoom;
        this.localLat = this.lat;
        this.localLon = this.lon;
    },
    mounted: function() {
        // Fix bogus display
        // https://github.com/KoRiGaN/Vue2Leaflet/issues/96#issuecomment-341453050
        this.localZoom = this.zoom;
        this.localLat = this.lat;
        this.localLon = this.lon;

        if (!this.enableZoomOnScroll) {
            this.$refs.map.mapObject.scrollWheelZoom.disable();
        }

        setTimeout(function() {
            window.dispatchEvent(new Event('resize'));
        }, 5000);

        if (this.drawAllow === true) {
            this.$nextTick(() => {
                const map = this.$refs.map.mapObject;
                const drawControl = new L.Control.Draw({
                    position: this.drawControlPosition,
                    draw: {
                        polyline: this.drawPolyline,
                        polygon: this.drawPolygon,
                        rectangle: this.drawRectangle,
                        circle: this.drawCircle,
                        circlemarker: this.drawCircleMarker,
                        marker: this.drawMarker,
                    },
                });
                map.addControl(drawControl);

                map.on(L.Draw.Event.CREATED, e => {
                    this.$emit(`draw-${e.layerType}`, e.layer);
                    // this.addLayerToMap(e.layer);
                });
            });
        }
        if (this.fullScreenButton === true) {
            this.$nextTick(() => {
                const map = this.$refs.map.mapObject;
                map.addControl(new L.Control.Fullscreen({
                    title: {
                        'false': 'Plein écran',
                        'true': 'Sortir du plein écran',
                    },
                }));
            });
        }
    },
    methods: {
        refreshMap() {
            this.key = this.key + 1;
        },
        updateSize() {
            this.$refs.map.mapObject.invalidateSize();
        },
        changeCenter: _.debounce(function(params) {
            this.$refs.map.setCenter({lat:this.lat, lon:this.lon});
        }, 200),
        
        addLayerToMap: function(layer) {
            const map = this.$refs.map.mapObject;
            const editableLayers = new L.FeatureGroup().addTo(map);
            editableLayers.addLayer(layer);
        },
        updatedZoom: function(factor) {
            this.$emit('zoom-updated', factor);
        },
        updatedCenter: function(position) {
            this.$emit('center-updated', position);
        },
        updatedBounds: function(bounds) {
            this.$emit('bounds-updated', bounds);
        },
        setValue: function(lat, lon, zoom = null) {
            this.setCenter(lat, lon);
            if (zoom) {
                this.setZoom(zoom);
            }
        },
        setCenter: function(lat, lon) {
            this.localLat = lat;
            this.localLon = lon;
        },
        setZoom: function(zoom) {
            this.localZoom = zoom;
        },
        markerSelected: function(item) {
            this.$emit('map_marker_selected', item);
        },
        rectangleSelected: function(item) {
            this.$emit('map_rectangle_selected', item);
        },
        polylineSelected: function(item) {
            this.$emit('map_polyline_selected', item);
        },
        polygonSelected: function(item) {
            this.$emit('map_polygon_selected', item);
        },
    },
};
</script>

<style scoped>
.map-field {
    height: 100%;
    width: 100%;
}
</style>
