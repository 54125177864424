<template>
  <div class="accordion-item" :class="{ 'with-padding': !narrow }">
    <div class="grid-x" :class="{ title: !narrow, 'align-middle': !alignTop, 'align-top': alignTop }" v-on:click="titleClicked">
      <div class="cell" :class="{ auto: !narrow, shrink: narrow }">
        <slot name="title" />
      </div>
      <div class="icon cell shrink" :class="{rotate: localOpened}">
        <font-awesome-icon :icon="['far', 'angle-down']" />
      </div>
    </div>
    <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
      <div class="content" :class="{ccclosed: !localOpened}" ref="content" v-if="localOpened">
        <div>
          <slot name="content" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
    name: 'AccordionItem',
    components: {},
    props: {
        alignTop: { default: false, type:Boolean },
        height: { default: '0', type: String },
        lineHeight: { default: 28, type: Number },
        opened: { default: false, type: Boolean },
        narrow: { default: false, type: Boolean },
    },
    data() {
        return {
            localOpened: false,
        };
    },
    computed: {
        isOpened: function() {
            return this.localOpened;
        },
    },
    watch: {
        opened: function() {
            this.localOpened = this.opened;
        },
    },
    created: function() {},
    mounted: function() {
        this.localOpened = this.opened;
    },
    methods: {
        titleClicked: function() {
            this.localOpened = !this.localOpened;
            this.$emit('change', this.localOpened);
        },
        open: function() {
            this.localOpened = true;
            this.$emit('change', this.localOpened);
        },
        openWithoutSignal: function() {
            this.localOpened = true;
        },
        close: function() {
            this.localOpened = false;
            this.$emit('change', this.localOpened);
        },
        closeWithoutSignal: function() {
            this.localOpened = false;
        },
        enter: function(element) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const height = getComputedStyle(element).height;

            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height; // eslint-disable-line no-unused-expressions

            // Trigger the animation.
            // We use `setTimeout` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            setTimeout(() => {
                element.style.height = height;
            });
        },
        afterEnter: function(element) {
            element.style.height = 'auto';
        },
        leave: function(element) {
            const height = getComputedStyle(element).height;

            element.style.height = height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height; // eslint-disable-line no-unused-expressions

            setTimeout(() => {
                element.style.height = 0;
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
.with-padding
    padding-left: 0.5rem
    padding-right: 0.5rem

.icon
    float: right
    transition: all 0.3s ease 0s

.icon.rotate
    transform: rotate(180deg)

*
    will-change: height
    backface-visibility: hidden

.expand-enter-active
.expand-leave-active
    transition-property: all
    transition-duration: 0.7s
    transition-timing-function: cubic-bezier(0.2, 1, 0.5, 1)
    overflow: hidden

.expand-enter
.expand-leave-to
    height: 0
</style>
