import apiClient from '@/api/api';
import _ from 'lodash';
import { initStoreWatchers, unwatchAll } from '@/apps/thermix/store/watchers';
import demos from './_demos';

const actions = {
    reset({ state, getters, dispatch }) {
        dispatch('productionSystemDefs/reset');
        dispatch('needsEstimation/reset');
        dispatch('solutions/reset');
    },

    init({ dispatch, commit, rootState }, { metroGrenoble, area, inseeCode, demo }) {
        commit('updateField', { path: 'initialDataLoaded', value: false });

        initStoreWatchers(this);

        Promise.all([
            dispatch('reset'),
            dispatch('energies/loadEnergies', null, { root: true }),
            dispatch('energies/loadProductionSystems', null, { root: true }),
            dispatch('energies/loadBuildingHeatingConsumptions', null, { root: true }),
            dispatch('energies/loadEnergyVectors', null, { root: true }),
            dispatch('energies/loadSecondaryEfficiencies', null, { root: true }),
        ]).then(() => {
            commit('updateField', { path: 'initialDataLoaded', value: true });

            if (demo) {
                dispatch('loadDemo', demo);
                return;
            }

            const isMetro = metroGrenoble || rootState?.whiteLabelling?.domain?.endsWith('metro-grenoble.thermix.org');

            if (isMetro) {
                dispatch('metroGrenoble/enableMetroGrenobleMode', {
                    area: area,
                    inseeCode: inseeCode,
                });
            }
        });
    },

    terminate() {
        unwatchAll(this);
    },

    computeResults({ state, getters, commit }) {
        const data = Object.assign({}, _.omit(state, [
            'results',
            'isDirty',
            'solutions',
        ]), { solutions: getters['solutions/forComputation'] });
        return new Promise(function(resolve, reject) {
            apiClient
                .computeResults(data)
                .then(response => {
                    commit('results/SET_RESULTS', response);
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    loadDemo({ dispatch, commit }, demoNum) {
        const demo = demos[demoNum];
        if (demo) {
            commit('needs/UPDATE', demo.needs);
            dispatch('solutions/updateSolutions', demo.solutions);
        }
    },

    setUserEnergyPrice({ state, dispatch, commit }, value) {
        const newValue = Object.assign(
            { group: null, identifier: null, price: null, priceVariation: null },
            (state.userEnergyPrices[value.group] || {})[value.identifier] || {},
            value || {}
        );
        if (state.userEnergyPrices[value.group] && state.userEnergyPrices[value.group][value.identifier] && _.isEqual(state.userEnergyPrices[value.group][value.identifier], newValue)) return;
        commit('SET_USER_ENERGY_PRICE', newValue);
        dispatch('productionSystemDefs/syncUserEnergyPriceForEnergyIdentifier', { group: newValue.group, energyIdentifier: newValue.identifier });
    },

    resetUserEnergyPrice({ commit, dispatch }, { group, identifier, greenIdentifier }) {
        commit('RESET_USER_ENERGY_PRICE', { group, identifier });
        if (greenIdentifier) commit('RESET_USER_ENERGY_PRICE', { group, identifier: greenIdentifier });
        dispatch('productionSystemDefs/syncUserEnergyPriceForEnergyIdentifier', { group: group, energyIdentifier: identifier, greenEnergyIdentifier: greenIdentifier });
        if (greenIdentifier) dispatch('productionSystemDefs/syncUserEnergyPriceForEnergyIdentifier', { group: group, energyIdentifier: greenIdentifier });
    },

    getPdfReport({ state, rootState }) {
        const data = state.results;
        return new Promise(function(resolve, reject) {
            apiClient
                .getPdfReport({
                    data: data,
                    domain: rootState.whiteLabelling.domain,
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    getPdfTempStore({ commit }, uuid) {
        return new Promise(function(resolve, reject) {
            apiClient
                .getPdfTempStore(uuid)
                .then(response => {
                    const data = response.data;
                    commit('results/SET_RESULTS', data);
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export default actions;
