import { addWatcher } from '../watchers';
import { store } from '@/store/store';

export default function initProductionSystemDefWatchers(defId) {
    // When identifier change we reset data values
    // If combined solar system we force it in synced def
    addWatcher(
        (state, getter) => state.thermix.productionSystemDefs.defs[defId].identifier,
        identifier => {
            if (identifier) store.dispatch('thermix/productionSystemDefs/resetSystemInitialValues', defId);
            const def = store.state.thermix.productionSystemDefs.defs[defId];
            if (identifier === 'solar_system_combined') {
                if (def.syncedHotWaterId) {
                    store.commit('updateField', {
                        path: `thermix.productionSystemDefs.defs[${def.syncedHotWaterId}].identifier`,
                        value: 'synced-with-heating',
                    });
                    return;
                }
                if (def.syncedId) {
                    store.commit('updateField', {
                        path: `thermix.productionSystemDefs.defs[${def.syncedId}].identifier`,
                        value: 'solar_system_combined',
                    });
                    store.commit('updateField', {
                        path: `thermix.productionSystemDefs.defs[${defId}].identifier`,
                        value: 'synced-with-heating',
                    });
                }
            } else if (identifier === 'heat_pump_hybrid') {
                if (!store.state.thermix.productionSystemDefs.defs[def.syncedHotWaterId].identifier) {
                    store.commit('updateField', {
                        path: `thermix.productionSystemDefs.defs[${def.syncedHotWaterId}].identifier`,
                        value: 'synced-with-heating',
                    });
                }

                const siblingId = def.firstSiblingId || def.secondSiblingId;
                if (!store.state.thermix.productionSystemDefs.defs[siblingId].identifier) {
                    store.commit('updateField', {
                        path: `thermix.productionSystemDefs.defs[${siblingId}].identifier`,
                        value: 'gaz_boiler_condensing',
                    });
                    const siblingDef = store.state.thermix.productionSystemDefs.defs[siblingId];
                    store.commit('updateField', {
                        path: `thermix.productionSystemDefs.defs[${siblingDef.syncedHotWaterId}].identifier`,
                        value: 'synced-with-heating',
                    });
                }
                return;
            } else if (identifier === 'heating_network') {
                if (def.syncedId) {
                    store.commit('updateField', {
                        path: `thermix.productionSystemDefs.defs[${def.syncedId}].identifier`,
                        value: 'heating_network',
                    });
                    store.commit('updateField', {
                        path: `thermix.productionSystemDefs.defs[${defId}].identifier`,
                        value: 'synced-with-heating',
                    });
                }
            }

            const siblingId = def.firstSiblingId || def.secondSiblingId;
            if (!siblingId) return;

            if (identifier === 'heat_pump_hybrid') {
                store.commit('updateField', { path: `thermix.productionSystemDefs.defs[${siblingId}].investment`, value: 0 });
                store.commit('updateField', { path: `thermix.productionSystemDefs.defs[${siblingId}].maintenance`, value: 0 });
                return;
            }

            const siblingDef = store.state.thermix.productionSystemDefs.defs[siblingId];
            if (siblingDef.identifier === 'heat_pump_hybrid') {
                store.commit('updateField', { path: `thermix.productionSystemDefs.defs[${defId}].investment`, value: 0 });
                store.commit('updateField', { path: `thermix.productionSystemDefs.defs[${defId}].maintenance`, value: 0 });
            }
        }
    );

    // when synced identifier change we reset data values and synced def data values.
    addWatcher(
        (state, getter) => {
            const syncedId = state.thermix.productionSystemDefs.defs[defId].syncedId;
            return syncedId ? state.thermix.productionSystemDefs.defs[syncedId].identifier : '';
        },
        syncedIdentifier => {
            const def = store.state.thermix.productionSystemDefs.defs[defId];
            if (def.identifier === 'synced-with-heating') {
                store.dispatch('thermix/productionSystemDefs/resetSystemInitialValues', defId);
            }
        },
    );

    // Specific watcher for metro Grenoble "forcedForNonWoodHeating" cases.
    // When none of synced system or sibling of synced system is wood powered, we force solar_water_heater
    addWatcher(
        (state, getter) => {
            const def = store.state.thermix.productionSystemDefs.defs[defId];
            if (!store.state.thermix.metroGrenoble.enabled || !def.forcedForNonWoodHeating) return null;

            if (!def.syncedId) return null;
            const syncedIdentifier = state.thermix.productionSystemDefs.defs[def.syncedId].identifier;

            const syncedSiblingId = state.thermix.productionSystemDefs.defs[def.syncedId].firstSiblingId;
            if (!syncedSiblingId) return null;
            const syncedSiblingIdentifier = state.thermix.productionSystemDefs.defs[syncedSiblingId].identifier;
            return `${syncedIdentifier}#${syncedSiblingIdentifier}`;
        },
        identifiersStr => {
            if (!identifiersStr) return;
            const identifiers = identifiersStr.split('#');

            const hasWoodHeating = identifiers.some(identifier => {
                if (!identifier) return false;
                const prodSystem = store.state.energies.productionSystemCollectionByIdentifier[identifier];
                return ['bag_granules', 'bulk_granules', 'shredded_wood', 'wood'].includes(prodSystem.energyIdentifier);
            });

            if (hasWoodHeating) {
                store.commit('updateField', {
                    path: `thermix.productionSystemDefs.defs[${defId}].locked`,
                    value: false,
                });
            } else {
                store.commit('updateField', {
                    path: `thermix.productionSystemDefs.defs[${defId}].identifier`,
                    value: 'solar_water_heater',
                });
                store.commit('updateField', {
                    path: `thermix.productionSystemDefs.defs[${defId}].locked`,
                    value: true,
                });
            }
        },
    );

    // Change solar panel surface for synced def
    addWatcher(
        (state, getter) => state.thermix.productionSystemDefs.defs[defId].solarPanelsSurface,
        solarPanelsSurface => {
            const def = store.state.thermix.productionSystemDefs.defs[defId];
            if (def.identifier.startsWith('solar') && def.syncedHotWaterId) {
                store.commit('updateField', {
                    path: `thermix.productionSystemDefs.defs[${def.syncedHotWaterId}].solarPanelsSurface`,
                    value: solarPanelsSurface,
                });
            }
        },
    );

    // Change green energy values from synced def
    addWatcher(
        (state, getter) => {
            const syncedId = state.thermix.productionSystemDefs.defs[defId].syncedId;
            const syncedDef = state.thermix.productionSystemDefs.defs[syncedId];
            return syncedId ? [syncedDef.identifier, syncedDef.useGreenEnergy, syncedDef.greenEnergyShare] : [''];
        },
        ([syncedIdentifier, syncedUseGreenEnergy, syncedGreenEnergyShare]) => {
            const def = store.state.thermix.productionSystemDefs.defs[defId];
            if (def.identifier === 'synced-with-heating') {
                store.commit('updateField', {
                    path: `thermix.productionSystemDefs.defs[${defId}].useGreenEnergy`,
                    value: syncedUseGreenEnergy,
                });
                store.commit('updateField', {
                    path: `thermix.productionSystemDefs.defs[${defId}].greenEnergyShare`,
                    value: syncedGreenEnergyShare,
                });
            }
        },
    );
}
