const SmtpAccountEdit = () => import(/* webpackChunkName: "messaging" */ '@/apps/messaging/smtp_account/smtp_account_edit');
const SmsAccountEdit = () => import(/* webpackChunkName: "messaging" */ '@/apps/messaging/sms_account/sms_account_edit');

var routes = [
    {
        path: '/courriel/gestion-de-compte',
        name: 'SmtpAccountEdit',
        component: SmtpAccountEdit,
        meta: {
            requiresAuth: true,
            requiresUserType: ['superadvisor'],
        },
    },
    {
        path: '/sms/gestion-de-compte',
        name: 'SmsAccountEdit',
        component: SmsAccountEdit,
        meta: {
            requiresAuth: true,
            requiresUserType: ['superadvisor'],
        },
    },
];

export default routes;
