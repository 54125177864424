import { mapFields } from 'vuex-map-fields';

export default {
    // props: {
    //     clientUser: {
    //         type: Object,
    //         required: false,
    //     },
    //
    // },

    computed: {
        ...mapFields(['user']),
    },
    watch: {
    },
};
