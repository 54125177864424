import _ from 'lodash';

function pad(i) {
    return i < 10 ? `0${i}` : `${i}`;
}

export const utils = {
    getDomainFromUrl: function() {
        // Return domain name from URL
        return window.location.hostname;
    },
    getProtocolFromUrl: function() {
        // Return protocol from URL (http:)
        return window.location.protocol;
    },
    sleep: function(ms) {
        // Sleep given ms before returning
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    getCookie: function(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === name + '=') {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    },
    arrayRemove: function(arr, value) {
        return arr.filter(function(ele) {
            return ele !== value;
        });
    },
    convertHexColorToRGB: function(hex) {
        // convertHexColorToRGB("#0033ff") => [0, 51, 255]
        // convertHexColorToRGB("#03f") => [0, 51, 255]
        return hex
            .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1)
            .match(/.{2}/g)
            .map(x => parseInt(x, 16));
    },
    deepCopy: function(object) {
        return _.cloneDeep(object);
    },
    deepCopyAndSnakeCase: function(object) {
        console.error('Deprecated ! remove deepCopyAndSnakeCase...');
        const _this = this; // eslint-disable-line consistent-this
        const newObject = {};
        if (object !== undefined) {
            if (object === null) {
                return null;
            }
            if (object instanceof Date) {
                return object;
            }
            if (typeof object !== 'object') {
                return object;
            }
            if (Array.isArray(object)) {
                return object.map(function(entry) {
                    return _this.deepCopyAndSnakeCase(entry);
                });
            }
            for (const prop in object) {
                newObject[_.snakeCase(prop)] = this.deepCopyAndSnakeCase(object[prop]);
            }
        }
        return newObject;
    },
    deepCopyAndCamelCase: function(object) {
        console.error('Deprecated ! remove deepCopyAndCamelCase...');
        const _this = this; // eslint-disable-line consistent-this
        const newObject = {};
        if (object !== undefined) {
            if (object === null) {
                return null;
            }
            if (object instanceof Date) {
                return object;
            }
            if (typeof object !== 'object') {
                return object;
            }
            if (Array.isArray(object)) {
                return object.map(function(entry) {
                    return _this.deepCopyAndCamelCase(entry);
                });
            }
            for (const prop in object) {
                newObject[_.camelCase(prop)] = this.deepCopyAndCamelCase(object[prop]);
            }
        }
        return newObject;
    },
    setSelectsFromSingleValue: function(object, lists, root) {
        /*
          used to populate multi-selects

          this.editedSlot = utils.setSelectsFromSingleValue(
              this.editedSlot,
              {
                  'catchmentArea': 'catchmentAreasList',
                  'timeBetweenSlots': 'timeBetweenSlotsList',
                  'deadline': 'deadlineList',
                  'visibility': 'visibilityList',
                  'place': 'placesList',
              },
              this,
          );
         */
        const newObject = {};
        const fields = _.keys(lists);
        if (object !== undefined) {
            for (const prop in object) {
                if (fields.indexOf(prop) !== -1) {
                    const fullList = root[lists[prop]];
                    if (typeof object[prop] === 'object') {
                        const value = fullList.filter(y => _.map(object[prop], x => x).indexOf(y.value) !== -1);
                        if (value) {
                            newObject[prop] = value[0];
                        }
                    } else {
                        const value = _.find(fullList, x => x.value === object[prop]);
                        if (value) {
                            newObject[prop] = value;
                        }
                    }
                } else {
                    newObject[prop] = this.deepCopy(object[prop]);
                }
            }
        }
        return newObject;
    },
    setSelectsFromValues: function(object, lists, root) {
        /*
          used to populate single selects
         */
        const newObject = {};
        const fields = _.keys(lists);
        if (object !== undefined) {
            for (const prop in object) {
                if (fields.indexOf(prop) !== -1) {
                    const fullList = root[lists[prop]];
                    // console.log(typeof object[prop]);
                    if (typeof object[prop] === 'object') {
                        newObject[prop] = fullList.filter(y => _.map(object[prop], x => x).indexOf(y.value) !== -1);
                    } else {
                        newObject[prop] = _.find(fullList, x => x.value === object[prop]);
                    }
                } else {
                    newObject[prop] = this.deepCopy(object[prop]);
                }
            }
        }
        return newObject;
    },
    parseFormatDate: function(string) {
        /*
          override parseFormatDate which convert string to date object
          It's used a lot in coutosuix

          Sample with browser timezone to UTC-2

          2019-01-01                       ⇨ Date 2019-01-01T02:00:00.000Z (mydate.getDate = 1)
          2019-01-01 00:00:00              ⇨ Date 2019-01-01T02:00:00.000Z (mydate.getDate = 1)
          2018-12-31                       ⇨ Date 2018-12-31T02:00:00.000Z (mydate.getDate = 31)
          2018-12-31 23:00:00              ⇨ Date 2019-01-01T01:00:00.000Z (mydate.getDate = 31)
          2018-12-31T00:00:00.000Z         ⇨ Date 2018-12-31T00:00:00.000Z (take care of given timezone) (mydate.getDate = 31)
          2018-12-31T00:00:00.000-02:30    ⇨ Date 2018-12-31T02:30:00.000Z (take care of given timezone) (mydate.getDate = 31)

          Adding time at end force only-date string to be parsed with current browser timezone
          so that "2019-01-01" is always "2019-01-01" in current browser timezone
          without that new Date will interpret "2019-01-01" in UTC so "2019-31-12" in Brazil !

          Then use native JS string parser using new Date()
        */
        if (string && string.length === 10) string += 'T00:00:00';
        return new Date(string);
    },
    formatDate(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleDateString('fr-FR');
    },
    formatDateWithWeekDay(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleDateString('fr-FR', {
            weekday: 'short',
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        });
    },
    formatDateShortWeekDay(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleDateString('fr-FR', {
            weekday: 'short',
        });
    },
    formatDateTime(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleString('fr-FR');
    },
    formatIsoDate(date) {
        if (!date) { return ''; }
        return new Date(date).toISOString().slice(0, 10);
    },
    formatIsoDateTime(date) {
        if (!date) { return ''; }
        return new Date(date).toISOString().slice(0, 16).replace('T', ' ');
    },
    formatSchedulerDateTime(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }).replaceAll('/', '-');
    },
    formatSchedulerDate(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        }).replaceAll('/', '-');
    },
    formatDay(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'numeric',
        });
    },
    formatDayName(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleDateString('fr-FR', {
            weekday: 'long',
        });
    },
    formatMonth(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'numeric',
        });
    },
    formatMonthByName(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'long',
        });
    },
    formatDateAndHour(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }).replace(' ', ' à ');
    },
    formatHour(date) {
        if (!date) { return ''; }
        return new Date(date).toLocaleString('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
        });
    },

    todayForFilename() {
        return new Date().toISOString().replaceAll('-', '_');
    },

    changeDateHour: function(date, hours) {
        date.setHours(parseInt(hours.split(':')[0]));
        date.setMinutes(parseInt(hours.split(':')[1]));
        date.setSeconds(0);
        return date;
    },
    stripTimeFromDate(date) {
        return new Date(new Date(date).toISOString().split('T')[0]);
    },
    getMonday: function(d) {
        d = new Date(d);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(d.setDate(diff));
    },
    getSunday: function(d) {
        d = new Date(d);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1) + 6;
        return new Date(d.setDate(diff));
    },

    isSameDay(day1, day2) {
        if (!day1 || !day2) { return false; }
        return day1.toLocaleDateString() === day2.toLocaleDateString();
    },

    isSameToMinute(date1, date2) {
        const format = date => date.toISOString().slice(0, 16);
        return format(date1) === format(date2);
    },

    isBetween(date, start, end) {
        return date - start >= 0 && date - end <= 0;
    },

    generateId: function() {
        const array = new Uint32Array(8);
        window.crypto.getRandomValues(array);
        let str = '';
        for (let i = 0; i < array.length; i++) {
            str += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4);
        }
        return str;
    },

    getURLParameters() {
        return new URLSearchParams(location.toString().split('?')[1]);
    },

    mulberry32: function(seed) {
        // Pseudo random generator
        // Use a seed to have consistent series
        let t = seed += 0x6D2B79F5;
        t = Math.imul(t ^ t >>> 15, t | 1);
        t ^= t + Math.imul(t ^ t >>> 7, t | 61);
        return ((t ^ t >>> 14) >>> 0) / 4294967296;
    },
    formatDateTimeAsZ: function(date) {
        const year = date.getUTCFullYear();
        const month = pad(date.getUTCMonth() + 1);
        const day = pad(date.getUTCDate());
        const hour = pad(date.getUTCHours());
        const minute = pad(date.getUTCMinutes());
        const second = pad(date.getUTCSeconds());
        return `${year}${month}${day}T${hour}${minute}${second}Z`;
    },
};
