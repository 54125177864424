/*
    Autogenerated class model from django
    -- 2020-02-24 10:50:23.416621
*/

import { make } from 'vuex-pathify';

const defaultState = {
    isLoading: 0,
    groupCollection: {},
    groupCollectionFilter: null,
    groupCollectionLastUpdate: 0,
    rgpdConsentCollection: {},
    rgpdConsentCollectionFilter: null,
    rgpdConsentCollectionLastUpdate: 0,
    userCollection: {},
    userCollectionFilter: null,
    userCollectionLastUpdate: 0,

};

const state = Object.assign({}, (defaultState));
const getters = make.getters(state);

const mutations = {
    ...make.mutations(state),

    resetState(state) {
        Object.assign(state, defaultState);
    },

    incrementLoading(state) {
        state.isLoading++;
    },

    decrementLoading(state) {
        state.isLoading--;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
