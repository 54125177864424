export default {
    enabled: false,
    area: '', // A,B,C,D,E
    inseeCode: '',
    floorSurface: '',
    groundSurface: '',
    requiredEnergyProductionByM2: 20,
    boilerPower: 'low',
    boilerPowers: {
        new_building: {
            low: 'Inférieure à 30 kW',
            high: 'Supérieure à 30 kW',
        },
        renovation: {
            low: 'Inférieure à 100 kW',
            high: 'Supérieure à 100 kW',
        },
    },
    energiesOverrodeData: {
        oil: { priceVariation: 0.0112 },
        gaz_b0: { priceVariation: 0 },
        gaz_b1: { priceVariation: 0.0095, ghgRatio: 0.220 },
        biomethane: { priceVariation: 0.0095 },
        propane: { priceVariation: 0.006 },
        electricity: { priceVariation: 0.0078 },
        green_electricity: { priceVariation: 0.0078 },
        wood: { priceVariation: 0 },
        shredded_wood: { priceVariation: 0 },
        bulk_granules: { priceVariation: 0 },
        bag_granules: { priceVariation: 0 },
        network: { priceVariation: 0, ghgRatio: 0.031 },
        thermal_solar: { priceVariation: 0 },
    },
    needsEstimation: {
        newBuilding: {
            baseEfficiency: 48,
        },
    },
    towns: {
        38057: { name: 'Bresson', altitude: 280 },
        38059: { name: 'Brié-et-Angonnes', altitude: 480 },
        38068: { name: 'Champagnier', altitude: 363 },
        38071: { name: 'Champ-sur-Drac', altitude: 300 },
        38111: { name: 'Claix', altitude: 300 },
        38126: { name: 'Corenc', altitude: 467 },
        38150: { name: 'Domène', altitude: 220 },
        38151: { name: 'Échirolles', altitude: 231 },
        38158: { name: 'Eybens', altitude: 244 },
        38169: { name: 'Fontaine', altitude: 206 },
        38170: { name: 'Fontanil-Cornillon', altitude: 204 },
        38179: { name: 'Gières', altitude: 220 },
        38185: { name: 'Grenoble', altitude: 220 },
        38187: { name: 'Le Gua', altitude: 385 },
        38188: { name: 'Herbeys', altitude: 500 },
        38200: { name: 'Jarrie', altitude: 334 },
        38229: { name: 'Meylan', altitude: 350 },
        38235: { name: 'Miribel-Lanchâtre', altitude: 760 },
        38252: { name: 'Montchaboud', altitude: 522 },
        38258: { name: 'Mont-Saint-Martin', altitude: 760 },
        38271: { name: 'Murianette', altitude: 220 },
        38277: { name: 'Notre-Dame-de-Commiers', altitude: 500 },
        38279: { name: 'Notre-Dame-de-Mésage', altitude: 270 },
        38281: { name: 'Noyarey', altitude: 225 },
        38309: { name: 'Poisat', altitude: 250 },
        38317: { name: 'Le Pont-de-Claix', altitude: 240 },
        38325: { name: 'Proveysieux', altitude: 490 },
        38328: { name: 'Quaix-en-Chartreuse', altitude: 525 },
        38364: { name: 'Saint-Barthélemy-de-Séchilienne', altitude: 450 },
        38382: { name: 'Saint-Égrève', altitude: 202 },
        38388: { name: 'Saint-Georges-de-Commiers', altitude: 400 },
        38421: { name: "Saint-Martin-d'Hères", altitude: 220 },
        38423: { name: 'Saint-Martin-le-Vinoux', altitude: 251 },
        38436: { name: 'Saint-Paul-de-Varces', altitude: 388 },
        38445: { name: 'Saint-Pierre-de-Mésage', altitude: 340 },
        38471: { name: 'Le Sappey-en-Chartreuse', altitude: 1014 },
        38472: { name: 'Sarcenas', altitude: 1100 },
        38474: { name: 'Sassenage', altitude: 208 },
        38478: { name: 'Séchilienne', altitude: 364 },
        38485: { name: 'Seyssinet-Pariset', altitude: 262 },
        38486: { name: 'Seyssins', altitude: 335 },
        38516: { name: 'La Tronche', altitude: 220 },
        38524: { name: 'Varces-Allières-et-Risset', altitude: 300 },
        38528: { name: 'Vaulnaveys-le-Bas', altitude: 326 },
        38529: { name: 'Vaulnaveys-le-Haut', altitude: 365 },
        38533: { name: 'Venon', altitude: 540 },
        38540: { name: 'Veurey-Voroize', altitude: 200 },
        38545: { name: 'Vif', altitude: 367 },
        38562: { name: 'Vizille', altitude: 290 },
    },

};
