import cases from './_cases';

export default {
    initialDataLoaded: false,
    housingType: null,
    housingTypes: {
        flat: {
            label: 'Un appartement chauffé individuellement',
            icon: 'building',
        },
        flatInMultiUnit: {
            label: 'Un appartement chauffé collectivement',
            icon: 'building',
        },
        house: {
            label: 'Une maison individuelle',
            icon: 'home-lg',
        },
    },
    renovationType: null,
    renovationTypes: {
        new_building: {
            label: 'Construire un logement neuf',
            icon: 'user-hard-hat',
        },
        global_renovation: {
            label: 'Rénover votre logement et changer votre mode de chauffage ou de prodution d\'eau chaude',
            icon: 'pencil-ruler',
        },
        systems_renovation: {
            label: 'Changer uniquement votre système de chauffage ou de production d\'eau chaude',
            icon: 'tools',
        },
        heating_system_renovation: {
            label: 'Changer uniquement votre système de chauffage',
            icon: 'thermometer-half',
        },
    },
    floorSurface: null,
    heatingSystemIdentifier: null,
    hotWaterSystemIdentifier: null,
    heatingNeeds: null,
    hotWaterNeeds: null,
    cases,
};
