const api = {
    searchPhone(phone) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/cti/${phone}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
