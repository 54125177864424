import { getField } from 'vuex-map-fields';
import _ from 'lodash';

export default {
    getField,

    solutionsValidityString(state, getters) {
        return state.solutions.map((solution) => getters.isValidSolution(solution)).toString();
    },

    forComputation(state, getters) {
        return state.solutions.filter(solution => getters.isValidSolution(solution)).map((solution, index) => {
            return getters.solutionForComputation(solution, index);
        });
    },

    solutionsDefinedCount(state, getters) {
        return state.solutions.filter(solution => getters.isValidSolution(solution)).length;
    },

    isValidSolution(state, getters, rootState, rootGetters) {
        return (solution) => {
            if (solution.isMetroGrenoble) return true;
            const heatingSystemsValids = (
                getters.isValidSolutionSystem(solution.heatingSystems, 0) ||
                getters.isValidSolutionSystem(solution.heatingSystems, 1)
            );
            const hotWaterSystemsValids = (
                getters.isValidSolutionSystem(solution.hotWaterSystems, 0) ||
                getters.isValidSolutionSystem(solution.hotWaterSystems, 1)
            );
            return (
                (rootGetters['thermix/needs/isManagingHeating'] && heatingSystemsValids) ||
                (rootGetters['thermix/needs/isManagingHotWater'] && hotWaterSystemsValids)
            );
        };
    },

    isValidSolutionSystem(state, getters) {
        return (systemsList, index) => {
            const def = getters.solutionSystemDefFromSystemsList(systemsList, index);
            return def && def.identifier;
        };
    },

    solutionSystemDefFromSystemsList(state, getters, rootState, rootGetters) {
        return (systemsList, index) => {
            const defId = systemsList[index];
            return rootGetters['thermix/productionSystemDefs/get'](defId);
        };
    },

    solutionsSystemsDefIds(state) {
        return state.solutions.reduce(
            (defIds, solution) => { defIds.push(...solution.heatingSystems, ...solution.hotWaterSystems); return defIds; },
            []
        );
    },

    solutionsSystemsDefs(state, getters, rootState) {
        return getters.solutionsSystemsDefIds.map((defId) => rootState.thermix.productionSystemDefs.defs[defId]);
    },

    solutionForComputation(state, getters) {
        return (solution, index) => {
            return {
                index: index,
                renewableElectricity: solution.renewableElectricity,
                renewableElectricityProduction: parseInt(solution.renewableElectricityProd),
                financialSupport: solution.financialSupport,
                financialSupportAmount: parseInt(solution.financialSupportAmount),
                heatingSystems: _.compact([
                    getters.solutionSystemForComputation(solution.heatingSystems, 0),
                    getters.solutionSystemForComputation(solution.heatingSystems, 1),
                ]),
                hotWaterSystems: _.compact([
                    getters.solutionSystemForComputation(solution.hotWaterSystems, 0),
                    getters.solutionSystemForComputation(solution.hotWaterSystems, 1),
                ]),
            };
        };
    },
    solutionSystemForComputation(state, getters, rootState, rootGetters) {
        return (systemsList, systemIndex) => {
            if (!systemsList) return null;
            const def = getters.solutionSystemDefFromSystemsList(systemsList, systemIndex);
            if (def.type === 'hot_water' && !rootGetters['thermix/needs/isManagingHotWater']) return null;
            if (def.type === 'heating' && !rootGetters['thermix/needs/isManagingHeating']) return null;
            let productionSystem;
            let solarPanelsSurface;
            if (def.identifier === 'synced-with-heating') {
                const syncedDef = rootGetters['thermix/productionSystemDefs/get'](def.syncedId);
                productionSystem = rootState.energies.productionSystemCollectionByIdentifier[syncedDef.identifier];
                solarPanelsSurface = syncedDef.solarPanelsSurface;
            } else {
                productionSystem = rootState.energies.productionSystemCollectionByIdentifier[def.identifier];
                solarPanelsSurface = def.solarPanelsSurface;
            }
            if (!productionSystem) return null;
            return {
                index: systemIndex,
                productionSystem: productionSystem.id,
                productionShare: parseFloat(def.share),
                estimatedInvestment: def.investment,
                efficiency: def.efficiency,
                maintenanceCost: def.maintenance,
                provisionsCost: def.provisions,
                energyPrice: def.energyPrice,
                greenEnergyPrice: def.greenEnergyPrice,
                energyPriceVariation: def.energyPriceVariation,
                ghgRatio: def.ghgRatio,
                greenEnergyGhgRatio: def.greenEnergyGhgRatio,
                solarPanelsSurface: solarPanelsSurface,
                useGreenEnergy: def.useGreenEnergy,
                greenEnergyShare: def.greenEnergyShare,
            };
        };
    },
    // solutionSystemInvestment(state, getters) {
    //     return (productionSystem, systemType) => {
    //         const fieldName = getters.solutionSystemDataFieldName('investment', systemType);
    //         // console.log(productionSystem, fieldName);
    //         return parseInt(productionSystem[fieldName]);
    //     };
    // },
    // solutionSystemMaintenance(state, getters) {
    //     return (productionSystem, systemType) => {
    //         const fieldName = getters.solutionSystemDataFieldName('maintenance', systemType);
    //         return parseInt(productionSystem[fieldName]);
    //     };
    // },
    // solutionSystemProvisions(state, getters) {
    //     return (productionSystem, systemType) => {
    //         const fieldName = getters.solutionSystemDataFieldName('provisions', systemType);
    //         return parseInt(productionSystem[fieldName]);
    //     };
    // },
    // solutionSystemEfficiency(state, getters) {
    //     return (productionSystem, systemType) => {
    //         const fieldName = _.camelCase(`efficiency_${systemType}`);
    //         return parseFloat(productionSystem[fieldName]);
    //     };
    // },
    solutionSystemDataFieldName(state, getters, rootState, rootGetters) {
        // Return something like "investmentSmallMultiUnitHeating" or "maintenanceIndividualHotWater"

        const multiUnitSize = rootGetters['thermix/needs/multiUnitSize'];
        const housingCategory = rootState.thermix.needs.housingCategory;
        const housingSize = housingCategory === 'multi_unit' ? `${multiUnitSize}_multi_unit` : 'individual';
        return (fieldName, systemType) => {
            return _.camelCase(`${fieldName}_${housingSize}_${systemType}`);
        };
    },

    deletableSolutions(state) {
        return state.solutions.map((solution) => !solution.isMetroGrenoble);
    },
};
