export default {

    reset({ commit, dispatch, rootState }) {
        commit('RESET_STATE');

        const excludedChoices = [...rootState.energies.thermalSolarIdentifiers, 'heating_network'];

        dispatch('thermix/productionSystemDefs/createCouplesSet', { excludedChoices }, { root: true }).then(({ heatingIds, hotWaterIds }) => {
            commit('updateField', { path: 'thermalCalculation.heatingSystems', value: heatingIds });
            commit('updateField', { path: 'thermalCalculation.hotWaterSystems', value: hotWaterIds });
        });

        dispatch('thermix/productionSystemDefs/createCouplesSet', null, { root: true }).then(({ heatingIds, hotWaterIds }) => {
            commit('updateField', { path: 'systemsRenovationInvoiceEuro.heatingSystems', value: heatingIds });
            commit('updateField', { path: 'systemsRenovationInvoiceEuro.hotWaterSystems', value: hotWaterIds });
        });

        dispatch('thermix/productionSystemDefs/createCouplesSet', null, { root: true }).then(({ heatingIds, hotWaterIds }) => {
            commit('updateField', { path: 'systemsRenovationInvoiceQuantity.heatingSystems', value: heatingIds });
            commit('updateField', { path: 'systemsRenovationInvoiceQuantity.hotWaterSystems', value: hotWaterIds });
        });
    },

    refreshSystemsRenovationInvoiceEuroInvoices({ commit, state, getters }) {
        const invoices = {};

        getters.systemsRenovationInvoiceEuroConcernedEnergyTypes.forEach((energyType) => {
            invoices[energyType.identifier] = Object.assign({}, state.systemsRenovationInvoiceEuro.emptyInvoice, {
                energyTypeIdentifier: energyType.identifier,
                initialEnergyPrice: energyType.currentPrice,
                energyPrice: energyType.currentPrice,
                heatingSystems: energyType.heatingSystems,
                hotWaterSystems: energyType.hotWaterSystems,
            });
        });

        state.systemsRenovationInvoiceEuro.invoices.forEach((storedInvoice) => {
            if (invoices[storedInvoice.energyTypeIdentifier]) {
                invoices[storedInvoice.energyTypeIdentifier].energyPrice = storedInvoice.energyPrice;
                invoices[storedInvoice.energyTypeIdentifier].amount = storedInvoice.amount;
            }
        });

        commit('updateField', { path: 'systemsRenovationInvoiceEuro.invoices', value: Object.values(invoices) });
    },

    refreshSystemsRenovationInvoiceQuantityInvoices({ commit, state, getters, rootGetters }) {
        const invoices = {};

        getters.systemsRenovationInvoiceQuantityConcernedEnergyTypes.forEach((energyType) => {
            const possibleVectors = rootGetters['energies/energyVectorsForEnergy'](energyType.identifier);

            invoices[energyType.identifier] = Object.assign({}, state.systemsRenovationInvoiceQuantity.emptyInvoice, {
                energyTypeIdentifier: energyType.identifier,
                possibleVectors: possibleVectors,
                vector: possibleVectors[0],
                heatingSystems: energyType.heatingSystems,
                hotWaterSystems: energyType.hotWaterSystems,
            });
        });

        state.systemsRenovationInvoiceQuantity.invoices.forEach((storedInvoice) => {
            if (invoices[storedInvoice.energyTypeIdentifier]) {
                invoices[storedInvoice.energyTypeIdentifier].quantity = storedInvoice.quantity;
                invoices[storedInvoice.energyTypeIdentifier].vector = storedInvoice.vector;
            }
        });

        commit('updateField', { path: 'systemsRenovationInvoiceQuantity.invoices', value: Object.values(invoices) });
    },

};
