<template>
  <div
    :class="{
            page: true,
            guides: withGuides,
            landscape: landscape,
        }"
    :style="pageStyle"
  >
    <slot name="header"></slot>
    <slot />
    <slot name="footer"></slot>
    <div class="foot-note" :style="footNoteStyle">
      <slot name="foot-note"><div v-html="defaultPageFootNote"></div></slot>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Page',
    components: {},
    props: {
        withGuides: { default: false, type: Boolean },
        landscape: { default: false, type: Boolean },
        margin: { default: false, type: [Boolean, Number] },
    },
    inject: {
        defaultPageFootNote: { default: '' },
    },
    data() {
        return {};
    },
    computed: {
        pageStyle() {
            const style = {};
            if (this.pageMargin) style.padding = this.pageMargin;
            return style;
        },
        footNoteStyle() {
            if (this.pageMargin)
                return { bottom: this.pageMargin, 'width': `calc(100% - ${this.pageMargin} - ${this.pageMargin})` };
            return {};
        },
        pageMargin() {
            if (typeof this.margin === 'number') return this.margin + 'mm';
            if (this.margin) return '15mm';
            return null;
        },
    },
    created: function() {},
    mounted: function() {},
    methods: {},
};
</script>

<style lang="stylus" scoped>
@require '../stylesheet/typography'

.page
    position: relative
    width: 210mm
    height: 290mm

    .landscape
        width: 297mm
        height: 210mm

    &.guides:after
        content: ''
        position: absolute
        width: inherit
        height: inherit
        top: 0
        left: 0
        border: 2px dotted red
        z-index: -1

    :deep(.foot-note)
        font-size: text-xxs
        position: absolute
        bottom: 0
        padding-bottom: 1mm
</style>
