/*
    Autogenerated class model from django SegmentActivitySubMissionLink
    -- 2023-03-13 18:02:20.830158
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class SegmentActivitySubMissionLinkClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'activity', rawData.activity);
        Vue.set(this, 'subMission', rawData.subMission);
    }

    getBaseUrl() {
        return '/listepro/segment-activity-submission-link/';
    }

    get isValid() {
        return true;
    }
}

class SegmentActivitySubMissionLinkCollection extends BaseCollection {
    initModel(rawData) {
        return new SegmentActivitySubMissionLinkClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/segment-activity-submission-link/';
    }
}

class SegmentActivitySubMissionLinkStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new SegmentActivitySubMissionLinkClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/segment-activity-submission-link/';
    }

    getStorePath() {
        return 'listepro/segmentActivitySubMissionLinkCollection';
    }
}

export {
    SegmentActivitySubMissionLinkClass,
    SegmentActivitySubMissionLinkCollection,
    SegmentActivitySubMissionLinkStoreCollection,
};
