const GroupList = () => import(/* webpackChunkName: "groups_and_users" */ '@/apps/groups_and_users/group_list');
const GroupEdit = () => import(/* webpackChunkName: "groups_and_users" */ '@/apps/groups_and_users/group_edit');
const UserEdit = () => import(/* webpackChunkName: "groups_and_users" */ '@/apps/groups_and_users/user_edit');
const UserList = () => import(/* webpackChunkName: "groups_and_users" */ '@/apps/groups_and_users/user_list');

const routes = [
    {
        path: '/groupes/',
        name: 'GroupList',
        component: GroupList,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager'],
        },
    },
    {
        path: '/groupes/:id',
        name: 'GroupEdit',
        component: GroupEdit,
        props: true,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'superadvisor'],
        },
    },
    {
        path: '/ma-structure',
        name: 'MaStructure',
        component: GroupEdit,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'superadvisor'],
        },
    },
    {
        path: '/utilisateurs/',
        name: 'UserList',
        component: UserList,
        props: true,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator'],
        },
    },
    {
        path: '/utilisateurs/:pk',
        name: 'UserEdit',
        component: UserEdit,
        props: true,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
    },
    {
        path: '/utilisateurs/creation',
        name: 'UserCreate',
        component: UserEdit,
        props: true,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
    },
];

export default routes;
