<template>    
    <div>
        <header class="header">
            <div class="grid-x grid-margin-x">
                <div class="cell small-10">
                    <i class="ico_pie69 icon-logo-pie69 logo-header"></i>
                    <span class="global-title">professionnels-renoperformante69.org<br>
                    <span class="title">Votre annuaire de professionnels du bâtiment</span></span>
                </div>
                <div class="cell small-2">
                    <a @click="hideModal"><font-awesome-icon class="close-modal" :icon="['fal', 'times-circle']" /></a>
                </div>
            </div>
        </header>
        
        <layout-container>
            <div class="grid-x grid-padding-x">
                <div class="cell small-9">
                    <h2 class="instruction">Entrez un commentaire sur la sélection de professionnels si vous le souhaitez</h2>
                </div>
                <div class="cell small-3">
                    <regular-button class="right bt-close" @click="startDownload">
                        Lancer le téléchargement
                    </regular-button>
                </div>
            </div>
        </layout-container>
        <layout-container>
            <div class="tip-tap">
                <tiptap-editor
                id="comment"
                v-model="commentPdf"
                />
            </div>
        </layout-container>
    </div>
</template>

<script>

import RegularButton from '@/components/regular_button';
import TiptapEditor from '@/components/tiptap_editor';

import { sync } from 'vuex-pathify';

export default {
    name: 'ModalComment',

    data() {
        return {
        };
    },
    created: function() {},
    mounted: function() {
    },
    methods: {
        hideModal() {
            this.$emit('hide-modal');
        },
        startDownload() {
            this.$emit('start-download');
        },
        
    },
    components: {
        RegularButton,
        TiptapEditor,
    },
    computed: {
        commentPdf:sync("listepro/commentPdf"),
    },
    props: {
    },
};


</script>
<style lang='stylus' scoped>
@require '../../../../stylesheet/variables'
@require '../../../../stylesheet/listepro_font'

.header
    padding: 1.5rem 0.5rem 1.5rem 0.5rem
    background-color: primary-color-500
    margin-bottom: 1rem

.close-modal
    color: #fff
    float: right
    font-size: 1.5rem

.global-title
    text-transform: uppercase
    font-size: 2.2rem
    line-height: 0.7
    font-weight: 700
    color: #fff
    margin-bottom: -15px
    vertical-align: middle
    display: inline-block

.title
    font-size: 1.25rem
    font-weight: normal
    text-transform: uppercase

.instruction
    font-weight: bold
    font-size: 1.4rem

.logo-header
    font-size: 6rem
    padding: 0 20px 0 0
    vertical-align: middle
    color: #fff
    margin-left: 1rem

.tip-tap
    margin-bottom: 2rem

.right
    float: right
</style>
