<template>
<div class="main-content">
  <router-view :key="$route.fullPath"></router-view>
</div>
</template>

<script>

import Vue from 'vue';
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);

export default {
    name: 'ListeProMenu',
};
</script>

<style lang="stylus" scoped>
@require '../../stylesheet/variables'
@require '../../stylesheet/typography'

.fade-enter-active
.fade-leave-active
    transition: opacity 0.2s

.fade-enter
.fade-leave-to
    opacity: 0

.slide
    background-color: gray
</style>
