<template>
  <div class="ProfessionalUserList">
    <layout-container>
      <section-title>Liste des professionnels</section-title>
      <div>
        <spinner :active="waitLoading" />


        <div class="margin-top-xl">
          <h3>Filtres</h3>
          <div class="grid-x grid-margin-x buttons user-tab-buttons align-top">
            <div class="small-12 medium-6 cell">
              <div>
                <select-referents
                  label="Référent :"
                  v-model="filters.referent"
                  :multiple="false"
                  />
              </div>
                  
              <div>
                <select-jobs
                  label="Métier :"
                  v-model="filters.job"
                  :multiple="false"
                  />
              </div>
            </div>
                  
            <div class="small-12 medium-6 cell margin-top-md">
              <div>
                <input type="checkbox" v-model="filters.bap" id="filterbap"><label for="filterbap">&nbsp;Ayant accès à la BAP</label>
              </div>
                  
              <div>
                <input type="checkbox" v-model="filters.subscribedToNewsletter" id="filternewsletter"><label for="filternewsletter">&nbsp;Inscrits à la newsletter</label>
              </div>

              <div>
                <input type="checkbox" v-model="filters.isMonAccompagnateurRenov" id="filtermap"><label for="filtermap">&nbsp;MAR</label>
              </div>

              <!--div>
                <input type="checkbox" v-model="filters.needUpdate" id="filterneedupdate"><label for="filterneedupdate">&nbsp;Sans mise à jour depuis un an</label>
              </div-->
                  
              <div>
                <select-field
                  label="Filtre :"
                  v-model="filters.visibility"
                  :reduce="x => x.value"
                  :searchable="false"
                  :options="visibilityList"
                  />
              </div>

              
            </div>
          </div>
        </div>

        <div class="grid-x grid-margin-x buttons user-tab-buttons align-bottom">
          <div class="small-12 medium-6 medium-offset-6 cell">
            <button type="button" class="button float-right add-button" v-on:click="addUser()">
              <font-awesome-icon icon="plus" />Ajouter un professionnel
            </button>
            <action-link label="Exporter les données" icon="download" icon-position="left" v-on:click="exportData" title="Exporter l'ensemble des données vers un tableur" class="float-right export-button"/>
          </div>
        </div>
      
        <vue-good-table
          class="users-table"
          v-bind:columns="columns"
          v-bind:rows="filteredUsers"
          :sort-options="{ enabled: true, initialSortBy: [{field: 'isValidationPending', type: 'desc'}, {field: 'professionalName', type: 'asc'}] }"
          :search-options="{ enabled: true }"
          :pagination-options="{
                              enabled: true,
                              mode: 'records',
                              perPage: 10,
                              perPageDropdown: [10, 25, 200],
                              dropdownAllowAll: true,
                              nextLabel: 'suivant',
                              prevLabel: 'précédent',
                              rowsPerPageLabel: 'Lignes par page',
                              ofLabel: 'de',
                              pageLabel: 'page', // for 'pages' mode
                              allLabel: 'Toutes',
                              }"
        >
          <template v-slot:table-row="props">
            <span v-if="props.column.field == 'professionalName'">
              <professional-infos :professional="props.row" />
            </span>
            
            <span v-else-if="props.column.field == 'referents'">
              <div class="referents" v-for="referent in sortReferents(props.row.referents)" :key="referent">{{ referent }}</div>
            </span>
            
            <span v-else-if="props.column.field == 'publicationStatusAsLabel'">
              <table
                v-if="props.row.hasProfessional"
                class="smart-workflow"
                >
                <tr>
                  <td>Présentation : </td><td><status-as-icon v-model="props.row.presentationPublicationStatus" /></td>
                </tr>
                <tr v-if="props.row.referencesPublicationStatus.length > 0">
                  <td>Références :</td>
                  <td>
                    <div class="references-block">
                      <div v-for="count, key of groupReferences(props.row.referencesPublicationStatus)" :key="key" class="references">
                        <status-as-icon v-model="key.split(',')[0]" /> {{ count }}
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </span>
            
            <span v-else-if="props.column.field == 'isValidationPending'">
              <true-false
                class="validation-pending"
                :value="props.row.isValidationPending"
                title-true="Des validations sont en attente"
                :icon-true="['fal', 'hourglass-half']"
                :icon-false="null"
                />
            </span>
            
            <span v-else-if="props.column.field == 'after'">
              <div v-if="!props.row.hasProfessional">
                <action-link label="Créer la fiche" icon="plus" icon-position="left" v-on:click="createOrEditPro(props.row.pk)" title="Accès au formulaire"/>
              </div>
              <div v-else>
                <action-link label="Éditer la fiche" icon="edit" icon-position="left" v-on:click="createOrEditPro(props.row.pk)" title="Accès au formulaire"/>
              </div>
            </span>

            
            <span v-else>
              <span
                v-bind:class="{'inactive-user': !props.row.isActive}"
              >{{ props.formattedRow[props.column.field] }}</span>
            </span>
          </template>
          <div slot="table-actions-bottom"></div>
        </vue-good-table>

        
      </div>

    </layout-container>
  </div>
</template>

<script>
/* global structuredClone */
/* eslint no-undef: "error" */
  
import apiClient from '@/api/api';
import { VueGoodTable } from 'vue-good-table';
import Spinner from '@/components/spinner';
import LayoutContainer from '@/components/layout_container';
import ActionLink from '@/components/action_link';
import SectionTitle from '@/components/section_title';
import TrueFalse from '@/components/true_false';
import SelectField from '@/components/select_field';

import ProfessionalInfos from '@/apps/listepro/professionals/professional_infos';
import StatusAsIcon from '@/apps/listepro/professionals/status_as_icon';

import SelectJobs from '@/apps/listepro/select_jobs';
import SelectReferents from '@/apps/listepro/select_referents';

import Storage from '@/storage';

export default {
    name: 'ProfessionalUserList',
    components: {
        ActionLink,
        LayoutContainer,
        ProfessionalInfos,
        SectionTitle,
        SelectField,
        SelectJobs,
        SelectReferents,
        Spinner,
        StatusAsIcon,
        TrueFalse,
        VueGoodTable,
    },
    data: function() {
        return {
            filters: {
                // needUpdate: false,
                bap: false,
                job: null,
                referent: null,
                subscribedToNewsletter: false,
                isMonAccompagnateurRenov: false,
                visibility: "all",
            },
            referents: [],
            waitLoading: false,
            users: [],
            visibilityList: [
                {label: "Tous", value: "all", isStatus: false},
                {label: "Visibles", value: "published-professionals", isStatus: false},
                {label: "Non Visibles", value: "unpublished-professionals", isStatus: false},
                {label: "Publiés, sans mise à jour depuis un an", value: "need-update", isStatus: false},

                {label: "statut : Initiés", value: "initial", isStatus: true},
                {label: "statut : À l'étude", value: "draft", isStatus: true},
                {label: "statut : En validation annuelle", value: "in_annual_validation", isStatus: true},
                {label: "statut : En validation", value: "in_validation", isStatus: true},
                {label: "statut : Probatoire", value: "probationary", isStatus: true},
                {label: "statut : Nouveaux en probatoire", value: "probationary_new", isStatus: true},
                {label: "statut : Publiés", value: "published", isStatus: true},
                {label: "statut : Dépubliés", value: "unpublished", isStatus: true},
                {label: "statut : Archivés", value: "closed", isStatus: true},
                
            ],
            columns: [
                {
                    label: 'Identité',
                    field: 'professionalName',
                    sortable: true,
                },
                {
                    label: 'Référents',
                    field: 'referents',
                    sortable: false,
                    width: '150px',
                },
                {
                    label: 'Parcours',
                    field: 'publicationStatusAsLabel',
                    // tdClass: 'text-center',
                    sortable: false,
                },
                {
                    label: 'À valider ?',
                    field: 'isValidationPending',
                    tdClass: 'text-center',
                    sortable: false,
                },
                {
                    label: 'Actions',
                    field: 'after',
                    sortable: false,
                    tdClass: 'td-actions',
                    width: '200px',
                },
                // Hidden but allow search
                {
                    label: 'Nom',
                    field: 'fullName',
                    hidden: true,
                },
                {
                    label: 'Email',
                    field: 'email',
                    hidden: true,
                },
                {
                    label: 'Structure',
                    field: 'professionalName',
                    tdClass: 'text-center',
                    hidden: true,
                },
                {
                    label: 'Métier',
                    field: 'professionalJob',
                    tdClass: 'text-center',
                    hidden: true,
                },
            ],
        };
    },
    computed: {
        filteredUsers() {
            let users = this.users;
            if (this.filters.bap) {
                users = users.filter(x => x.allowToRegisterToBap === true);
            }
            
            // if (this.filters.needUpdate) {
            //     users = users.filter(x => x.needUpdate === true);
            // }
            
            if (this.filters.subscribedToNewsletter) {
                users = users.filter(x => x.subscribedToNewsletter === true);
            }
            
            if (this.filters.isMonAccompagnateurRenov) {
                users = users.filter(x => x.isMonAccompagnateurRenov === true);
            }
            
            if (this.filters.referent) {
                users = users.filter(x => x.referentsPk.includes(this.filters.referent));
            }
            
            if (this.filters.job) {
                users = users.filter(x => x.professionalJobPk === this.filters.job);
            }

            if (this.filters.visibility) {
                if (this.filters.visibility === 'need-update') {
                    users = users.filter(x => x.needUpdate === true);
                }
                else if (this.visibilityList.filter(x=> x.value === this.filters.visibility )[0].isStatus===true) {
                    users = users.filter(x => x.transitionState === this.filters.visibility);
                }
                else if (this.filters.visibility !== 'all') {
                    users = users.filter(x => x.isPublished === (this.filters.visibility === 'published-professionals'));
                }
            }
            
            return users;
        },
        referentsOptions() {
            const options = this.referents.filter(
                ({ isActive, pk }) => isActive // || currentReferents.includes(pk)
            ).map(({ groupName, firstName, lastName, email, pk }) => ({
                label: `${firstName} ${lastName} (${email}) - ${groupName}`,
                value: pk,
                pk: pk,
            }));
            options.sort();
            return options;
        },
    },
    watch: {
        filters: {
            handler(newVal) {
                this.saveFilters();
            },
            deep: true,
        },
    },
    mounted: function() {
        this.loadReferents();
        this.restoreFilters();
        this.load();
    },
    methods: {
        restoreFilters() {
            this.filters = Storage.get("professional-user-list", {
                bap: false,
                job: null,
                referent: null,
                subscribedToNewsletter: false,
            });
        },
        saveFilters() {
            Storage.set("professional-user-list", this.filters);
        },
        load() {
            this.waitLoading = true;
            apiClient
                .genericGet('listepro/user/')
                .then(response => {
                    this.users = response;
                    this.waitLoading = false;
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        this.showErrorForbidden();
                        this.waitLoading = false;
                    }
                });
        },
        
        loadReferents() {
            apiClient.genericList('listepro/advisor/')
                .then(advisors => {
                    this.referents = advisors;
                });
        },

        createOrEditPro(pk) {
            this.$router.push({
                name: 'ListeProProfessionalAdminForm',
                params: {
                    pk: pk,
                },
            });
        },
        
        addUser() {
            this.$router.push({ name: 'ListeProAccountCreate', params: { pk: null, groupPk: null } });
        },

        exportData() {
            this.waitLoading = true;
            apiClient.genericDownloadFile('listepro/exports/professional')
                .catch(error => {
                    const message = error;
                    this.$notify({
                        group: 'root',
                        type: 'error',
                        title: 'Erreur',
                        text: message,
                    });
                    console.log(error);
                })
                .finally(() => {
                    this.waitLoading = false;
                });
        },

        sortReferents(referents) {
            const orderedReferents = structuredClone(referents);
            orderedReferents.sort();
            return orderedReferents;
        },
        
        groupReferences(references) {
            if (references === null) {
                return [];
            }
            const groupedReferences = {};
            const countReferences = {};
            for (const reference of references) {
                for (const [key, value] of Object.entries(reference)) {
                    if (countReferences[[key, value]] === undefined) {
                        countReferences[[key, value]] = 1;
                    } else {
                        countReferences[[key, value]] += 1;
                    }
                }
            }
            for (const [key, value] of Object.entries(countReferences)) {
                groupedReferences[key] = value;
            }
            return groupedReferences;
        },
    },
};
</script>

<style  lang='stylus' scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/typography'

.filters
    padding-left: space-md
    margin-bottom: space-md

.add-button
    margin-bottom: space-xs

.export-button
    margin-right: space-md
    margin-top: 8px

.pro-status
    text-transform: uppercase
    padding: 0.2rem
    font-size: 0.85rem
    width: 100px
    display: inline-block
    margin-bottom: 0.2rem

.pro-published
    color: #fff
    background-color: success-color-400

.pro-unpublished
.pro-closed
    color: #fff
    background-color: error-color-400

.pro-created
.pro-draft
    color: #fff
    background-color: warning-color-400

.not-allowed-to-register-to-bap
    color: grey

.validation-pending
    :deep(.success-icon)
        color: warning-color

:deep(.text-center)
    text-align: center !important

.smart-workflow
    tr
        background: unset !important

        td
            border: none !important
            vertical-align: top
            padding: 2px 0
            width: 70px

        td.reference-column
            width: 36px

    :deep(.status-as-icon)
        display: inline-block
</style>
