const Therminix = () => import(/* webpackChunkName: "therminix" */ '@/apps/therminix/therminix');
const TherminixInformations = () => import(/* webpackChunkName: "therminix" */ '@/apps/therminix/informations');
const TherminixNeedsEstimation = () => import(/* webpackChunkName: "therminix" */ '@/apps/therminix/needs_estimation');
const TherminixProposition = () => import(/* webpackChunkName: "therminix" */ '@/apps/therminix/proposition');
const NewBuilding = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/new_building');
const GlobalRenovation = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/global_renovation');
const SystemsRenovationBuilding = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/systems_renovation_building');
const SystemsRenovationInvoiceEuro = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/systems_renovation_invoice_euro');
const SystemsRenovationInvoiceQuantity = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/systems_renovation_invoice_quantity');

export default [
    {
        path: '/therminix',
        name: 'Therminix',
        component: Therminix,
        redirect: { name: 'TherminixInformations' },
        meta: {
            requiresAuth: false,
            // requiresUserType: ['administrator', 'manager', 'advisor'],
        },
        children: [
            {
                name: 'TherminixInformations',
                path: 'votre-projet',
                component: TherminixInformations,
                // redirect: { name: 'ThermixBasicNeeds' },
                meta: {
                    tabLabel: 'Votre projet',
                },
            },
            {
                path: 'nos-preconisations',
                name: 'TherminixProposition',
                component: TherminixProposition,
                // redirect: { name: 'ThermixNeeds' },
                meta: {
                    tabLabel: 'Nos préconisations',
                    requiresAuth: false,
                    // requiresUserType: ['administrator', 'manager', 'advisor'],
                },
            },

            {
                path: 'estimez-vos-besoins',
                name: 'TherminixNeedsEstimation',
                component: TherminixNeedsEstimation,
                // redirect: { name: 'ThermixNeeds' },
                meta: {
                    // tabLabel: 'Estimez vos besoins',
                    tabHidden: true,
                    requiresAuth: false,
                    // requiresUserType: ['administrator', 'manager', 'advisor'],
                },
                children: [

                    {
                        name: 'TherminixNeedsEstimationNewBuilding',
                        path: 'pour-une-construction-neuve',
                        component: NewBuilding,
                        meta: {
                            isTherminix: true,
                            goBackRouteName: 'TherminixInformations',
                        },
                    },
                    {
                        name: 'TherminixNeedsEstimationGlobalRenovation',
                        path: 'pour-une-renovation-globale',
                        component: GlobalRenovation,
                        meta: {
                            isTherminix: true,
                            goBackRouteName: 'TherminixInformations',
                        },
                    },
                    {
                        name: 'TherminixNeedsEstimationSystemsRenovationBuilding',
                        path: 'pour-une-renovation-des-systemes-a-partir-de-l-epoque-du-batiment',
                        component: SystemsRenovationBuilding,
                        meta: {
                            isTherminix: true,
                            goBackRouteName: 'TherminixInformations',
                        },
                    },
                    {
                        name: 'TherminixNeedsEstimationSystemsRenovationInvoiceEuro',
                        path: 'pour-une-renovation-des-systemes-a-partir-des-factures-en-euro',
                        component: SystemsRenovationInvoiceEuro,
                        meta: {
                            isTherminix: true,
                            goBackRouteName: 'TherminixInformations',
                        },
                    },
                    {
                        name: 'TherminixNeedsEstimationSystemsRenovationInvoiceQuantity',
                        path: 'pour-une-renovation-des-systemes-a-partir-quantite-de-combustible-ou-kwh',
                        component: SystemsRenovationInvoiceQuantity,
                        meta: {
                            isTherminix: true,
                            goBackRouteName: 'TherminixInformations',
                        },
                    },
                ],

            },
        ],
    },

];
