import defaultState from './_defaultState';
import { updateField } from 'vuex-map-fields';

export default {
    RESET_STATE(state) {
        Object.assign(state, defaultState);
    },

    SWAP_SOLUTIONS(state, { fromIndex, toIndex }) {
        const tmpSolution = state.solutions[toIndex];
        state.solutions.splice(toIndex, 1, state.solutions[fromIndex]);
        state.solutions.splice(fromIndex, 1, tmpSolution);
    },

    updateField,
};
