<template>
<div class="liste-result">
  <div :key=professional.id  v-for="professional in professionals" class="align-centeir">
    <professional-card-in-list
      class="liste-card"
      :professional="professional"
      :allMode="allMode"
      @locate-professional="locateProfessional"
      />
  </div>
  <div class="disclaimer">
    Les professionnels sont présentés dans un ordre aléatoire.
  </div>
</div>
</template>


<script>

import ProfessionalCardInList from '@/apps/listepro/public/search/professional_card_in_list';

  
export default {
    name: 'ListeResults',
    mixins: [],
    components: {
        ProfessionalCardInList,
    },
    props: {
        professionals: { default: () => [], type: Array },
        allMode: { default: false, type: Boolean },
    },
    data: function() {
        return {
        };
    },
    computed: {
    },
    methods: {
        locateProfessional(professionalPk) {
            this.$emit("locate-professional", professionalPk);
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../../stylesheet/variables'
@require '../../../../stylesheet/typography'

.liste-result
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    align-items: flex-start
    align-content: flex-start
    gap: 20px

    .liste-card
        min-width: 370px

.disclaimer
    color: gray-500
    font-style: italic
</style>
