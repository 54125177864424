import DashboardAdvisor from '@/apps/dashboards/dashboard_advisor';
import DashboardHome from '@/apps/dashboards/dashboard_home';

const routes = [
    {
        path: '/tableau-de-bord/administrateur',
        name: 'DashboardAdministrator',
        component: DashboardAdvisor,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator'],
        },
    },
    {
        path: '/tableau-de-bord/manager',
        name: 'DashboardManager',
        component: DashboardAdvisor,
        meta: {
            requiresAuth: true,
            requiresUserType: ['manager'],
        },
    },
    {
        path: '/tableau-de-bord/conseiller',
        name: 'DashboardAdvisor',
        component: DashboardAdvisor,
        meta: {
            requiresAuth: true,
            requiresUserType: ['advisor', 'superadvisor'],
        },
    },
    {
        path: '/tableau-de-bord/',
        name: 'DashboardHome',
        component: DashboardHome,
    },
];

export default routes;
