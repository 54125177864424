<template>
<div class="newsletters-recipient">
  <h3>Destinataires des newsletters :</h3>
  
  <div class="errors" v-if="isError">
    Erreur, valeur invalide
  </div>
  
  <table class="emails">
    <tr v-for="email in localValue" :key="email" class="email">
      <td><font-awesome-icon icon="envelope" title="" /> {{ email }}</td>
      <td class="action"><action-link label="Retirer" icon="trash" icon-position="left" @click="remove(email)"/></td>
    </tr>
    <tr class="add-email">
      <td>
        <validation-observer
          ref="validator"
          tag="div"
          v-slot="{ invalid }"
          >
          <text-field
            id="emails"
            v-model="newEmail"
            label="Ajouter un courriel"
            :type="newEmail ? 'email' : 'text' /* hack pour veevalidate */"
            validate-rules="email"
            :isError="invalid"
            />
        </validation-observer>
          
      </td>
      <td class="action"><action-link label="Ajouter" icon="plus" icon-position="left" @click="add()"/></td>
    </tr>
  </table>
  
</div>
</template>

<script>
import ActionLink from '@/components/action_link';
import TextField from '@/components/text_field';

export default {
    name: 'NewslettersRecipient',
    mixins: [],
    components: {
        ActionLink,
        TextField,
    },
    props: {
        value: {type: [Array, Number], default: () => []},
        isError: { default: false, type: Boolean },
    },
    data: function() {
        return {
            newEmail: "",
        };
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                if (val !== this.value) {
                    this.$emit('input', val);
                }
            },
            deep: true,
        },
    },
    watch: {
        value: function() {
            this.localValue = this.value;
        },
    },
    created: function() {
    },
    mounted: function() {
    },
    methods: {
        async add() {
            if (await this.$refs.validator.validate()) {
                if (!this.localValue.includes(this.newEmail) && this.newEmail.length > 5) {
                    this.localValue.push(this.newEmail.toLowerCase());
                } 
                this.newEmail = "";
            } else {
                this.$notify({
                    group: 'root',
                    type: 'error',
                    title: 'Erreur',
                    text: "Email invalide",
                });
            }
        },
        addEmailIfEmpty(email) {
            if (this.localValue.length === 0) {
                this.localValue.push(email.toLowerCase());
            }
        },
        remove(email) {
            this.localValue = this.localValue.filter(x => x !== email);
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

table.emails
    margin-bottom: 0
    padding-bottom: 0

.errors
    color: error-color

.action
    width: 120px
    padding-left: space-md

.add-email
    td
        padding-top: space-xs
</style>
