<template functional>
<component
   :is="injections.components.DataTable"
   :search-options="props.searchOptions"
   :pagination-options="props.paginationOptions"
   v-bind="data.attrs"
   v-on="listeners"
   >
  <template v-slot:table-header-row="props">
      <slot name="table-header-row" v-bind="props">
      </slot>
  </template>
  <template v-slot:table-row="props">
      <slot name="table-row" v-bind="props">
      </slot>
  </template>
  
  <div slot="table-actions-bottom"></div>
</component>
</template>

<script>
import DataTable from '@/components/data_table';

export default {
    props: {
        searchOptions: { default: () => { return { enabled: true }; } },
        paginationOptions: {
            default: () => {
                return {
                    enabled: true,
                    mode: 'records',
                    perPage: 10,
                    perPageDropdown: [10, 25, 200],
                };
            },
        },
    },
    inject: {
        components: {
            default: {
                DataTable,
            },
        },
    },
};
</script>
