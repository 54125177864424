<template>
<div class="professional-infos">

  <template v-if="!noProfessionalYet">
    <div class="smart-infos">
      <true-false
        class="is-published"
        :value="professional.isPublished"
        title-true="Visible sur l'annuaire public"
        title-false="Non visible sur l'annuaire public"
        />

      <font-awesome-icon
        v-if="!professional.allowToRegisterToBap"
        title="Accès non autorisé à la BAP"
        icon="ban"
        class="not-allowed-to-register-to-bap"
        />
      <true-false
        v-else
        class="bap-registration"
        :value="professional.isRegisteredOnBap"
        :icon-true="['fal', 'house']"
        :icon-false="['fas', 'sign-in']"
        title-true="Est enregistré sur la BAP"
        title-false="Autorisé mais n'est pas encore enregistré sur la BAP"
        />

      <true-false
        class="is-mar"
        :value="professional.isMonAccompagnateurRenov"
        icon-true="user-hard-hat"
        title-true="Est MAR"
        icon-false="ban"
        title-false="N'est pas MAR"
        />
      
      <true-false
        class="suscribed-to-newsletter"
        :value="professional.subscribedToNewsletter"
        icon-true="envelope"
        icon-false="comment-slash"
        title-true="Souhaite recevoir la newsletter"
        title-false="Ne souhaite pas recevoir la newsletter"
        />


      <true-false
        class="need-update"
        :value="professional.needUpdate"
        icon-true="exclamation-triangle"
        icon-false="exclamation-triangle"
        title-true="N'a pas de référence à jour"
        title-false="A au moins une référence à jour"
        />


      
    </div>

    <div class="name">{{ professional.professionalName }}</div>
    <div class="job">{{ professional.professionalJob }}</div>
    <img v-bind:src="professional.professionalLogo" width="60" height="60" @error="logoLoadError" class="logo" v-if="logoIsValid">
  </template>
  <template v-else>
    <div class="no-professional-yet">La fiche n'a pas encore été initialisée</div>
  </template>
  
  <div class="full-name">{{ professional.fullName }}</div>
  <div class="email">{{ professional.email }}</div>
  <div class="status" :class="professional.transitionState">{{ professional.statusAsLabel }}</div>
</div>
</template>

<script>
import TrueFalse from '@/components/true_false';
        
export default {
    name: 'ProfessionalInfos',
    props: {
        professional: { default: undefined, type: Object },
    },
    components: {
        TrueFalse,
    },
    data: function() {
        return {
            logoIsValid: true,
            noProfessionalYet: false,
        };
    },
    mounted() {
        if (this.professional.professionalName === null && this.professional.professionalJob === null) {
            this.noProfessionalExistsYet();
        }
    },
    methods: {
        logoLoadError() {
            console.log(this.professional.name, this.professional.professionalLogo);
            this.logoIsValid = false;
        },
        noProfessionalExistsYet() {
            this.logoIsValid = false;
            this.noProfessionalYet = true;
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/variables'

.professional-infos
    .logo
        float: left

    .name
        font-weight: bold

    .full-name
        text-align: right
        color: gray-600

    .email
        text-align: right
        color: gray-500

    .status
        text-align: right
        font-style: italic

    .status.initial
        color: body-font-color

    .status.draft
        color: body-font-color

    .status.in_annual_validation
        color: warning-color
        font-weight: bold

    .status.in_validation
        color: warning-color
        font-weight: bold

    .status.probationary
        color: body-font-color
        font-weight: bold

    .status.probationary_new
        color: body-font-color

    .status.published
        color: body-font-color

    .status.unpublished
        color: body-font-color

    .status.closed
        color: body-font-color

    .no-professional-yet
        color: error-color-600

    .smart-infos
        border: solid 1px gray-200
        border-radius: 8px
        float: right

        div
            display: inline-block

        .not-allowed-to-register-to-bap
            color: gray-500

        .bap-registration
            :deep(.error-icon)
                color: warning-color

        .is-mar
            :deep(.error-icon)
                color: gray-500

        .suscribed-to-newsletter
            :deep(.error-icon)
                color: gray-500

        .need-update
            :deep(.success-icon)
                color: warning-color

            :deep(.error-icon)
                color: gray-500
</style>
