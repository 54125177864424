<template>
<div class="liste-results-map">
  <action-link
    v-show="!fullscreen"
    label="Agrandir la carte"
    icon="search-plus"
    icon-position="left"
    color="text-color"
    @click="resizeMap"
    />
  <action-link
    v-show="fullscreen"
    label="Réduire la carte"
    icon="search-minus"
    icon-position="left"
    color="text-color"
    @click="resizeMap"
    />

  <l-map
    id="id_map"
    name="mapfield"
    ref="map"
    :center="center"
    :zoom="zoom"
    :zoomControl="true"
    >
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <v-marker-cluster>
      <template v-for="pro in professionalsForMap" >
        <l-marker :key=pro.id :lat-lng="pro.latLng" :icon="blue" :ref="getPopupRef(pro.id)" v-if="!favoritePro.includes(pro.id)">
          <l-popup>
            <div class="popup">
              <div>
                <img v-bind:src="pro.logo" width="100" height="150" @error="logoLoadError(pro.id)" v-show="showLogo(pro.id)"><br>
                <span class="bold">{{pro.name}}</span><br>
                {{ pro.address }}
              </div>
              <div>
                <router-link :to="{ name: 'ListeProPublicDetail', params: { pk: pro.id, breadCrumbsVisible : 'test' }}">
                  <regular-button class="button-popup">
                    voir la fiche <font-awesome-icon icon="chevron-circle-right" class="icon"/>
                  </regular-button>
                </router-link>
              </div>
            </div>
          </l-popup>
        </l-marker>
        <l-marker :key=pro.id :lat-lng="pro.latLng" :icon="green" :ref="getPopupRef(pro.id)" v-if="favoritePro.includes(pro.id)">
          <l-popup>
            <div class="popup">
              <div>
                <img v-bind:src="pro.logo" width="100" height="150" @error="logoLoadError(pro.id)" v-show="showLogo(pro.id)"><br>
                <span class="bold">{{pro.name}}</span><br>
                {{ pro.address }}
              </div>
              <div>
                <router-link :to="{ name: 'ListeProPublicDetail', params: { pk: pro.id, breadCrumbsVisible : 'test' }}">
                  <regular-button class="button-popup">
                    voir la fiche <font-awesome-icon icon="chevron-circle-right" class="icon"/>
                  </regular-button>
                </router-link>
              </div>
            </div>
          </l-popup>
        </l-marker>
      </template>
    </v-marker-cluster>
  </l-map>
</div>
</template>


<script>
import ActionLink from '@/components/action_link';
import RegularButton from '@/components/regular_button';
import { LMap, LTileLayer, LPopup, LMarker } from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet-draw/dist/images/marker-icon-2x.png';
import 'leaflet-draw/dist/images/marker-icon.png';

import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

import _ from 'lodash';

import { sync } from 'vuex-pathify';

  
export default {
    name: 'ListeResultsMap',
    components: {
        'v-marker-cluster': Vue2LeafletMarkerCluster,
        ActionLink,
        LMap,
        LMarker,
        LPopup,
        LTileLayer,
        RegularButton,
    },
    props: {
        professionals: { default: () => [], type: Array },
        focusOnProfessional: null,  // id of professional to center on the map
    },
    data: function() {
        return {
            errorOnLogos: [],
            fullscreen: false,
            zoom: 9,
            center: L.latLng(45.876252, 4.630377),
            url: 'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            blue: new L.Icon({
                iconUrl: '/static/img/marker-icon-2x-blue.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
            }),
            green: new L.Icon({
                iconUrl: '/static/img/marker-icon-2x-green.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
            }),
        };
    },
    computed: {
        favoritePro: sync("listepro/favoritePro"),
        focussedProfessional() {
            if (this.focusOnProfessional !== null) {
                const pro = this.professionals.find(x => x.pk === parseInt(this.focusOnProfessional));
                if (pro) {
                    return pro;
                }
            }
            return null;
        },
        professionalsForMap() {
            const proForMap = [];
            for (const pro of this.professionals) {
                proForMap.push(
                    ...this.getFormatedProfessionalWithAdditionalAddresses(pro)
                );
            }

            return proForMap;
        },
    },
    watch: {
        focusOnProfessional() {
            if (this.focusOnProfessional === null) {
                return;
            }
            
            const name = `popup-${this.focusOnProfessional}`;
            const position = L.latLng(
                this.focussedProfessional.geom.lat,
                this.focussedProfessional.geom.lon
            );

            // To allow the map to update before opening popup
            this.$refs.map.mapObject.panTo(position);
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.$refs.map.mapObject.setZoomAround(position, 17);
                        this.$nextTick(() => {
                            this.$nextTick(() => {
                                this.$nextTick(() => {
                                    this.$nextTick(() => {
                                        this.$nextTick(() => {
                                            this.$refs[name][0].mapObject.openPopup();
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
            
        },
    },
    methods: {
        getPopupRef(pk) {
            return `popup-${pk}`;
        },
        
        getFormatedProfessionalWithAdditionalAddresses(pro) {
            const pros = [];
            pros.push(
                this.formatProfessional(pro)
            );
            for (const additionalAddress of pro.additionalAddresses) {
                const item = _.cloneDeep(pro);
                
                item.id = `${item.id}-${additionalAddress.id}`;
                item.email = additionalAddress.email;
                item.url = additionalAddress.url;
                item.phoneNumber = additionalAddress.phoneNumber;
                item.address = additionalAddress.address;
                item.town = additionalAddress.town;
                item.postcode = additionalAddress.postcode;
                item.geom = additionalAddress.geom;
                        
                pros.push(
                    this.formatProfessional(item)
                );
            }
            
            return pros;
        },
        
        formatProfessional(pro) {
            return {
                id: pro.id,
                pk: pro.id,
                name: pro.name,
                email: pro.email,
                address: pro.address,
                logo: pro.logo,
                url: pro.url,
                latLng: L.latLng(pro.geom.lat, pro.geom.lon),
            };
        },
        
        logoLoadError(id) {
            this.errorOnLogos.push(id);
        },
        
        resizeMap() {
            this.fullscreen = !this.fullscreen;
            this.$emit('resize-map', this.fullscreen);
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.$refs.map.mapObject.invalidateSize();
                    });
                });
            });
        },
        
        showLogo(id) {
            return this.errorOnLogos.findIndex(x => x === id) === -1;
        },
    },
};
</script>

<style lang="stylus" scoped>
.liste-results-map
    height: 800px

.favorite
    font-size: 1.4rem

.button
    vertical-align: middle
    border-radius: 0.7rem
    color: #fff
    font-size: 0.8rem

.button-popup
    border-radius: 0.7rem
    color: #fff
    margin-left: 0.8rem
    margin-top: 1rem

.logo
    width: 100px

.popup
    width: 10rem
    font-family: 'D-DIN', Arial, sans-serif

.bold
    font-weight: bold
</style>
