import defaultState from './_defaultState';
import { updateField } from 'vuex-map-fields';

export default {
    RESET_STATE(state) {
        Object.assign(state, defaultState);
    },
    SET_RESULTS(state, data) {
        Object.assign(state, data);
    },
    updateField,
};
