<!--
Usage :

  <form-buttons
     @primaryAction="save"
     @secondaryAction="delete"
     @tertiaryAction="cancel"
     >
  </form-buttons>

Somewhere else in your form you can add a button form FormButtons

<portal-target name="form-buttons-tertiary"></portal-target>


All options :

  <form-buttons
     @primaryAction="save"
     @secondaryAction="delete"
     @tertiaryAction="cancel"
     :hidePrimary="false"
     :hideSecondary="false"
     :hideTertiary="false"
     primaryIcon="angle-left"
     secondaryIcon="trash-alt"
     tertiaryIcon="angle-left"
     :disable="disable"
     :disablePrimary="disablePrimary"
     :disableSecondary="disableSecondary"
     :disableTertiary="disableTertiary"
     :confirmActionPrimary="false"
     :confirmActionSecondary="false"
     :confirmActionTertiary="false"
     confirmActionPrimaryText="<p>Vous allez <b>sauvergarder cette entrée</b></br>Souhaitez-vous poursuivre ?</p>"
     confirmActionSecondaryText="<p>Vous êtes sur le point de <b>supprimer cette entrée</b></br>Souhaitez-vous poursuivre ?</p>"
     confirmActionTertiaryText="<p>Vous allez <b>perdre les modification apportées à cette entrée</b></br>Souhaitez-vous poursuivre ?</p>"
     >
    <template v-slot:primary>
      Enregistrer
    </template>
    <template v-slot:secondary>
      Supprimer
    </template>
    <template v-slot:tertiary>
      Annuler
    </template>
  </form-buttons>

Where :
- xxAction : function when xx button is clicked
- hideXX : hide button xx
- xxIcon : icon for button xx
- disableXx : disable xx button
- disable : disable all buttons

-->
<template>
  <div class="buttons">
    <!-- Portals -->
    <portal to="form-buttons-tertiary">
        <action-link
            v-if="!hideTertiary"
            class="color-link"
            @click="tertiaryActionButton"
            :label="tertiaryLabel"
            icon="angle-left"
            />
    </portal>
    <portal to="form-buttons-secondary">
      <button
        v-if="!hideSecondary"
        type="button"
        class="button secondary"
        :class="{ disabled: secondaryIsDisabled }"
        @click.prevent="secondaryActionButton"
      >
        <font-awesome-icon v-if="secondaryIcon" :icon="secondaryIcon" />
        <slot name="secondary">{{ secondaryLabel }}</slot>
      </button>
    </portal>
    <portal to="form-buttons-primary">
      <button
        v-if="!hidePrimary"
        type="button"
        class="submit button primary"
        :class="{ disabled: primaryIsDisabled }"
        @click.prevent="primaryActionButton"
      >
        <font-awesome-icon v-if="primaryIcon" :icon="primaryIcon" />
        <slot name="primary">{{ primaryLabel }}</slot>
      </button>
    </portal>
    <!-- /Portals -->

    <!-- Regular buttons -->
    <div class="grid-x buttons-container align-justify">
      <div class="cell shrink">
          <action-link
            v-if="!hideTertiary && !tertiaryOnlyInPortal"
            class="color-link"
            :class="{ disabled: tertiaryIsDisabled }"
            @click="tertiaryActionButton"
            :label="tertiaryLabel"
            icon="angle-left"
            />
      </div>
      <div class="cell shrink grid-x">
        <div class="cell shrink">
          <button
            v-if="!hideSecondary"
            type="button"
            class="button secondary"
            :class="{ disabled: secondaryIsDisabled }"
            @click.prevent="secondaryActionButton"
          >
            <font-awesome-icon v-if="secondaryIcon" :icon="secondaryIcon" />
            <slot name="secondary">{{ secondaryLabel }}</slot>
          </button>
        </div>
        <div class="cell shrink">
          <button
            v-if="!hidePrimary"
            type="button"
            class="submit button primary"
            :class="{ disabled: primaryIsDisabled }"
            @click.prevent="primaryActionButton"
          >
            <font-awesome-icon v-if="primaryIcon" :icon="primaryIcon" />
            <slot name="primary">{{ primaryLabel }}</slot>
          </button>
        </div>
      </div>
    </div>

    <!-- /Regular buttons -->
  </div>
</template>

<script>
import _ from 'lodash';

import ActionLink from '@/components/action_link';

const CLICK_DELAY = 300;
const debounce = fn => _.debounce(fn, CLICK_DELAY);

export default {
    name: 'FormButtons',
    components: {
        ActionLink,
    },
    inject: [ 'showGenericDialog', 'hideGenericDialog' ],
    props: {
        disable: { default: false },
        disablePrimary: { default: false },
        disableSecondary: { default: false },
        disableTertiary: { default: false },
        primaryIcon: { default: 'save' },
        secondaryIcon: { default: 'trash-alt' },
        tertiaryIcon: { default: 'angle-left' },
        primaryLabel: { default: 'Enregistrer' },
        secondaryLabel: { default: 'Supprimer' },
        tertiaryLabel: { default: 'Annuler' },
        hidePrimary: { default: false },
        hideSecondary: { default: false },
        hideTertiary: { default: false },
        tertiaryOnlyInPortal: { default: false },
        confirmActionPrimary: { default: false },
        confirmActionSecondary: { default: false },
        confirmActionTertiary: { default: false },
        confirmActionPrimaryText: {
            default: '<p>Vous allez <b>sauvegarder cette entrée</b></br>Souhaitez-vous poursuivre ?</p>',
        },
        confirmActionSecondaryText: {
            default: '<p>Vous êtes sur le point de <b>supprimer cette entrée</b></br>Souhaitez-vous poursuivre ?</p>',
        },
        confirmActionTertiaryText: {
            default:
                '<p>Vous allez <b>perdre les modification apportées à cette entrée</b></br>Souhaitez-vous poursuivre ?</p>',
        },
    },
    computed: {
        primaryIsDisabled() {
            return this.disablePrimary || this.disable;
        },
        secondaryIsDisabled() {
            return this.disableSecondary || this.disable;
        },
        tertiaryIsDisabled() {
            return this.disableTertiary || this.disable;
        },
    },
    methods: {
        primaryActionButton: debounce(function() {
            if (!this.primaryIsDisabled) {
                if (this.confirmActionPrimary) {
                    this.confirmAction('primaryAction', this.confirmActionPrimaryText);
                } else {
                    this.$emit('primaryAction');
                }
            }
        }),
        secondaryActionButton: debounce(function() {
            if (!this.secondaryIsDisabled) {
                if (this.confirmActionSecondary) {
                    this.confirmAction('secondaryAction', this.confirmActionSecondaryText);
                } else {
                    this.$emit('secondaryAction');
                }
            }
        }),
        tertiaryActionButton: debounce(function() {
            if (!this.tertiaryIsDisabled) {
                if (this.confirmActionTertiary) {
                    this.confirmAction('tertiaryAction', this.confirmActionTertiaryText);
                } else {
                    this.$emit('tertiaryAction');
                }
            }
        }),
        confirmAction: function(signal, text) {
            this.showGenericDialog({
                title: 'Attention !',
                text: text,
                buttons: [
                    {
                        title: 'Oui',
                        default: true,
                        handler: () => {
                            this.$emit(signal);
                        },
                    },
                    {
                        title: 'Non',
                    },
                ],
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../stylesheet/variables'
@require '../stylesheet/typography'

.buttons
    margin-top: space-md

.button.primary
    background-color: success-color-400
    color: success-color-800
    box-shadow: 0 4px 6px hsla(0, 0%, 0%, 0.2), inset 0 1px 0px hsl(120, 50%, 84%)

.button.secondary
    background-color: #dadada
    color: #555
    box-shadow: 0 4px 6px hsla(0, 0%, 0%, 0.2), inset 0 1px 0px hsl(0, 0%, 94%)
    margin-left: space-md
    margin-right: space-md

.button.tertiary
    background-color: unset !important
    color: #666
    text-align: left
    padding-left: 0 !important

.button.primary:hover
.button.secondary:hover
    filter: brightness(90%)

.button.primary:hover
    box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.2), inset 0 1px 0px hsl(120, 50%, 84%)

.button.secondary:hover
    box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.2), inset 0 1px 0px hsl(0, 0%, 94%)

.button.tertiary:hover
    color: #888
    font-weight: bold

.button.disabled
    opacity: 0.25
    cursor: not-allowed

.button.disabled.primary
    background-color: success-color
    color: #333

.button.disabled.secondary
    background-color: #dadada
    color: #555

.button.disabled.tertiary
    background-color: unset !important
    color: #666

.buttons-container
    align-items: center
    gap: space-md

.button
    margin: 0
</style>
