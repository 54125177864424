const api = {
    createClientAndContactWithRgpd(data) {
        return new Promise((resolve, reject) => {
            this.apiClient().post('/dialogwatt/create-client-and-contact-with-rgpd/', data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export default api;
