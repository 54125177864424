<template>
<div :class="{disabled: disabled}" :disabled="disabled" v-if="localPresentation!==null">
  
<h2 class="title">DESCRIPTION DE VOS ACTIVITÉS</h2>

<div class="grid-x grid-margin-x" v-if="!disabled">
  <div class="cell">
    <div class="error" v-if="formErrorsMessages.all">
      {{formErrorsMessages.all}}
    </div>
  </div>
</div>

<div class="grid-x grid-margin-x" v-if="localPresentation != null">
  <div class="cell small-12 medium-8">
    <label class="form-label">Vos domaines d'activités :</label>
    <p class="form-helper">{{helperActivity1}}</p>
    <select-field
      :class="{'field-is-different': isFieldIsDifferent('activities')}"
      id="activities"
      :options="activities.asList()"
      optionLabel="name"
      multiple
      mandatory
      validateRules="required"
      :scope="'localPresentation'"
      v-model="localPresentation.activities"
      placeholder="Sélectionner vos domaines d'activité"
      :close-on-select="false"
      :clear-on-select="true"
      :preserve-search="false"
      :hide-selected="false"
      :reduce="(x) => x.pk"
      :isError="formErrors.activities"
      >
    </select-field>
  </div>

  <div class="cell" v-if="useMission">
    <label class="form-label">Missions proposées :</label>
    <div class="form-helper">{{helperMission}}</div>
    <multiselect-field
      :class="{'field-is-different': isFieldIsDifferent('subMissions')}"
      v-if="showMission"
      id="missions"
      :options="sortedSubMissions"
      optionLabel="name"
      multiple
      mandatory
      validateRules="required"
      :scope="'localPresentation'"
      v-model="localPresentation.subMissions"
      placeholder="Sélectionner vos missions"
      :close-on-select="false"
      :clear-on-select="true"
      :preserve-search="false"
      :hide-selected="false"
      :searchable="true"
      :preselect-first="true"
      group-values="submission"
      group-label="mission"
      :group-select="false"
      track-by="pk"
      :isError="formErrors.subMissions"
      >
      <template v-slot:option="subMission">
        <font-awesome-icon v-if="subMission.option.name && checkOptions(localPresentation.subMissions, subMission.option.name)" icon="check" class="icon-check" />
        <span v-if="subMission.option.name && !checkOptions(localPresentation.subMissions, subMission.option.name)">&emsp;&ensp;</span>
        <span class="category" v-if="subMission.option.$groupLabel">{{subMission.option.$groupLabel}}</span>
        <span v-if="subMission.option.name">{{subMission.option.name}}</span>
      </template>
    </multiselect-field>
    <div
      v-else
      :class="{'field-is-different': isFieldIsDifferent('subMissions')}"
      >
      Sélectionnez auparavant un segment de marché et au moins un domaine d'activité.
    </div>
  </div>
  <div class="cell">
    <label class="form-label">Description de votre entreprise / équipe / activité <span class="text-italic text-unbold text-sm">(max 5000 caractères)</span></label>

    <wysiwyg-field
      :class="{'field-is-different': isFieldIsDifferent('description')}"
      id="description"
      v-model="localPresentation.description"
      validateRules="required|max:5000"
      :scope="'localPresentation'"
      :isError="formErrors.description"
      :disabledExtensions="{headings: true, tables: true, blocks: true, links: true}"
      :allowRawEdit="false"
    />
  </div>
  <div class="cell">
    <h2 class="title">DÉFINISSEZ-VOUS PAR DES MOTS-CLÉS</h2>
    <p class="form-helper">{{helperKeyWord}}</p>
    <h3 class="subtitle">SÉLECTIONNEZ VOS MOTS-CLÉS PRINCIPAUX (5 MAXIMUM)</h3>
    <p class="form-helper">{{helperMainKeyWord}}</p>

    <div class="cell" :class="{'field-is-different': isFieldIsDifferent('primaryKeyWords')}">
      <span v-bind:key=kw.id v-for="kw in tempPrimaryKW">
        <span class="multiselect__tag">
          <span>{{kw.name}}</span>
          <i class="multiselect__tag-icon" @click="deleteKW(kw)"></i>
        </span>
      </span>
    </div>
    <div class="cell">
      <regular-button class="button-kew-word" @click="prepareKW();">
        Modifier vos 5 mots-clés principaux
      </regular-button>
    </div>

  </div>
</div>

<DialogWrapper
  ref="modalKeyWords"
  :adaptive="false"
  :width="1400"
  height="auto"
  clickToClose
  :scrollable="true"
>
  <div>
    <modal-key-words
      @hide-modal="hideModalKeyWords"
      @valid-kw="validKW"
      :keyWordArray="keyWordModal"
      :keyWordCurrent="keyWordCurrent"
      :helper="helperModal"
      :nbMax="nbMaxKWModal"
    />
  </div>
</DialogWrapper>
    
</div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';

import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';

import SelectField from '@/components/select_field';
import MultiselectField from '@/components/multiselect_field';
import RegularButton from '@/components/regular_button';
import ModalKeyWords from '@/apps/listepro/professional_form/modal_key_words';
import { SubMissionStoreCollection } from '@/models/listepro/sub_mission';
import { ActivityStoreCollection } from '@/models/listepro/activity';
import { JobStoreCollection } from '@/models/listepro/job';
import { MissionStoreCollection } from '@/models/listepro/mission';
import { KeyWordStoreCollection } from '@/models/listepro/key_word';
import { KeyWordCategoryStoreCollection } from '@/models/listepro/key_word_category';
import { SegmentActivitySubMissionLinkCollection } from '@/models/listepro/segment_activity_submission_link';
import { mapFields } from 'vuex-map-fields';
import WysiwygField from '@/components/wysiwyg_field';

export default {
    name: 'PresentationData',
    mixins: [ handleFormErrorsMixin ],
    components: {
        ModalKeyWords,
        MultiselectField,
        RegularButton,
        SelectField,
        WysiwygField,
    },
    props: {
        value: Object,
        differences: {
            type: Array,
            default(){
                return [];
            },
        },
        disabled: { default: false, type: Boolean },
    },
    data() {
        return {
            localPresentation: null,
            
            activities: new ActivityStoreCollection(),
            keyWords: new KeyWordStoreCollection,
            keyWordsCategories: new KeyWordCategoryStoreCollection,
            jobs: new JobStoreCollection(),
            links : new SegmentActivitySubMissionLinkCollection(),
            subMissions: new SubMissionStoreCollection(),
            missions:new MissionStoreCollection(),
            helperActivity1: "Sélectionnez vos domaines d'activités sur lesquels vous souhaitez vous positionner en priorité et pour lesquels vous avez fourni des références. ",
            helperMission: "Sélectionnez les missions que vous proposez et pour lesquelles vous avez fourni des références auprès de l'Espace Info Énergie. ",
            helperKeyWord : "L'emploi des mots-clés doit correspondre aux activités pour lesquelles vous êtes en capacité de nous fournir des références.",
            helperMainKeyWord : "Ces mots-clés principaux apparaitront directement dans l'annuaire via votre vignette de présentation.",
            keyWordModal:[],
            keyWordCurrent:[],
            keyWordType :"",
            helperModal:"",
            nbMaxKWModal:0,
        };
    },
    computed: {
        ...mapFields([ 'user' ]),
        sortedSubMissions: function() {
            const listM = this.missions.asList();
            const listS = this.filteredSubMissions;
            const sorted = [];
            for(const indexM in listM){
                const subGroup = [];
                for(const indexS in listS){
                    if(listS[indexS].mission === listM[indexM].id){
                        subGroup.push(listS[indexS]);
                    }
                }
                sorted.push({"mission":listM[indexM].name, "submission":subGroup});
            }
            return sorted;
        },

        userIsAllowed : function(){
            if(this.user &&  ["advisor", "administrator", "superadvisor", "listeproadmin"].includes(this.user.userType)) {
                return true;
            }
            return false;
        },
        
        formType() {
            return this.localPresentation?.job?.form || 'generic';
        },
        
        useMission() {
            const flag = {
                'generic': true,
                'bank': false,
                'broker': false,
                'real_estate_agency': false,
            };
            let value = flag[this.formType];
            if (value === undefined) {
                console.error(`useMission: missing key "${this.formType}"`);
                value = true;
            }
            return value;
        },
        
        showMission: function() {
            return this.localPresentation.activities.length > 0 && this.localPresentation !== undefined;
        },
        
        filteredSubMissions: function() {
            // FIND A BETTER WAY TO FILTER
            const listAct = this.localPresentation.activities;
            const listeSub=this.subMissions.asList();
            const listLinks=this.links.asList();
            const filtered=[];

            for(const indexSub in listeSub){
                // eslint-disable-next-line no-labels
                loop: for(const indexLinks in listLinks){
                    for(const indexAct in listAct){
                        if(
                            listLinks[indexLinks].activity === listAct[indexAct]
                                && listLinks[indexLinks].subMission === listeSub[indexSub].id
                        ) {
                            filtered.push(listeSub[indexSub]);
                            // eslint-disable-next-line no-labels
                            break loop;
                        } 
                    }
                }
            }
            return filtered;
        },
        sortedPrimaryKeyWords: function() {
            let listeC=this.keyWordsCategories.asList();
            let listeKW=this.keyWords.asList();

            listeC = _.sortBy(listeC, ['order', 'name']);
            listeKW = _.sortBy(listeKW, ['order', 'category', 'name']);
            
            const sorted=[];
            for(const indexC in listeC){
                const subGroup=[];
                for(const indexKW in listeKW){
                    if(listeKW[indexKW].category===listeC[indexC].id){
                        subGroup.push(listeKW[indexKW]);
                    }
                }
                sorted.push({"category":listeC[indexC].name, "keyword":subGroup});
            }
            return sorted;
        },
        getActivities: function() {
            const activities=[];
            activities.push(this.localPresentation?.activityFirst);
            activities.push(this.localPresentation?.activitySecond);
            activities.push(this.localPresentation?.activityThird);
            activities.push(this.localPresentation?.activityFourth);
            return activities.filter(x => x !== null);
        },
        jobsAsList: function() {
            const list = this.jobs.asList();
            list.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                } else if (a.order === b.order) {
                    if (a.name < b.name) {
                        return -1;
                    } else if (a.name === b.name) {
                        return 0;
                    } else {
                        return 1;
                    }
                } else {
                    return 1;
                }
            });
            return list;
        },
        tempPrimaryKW: {
            get: function() {
                return this.localPresentation.primaryKeyWords;
            },
            set: function(value) {
                this.localPresentation.primaryKeyWords=value;
            },
        },
    },
    watch: {
        value: {
            handler(value) {
                if (value !== null && value !== undefined) {
                    this.localPresentation = value;
                }
                else {
                    Vue.set(
                        this,
                        'localPresentation',
                        {
                            "activities":[],
                            "description":"",
                            "primaryKeyWords":[],
                        }
                    );
                }
            },
            immediate: true,
            deep: true,
        },
        localPresentation: {
            handler(value) {
                if (value !== null && value !== undefined) {
                    this.$emit('input', value);
                }
            },
            deep: true,
        },
    },
    created: function() {},
    mounted: function() {
        this.links.load();
        this.subMissions.load();
        this.activities.load();
        this.jobs.load();
        this.missions.load();
        this.keyWords.load();
        this.keyWordsCategories.load();
    },
    methods: {
        isFieldIsDifferent(id) {
            return this.differences.includes(id);
        },
        createProfessional() {
            this.$emit('professionalCreated', this.localPresentation);
        },
        checkOption(userChoice, optionName) {
            if(userChoice && optionName === userChoice.name){
                return true;
            }
            return false;
        },
        checkOptions(userChoice, optionName) {
            for (const index in userChoice){
                if(userChoice && optionName === userChoice[index].name){
                    return true;
                }
            }
            return false;
        },
        styleDisabled(currentOption) {
            const activities = this.getActivities.map(x => x.pk);
            return !activities.includes(currentOption.pk);
        },
        clearActivities(order) {
            if (order === 1){
                this.localPresentation.activityFirst=null;
            }
            if (order===1||order===2){
                this.localPresentation.activitySecond=null;
            }
            if (order===1||order===2||order===3){
                this.localPresentation.activityThird=null;
            }
            this.localPresentation.activityFourth=null;
        },
        activityDisabled(currentActIndex) {
            const activities = this.getActivities;
            return currentActIndex >= activities.length + 2;
        },
        prepareKW(){
            this.keyWordModal=this.sortedPrimaryKeyWords;
            this.helperModal=this.helperMainKeyWord;
            this.nbMaxKWModal=5;
            if(this.tempPrimaryKW){
                this.keyWordCurrent=this.tempPrimaryKW;
            }
            this.showModalKeyWords();
        },
        validKW(tempKW){
            this.tempPrimaryKW=tempKW;
        },
        deleteKW(keyWord){
            const indexP = this.tempPrimaryKW.indexOf(keyWord);
            if (indexP > -1) {
                this.tempPrimaryKW.splice(indexP, 1);
            }
        },
        hideModalKeyWords() {
            this.$refs.modalKeyWords.hide();
        },
        showModalKeyWords() {
            this.$refs.modalKeyWords.show();
        },
    },
};


</script>
<style lang='stylus' scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/typography'

:deep(.vs__selected)
    background: var(--gray-600)
    color: white

    button.vs__deselect
        margin-left: 8px
        margin-right: -10px
        width: 25px
        fill: #fff
        scale: 75%

.field-is-different
    border: solid 4px warning-color
    padding-left: space-md

.test
    pointer-events: none

.form-control
    cursor: not-allowed // Trouver un équivalent

.form-label
    font-weight: bold
    margin-top: 0.5rem

.form-helper
    font-size: 0.8125rem
    font-style: italic
    color: #6e6e6e

.modal-header
    padding: 1rem 0.3rem 1rem 0.3rem
    background-color: primary-color-500
    margin-bottom: 1rem

.modal-title
    color: #fff
    text-transform: uppercase
    padding: 3%

.number-select
    margin: 0 0 0 1rem
    font-size: 0.875rem
    border-radius: 1.2rem
    padding: 0.4rem
    height: 2.2rem
    background-color: primary-color-500
    color: white

.act
    color: #adadad

.icon-check
    height: 0.8rem

.category
    font-weight: bold

.icon
    height: 1rem
    margin: 0.6rem 0.8rem 0 0

.button-right
    float: right
    padding: 1.2rem 1.2rem
    font-size: 1.1rem
    margin-top: 1.5rem

.button-kew-word
    padding: 1.2rem 1.2rem
    font-size: 1.1rem
    margin-bottom: 2.5rem

.logo
    margin-right: 2rem
    border: 0.2rem solid gray-500
    border-style: double
    vertical-align: middle
    width: 100px
    height: 85px

.warning-card
    margin-bottom: 1rem

.float-center
    margin-left: 6rem

.popup-content
    margin-left: 0.5rem

.primary
    background-color: red
    color: #fff

input[type='checkbox']
    margin-bottom: 0.5rem
    -webkit-appearance: none
    height: 15px
    width: 15px
    background: #fff
    border: 1px solid gray
    border-radius: 4px
    vertical-align: middle
    transition-duration: 0.5s
    margin-top: 0.5rem
    cursor: pointer

input[type='checkbox']:checked
    background-color: primary-color-500
    transition-duration: 0.5s
    border: 1px solid primary-color-500

    &:before
        // font-family: 'Font Awesome'
        // content: '\f138'
        content: 'X'
        display: block
        color: #fff
        font-size: 12px
        margin-left: 0.2rem
        font-weight: bold
</style>
