import { calculate } from '@/apps/therminix/calculator';

export default {

    proposedSolutions(state, getters, rootState) {
        const area = rootState.thermix.metroGrenoble.area;
        const housingType = rootState.therminix.housingType;
        const renovationType = rootState.therminix.renovationType;
        return rootState.therminix.cases?.[area]?.[housingType]?.[renovationType] || [];
    },

    computedResults(state, getters, rootState, rootGetters) {
        return getters.proposedSolutions.map((proposedSystems) => {
            if (rootGetters['therminix/isMultiUnit']) return proposedSystems;

            const needs = {
                heatingNeeds: rootState.therminix.heatingNeeds,
                hotWaterNeeds: rootState.therminix.hotWaterNeeds,
            };
            const currentSystems = {
                heatingSystem: { identifier: rootState.therminix.heatingSystemIdentifier },
                hotWaterSystem: { identifier: rootState.therminix.hotWaterSystemIdentifier },
            };

            const currentValues = calculate(needs, currentSystems);
            const estimatedValues = calculate(needs, proposedSystems);
            const savedValues = {
                combustibleCostFirstYear: currentValues.combustibleCostFirstYear - estimatedValues.combustibleCostFirstYear,
                carbonEmissionByYear: currentValues.carbonEmissionByYear - estimatedValues.carbonEmissionByYear,
            };

            return {
                ...proposedSystems,
                currentValues,
                estimatedValues,
                savedValues,
            };
        });
    },
};
