
import apiClient from '@/api/api';

export const searchMixin = {
    data() {
        return {
            maxStep: 3,
            searchTreeLoading: false,
            // Must be defined in mixin user
            // searchTree: [],
            // selectedActivity: null,
            // selectedMission: null,
            // selectedSubMission: null,
        };
    },
    computed: {
        activities() {
            return this.searchTree;
        },
        missions() {
            return this.activity.missions;
        },
        subMissions() {
            if (this.mission) {
                return this.mission.subMissions;
            }
            return [];
        },
        activity() {
            if (this.selectedActivity) {
                return this.activities.find(x => (x.pk === this.selectedActivity));
            }
            return null;
        },
        mission() {
            if (this.selectedMission) {
                return this.missions.find(x => (x.pk === this.selectedMission));
            }
            return null;
        },
        subMission() {
            if (this.selectedSubMission) {
                return this.subMissions.find(x => (x.pk === this.selectedSubMission));
            }
            return null;
        },
        matchingProfessionalValidationWorkflows() {
            if (
                this.searchTree.length > 0 &&
                    this.selectedActivity !== null &&
                    this.selectedMission !== null &&
                    this.selectedSubMission !== null
            ) {
                try {
                    return this.subMission.professionals;
                } catch {
                    return [];
                };
            }
            return [];
        },
        currentStep() {
            if (this.selectedSubMission) {
                return 4;
            }
            if (this.selectedMission) {
                return 3;
            }
            if (this.selectedActivity) {
                return 2;
            }
            return 1;
        },
    },

    methods: {
        loadSearchTree() {
            this.searchTreeLoading = true;
            apiClient.genericGet('/listepro/public/search-tree/')
                .then(response => {
                    this.searchTree = response;
                })
                .catch(error => {
                    this.$notify({
                        group: 'root',
                        type: 'error',
                        title: 'Erreur serveur',
                        text: 'La liste des professionnels n\'a pas put être chargée.',
                    });
                    console.error(error);
                }).finally(() => {
                    this.searchTreeLoading = false;
                });
        },
        resetSearch() {
            this.currentStep = 1;
            this.showResults = false;
            this.subMission = null;
            this.activity = null;
            this.mission = null;
        },
        goBack() {
            const props = {
                2: 'selectedActivity',
                3: 'selectedMission',
                4: 'selectedSubMission',
            };
            this[props[this.currentStep]] = null;
        },
        setSelection(choice, step, forceFirst = false) {
            if (step === 1) {
                this.selectedActivity = choice;
                if (forceFirst) {
                    this.keepCurrentMissionOrSelectFirst();
                } else {
                    this.selectedMission = null;
                    this.selectedSubMission = null;
                }
            } else if (step === 2) {
                this.selectedMission = choice;
                if (forceFirst) {
                    this.keepCurrentSubMissionOrSelectFirst();
                } else {
                    this.selectedSubMission = null;
                }
            } else {
                this.selectedSubMission = choice;
            }
        },
        keepCurrentActivityOrSelectFirst() {
            if (!this.activities.find(x => x === this.selectedActivity)) {
                this.selectedActivity = this.activities[0];
            }
            this.keepCurrentMissionOrSelectFirst();
        },
        keepCurrentMissionOrSelectFirst() {
            const newMissions = this.activities.find(x => (x.pk === this.selectedActivity)).missions.map(x => x.id);
            if (!newMissions.find(x => x === this.selectedMission)) {
                this.selectedMission = newMissions[0];
            }
            this.keepCurrentSubMissionOrSelectFirst();
        },
        keepCurrentSubMissionOrSelectFirst() {
            const newSubMissions = this.missions.find(x => (x.pk === this.selectedMission)).subMissions.map(x => x.id);
            this.selectedSubMission = newSubMissions[0];
        },
    },
};
