<template>
<div class="content second-plan">
  <spinner :active="professionalsLoading" />

  <div class="content result-container">
    <div class="result-cards" v-show="!fullscreenMap">
      <liste-results
        :professionals="professionals"
        :allMode="true"
        @locate-professional="setFocusOnProfessional"
        />
    </div>
    <div :class="{ 'result-map': !fullscreenMap, 'result-map-fullscreen': fullscreenMap }">
      <liste-results-map
        class="map-object"
        :focusOnProfessional="focusOnProfessional"
        :professionals="professionals"
        @resize-map="resizeMap"
        />
        
    </div>
  </div>

</div>
</template>

<script>
import apiClient from '@/api/api';

import Spinner from '@/components/spinner';
import ListeResults from '@/apps/listepro/public/search/liste_results';
import ListeResultsMap from '@/apps/listepro/public/search/liste_results_map';

export default {
    name: 'ListeAll',
    data() {
        return {
            professionalsLoading: false,
            professionals: [],
            focusOnProfessional: null,
            fullscreenMap: false,
        };
    },
    provide() {
        return { parentValidator: this.$validator };
    },
    components: {
        ListeResults,
        ListeResultsMap,
        Spinner,
    },
    computed: {
    },
    mounted: function() {
        this.loadProfessionals();
    },
    watch: {
    },
    methods: {
        loadProfessionals() {
            this.professionalsLoading = true;
            apiClient.genericList(`/listepro/public/professional/`)
                .then(response => {
                    this.professionals = response;
                })
                .catch(error => {
                    console.debug(error);
                    this.$notify({
                        group: 'root',
                        type: 'error',
                        title: 'Erreur serveur',
                        text: 'La liste des professionnels n\'a pas pu être chargée.',
                    });
                })
                .finally( () => {
                    this.professionalsLoading = false;
                });
            
        },
        resizeMap(fullscreen) {
            this.fullscreenMap = fullscreen;
        },
        
        setFocusOnProfessional(professionalPk) {
            this.focusOnProfessional = null;
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.focusOnProfessional = professionalPk;
                });
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/typography'

.result-container
    padding: 0 space-md 0 space-md
    // background-color: red !important
    display: grid
    grid-template-columns: minmax(365px, 780px) auto
    grid-gap: 20px

    .result-cards
        padding-top: 27px
        padding-bottom: space-xl
        grid-column: 1
        grid-row: 1
        // min-width: 362px
        // width: 362px

    .result-map
        height: 100%
        grid-column: 2
        grid-row: 1

        :deep(.liste-results-map)
            position: sticky
            top: 0

    .result-map-fullscreen
        height: 100%
        grid-column: 1 / 3
        grid-row: 1

    @media (max-width: 600px)
        .result-map
            display: none

.content
    background-color: gray-100
    padding-top: 0.5rem
</style>
