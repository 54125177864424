// import { getField } from 'vuex-map-fields';

export default {
    // getField,
    isInformationsValid(state, getters) {
        let isValid = (
            state.housingType &&
            state.renovationType
        );
        if (getters.isMultiUnit) return isValid;

        isValid = (
            isValid &&
            state.floorSurface &&
            state.heatingSystemIdentifier &&
            state.heatingNeeds
        );

        if (!getters.isHotWater) return isValid;

        return (isValid && state.hotWaterSystemIdentifier && state.hotWaterNeeds);
    },

    isNewBuilding(state) {
        return state.renovationType === 'new_building';
    },

    isMultiUnit(state) {
        return state.housingType === 'flatInMultiUnit';
    },

    isHotWater(state) {
        return state.renovationType !== 'heating_system_renovation';
    },

    heatingSystem(state, getters, rootState) {
        return rootState.energies.productionSystemCollectionByIdentifier[state.heatingSystemIdentifier];
    },

    hotWaterSystem(state, getters, rootState) {
        if (state.hotWaterSystem === 'synced-with-heating') {
            return getters.heatingSystem;
        }
        return rootState.energies.productionSystemCollectionByIdentifier[state.hotWaterSystemIdentifier];
    },

};
