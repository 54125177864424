/*
    Autogenerated class model from django ProductionSystem
    -- 2020-02-10 10:50:39.385223
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';
import { loadThenStoreByIdentifier } from './_helpers';

class ProductionSystemClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'identifier', rawData.identifier);
        Vue.set(this, 'energy', rawData.energy);
        Vue.set(this, 'isHeating', rawData.isHeating);
        Vue.set(this, 'isHotWater', rawData.isHotWater);
        Vue.set(this, 'isIndividual', rawData.isIndividual);
        Vue.set(this, 'isMultiUnit', rawData.isMultiUnit);
        Vue.set(this, 'isHydro', rawData.isHydro);
        Vue.set(this, 'efficiencyHeating', rawData.efficiencyHeating);
        Vue.set(this, 'efficiencyHotWater', rawData.efficiencyHotWater);
        Vue.set(this, 'enrRatioHeating', rawData.enrRatioHeating);
        Vue.set(this, 'enrRatioHotWater', rawData.enrRatioHotWater);
        Vue.set(this, 'investmentIndividualHeating', rawData.investmentIndividualHeating);
        Vue.set(this, 'investmentIndividualHotWater', rawData.investmentIndividualHotWater);
        Vue.set(this, 'maintenanceIndividualHeating', rawData.maintenanceIndividualHeating);
        Vue.set(this, 'maintenanceIndividualHotWater', rawData.maintenanceIndividualHotWater);
        Vue.set(this, 'investmentSmallMultiUnitHeating', rawData.investmentSmallMultiUnitHeating);
        Vue.set(this, 'investmentSmallMultiUnitHotWater', rawData.investmentSmallMultiUnitHotWater);
        Vue.set(this, 'maintenanceSmallMultiUnitHeating', rawData.maintenanceSmallMultiUnitHeating);
        Vue.set(this, 'maintenanceSmallMultiUnitHotWater', rawData.maintenanceSmallMultiUnitHotWater);
        Vue.set(this, 'provisionsSmallMultiUnitHeating', rawData.provisionsSmallMultiUnitHeating);
        Vue.set(this, 'provisionsSmallMultiUnitHotWater', rawData.provisionsSmallMultiUnitHotWater);
        Vue.set(this, 'investmentMediumMultiUnitHeating', rawData.investmentMediumMultiUnitHeating);
        Vue.set(this, 'investmentMediumMultiUnitHotWater', rawData.investmentMediumMultiUnitHotWater);
        Vue.set(this, 'maintenanceMediumMultiUnitHeating', rawData.maintenanceMediumMultiUnitHeating);
        Vue.set(this, 'maintenanceMediumMultiUnitHotWater', rawData.maintenanceMediumMultiUnitHotWater);
        Vue.set(this, 'provisionsMediumMultiUnitHeating', rawData.provisionsMediumMultiUnitHeating);
        Vue.set(this, 'provisionsMediumMultiUnitHotWater', rawData.provisionsMediumMultiUnitHotWater);
        Vue.set(this, 'investmentLargeMultiUnitHeating', rawData.investmentLargeMultiUnitHeating);
        Vue.set(this, 'investmentLargeMultiUnitHotWater', rawData.investmentLargeMultiUnitHotWater);
        Vue.set(this, 'maintenanceLargeMultiUnitHeating', rawData.maintenanceLargeMultiUnitHeating);
        Vue.set(this, 'maintenanceLargeMultiUnitHotWater', rawData.maintenanceLargeMultiUnitHotWater);
        Vue.set(this, 'provisionsLargeMultiUnitHeating', rawData.provisionsLargeMultiUnitHeating);
        Vue.set(this, 'provisionsLargeMultiUnitHotWater', rawData.provisionsLargeMultiUnitHotWater);
    }

    getBaseUrl() {
        return '/energies/production-system/';
    }

    get isValid() {
        return true;
    }
}

class ProductionSystemCollection extends BaseCollection {
    initModel(rawData) {
        return new ProductionSystemClass(rawData);
    }

    getBaseUrl() {
        return '/energies/production-system/';
    }
}

class ProductionSystemStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new ProductionSystemClass(rawData);
    }

    getBaseUrl() {
        return '/energies/production-system/';
    }

    getStorePath() {
        return 'energies/productionSystemCollection';
    }

    load(filter = null) {
        return loadThenStoreByIdentifier(this, super.load, 'identifier', filter);
    }
}

export {
    ProductionSystemClass,
    ProductionSystemCollection,
    ProductionSystemStoreCollection,
};
