<!--
Usage :

    <user-avatar
       :username="fullName"
       :profilePic="profilePicPath"
       :active="isActive"
       :size="35"
       :color="#fff"
       >
    </user-avatar>

where :

- username : username to display
- profilePic : URL of image to display
- active : is this user is active
- size : size of the avatar

-->

<template>
<div class="user-avatar" :class="{ inactive: !active }" :title="title">
  <span
     v-if="showPic"
     >
    <avatar
       :username="username"
       :src="profilePic"
       :size="size"
       ></avatar>
  </span>
  <span
     v-if="!showPic"
     >
    <avatar
       :username="username"
       :size="size"
       :backgroundColor="backgroundColor"
       ></avatar>
  </span>
  <img :src="profilePic" @error="imageLoadError" class="image-test"/>
</div>
</template>

<script>
import Avatar from 'vue-avatar';

export default {
    name: 'UserAvatar',
    components: {
        Avatar,
    },
    props: {
        username: { default: '', type: String },
        profilePic: { default: null, type: String },
        active: { default: true, type: Boolean },
        size: { default: 35, type: Number },
        backgroundColor: { default: '', type: String },
    },
    data: function() {
        return {
            imageIsOk: true,
        };
    },
    computed: {
        showPic: function() {
            return this.profilePic !== null && this.imageIsOk === true;
        },
        title: function() {
            if (this.active) {
                return this.username;
            } else {
                return this.username + ' (inactif)';
            }
        },
    },
    methods: {
        imageLoadError: function() {
            this.imageIsOk = false;
        },
    },
};
</script>

<style  lang='stylus' scoped>
.user-avatar
    width: min-content

.inactive
    filter: opacity(20%)

.image-test
    display: none
</style>
