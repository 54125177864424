import { getField, updateField } from 'vuex-map-fields';
import { make } from 'vuex-pathify';

import defaultState from './_defaultState';
import actions from './_actions';

const state = Object.assign({}, defaultState);

export default {
    namespaced: true,
    modules: {},
    state,
    getters: {
        ...make.getters(state),
        getField,
        /**
         * Get an empty report without ids
         */
        emptyReport: state => (housingPk, advisorPk, groupPk) => {
            let emptyReport = JSON.parse(JSON.stringify(defaultState.report));
            emptyReport.id = null;
            emptyReport.pk = null;
            emptyReport.housing = housingPk;
            emptyReport.advisor = advisorPk;
            emptyReport.group = groupPk;
            return emptyReport;
        },
    },
    mutations: {
        ...make.mutations(state),
        updateField,
        resetReport(state) {
            Object.assign(state.report, this.getters.emptyReport);
        },
    },
    actions,
};
