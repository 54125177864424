
const CTI = () => import(/* webpackChunkName: "cti" */ '@/apps/cti/cti');

const routes = [
    {
        path: '/cti/:phone',
        name: 'CTIphone',
        component: CTI,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
        props: true,
    },
    {
        path: '/cti/',
        name: 'CTI',
        component: CTI,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
    },
];

export default routes;
