/*
    Autogenerated class model from django
    -- 2019-10-30 21:36:39.130747
*/

import { make } from 'vuex-pathify';

const defaultState = {
    isLoading: 0,
    groupOfNewslettersCollection: {},
    groupOfNewslettersCollectionFilter: null,
    groupOfNewslettersCollectionLastUpdate: 0,
    groupOfNewslettersPublicCollection: {},
    groupOfNewslettersPublicCollectionFilter: null,
    groupOfNewslettersPublicCollectionLastUpdate: 0,
    imageCollection: {},
    imageCollectionFilter: null,
    imageCollectionLastUpdate: 0,
    newsletterCollection: {},
    newsletterCollectionFilter: null,
    newsletterCollectionLastUpdate: 0,
    graphicCharter: {},
};

const state = Object.assign({}, (defaultState));
const getters = make.getters(state);

const mutations = {
    ...make.mutations(state),

    resetState(state) {
        Object.assign(state, defaultState);
    },

    incrementLoading(state) {
        state.isLoading++;
    },

    decrementLoading(state) {
        state.isLoading--;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
