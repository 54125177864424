<template>
<div class="reference-data">

<layout-container color="#eee" v-if="additionalAddresses && additionalAddresses.length > 0">

  <div class="grid-x grid-padding-x">

    <div class="cell">
      <checkbox-field
        class="checkbox-active"
        v-model="localReference.useOnMainAddress"
        :class="{'field-is-different': isFieldIsDifferent('useOnMainAddress')}"
        id="useOnMainAddress"
        ref="useOnMainAddress"
        :label="localReference.useOnMainAddress ? 'Référence visible sur la fiche principale' : 'Référence non visible sur la fiche principale'"
        />

      <select-value
        id="useOnThisAddresses"
        v-model="localReference.useOnThisAddresses"
        :class="{'field-is-different': isFieldIsDifferent('useOnThisAddresses')}"
        :options="additionalAddressesAsSelect"
        track-by="value"
        label="Afficher cette référence sur ces addresses supplémentaires"
        option-label="label"
        multiple
        >
        <template v-slot:option="props">
          {{ props.option.label }}
        </template>
      </select-value>

    </div>
  </div>
</layout-container>

<layout-container>
  <div class="grid-x grid-padding-x">

    <div class="cell">
      <div class="error" v-if="formErrorsMessages.all">
        form : {{formErrorsMessages.all}}
      </div>
    </div>
    <div class="cell medium-6 ">
      <text-field
        id="productionName"
        label="Titre du projet"
        placeholder="Saisissez le titre du projet"
        mandatory
        :isError="formErrors.productionName"
        v-model="localReference.productionName"
        validateRules="required"
        :class="{'field-is-different': isFieldIsDifferent('productionName')}"
        />
    </div>
    <div class="cell medium-6 ">
      <text-field
        id="year"
        label="Année"
        placeholder="Année de réalisation du projet"
        mandatory
        v-model="localReference.year"
        type="number"
        :isError="formErrors.year"
        validateRules="required|length:4|numeric"
        :class="{'field-is-different': isFieldIsDifferent('year')}"
        />
    </div>
    <div class="cell medium-6 ">
      <text-field
        id="place"
        label="Lieu"
        placeholder="Saisissez le lieu du projet"
        mandatory
        v-model="localReference.place"
        type="text"
        :isError="formErrors.place"
        validateRules="required"
        :class="{'field-is-different': isFieldIsDifferent('place')}"
        />
    </div>
    <div class="cell medium-6 ">
      <text-field
        id="label"
        label="Label"
        placeholder="Label obtenu"
        v-model="localReference.label"
        :isError="formErrors.label"
        :class="{'field-is-different': isFieldIsDifferent('label')}"
        />
    </div>
    <div class="cell medium-6 ">
      <text-field
        id="consumptionBefore"
        label="Consommation énergie primaire (avant travaux)"
        unit="kWh/m2/an"
        mandatory
        v-model="localReference.consumptionBefore"
        :isError="formErrors.consumptionBefore"
        validateRules="numeric|required"
        type="number"
        :class="{'field-is-different': isFieldIsDifferent('consumptionBefore')}"
        />
      <text-field
        id="consumptionAfter"
        label="Consommation énergie primaire (après travaux)"
        unit="kWh/m2/an"
        mandatory
        v-model="localReference.consumptionAfter"
        type="number"
        :isError="formErrors.consumptionAfter"
        validateRules="required|numeric"
        :class="{'field-is-different': isFieldIsDifferent('consumptionAfter')}"
        />
    </div>
    <div class="cell medium-6 ">
      <select-field
        id="calculationMethod"
        label="Méthode de calcul"
        optionLabel="name"
        :options="calculationMethodAsList"
        :multiple=false
        :mandatory=true
        :isError="formErrors.calculationMethod"
        validateRules="required"
        v-model="localReference.calculationMethod"
        :class="{'field-is-different': isFieldIsDifferent('calculationMethod')}"
        >
        <template v-slot:option="method">
          <font-awesome-icon v-if="checkOption(localReference.calculationMethod, method.name)" icon="check" class="icon-check" />
          <span v-if="!checkOption(localReference.calculationMethod, method.name)">&emsp;&ensp;</span>
          <span v-if="checkOption(localReference.calculationMethod, method.name)" class="category">{{method.name}}</span>
          <span v-if="!checkOption(localReference.calculationMethod, method.name)" >{{method.name}}</span>
        </template>
      </select-field>
      <multiselect-field
        id="consumptionUsages"
        label="Usages de consommation considérés"
        optionLabel="name"
        :options="consumptionUsagesAsList"
        multiple
        mandatory
        :isError="formErrors.consumptionUsages"
        validateRules="required"
        v-model="localReference.consumptionUsages"
        :close-on-select="false"
        :clear-on-select="true"
        :preserve-search="false"
        :hide-selected="false"
        :searchable="true"
        :preselect-first="true"
        track-by="name"
        :class="{'field-is-different': isFieldIsDifferent('consumptionUsages')}"
        >
      </multiselect-field>
    </div>
    <div class="cell">
      <label class="form-label">Ajouter un visuel pour ce projet</label>
      <regular-button class="left-button" @click="showModalImageRef">
        AJOUTER UNE IMAGE AU PROJET
      </regular-button>

      <div class="grid-x grid-padding-x" :class="{'field-is-different': isFieldIsDifferent('productionImages')}">
        <span v-bind:key="pic.pk" v-for="pic in localReference.productionImages">
          <div class="cell pictures">
            <div v-if="true" class="multiselect__tag">
              <span class="img-name">Image &nbsp; {{localReference.productionImages.indexOf(pic)+1}}</span>
              <i class="multiselect__tag-icon" title="Supprimer l'image" @click="deletePicture(pic.pk, localReference.productionImages.indexOf(pic)+1)"></i>
            </div>
            <img v-if="!isNewPic(pic)" v-bind:src="pic.cropped" width="255" height="150" class="logo">
            <img v-if="isNewPic(pic)" v-bind:src="pic.new.content" width="255" height="150" class="logo">
          </div>
        </span>
      </div>
      <DialogWrapper
        ref="modalImageRef"
        class="modal-image-ref"
        :adaptive="false"
        :width="900"
        height="auto"
        :scrollable="true"
        clickToClose
        >
        <div>
          <header class="modal-header">
            <h2 class="modal-title">Choisissez une image pour votre projet </h2>
          </header>
          <div class="grid-x grid-margin-x popup-content">
            <div class="cell">
              <cropper
                url=""
                layout="square"
                :cropSize="{ width: 425, height: 300}"
                ref="cropper"
                >
              </cropper>
            </div>
          </div>

          <form-buttons
            class="buttons"
            @primaryAction="saveCropper();hideModalImageRef()"
            :hideSecondary="true"
            @tertiaryAction="hideModalImageRef"
            >
            <template v-slot:primary>
              Valider
            </template>
          </form-buttons>

        </div>
      </DialogWrapper>
    </div>
  </div>
</layout-container>
<layout-container class="content-white">
  <div class="grid-x grid-padding-x">
    <div class="cell ">
      <label class="form-label">Historique/contexte
        <span class="text-italic text-unbold text-sm">- obligatoire (max 600 caractères)</span>
      </label>
      <wysiwyg-field
        id="history"
        v-model="localReference.history"
        validateRules="required|max:500"
        :isError="formErrors.history"
        :disabledExtensions="{headings: true, tables: true, blocks: true, links: true}"
        :allowRawEdit="false"
        :class="{'field-is-different': isFieldIsDifferent('history')}"
        />
      <label class="form-label">Missions réalisées
        <span class="text-italic text-unbold text-sm">- obligatoire (max 600 caractères)</span>
      </label>
      <wysiwyg-field
        id="completedMission"
        v-model="localReference.completedMission"
        validateRules="required|max:500"
        :isError="formErrors.completedMission"
        :disabledExtensions="{headings: true, tables: true, blocks: true, links: true}"
        :allowRawEdit="false"
        helpText="Saisissez l'historique / contexte du projet"
        :class="{'field-is-different': isFieldIsDifferent('completedMission')}"
       />
    </div>
    <div class="cell medium-6 ">
      <label class="form-label">Enveloppe thermique
        <span class="text-italic text-unbold text-sm">- obligatoire (max 600 caractères)</span>
      </label>
      <wysiwyg-field
        id="thermalEnvelope"
        v-model="localReference.thermalEnvelope"
        validateRules="required|max:500"
        :isError="formErrors.thermalEnvelope"
        :disabledExtensions="{headings: true, tables: true, blocks: true, links: true}"
        :allowRawEdit="false"
        helpText="Indiquez l'enveloppe thermique"
        :class="{'field-is-different': isFieldIsDifferent('thermalEnvelopes')}"
        />
      <label class="form-label">Résultat du test d'étanchéité à l'air
        <span class="text-italic text-unbold text-sm">- maisons seulement (max 600 caractères)</span>
      </label>
      <wysiwyg-field
        id="airtightnessTestResult"
        v-model="localReference.airtightnessTestResult"
        validateRules="max:500"
        :isError="formErrors.airtightnessTestResult"
        :disabledExtensions="{headings: true, tables: true, blocks: true, links: true}"
        :allowRawEdit="false"
        helpText="Indiquez le résultat du test d'étanchéité à l'air "
        :class="{'field-is-different': isFieldIsDifferent('airtightnessTestResult')}"
        />
    </div>
    <div class="cell medium-6 ">
      <label class="form-label">Système
        <span class="text-italic text-unbold text-sm">(max 600 caractères)</span>
      </label>
      <wysiwyg-field
        id="system"
        v-model="localReference.system"
        validateRules="max:500"
        :isError="formErrors.system"
        :disabledExtensions="{headings: true, tables: true, blocks: true, links: true}"
        :allowRawEdit="false"
        helpText="Indiquez le système"
        :class="{'field-is-different': isFieldIsDifferent('system')}"
        />
      <label class="form-label">Autres informations sur ce projet
        <span class="text-italic text-unbold text-sm">(max 600 caractères)</span>
      </label>
      <wysiwyg-field
        id="otherInformation"
        v-model="localReference.otherInformation"
        validateRules="max:500"
        :isError="formErrors.otherInformation"
        :disabledExtensions="{headings: true, tables: true, blocks: true, links: true}"
        :allowRawEdit="false"
        helpText="Autres informations sur ce projet"
        :class="{'field-is-different': isFieldIsDifferent('otherInformation')}"
        />
    </div>
  </div>
</layout-container>
  
</div>
</template>

<script>
import Vue from 'vue';
  
import CheckboxField from '@/components/checkbox_field';
import Cropper from '@/components/cropper';
import FormButtons from '@/components/form_buttons';
import LayoutContainer from '@/components/layout_container';
import MultiselectField from '@/components/multiselect_field';
import RegularButton from '@/components/regular_button';
import SelectField from '@/components/select_field';
import SelectValue from '@/components/select_value';
import TextField from '@/components/text_field';
import WysiwygField from '@/components/wysiwyg_field';

import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';

import confirmActionMixin from '@/mixins/confirm_action_mixin';
import { ConsumptionUsageStoreCollection } from '@/models/listepro/consumption_usage';
import { CalculationMethodStoreCollection } from '@/models/listepro/calculation_method';

export default {
    name: 'ReferenceData',
    mixins: [handleFormErrorsMixin, confirmActionMixin],
    components: {
        CheckboxField,
        Cropper,
        FormButtons,
        LayoutContainer,
        MultiselectField,
        RegularButton,
        SelectField,
        SelectValue,
        TextField,
        WysiwygField,
    },
    props: {
        value: Object,
        differences: {
            type: Array,
            default(){
                return [];
            },
        },
        additionalAddresses: {
            type: Array,
            default(){
                return [];
            },
        },
    },
    data() {
        return {
            calculationMethod: new CalculationMethodStoreCollection(),
            consumptionUsages: new ConsumptionUsageStoreCollection(),
            localReference: {},
        };
    },
    computed: {
        additionalAddressesAsSelect() {
            return this.additionalAddresses.map(x => { return {value: x.pk, label: `${x.name} - ${x.address}`, name: `${x.name} - ${x.address}`}; });
        },
        calculationMethodAsList() {
            return this.calculationMethod.asList();
        },
        consumptionUsagesAsList() {
            return this.consumptionUsages.asList();
        },
    },
    watch: {
        value: {
            handler(value) {
                if (value) {
                    this.localReference = value;
                }
            },
            immediate: true,
            deep: true,
        },
        localReference: {
            handler(value) {
                this.$emit('input', value);
            },
            deep: true,
        },
    },
    created: function() {
    },
    mounted: function() {
        this.calculationMethod.load();
        this.consumptionUsages.load();
    },
    methods: {
        validate() {
            return this.$refs.validator.validate();
        },
        isFieldIsDifferent(id) {
            return this.differences.includes(id);
        },
        isNewPic(pic) {
            if (pic.new !== undefined) {
                return true;
            }
            return false;
        },
        saveCropper(){
            const pic = this.$refs.cropper.getImage();
            if (pic != null) {
                if (pic.startsWith('data:')) {
                    this.localReference.productionImages.push(
                        {
                            new: {
                                fileName: this.$refs.cropper.getImageName(),
                                content: pic,
                            },
                        }
                    );
                }
            }
        },
        deletePicture(pk, index){
            this.confirmAction("supprimer l'image", index, () => {
                const imgs = this.localReference.productionImages.filter(x => { return x.pk !== pk; });
                Vue.set(this.localReference, 'productionImages', imgs);
            });
            
        },
        showModalImageRef() {
            this.$refs.modalImageRef.show();
        },
        hideModalImageRef() {
            this.$refs.modalImageRef.hide();
        },
        checkOption(userChoice, optionName) { // for a select field with unique value
            if(userChoice && optionName===userChoice.name){
                return true;
            }
            return false;
        },
        checkOptions(userChoice, optionName) { // for a select field with multiple values
            for (const index in userChoice){
                if(userChoice && optionName===userChoice[index].name){
                    return true;
                }
            }
            return false;
        },        
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

.reference-data
    .field-is-different
        border: solid 4px warning-color
        padding-left: space-md

    .pictures
        margin-right: space-md

        img
            display: block

        .multiselect__tag
            display: block
            margin-top: space-xs
            margin-right: 20px

    .modal-image-ref
        .popup-content
            padding-left: space-md
            padding-right: space-md

        .modal-header
            padding: 1rem 0.3rem 1rem 0.3rem
            background-color: primary-color-500
            margin-bottom: 1rem

        .modal-title
            color: #fff
            text-transform: uppercase
            padding: 3%

    .buttons
        padding: space-md
</style>
