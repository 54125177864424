import { initNeedsEstimationWatchers } from '@/apps/thermix/store/needs_estimation/watchers';

/* ***************************************************************************************************
IMPLEMENTATION NOTE:

Therminix use Thermix needs estimation modules, so we need to initilize some energies and Thermix dependencies :
 - energies/energie,
 - energies/builing heating consumption
 - energies/energy vectors
 - energies/secondary efficiency
 - Thermix' needs estimation watchers (that we need to unwatch)
*************************************************************************************************** */

const unwatchFunctions = [];

export default {

    init({ dispatch, commit }, { metroGrenoble, area, inseeCode, demo }) {
        return new Promise((resolve, reject) => {
            if (metroGrenoble) {
                dispatch('enableMetroGrenobleMode', { area, inseeCode });
            }

            Promise.all([
                // Needed by estimation module and results computation :
                dispatch('thermix/needsEstimation/reset', null, { root: true }),
                dispatch('energies/loadEnergies', null, { root: true }),
                dispatch('energies/loadProductionSystems', null, { root: true }),
                dispatch('energies/loadBuildingHeatingConsumptions', null, { root: true }),
                dispatch('energies/loadEnergyVectors', null, { root: true }),
                dispatch('energies/loadSecondaryEfficiencies', null, { root: true }),
            ]).then(() => {
                commit('SET_INITIAL_DATA_LOADED', true);

                if (demo) dispatch('setupDemo', demo);

                unwatchFunctions.push(...initNeedsEstimationWatchers());

                resolve();
            });
        });
    },

    terminate() {
        while (unwatchFunctions.length) {
            const unwatchFn = unwatchFunctions.shift();
            if (typeof unwatchFn === 'function') unwatchFn();
        }
    },

    enableMetroGrenobleMode({ dispatch }, { area, inseeCode }) {
        dispatch('thermix/metroGrenoble/enableMetroGrenobleMode', { area, inseeCode }, { root: true });
    },

    setupDemo({ commit, dispatch }, demo) {
        dispatch('setupNeeds', { heating: 15000, hotWater: 3000 });
        commit('SET_HOUSING_TYPE', 'flat');
        commit('SET_RENOVATION_TYPE', 'new_building');
        commit('SET_FLOOR_SURFACE', 100);
        commit('SET_HEATING_SYSTEM_IDENTIFIER', 'oil_boiler_condensing');
        commit('SET_HOT_WATER_SYSTEM_IDENTIFIER', 'synced-with-heating');
    },

    setupNeeds({ commit }, needs) {
        commit('SET_HEATING_NEEDS', needs.heating);
        commit('SET_HOT_WATER_NEEDS', needs.hotWater);
    },

    computeProposition({ state, getters, dispatch, commit }) {
        return new Promise((resolve, reject) => {
            if (!getters.isInformationsValid) {
                return reject(Error({ invalid: true }));
            }
            resolve();
            // dispatch('proposition/compute').then(resolve, reject);
        });
    },
};
