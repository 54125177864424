import { make } from 'vuex-pathify';

const defaultState = {
    isLoading: 0,

    advisorDowntimeCollection: {},
    advisorDowntimeCollectionFilter: null,
    advisorDowntimeCollectionLastUpdate: 0,
    appointmentCollection: {},
    appointmentCollectionFilter: null,
    appointmentCollectionLastUpdate: 0,
    calendarPermanentUrlCollection: {},
    calendarPermanentUrlCollectionFilter: null,
    calendarPermanentUrlCollectionLastUpdate: 0,
    catchmentAreaCollection: {},
    catchmentAreaCollectionFilter: null,
    catchmentAreaCollectionLastUpdate: 0,
    exchangeAttachmentCollection: {},
    exchangeAttachmentCollectionFilter: null,
    exchangeAttachmentCollectionLastUpdate: 0,
    exchangeCollection: {},
    exchangeCollectionFilter: null,
    exchangeCollectionLastUpdate: 0,
    notificationCollection: {},
    notificationCollectionFilter: null,
    notificationCollectionLastUpdate: 0,
    notificationRequestedCollection: {},
    notificationRequestedCollectionFilter: null,
    notificationRequestedCollectionLastUpdate: 0,
    placeCollection: {},
    placeCollectionFilter: null,
    placeCollectionLastUpdate: 0,
    placeListCollection: {},
    placeListCollectionFilter: null,
    placeListCollectionLastUpdate: 0,
    reasonCollection: {},
    reasonCollectionFilter: null,
    reasonCollectionLastUpdate: 0,
    reasonListCollection: {},
    reasonListCollectionFilter: null,
    reasonListCollectionLastUpdate: 0,
    slotCollection: {},
    slotCollectionFilter: null,
    slotCollectionLastUpdate: 0,

};

const state = Object.assign({}, (defaultState));
const getters = make.getters(state);

const mutations = {
    ...make.mutations(state),

    resetState(state) {
        Object.assign(state, defaultState);
    },

    incrementLoading(state) {
        state.isLoading++;
    },

    decrementLoading(state) {
        state.isLoading--;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
