<template>
<div class="note">
  <div class="grid-x grid-margin-x">
    <div class="cell medium-6 ">
        
    </div>
    <div class="cell medium-6 text-right">
      <action-link
        class="action text-right"
        label="Ajouter une note"
        icon="plus"
        @click="addNote"
        />
    </div>
  </div>
    
  <mixeur-table
    v-if="value"
    :columns="columns"
    :rows="value"
    :sort-options="{ enabled: true }"
    :search-options="{ enabled: false }"
    :pagination-options="paginationOptions"
    >
    <template v-slot:table-row="props">
      <span v-if="props.column.field == 'updatedAt'">
        {{ formatDate(new Date(props.formattedRow[props.column.field])) }}
      </span>
      <span v-else-if="props.column.field == 'note'" class="note-small">
        <span v-html="props.formattedRow[props.column.field]" />
      </span>
      <span v-else-if="props.column.field == 'action'">
        <action-link
          class="action"
          label="Éditer"
          icon="edit"
          @click="editNote(props.formattedRow.id)"
          />
      </span>
      <span v-else-if="props.column.field == 'pinned'">
        <true-false
          :value="props.row.pinned"
          icon-true="thumbtack"
          :icon-false="null"
          />
      </span>
      
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
  
  </mixeur-table>

  <note-edit
    ref="noteEditModal"
    v-on:reload-notes="reloadNotes"
    />

  
</div>
</template>

<script>
import ActionLink from '@/components/action_link';
import MixeurTable from '@/components/mixeur_table';
import TrueFalse from '@/components/true_false';
import { utils } from '@/utils';

import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';

import NoteEdit from '@/apps/listepro/professional_form/note_edit';


export default {
    name: 'Note',
    mixins: [handleFormErrorsMixin],
    components: {
        ActionLink,
        MixeurTable,
        NoteEdit,
        TrueFalse,
    },
    props: {
        value: { default: () => { return []; }, type: Array },
        professionalPk : { type: Number, default: null },
    },
    data: function() {
        return {
            isLoading: false,
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 5,
                perPageDropdown: [5, 10, 25, 200],
                dropdownAllowAll: true,
                nextLabel: 'suivant',
                prevLabel: 'précédent',
                rowsPerPageLabel: 'Lignes par page',
                ofLabel: 'de',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'Toutes',
            },
            columns: [
                {
                    label: '',
                    field: 'id',
                    width: '1em',
                    sortable: false,
                    hidden: true, 
                },
                {
                    label: 'Date de mise à jour',
                    field: 'updatedAt',
                    sortable: true,
                },
                // {
                //     label: 'Tags',
                //     field: 'tags',
                //     sortable: false,
                // },
                {
                    label: 'Par',
                    field: 'creatorName',
                    sortable: true,
                },
                {
                    label: 'Note',
                    field: 'note',
                    sortable: true,
                },
                // {
                //     label: 'Rappel',
                //     field: 'reminder.date',
                // },
                // {
                //     label: 'Personnes à rappeler',
                //     field: 'reminder.persons',
                //     sortable: false,
                // },
                // {
                //     label: 'Traitée',
                //     field: 'done',
                //     sortFn: this.sortBoolean,
                // },
                {
                    label: 'Épinglée',
                    field: 'pinned',
                    sortable: false,
                    tdClass: 'column-center',
                    thClass: 'column-center',
                },
                {
                    label: 'Actions',
                    field: 'action',
                    sortable: false,
                    width: '100px', 
                },
            ],

        };
    },
    methods: {
        formatDate(date) {
            return utils.formatDateTime(date);
        },
        addNote() {
            this.editNote();
        },
        editNote(pk) {
            this.$refs.noteEditModal.load(pk, this.professionalPk);
        },
        reloadNotes() {
            this.$emit('reload-notes');
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

.note-small
    display: block
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
</style>
