<template>
<validation-observer ref="validator" slim>
<section
  v-if="readOnly"
  v-show="isActive"
  :aria-hidden="!isActive"
  class="panels-component-panel read-only"
  :id="id"
  role="panel"
>
  <h2 v-if="withTitle" class="title" v-html="name"></h2>
  
  <div class="panel"
       :disabled="readOnly"
       >
    <slot />
  </div>
</section>
<form
  v-else
  v-show="isActive"
  :aria-hidden="!isActive"
  class="panels-component-panel"
  :id="id"
  role="panel"
  :data-vv-scope="id"
>
  <h2 v-if="withTitle" class="title" v-html="name"></h2>

  <div class="panel"><slot /></div>
</form>
</validation-observer>
</template>

<script>
export default {
    name: 'Panel',
    components: {},
    props: {
        id: {
            default : () => '_' + Math.random().toString(36).substring(2, 9),
            type: String,
        },
        name: { required: true },
        prefix: { default: '' },
        suffix: { default: '' },
        withTitle: { default: true, type: Boolean },
        readOnly: { default: false, type: Boolean },
        disabled: { default: false, type: Boolean },
    },
    data() {
        return {
            localId: null,
            isActive: false,
        };
    },
    computed: {
        header() {
            return this.prefix + this.name + this.suffix;
        },
        errorCount() {
            try {
                const errors = Object.values(this.$refs.validator.errors);
                return errors.filter(v => v.length > 0).length;
            } catch {
                return 0;
            }
        },
    },
    mounted() {
        if (this.id === null) {
            this.localId = this.name.toLowerCase().replace(/ /g, '-');
        } else {
            this.localId = this.id;
        }
    },
    methods: {
        validate() {
            return this.$refs.validator.validate();
        },
    },
};
</script>

<style lang="stylus" scoped>
.read-only
    opacity: 0.6
    cursor: not-allowed
</style>
