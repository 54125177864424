<template>
  <div class="thermix-iframe">
    <iframe
      src="https://actimmo.thermix.org/"
      title="Thermix"
      width="100%"
      height="800px"
      style="overflow-y:visible;"
    ></iframe>
  </div>
</template>

<script>
export default {
    name: 'OldThermix',
    data: function() {
        return {};
    },
};
</script>

<style lang="stylus" scoped>
iframe
    border: none
</style>
