const DialogwattHome = () => import(/* webpackChunkName: "dialogwatt_client" */ '@/apps/dialogwatt_client/dialogwatt_home');
const DialogwattGetAppointmentsList = () => import(/* webpackChunkName: "dialogwatt_client" */ '@/apps/dialogwatt_client/dialogwatt_get_appointment_list');
const DialogwattAppointmentManage = () => import(/* webpackChunkName: "dialogwatt_client" */ '@/apps/dialogwatt_client/appointment_book/appointment_manage');
const DialogwattPlacesList = () => import(/* webpackChunkName: "dialogwatt_client" */ '@/apps/dialogwatt_client/places_list/places_list');
const DialogwattPlaceInfos = () => import(/* webpackChunkName: "dialogwatt_client" */ '@/apps/dialogwatt_client/places_list/place_infos');
const AppointmentBook = () => import(/* webpackChunkName: "dialogwatt_client" */ '@/apps/dialogwatt_client/appointment_book/appointment_book');
const AppointmentBookSelect = () => import(/* webpackChunkName: "dialogwatt_client" */ '@/apps/dialogwatt_client/appointment_book/appointment_book_select');
const AppointmentBookQuestionnaire = () => import(/* webpackChunkName: "dialogwatt_client" */ '@/apps/dialogwatt_client/appointment_book/appointment_book_questionnaire');
const AppointmentBookLogin = () => import(/* webpackChunkName: "dialogwatt_client" */ '@/apps/dialogwatt_client/appointment_book/appointment_book_login');
const AppointmentBookRecap = () => import(/* webpackChunkName: "dialogwatt_client" */ '@/apps/dialogwatt_client/appointment_book/appointment_book_recap');

const dialogwattAppointmentBookRoute = '/DialogWatt/rendez-vous/:slug';

const routes = [
    {
        path: '/dialogwatt/rendez-vous/',
        name: 'DialogwattHome',
        component: DialogwattHome,
        props: true,
        meta: {},
    },
    {
        path: '/dialogwatt/rendez-vous/liste/',
        name: 'DialogwattGetAppointmentsList',
        component: DialogwattGetAppointmentsList,
        props: true,
        meta: {},
    },
    {
        path: '/dialogwatt/rendez-vous/annulation/:pk/:uuid',
        name: 'DialogwattAppointmentCancel',
        component: DialogwattAppointmentManage,
        props: true,
        meta: {
            mode: 'cancel',
        },
    },
    {
        path: '/da/:uuid',
        name: 'DialogwattAppointmentCancel',
        component: DialogwattAppointmentManage,
        props: true,
        meta: {
            mode: 'cancel',
        },
    },
    {
        path: '/dc/:uuid',
        name: 'DialogwattAppointmentConfirmation',
        component: DialogwattAppointmentManage,
        props: true,
        meta: {
            mode: 'confirm',
        },
    },
    {
        path: '/dg/:uuid',
        name: 'DialogwattAppointmentManagement',
        component: DialogwattAppointmentManage,
        props: true,
        meta: {
            mode: 'manage',
        },
    },
    {
        path: '/dialogwatt/lieux/:inseecode/',
        name: 'DialogwattPlacesList',
        component: DialogwattPlacesList,
        props: true,
        meta: {},
    },
    {
        path: '/dialogwatt/lieux/infos/:placePk',
        name: 'DialogwattPlaceInfos',
        component: DialogwattPlaceInfos,
        props: true,
        meta: {},
    },
    {
        path: dialogwattAppointmentBookRoute,
        name: 'DialogwattAppointmentBook',
        component: AppointmentBook,
        props: true,
        meta: {},
        children: [
            {
                name: 'DialogwattAppointmentBookSelect',
                path: dialogwattAppointmentBookRoute + '/selection',
                component: AppointmentBookSelect,
                meta: {
                    tabLabel: 'Sélection du rendez-vous',
                },
            },
            {
                name: 'DialogwattAppointmentBookQuestionnaire',
                path: dialogwattAppointmentBookRoute + '/questionnaire',
                component: AppointmentBookQuestionnaire,
                meta: {
                    tabLabel: 'Questionnaire',
                },
            },
            {
                name: 'DialogwattAppointmentBookLogin',
                path: dialogwattAppointmentBookRoute + '/identification',
                component: AppointmentBookLogin,
                meta: {
                    tabLabel: 'Identification',
                },
            },
            {
                name: 'DialogwattAppointmentBookRecap',
                path: dialogwattAppointmentBookRoute + '/recapitulatif',
                component: AppointmentBookRecap,
                meta: {
                    tabLabel: 'Récapitulatif',
                },
            },
        ],
    },
];

export default routes;
