import _ from 'lodash';

const heatingNetwork = {
    heatingSystem: { identifier: 'heating_network', name: 'Réseau de chaleur' },
    hotWaterSystem: { identifier: 'heating_network', name: 'Réseau de chaleur' },
};
const gazBoilerCondensing = {
    heatingSystem: { identifier: 'gaz_boiler_condensing' },
    hotWaterSystem: { identifier: 'gaz_boiler_condensing' },
};
const gazBoilerCondensingAndSolarWaterHeater = {
    heatingSystem: { identifier: 'gaz_boiler_condensing' },
    hotWaterSystem: { identifier: 'solar_water_heater' },
};
// const gazBoilerCondensing = {
//     heatingSystem: { identifier: 'gaz_boiler_condensing', efficiency: 0.9 },
//     hotWaterSystem: { identifier: 'gaz_boiler_condensing' },
// };
// const gazBoilerCondensingAndSolarWaterHeater = {
//     heatingSystem: { identifier: 'gaz_boiler_condensing', efficiency: 0.9 },
//     hotWaterSystem: { identifier: 'solar_water_heater' },
// };
const heatPumpGeothermalLte = {
    heatingSystem: { identifier: 'heat_pump_geothermal_lte', name: 'Pompe à chaleur géothermale' },
    hotWaterSystem: { identifier: 'heat_pump_geothermal_lte', name: 'Pompe à chaleur géothermale' },
};
const heatPumpGeothermalVlte = {
    heatingSystem: { identifier: 'heat_pump_geothermal_vlte', name: 'Pompe à chaleur géothermale' },
    hotWaterSystem: { identifier: 'heat_pump_geothermal_vlte', name: 'Pompe à chaleur géothermale' },
};
// const solarWaterHeater = {
//     identifier: 'solar_water_heater',
// };
const granulatedWoodBoiler = {
    heatingSystem: { identifier: 'granulated_wood_boiler' },
    hotWaterSystem: { identifier: 'granulated_wood_boiler' },
};
const granulatedWoodStoveAndSolarWaterHeater = {
    heatingSystem: { identifier: 'granulated_wood_stove' },
    hotWaterSystem: { identifier: 'solar_water_heater' },
};
const granulatedWoodStoveAndThermodynamicWaterHeater = {
    heatingSystem: { identifier: 'granulated_wood_stove' },
    hotWaterSystem: { identifier: 'thermodynamic_cmv' },
};
const heatPumpAerothermalAndSolarWaterHeater = {
    heatingSystem: { identifier: 'granulated_wood_stove' },
    hotWaterSystem: { identifier: 'solar_water_heater' },
};
// const gazBoilerCondensingAndSolarHeating = {
//     heatingSystem: { identifier: 'gaz_boiler_condensing' },
//     hotWaterSystem: { identifier: 'solar_system_combined' },
// };
// const thermodynamicCmv = {
//     identifier: 'thermodynamic_cmv',
// };

function heatingOnly(data) {
    return { heatingSystem: _.cloneDeep(data.heatingSystem) };
}

export default {
    A: {
        flatInMultiUnit: {
            new_building: [_.cloneDeep(heatingNetwork)],
            global_renovation: [_.cloneDeep(heatingNetwork)],
            systems_renovation: [_.cloneDeep(heatingNetwork)],
            heating_system_renovation: [heatingOnly(heatingNetwork)],
        },
        // Same as B
        flat: {
            new_building: [_.cloneDeep(gazBoilerCondensing)],
            global_renovation: [_.cloneDeep(gazBoilerCondensing)],
            systems_renovation: [_.cloneDeep(gazBoilerCondensing)],
            heating_system_renovation: [heatingOnly(gazBoilerCondensing)],
        },
        house: {
            // TODO new_building : the second option is not he wanted one (gaz and solar_system_combined)
            // We only manage a single system in therminix
            new_building: [_.cloneDeep(heatPumpGeothermalVlte), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            global_renovation: [_.cloneDeep(heatPumpGeothermalLte), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            systems_renovation: [_.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            heating_system_renovation: [heatingOnly(gazBoilerCondensing)],
        },
    },
    B: {
        flatInMultiUnit: {
            new_building: [_.cloneDeep(heatPumpGeothermalVlte), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            global_renovation: [_.cloneDeep(heatPumpGeothermalLte)],
            systems_renovation: [_.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            heating_system_renovation: [heatingOnly(gazBoilerCondensing)],
        },
        flat: {
            new_building: [_.cloneDeep(gazBoilerCondensing)],
            global_renovation: [_.cloneDeep(gazBoilerCondensing)],
            systems_renovation: [_.cloneDeep(gazBoilerCondensing)],
            heating_system_renovation: [heatingOnly(gazBoilerCondensing)],
        },
        house: {
            // TODO new_building : the second option is not he wanted one (gaz and solar_system_combined)
            // We only manage a single system in therminix
            new_building: [_.cloneDeep(heatPumpGeothermalVlte), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            global_renovation: [_.cloneDeep(heatPumpGeothermalLte), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            systems_renovation: [_.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            heating_system_renovation: [heatingOnly(gazBoilerCondensing)],
        },
    },
    C: {
        flatInMultiUnit: {
            new_building: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(heatPumpGeothermalVlte)],
            global_renovation: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(heatPumpGeothermalLte)],
            systems_renovation: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            heating_system_renovation: [heatingOnly(granulatedWoodBoiler)],
        },
        flat: {
            new_building: [_.cloneDeep(gazBoilerCondensing), _.cloneDeep(granulatedWoodStoveAndThermodynamicWaterHeater)],
            global_renovation: [_.cloneDeep(gazBoilerCondensing), _.cloneDeep(granulatedWoodStoveAndThermodynamicWaterHeater)],
            systems_renovation: [_.cloneDeep(gazBoilerCondensing), _.cloneDeep(granulatedWoodStoveAndThermodynamicWaterHeater)],
            heating_system_renovation: [heatingOnly(gazBoilerCondensing), heatingOnly(granulatedWoodStoveAndSolarWaterHeater)],
        },
        house: {
            new_building: [_.cloneDeep(granulatedWoodStoveAndSolarWaterHeater), _.cloneDeep(heatPumpGeothermalVlte)],
            global_renovation: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(heatPumpGeothermalLte)],
            systems_renovation: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            heating_system_renovation: [heatingOnly(granulatedWoodBoiler)],
        },
    },
    D: {
        flatInMultiUnit: {
            new_building: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            global_renovation: [_.cloneDeep(granulatedWoodBoiler)],
            systems_renovation: [_.cloneDeep(granulatedWoodBoiler)],
            heating_system_renovation: [heatingOnly(granulatedWoodBoiler)],
        },
        flat: {
            new_building: [_.cloneDeep(gazBoilerCondensing), _.cloneDeep(granulatedWoodStoveAndThermodynamicWaterHeater)],
            global_renovation: [_.cloneDeep(gazBoilerCondensing), _.cloneDeep(granulatedWoodStoveAndThermodynamicWaterHeater)],
            systems_renovation: [_.cloneDeep(gazBoilerCondensing), _.cloneDeep(granulatedWoodStoveAndThermodynamicWaterHeater)],
            heating_system_renovation: [heatingOnly(gazBoilerCondensing), heatingOnly(granulatedWoodStoveAndSolarWaterHeater)],
        },
        house: {

            // TODO new_building : the second option is not he wanted one (gaz and solar_system_combined)
            // We only manage a single system in therminix
            new_building: [_.cloneDeep(granulatedWoodStoveAndSolarWaterHeater), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            global_renovation: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            systems_renovation: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(gazBoilerCondensingAndSolarWaterHeater)],
            heating_system_renovation: [heatingOnly(granulatedWoodBoiler)],
        },
    },
    E: {
        flatInMultiUnit: {
            new_building: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(granulatedWoodStoveAndSolarWaterHeater)],
            global_renovation: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(granulatedWoodStoveAndSolarWaterHeater)],
            systems_renovation: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(granulatedWoodStoveAndSolarWaterHeater)],
            heating_system_renovation: [heatingOnly(granulatedWoodBoiler)],
        },
        flat: {
            new_building: [_.cloneDeep(granulatedWoodStoveAndThermodynamicWaterHeater), _.cloneDeep(heatPumpAerothermalAndSolarWaterHeater)],
            global_renovation: [_.cloneDeep(granulatedWoodStoveAndThermodynamicWaterHeater), _.cloneDeep(heatPumpAerothermalAndSolarWaterHeater)],
            systems_renovation: [_.cloneDeep(granulatedWoodStoveAndThermodynamicWaterHeater)],
            heating_system_renovation: [heatingOnly(granulatedWoodStoveAndThermodynamicWaterHeater)],
        },
        house: {
            new_building: [_.cloneDeep(granulatedWoodStoveAndSolarWaterHeater), _.cloneDeep(heatPumpAerothermalAndSolarWaterHeater)],
            global_renovation: [_.cloneDeep(granulatedWoodBoiler), _.cloneDeep(heatPumpAerothermalAndSolarWaterHeater)],
            systems_renovation: [_.cloneDeep(granulatedWoodBoiler)],
            heating_system_renovation: [heatingOnly(granulatedWoodBoiler)],
        },

    },
};
