/*
    Autogenerated class model from django BuildingHeatingConsumption
    -- 2020-02-10 10:50:39.383432
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';
import { loadThenStoreByIdentifier } from './_helpers';

class BuildingHeatingConsumptionClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'criterion', rawData.criterion);
        Vue.set(this, 'heatingConsumption', rawData.heatingConsumption);
        Vue.set(this, 'comment', rawData.comment);
        Vue.set(this, 'order', rawData.order);
    }

    getBaseUrl() {
        return '/energies/building-heating-consumption/';
    }

    get isValid() {
        return true;
    }
}

class BuildingHeatingConsumptionCollection extends BaseCollection {
    initModel(rawData) {
        return new BuildingHeatingConsumptionClass(rawData);
    }

    getBaseUrl() {
        return '/energies/building-heating-consumption/';
    }
}

class BuildingHeatingConsumptionStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new BuildingHeatingConsumptionClass(rawData);
    }

    getBaseUrl() {
        return '/energies/building-heating-consumption/';
    }

    getStorePath() {
        return 'energies/buildingHeatingConsumptionCollection';
    }

    load(filter = null) {
        return loadThenStoreByIdentifier(this, super.load, 'criterion', filter);
    }
}

export {
    BuildingHeatingConsumptionClass,
    BuildingHeatingConsumptionCollection,
    BuildingHeatingConsumptionStoreCollection,
};
