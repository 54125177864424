import Vue from 'vue';
import Vuex from 'vuex';
import pathify, { make } from 'vuex-pathify';
import { getField, updateField } from 'vuex-map-fields';
import apiClient from '@/api/api';
import {
    utils,
} from '@/utils';

import accounts from '@/apps/accounts/store_accounts.js';
import dialogwatt from '@/apps/dialogwatt/store_dialogwatt.js';
import bap from '@/apps/bap/store_bap.js';
import ecorenover from '@/apps/ecorenover/store_ecorenover';
import energies from '@/apps/energies/store_energies';
import events from '@/apps/events/store_events.js';
import experiences from '@/apps/experiences/store_experiences.js';
import fac from '@/apps/fac/store_fac';
import listepro from '@/apps/listepro/store_listepro.js';
import newsletters from '@/apps/newsletters/store_newsletters.js';
import territories from '@/apps/territories/store_territories.js';
import therminix from '@/apps/therminix/store';
import thermix from '@/apps/thermix/store';
import userUtilities from '@/store/user_utilities.js';
import visitReport from '@/apps/visit_report/store';

import expiringStorage from '../expiring_storage';

Vue.use(Vuex);

/// /// STORE /////////////////////////////////////////

const state = {
    layout: 'web',
    // API
    domainName: '',
    domainProtocol: '',
    whiteLabelling: {},

    isAuthenticated: false,
    jwt: null,

    pageTitle: '',

    user: null,
    consent: null,
    versionNumber: '',
    serverVersion: undefined,
    serverVersionAlertShown: false,
    googleTagId: undefined,
    googleTagAccepted: undefined,
};

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
    ...make.mutations(state),
    updateField,

    setVersionNumber(state, version) {
        Vue.set(state, 'versionNumber', version);
    },
    setServerVersion(state, version) {
        Vue.set(state, 'serverVersion', version);
    },
    setServerVersionAlertShown(state, flag) {
        Vue.set(state, 'serverVersionAlertShown', flag);
    },
    setDomainName(state, domainName) {
        Vue.set(state, 'domainName', domainName);
    },
    setDomainProtocol(state, domainProtocol) {
        Vue.set(state, 'domainProtocol', domainProtocol);
    },
    setWhiteLabelling(state, data) {
        Vue.set(state, 'whiteLabelling', data);
    },
    setGoogleTagId(state, googleTagId) {
        Vue.set(state, 'googleTagId', googleTagId);
    },
    setGoogleTagAccepted(state, accept) {
        Vue.set(state, 'googleTagAccepted', accept);
    },
    // update JWT information
    updateToken(state, newToken) {
        localStorage.setItem('token', newToken);
        state.jwt = newToken;
        state.isAuthenticated = true;
    },
    removeToken(state) {
        localStorage.removeItem('token');
        state.jwt = null;
        state.user = null;
        state.isAuthenticated = false;
        // state.keepJwtUp = null;
    },
    updateProfileAndConsent(state, data) {
        Vue.set(state, 'user', data.user);
        Vue.set(state, 'consent', data.rgpdConsent);
    },
    updateProfilePic(state, data) {
        const user = state.user;
        user.profilePic = data.profilePic;
        Vue.set(state, 'user', user);
    },
    updateLayout(state, layout) {
        state.layout = layout;
    },
    updatePageTitle(state, title) {
        state.pageTitle = title;
    },
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
    getWhiteLabelling({ state, commit }, domainName) {
        return new Promise((resolve, reject) => {
            if (domainName === undefined) {
                commit('setDomainName', utils.getDomainFromUrl());
            } else {
                commit('setDomainName', domainName);
            }
            commit('setDomainProtocol', window.location.protocol);
            apiClient
                .getWhiteLabelling(state.domainName)
                .then(function(data) {
                    const urlParams = utils.getURLParameters();
                    if (urlParams.has('iframe')) {
                        data.hasBreadcrumb = false;
                        data.hasHeaderImg = false;
                        data.hasMenu = false;
                        data.hasMainHeader = true;
                    }

                    if (data.redirectDomain) {
                        const url = 'http://' + data.redirectDomain;
                        window.location.replace(url);
                        return;
                    }

                    if (data.domain) {
                        if (state.domainName !== data.domain) {
                            const url = 'http://' + data.domain;
                            window.location.replace(url);
                            return;
                        };

                        // nginx is used to generate PDF
                        if (!`${window.location}`.startsWith('http://nginx') && data.googleTagIsActive && data.googleTagId) {
                            commit('setGoogleTagId', data.googleTagId);
                            commit('setGoogleTagAccepted', undefined);
                            if (expiringStorage.get('acceptToSellMySoul') === true) {
                                commit('setGoogleTagAccepted', true);
                            } else {
                                const acceptToSellMySoul = confirm(`${data.siteTitle} - ${data.siteBaseline}\n\nBonjour, notre site utilise des traceurs (Google) a des fins de collecte statistiques. Nous requérons votre accord pour activer ces fonctionnalités sur votre appareil.`);
                                commit('setGoogleTagAccepted', acceptToSellMySoul);
                                if (acceptToSellMySoul) {
                                    expiringStorage.set(
                                        'acceptToSellMySoul',
                                        true,
                                        60 * 24 * 200,
                                    );
                                }
                            }
                        } else {
                            commit('setGoogleTagId', null);
                            commit('setGoogleTagAccepted', false);
                        }

                        commit('setWhiteLabelling', data);
                        resolve();
                    } else {
                        reject(new Error("Il n'y a pas de marque blanche définie pour le domaine"));
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    reject(error);
                });
        });
    },
    resetStore({ state, commit }) {
        commit('accounts/resetState');
        commit('bap/resetState');
        commit('dialogwatt/resetState');
        commit('customForms/resetState');
        commit('ecorenover/resetState');
        commit('events/resetState');
        commit('experiences/resetState');
        commit('fac/resetState');
        commit('listepro/resetState');
        commit('newsletters/resetState');
        commit('territories/resetState');
        commit('thermix/RESET_STATE');
        commit('visitReport/resetReport');
    },
};

// getters are functions
const getters = {
    getField,
};

export const store = new Vuex.Store({
    // namespaced: true,
    plugins: [pathify.plugin],
    state,
    getters,
    actions,
    mutations,
    modules: {
        accounts,
        bap,
        dialogwatt,
        ecorenover,
        energies,
        events,
        experiences,
        fac,
        listepro,
        newsletters,
        territories,
        therminix,
        thermix,
        userUtilities,
        visitReport,
    },
    strict: true,
});

/// /// /STORE /////////////////////////////////////////
