<template>
  <div class="true-false">
    <font-awesome-icon
      v-if="value && iconTrue"
      class="success-icon"
      :icon="iconTrue"
      :title="titleTrue"
      />
    <font-awesome-icon
      v-if="!value && iconFalse"
      class="error-icon"
      :icon="iconFalse"
      :title="titleFalse"
      />
  </div>
</template>

<script>
        
export default {
    name: 'TrueFalse',
    props: {
        value: { default: false, type: Boolean },
        titleTrue: { default: null, type: String },
        titleFalse: { default: null, type: String },
        iconFalse: { default: 'times-circle', type: [Array, String] },
        iconTrue: { default: 'check-circle', type: [Array, String] },
    },
};
</script>

<style lang="stylus" scoped>
@require '../stylesheet/typography'
@require '../stylesheet/variables'

:deep(.success-icon)
    color: success-color

:deep(.error-icon)
    color: error-color
</style>
