import _ from 'lodash';

import smtpAccounts from '@/apps/messaging/api/smtp_account';

const api = {};

_.assign(
    api,
    smtpAccounts,
);

export default api;
