import { getField } from 'vuex-map-fields';

export default {

    getField,
    multiUnitSize(state) {
        if (state.dwellingsNumber < 50) return 'small';
        if (state.dwellingsNumber < 150) return 'medium';
        return 'large';
    },
    isMultiUnit(state) {
        return state.housingCategory === 'multi_unit';
    },
    housingSize(state, getters, rootState) {
        if (getters.isMultiUnit) return `${getters.multiUnitSize}_${state.housingCategory}`;
        return state.housingCategory; // 'individual'
    },
    isManagingHeating(state) {
        return state.heating !== 0;
    },
    isManagingHotWater(state) {
        return state.hotWater !== 0;
    },
};
