import { store } from '@/store/store';
import apiClient from '@/api/api';
import NProgress from 'nprogress';

// Routing error management (ChunkLoadError)

function showRoutingError(errorMessage) {
    errorMessage = errorMessage || 'Une erreur est survenue lors du chargement de la page. Veuillez réessayer ultérieurement.';
    alert(errorMessage);
}

export default function(error) {
    // Only manage chunk load errors when lazy load route
    if (error.name !== 'ChunkLoadError') throw error;

    // try to fetch app version from server.
    // If differ from client's one, warn user to refresh.
    // Else show generic error
    apiClient.getAppInfos().then((data) => {
        NProgress.done();
        if (data.version !== store.state.versionNumber) {
            showRoutingError("Une mise à jour a été installée, certaines sections peuvent ne plus fonctionner correctement. Pour continuer veuillez sauvegarder vos saisies en cours et recharger l'application.");
        } else {
            throw error;
        }
    }).catch((appInfosError) => {
        NProgress.done();
        showRoutingError();
        throw appInfosError;
    });
};
