<template>
  <a :class="actionLinkCssClass" @click="$emit('click', $event)">
    <font-awesome-icon
      v-if="icon != '' && iconPosition === 'left'"
      class="action-link-icon"
      :icon="icon"
    />
    <span class="action-link-label">{{ label }}</span>
    <font-awesome-icon
      v-if="icon != '' && iconPosition === 'right'"
      class="action-link-icon"
      :icon="icon"
    />
  </a>
</template>

<script>
export default {
    /** Simple link with animation on hover
    Can also be used as a css class or mixin
    Examples : http://avoranfix.hespul.org/pages/viewpage.action?pageId=10191751
    */
    name: 'ActionLink',
    components: {},
    props: {
        label: { default: '', type: String },
        icon: { default: '', type: [String, Array] },
        iconPosition: { default: 'left', type: String },
        /** Colors enables :
         *  default -> primary-color
         *  'secondary-color'
         */
        color: { default: '', type: String },
        disabled: { default: false, type: Boolean },
    },
    computed: {
        actionLinkCssClass() {
            if (this.disabled) return 'action-link-disabled';
            if (this.color === 'secondary-color') return 'action-link-secondary';
            if (this.color === 'red') return 'action-link-red';
            if (this.color === 'gray') return 'action-link-gray';
            if (this.color === 'text-color') return 'action-link-text-color';
            return 'action-link';
        },
    },
};
</script>

<style lang='stylus' scoped></style>
