<template>
<field-wrapper
  class="address-field"
  :field-data="fieldData"
  ref="wrapper"
>
  <div class="input-group">
    <div
      class="dropdown"
      :class="{ open: open }"
      >
      <spinner
        :active="waitingGps"
        />
      <input
        autocomplete="off"
        ref="search"
        type="text"
        :id="id"
        :name="id"
        :class="{ 'map-address-suggestion': true }"
        :placeholder="placeholder"
        v-model="localValue"
        :readonly="readonly"
        @input="searchChanged"
        @keydown.enter="
                        suggestionSelected(
                        matches[highlightIndex],
                        highlightIndex,
                        );"
        @keydown.down="down"
        @keydown.up="up"
        @keydown.esc="setOpen(false);"
        @blur="emitBlur"
        @change="$emit('input', $event.target.value);"
        @placechanged="getAddressData"
      >
      <ul class="suggestion-list">
        <li
          v-for="(suggestion, index) in matches"
          :class="{ active: index == highlightIndex }"
          @mousedown.prevent
          @click="suggestionSelected(suggestion, index);"
          :key="index"
          v-html="suggestion[0]"
          ></li>
        <li class="powered-by">
          Source des données de géolocalisation :
          https://adresse.data.gouv.fr
        </li>
      </ul>
    </div>
    
    <span class="input-group-label">
      <span
        aria-label="Me localiser"
        title="Me localiser"
        @click="getPositionFromGPS"
        class="get-gps input-icon"
        v-show="!waitingForAddressApi && showGpsButton && !localizationIsBlocked && !waitingGps"
        >
        <font-awesome-icon icon="map-marker-alt" />
      </span>
      <span
        aria-label="En attente"
        class="get-gps input-icon"
        v-show="waitingForAddressApi"
        >
        <font-awesome-icon icon="spinner-third" spin />
      </span>
      <span
        aria-label="localisation en cours..."
        title="Localisation en cours…"
        class="get-gps input-icon"
        v-show="!waitingForAddressApi && showGpsButton && !localizationIsBlocked && waitingGps"
        >
        <font-awesome-icon icon="street-view" />
      </span>
      <span
        aria-label="localisation impossible à l'aide du GPS..."
        title="Localisation impossible à l'aide du GPS…"
        class="get-gps input-icon"
        v-show="!waitingForAddressApi && localizationIsBlocked"
        >
        <font-awesome-icon icon="map-marker-slash" />
      </span>
    </span>
  </div>
  
  <span
    v-if="localizationIsBlocked"
    class="error"
    >
    <font-awesome-icon icon="map-marker-slash" /> Impossible d'obtenir votre
    position géographique, entrez votre adresse.
  </span>
</field-wrapper>
</template>

<script>
import _ from 'lodash';
import fieldMixin from './field_mixin';
import Spinner from '@/components/spinner';

import axios from 'axios';

export default {
    name: 'AddressField',
    mixins: [ fieldMixin ],
    components: {
        Spinner,
    },
    props: {
        value: { type: String, default: '' },
        placeholder: { type: String, default: 'Adresse complète…' },
        minimalLengthForSearching: { default: 10, type: Number },
        forceSelectOnBlur: { default: false, type: Boolean },
        useDistrict: { default: false, type: Boolean },       // allow Arrondissement in search results
        allowMoreFields: { default: true, type: Boolean },
        readonly: { default: false, type: Boolean },
        // Type : zipcode, municipality, road, address
        // zipcode : retourne le code postal
        // municipality : retourne la ville
        // road : retourne la voie
        // address: retourne l'adresse complète (fonctionnement attendu par défaut)
        type: { default: 'address', type: String },
    },
    data() {
        return {
            localValue: '',
            zipCode: '',
            town: '',
            inseeCode: '',
            currentValueIsSelected: false,
            preventEmptyField: false,
            showGpsButton: true,
            showMoreFields: false,
            localizationIsBlocked: false,
            waitingGps: false,

            waitingForAddressApi: false,
            suggeredAddress: '',
            selectedOption: null,
            open: false,
            highlightIndex: 0,
            lastSearchText: '',

            lat: 0,
            lon: 0,
        };
    },
    mounted() {
        this.localValue = this.value;
        if (!(navigator && navigator.geolocation)) {
            this.showGpsButton = false;
        }
        if (this.readonly) {
            this.showGpsButton = false;
        }
    },
    watch: {
        value: function() {
            this.localValue = this.value;
        },
        localValue: function() {
            this.$emit('input', this.localValue);
        },
    },
    computed: {
        matches: function() {
            const matches = {};
            let i = 1;
            for (const feature of this.suggeredAddress) {
                if (this.type === "municipality") {
                    matches[i] = [`<span class="entry"><span class="town">${feature.properties.label}</span> <span class="postcode">${feature.properties.postcode}</span></span>`];
                } else {
                    matches[i] = [`<span class="entry"><span class="town">${feature.properties.label}</span></span>`];
                }
                i++;
            }
            return matches;
        },
    },
    methods: {
        toggleMoreFields() {
            this.showMoreFields = !this.showMoreFields;
        },
        getPositionFromGPS: function() {
            if (navigator && navigator.geolocation) {
                this.waitingGps = true;
                const self = this;
                navigator.geolocation.getCurrentPosition(
                    function(location) {
                        self.updateAddressFromLatLon(
                            location.coords.latitude,
                            location.coords.longitude,
                        );
                        // self.emitPosition();
                    },
                    function() {
                        console.log('Cannot get localization');
                        self.localizationIsBlocked = true;
                        self.waitingGps = false;
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 20000,
                        maximumAge: 600000,
                    },
                );
            } else {
                console.log('Cannot get localization from navigator');
                self.waitingGps = false;
            }
        },
        updateAddressFromLatLon: function(lat, lon) {
            const self = this;
            function debouncedUpdateAddressFromLatLon(obj, lat, lon) {
                self.lat = lat;
                self.lon = lon;
                axios
                    .get(
                        `/api-ban/reverse/?lon=${lon}&lat=${lat}`,
                    )
                    .then(response => {
                        if (response.data.features.length >= 1) {
                            const addr = response.data.features[0].properties.label;
                            self.lat = response.data.features[0].geometry.coordinates[1];
                            self.lon = response.data.features[0].geometry.coordinates[0];
                            obj.localValue = addr;
                            self.waitingGps = false;
                            self.$emit('input', addr);
                            self.$emit('position', {
                                lat: self.lat,
                                lon: self.lon,
                            });

                            self.$emit('city', response.data.features[0].properties.city);
                            self.$emit(
                                'postcode',
                                response.data.features[0].properties.postcode,
                            );
                            self.getInseeCode(response.data.features[0].properties.city,  response.data.features[0].properties);
                        }
                    });
            }

            const debounceUpdate = _.debounce(
                debouncedUpdateAddressFromLatLon,
                1500,
            );
            debounceUpdate(this, lat, lon);
        },
        getHeight: function() {
            return this.$refs.app.clientHeight;
        },
        /**
         * When the location found
         * @param {Object} addressData Data of the found location
         * @param {Object} placeResultData PlaceResult object
         * @param {String} id Input container ID
         */
        getAddressData: function(addressData, placeResultData, id) {
            this.address = addressData;
            this.preventEmptyField = true;
        },
        setOpen: function(isOpen) {
            this.open = isOpen;

            if (this.open) {
                this.$refs.search.focus();
                this.lastSearchText = this.localValue;
                this.localValue = '';
            } else if (this.localValue !== null && this.localValue.trim() === '') {
                this.localValue = this.lastSearchText;
            }
        },
        emitBlur() {
            if (this.forceSelectOnBlur && !this.currentValueIsSelected) {
                if (this.localValue !== null && this.localValue.trim() !== '' && this.matches[1]) {
                    this.suggestionSelected(this.matches[1], 1);
                } else {
                    this.localValue = '';
                    this.currentValueIsSelected = false;
                    this.$emit('position', null);
                    this.$emit('city', null);
                    this.$emit('inseecode', null);
                    this.$emit('postcode', null);
                    this.setOpen(false);
                }
            } else {
                this.setOpen(false);
            }
        },
        updateComponentWithValue: function(newValue) {
            if (Object.values(this.options).indexOf(newValue) > -1) {
                // Find the matching text for the supplied option value
                for (const text in this.options) {
                    if (this.options.hasOwnProperty(text)) {
                        if (this.options[text] === newValue) {
                            this.localValue = text;
                        }
                    }
                }
            }
        },

        searchChanged: _.debounce(function() {
            this.currentValueIsSelected = false;
            if (this.localValue !== null) {
                if (this.localValue.length >= this.minimalLengthForSearching) {
                    // CF https://geo.api.gouv.fr/adresse
                    // Type : type de résultat trouvé
                    // - housenumber : numéro « à la plaque »
                    // - street : position « à la voie », placé approximativement au centre de celle-ci
                    // - locality : lieu-dit
                    // - municipality : numéro « à la commune »
                    const type = {
                        'address': 'housenumber',
                        'road': 'housenumber',
                        'zipcode': 'municipality',
                        'municipality': 'municipality',
                    }[this.type];
                    axios
                        .get(
                            `/api-ban/search/?q=${this.localValue}&limit=15&type=${type}`
                        )
                        .then(response => {
                            if (response.data.features.length >= 1) {
                                // Filter response using arrondissements (Lyon, Paris, Marseille)
                                // as we don't use them
                                if (this.useDistrict) {
                                    this.suggeredAddress = response.data.features;
                                } else {
                                    this.suggeredAddress = response.data.features.filter(x => { return x.properties.city.search('Arrondissement') === -1; });
                                }
                                if (!this.open) {
                                    this.open = true;
                                }
                            }
                        });
                }
            }
            this.highlightIndex = 0;
        },800),
        suggestionSelected: function(suggestion, index) {
            if (suggestion === undefined) {
                this.searchChanged();
                return;
            }
            this.open = false;
            this.currentValueIsSelected = true;

            const fullAddress = this.suggeredAddress[index - 1].properties.label;
            const road = this.suggeredAddress[index - 1].properties.name;
            const postcode = this.suggeredAddress[index - 1].properties.postcode;
            const city = this.suggeredAddress[index - 1].properties.city;

            this.lat = this.suggeredAddress[index - 1].geometry.coordinates[1];
            this.lon = this.suggeredAddress[index - 1].geometry.coordinates[0];

            this.$emit('position', { lat: this.lat, lon: this.lon });
            this.$emit('city', city);
            this.$emit('postcode', postcode);
            this.$emit('road', road);

            this.localValue = {
                'address': fullAddress,
                'road': road,
                'municipality': city,
                'zipcode': postcode,
            }[this.type];

            this.getInseeCode(this.suggeredAddress[index - 1].properties.city, this.suggeredAddress[index - 1].properties);
        },

        getInseeCode: function(city, properties = null) {
            axios
                .get(
                    `/api-ban/search/?q=${city}&limit=10&type=municipality`,
                )
                .then(response => {
                    if (response.data.features.length >= 1) {
                        for (const i in response.data.features) {
                            if (
                                response.data.features[i].properties.name === city
                                    && response.data.features[i].properties.context === properties?.context
                                    && (
                                        response.data.features[i].properties?.city.indexOf('Arrondissement') === -1
                                    )
                            ) {
                                const address = response.data.features[i];
                                this.$emit('inseecode', address.properties.citycode);
                                return;
                            }
                        }
                        if (properties !== null) {
                            this.$emit('inseecode', properties.citycode);
                        }
                    }
                });
        },

        up: function() {
            if (this.open) {
                if (this.highlightIndex > 0) {
                    this.highlightIndex--;
                }
            } else {
                this.setOpen(true);
            }
        },

        down: function() {
            if (this.open) {
                if (this.highlightIndex < this.suggeredAddress.length) {
                    this.highlightIndex++;
                }
            } else {
                this.setOpen(true);
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../stylesheet/variables'
@require '../stylesheet/typography'

.get-gps:hover
    cursor: pointer

.input-group
    margin: 0
</style>
