/*
    Autogenerated class model from django ListeproProxyUser
    -- 2023-03-13 18:02:20.828342
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class ListeproProxyUserClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'email', rawData.email || '');
        Vue.set(this, 'civility', rawData.civility || '');
        Vue.set(this, 'firstName', rawData.firstName || '');
        Vue.set(this, 'lastName', rawData.lastName || '');
        Vue.set(this, 'isStaff', rawData.isStaff === undefined ? false : rawData.isStaff);
        Vue.set(this, 'isActive', rawData.isActive === undefined ? true : rawData.isActive);
        Vue.set(this, 'dateJoined', rawData.dateJoined);
        Vue.set(this, 'userType', rawData.userType || 'professional');
        Vue.set(this, 'title', rawData.title || '');
        Vue.set(this, 'color', rawData.color || '#888');
        Vue.set(this, 'phoneCache', rawData.phoneCache || '');
        Vue.set(this, 'group', rawData.group);
        Vue.set(this, 'whiteLabelling', rawData.whiteLabelling);
        Vue.set(this, 'profilePic', rawData.profilePic || '');
        Vue.set(this, 'phone', rawData.phone || '');
        Vue.set(this, 'allowedToSeeBapMenu', rawData.allowedToSeeBapMenu || false);
    }

    getBaseUrl() {
        return '/listepro/user/';
    }

    get isValid() {
        return true;
    }
}

class ListeproProxyUserCollection extends BaseCollection {
    initModel(rawData) {
        return new ListeproProxyUserClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/user/';
    }
}

class ListeproProxyUserStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new ListeproProxyUserClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/user/';
    }

    getStorePath() {
        return 'listepro/listeproProxyUserCollection';
    }
}

export {
    ListeproProxyUserClass,
    ListeproProxyUserCollection,
    ListeproProxyUserStoreCollection,
};
