export default {
    defs: {},
    emptyDef: {
        group: '',
        id: '',
        type: '',
        identifier: '',
        share: 1,
        syncedId: '',
        syncedHotWaterId: '',
        firstSiblingId: '',
        secondSiblingId: '',
        locked: false,
        lockedReason: '',
        efficiency: '',
        investment: '',
        maintenance: '',
        provisions: '',
        useGreenEnergy: false,
        greenEnergyShare: 0,
        energyPrice: '',
        greenEnergyPrice: '',
        energyPriceVariation: '',
        ghgRatio: '',
        greenEnergyGhgRatio: '',
        solarPanelsSurface: '',
        forcedForNonWoodHeating: false, // Specific to metro-grenoble forced solutions
        limitedChoices: [], // Specific to metro-grenoble forced solutions
        excludedChoices: ['heating_network'], // heating_network is available only for metro grenoble
        messageHint: '',
        initialValues: {
            efficiency: '',
            investment: '',
            maintenance: '',
            provisions: '',
            energyPrice: '',
            greenEnergyPrice: '',
            energyPriceVariation: '',
            solarPanelsSurface: '',
        },
    },
};
