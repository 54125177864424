<template>
<validation-observer ref="validator" slim>
  <div class="reference-data">
    <layout-container color="#eee" v-if="additionalAddresses && additionalAddresses.length > 0">
      <div class="grid-x grid-padding-x">
        
        <div class="cell">
          <checkbox-field
            class="checkbox-active"
            v-model="localOffer.useOnMainAddress"
            id="useOnMainAddress"
            ref="useOnMainAddress"
            :label="localOffer.useOnMainAddress ? 'Référence visible sur la fiche principale' : 'Référence non visible sur la fiche principale'"
            :class="{'field-is-different': isFieldIsDifferent('useOnMainAddress')}"
            />
          
          <select-value
            id="useOnThisAddresses"
            v-model="localOffer.useOnThisAddresses"
            :options="additionalAddressesAsSelect"
            track-by="value"
            label="Afficher cette référence sur ces addresses supplémentaires"
            option-label="label"
            multiple
            :class="{'field-is-different': isFieldIsDifferent('useOnThisAddresses')}"
            >
            <template v-slot:option="props">
              {{ props.option.label }}
            </template>
          </select-value>
          
        </div>
      </div>
    </layout-container>
    
    
    <layout-container>
      <div class="grid-x grid-padding-x">
        <div class="cell">
          <div class="error" v-if="formErrorsMessages.all">
            form : {{formErrorsMessages.all}}
          </div>
        </div>
        <div class="cell medium-6 ">
          
          <text-field
            id="offerName"
            label="Nom de l'offre"
            placeholder="Saisissez le nom de l'offre"
            mandatory
            :isError="formErrors.offerName"
            v-model="localOffer.offerName"
            validateRules="required"
            :scope="'localOffer'"
            :class="{'field-is-different': isFieldIsDifferent('offerName')}"
            />
        </div>
        
      </div>
    </layout-container>
    <layout-container class="content-white">
      <div class="grid-x grid-padding-x">
        <div class="cell ">
          <label class="form-label">
            Description de l'offre
            <span class="text-italic text-unbold text-sm">- obligatoire</span>
          </label>
          <wysiwyg-field
            id="offerDescription"
            placeholder="Saisissez l'historique / contexte du projet"
            v-model="localOffer.offerDescription"
            validateRules="required|max:1024"
            :scope="'localOffer'"
            :isError="formErrors.offerDescription"
            :class="{'field-is-different': isFieldIsDifferent('offerDescription')}"
            />
        </div>
      </div>
    </layout-container>
    <layout-container class="content-gray">
      <div class="grid-x grid-padding-x">
        <div class="cell ">
          <label class="form-label">
            Caractéristiques de l'offre
            <span class="text-italic text-unbold text-sm">- obligatoire</span>
          </label>
          <wysiwyg-field
            id="offerCharacteristics"
            placeholder="Saisissez l'historique / contexte du projet"
            v-model="localOffer.offerCharacteristics"
            ivalidateRules="required|max:1024"
            :scope="'localOffer'"
            :isError="formErrors.offerCharacteristics"
            :class="{'field-is-different': isFieldIsDifferent('offerCharacteristics')}"
            />
        </div>
      </div>
    </layout-container>
    
    <layout-container class="content-white">
      <div class="grid-x grid-padding-x">
        <div class="cell ">
          <text-field
            id="link"
            label="Lien vers l'offre"
            placeholder="URL de l'offre"
            :isError="formErrors.link"
            v-model="localOffer.link"
            validateRules="url"
            type="url"
            :scope="'localOffer'"
            :class="{'field-is-different': isFieldIsDifferent('link')}"
            />
        </div>
      </div>
    </layout-container>
    
  </div>
</validation-observer>
</template>

<script>
  
import CheckboxField from '@/components/checkbox_field';
import LayoutContainer from '@/components/layout_container';
import SelectValue from '@/components/select_value';
import TextField from '@/components/text_field';
import WysiwygField from '@/components/wysiwyg_field';

import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';


export default {
    name: 'BankData',
    mixins: [handleFormErrorsMixin],
    components: {
        CheckboxField,
        LayoutContainer,
        SelectValue,
        TextField,
        WysiwygField,
    },
    props: [
        'value',
        'differences',
        'additionalAddresses',
    ],
    data: function() {
        return {
            isLoading: false,
        };
    },
    computed: {
        additionalAddressesAsSelect() {
            return this.additionalAddresses.map(x => { return {value: x.pk, label: `${x.name} - ${x.address}`, name: `${x.name} - ${x.address}`}; });
        },
    },
    watch: {
        value: {
            handler(value) {
                if (value) {
                    this.localOffer = value;
                    // this.draft = value.referenceDraft;
                    // this.published = value.referencePublished;
                }
            },
            immediate: true,
            deep: true,
        },
        localOffer: {
            handler(value) {
                this.$emit('input', value);
            },
            deep: true,
        },
    },
    created: function() {
    },
    mounted: function() {
    },
    methods: {
        validate() {
            return this.$refs.validator.validate();
        },
        isFieldIsDifferent(id) {
            return this.differences.includes(id);
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

.reference-data
    .field-is-different
        border: solid 4px warning-color
        padding-left: space-md

    .pictures
        margin-right: space-md

        img
            display: block

        .multiselect__tag
            display: block
            margin-top: space-xs
            margin-right: 20px

    .modal-image-ref
        .popup-content
            padding-left: space-md
            padding-right: space-md

        .modal-header
            padding: 1rem 0.3rem 1rem 0.3rem
            background-color: primary-color-500
            margin-bottom: 1rem

        .modal-title
            color: #fff
            text-transform: uppercase
            padding: 3%

    .buttons
        padding: space-md
</style>
