let api = {
    getPdfTempStore(uuid) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/pdf_generator/pdf_temp_store/${uuid}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
