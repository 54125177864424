<template>
    <pdf-layout
        v-if="layout === 'pdf'"
    />
    <public-newsletters-layout
        v-else-if="layout === 'public-newsletters'"
    />
    <web-layout
        v-else
    />
</template>

<script>
import { mapState } from 'vuex';
import { store } from '@/store/store';
import WebLayout from '@/apps/web_layout';
import PdfLayout from '@/apps/pdf_layout';
import PublicNewslettersLayout from '@/apps/public_newsletters_layout';

export default {
    name: 'Layout',
    store,
    components: {
        WebLayout,
        PdfLayout,
        PublicNewslettersLayout,
    },
    data() {
        return {};
    },
    computed: mapState(['layout']),
};
</script>
