import defaultState from './_defaultState';
import getters from './_getters';
import mutations from './_mutations';
import actions from './_actions';

const state = Object.assign({}, (defaultState));

export default {
    namespaced: true,
    modules: {},
    state,
    getters,
    mutations,
    actions,
};
