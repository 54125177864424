function getRouteParameter(pkToParse, routeParams) {
    let pk = Number.parseInt(pkToParse, 10);
    if (Number.isNaN(pk)) {
        pk = -1;
    }
    return Object.assign(routeParams || {}, { pk });
}

const routes = [
    {
        path: '/fac',
        name: 'fac',
        component: () => import('@/apps/fac/fac'),
        redirect: { name: 'FacContactsList' },
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
        children: [
            {
                path: 'contacts',
                name: 'FacContactsList',
                component: () => import('@/apps/fac/contacts/list'),
                meta: {
                    tabLabel: 'Contacts',
                    tabIcon: 'user',
                },
            },
            {
                path: 'contacts/:pk',
                name: 'FacContactsEdit',
                component: () => import('@/apps/fac/contacts/edit'),
                props({ params }) {
                    const otherParams = { openTabAfterRoute: params.openTabAfterRoute };
                    return getRouteParameter(params.pk, otherParams);
                },
            },

            {
                path: 'organizations',
                name: 'FacOrganizationsList',
                component: () => import('@/apps/fac/organizations/list'),
                meta: {
                    tabLabel: 'Structures',
                    tabIcon: 'users',
                },
            },
            {
                path: 'organizations/:pk',
                name: 'FacOrganizationsEdit',
                component: () => import('@/apps/fac/organizations/edit'),
                props({ params }) {
                    const otherParams = { openTabAfterRoute: params.openTabAfterRoute };
                    return getRouteParameter(params.pk, otherParams);
                },
            },
            {
                path: 'reminders',
                name: 'FacReminderListContainer',
                component: () => import('@/apps/fac/reminders/reminders_list_container'),
            },
            {
                path: 'lists',
                name: 'FacListsV2List',
                component: () => import('@/apps/fac/lists/list_listv2'),
                meta: {
                    tabLabel: 'Listes',
                    tabIcon: 'list',
                },
            },
            {
                path: 'lists/new',
                name: 'FacListsNew',
                component: () => import('@/apps/fac/lists/lists_new'),
            },
            {
                path: 'lists/:pk',
                name: 'FacListsEdit',
                component: () => import('@/apps/fac/lists/lists_edit'),
                props({ params }) {
                    return getRouteParameter(params.pk);
                },
            },
            {
                path: 'tags',
                name: 'FacTagsList',
                component: () => import('@/apps/fac/tags/list'),
                meta: {
                    tabLabel: 'Tags',
                    tabIcon: 'tag',
                },
            },
            {
                path: 'tags/:pk',
                name: 'FacTagsEdit',
                component: () => import('@/apps/fac/tags/edit'),
                props({ params }) {
                    return getRouteParameter(params.pk);
                },
            },
        ],
    },
    {
        path: '/fac/statistiques',
        name: 'FacStatisticsContainer',
        component: () => import('@/apps/fac/statistics/container_layout'),
        redirect: { name: 'FacStatistics' },
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
        children: [
            {
                path: '/fac/suivi',
                name: 'FacStatistics',
                component: () => import('@/apps/fac/statistics/layout'),
                meta: {
                    tabLabel: 'Suivi',
                    tabIcon: ['far', 'chart-area'],
                },
            },
            {
                path: '/fac/pivot',
                name: 'FacPivot',
                component: () => import('@/apps/fac/statistics/pivot_layout'),
                meta: {
                    tabLabel: 'Tableau pivot',
                    tabIcon: ['far', 'th-list'],
                },
            },
        ],
    },

    {
        path: '/fac/saisies-incompletes',
        name: 'FacIncompleteDataContainer',
        component: () => import('@/apps/fac/incomplete_data/incomplete_data_container'),
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
    },
    {
        path: '/fac/doublons',
        name: 'FacDuplicates',
        component: () => import('@/apps/fac/duplicates/duplicates'),
        redirect: { name: 'FacContactsDuplicatesList' },
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
        children: [
            {
                path: 'contacts',
                name: 'FacContactsDuplicatesList',
                component: () => import('@/apps/fac/duplicates/contacts_duplicates_list'),
                meta: {
                    tabLabel: 'Contacts',
                    tabIcon: 'user',
                },
            },
            {
                path: 'structures',
                name: 'FacOrganizationsDuplicatesList',
                component: () => import('@/apps/fac/duplicates/organizations_duplicates_list'),
                meta: {
                    tabLabel: 'Structures',
                    tabIcon: 'users',
                },
            },
        ],
    },
    {
        path: '/fac/retours-tbs',
        name: 'FacTBSFeedback',
        component: () => import('@/apps/fac/tbs_feedback/tbs_feedback.vue'),
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
    },
    {
        path: '/fac/retours-tbs/:pk',
        name: 'FacTBSErrorList',
        component: () => import('@/apps/fac/tbs_feedback/tbs_error_list.vue'),
        props({ params }) {
            return getRouteParameter(params.pk);
        },
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
    },

    {
        path: '/fac/partage-projets',
        name: 'FacProjectShareList',
        component: () => import('@/apps/fac/fap/project_share_list'),
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator'],
        },
    },
    {
        path: '/fac/partage-projets/nouveau',
        name: 'FacProjectShareNew',
        component: () => import('@/apps/fac/fap/project_share_new'),
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator'],
        },
    },

    {
        path: '/fac/partage-projets/:pk',
        name: 'FacProjectShareEdit',
        component: () => import('@/apps/fac/fap/project_share_edit'),
        props({ params }) {
            return getRouteParameter(params.pk);
        },
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator'],
        },
    },

    {
        path: '/fac/comparaison-tbs',
        name: 'FacCompareTBSAndIncompleteData',
        component: () => import('@/apps/fac/incomplete_data/compare_tbs_and_incomplete_data'),
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator'],
        },
    },

    {
        path: '/fac/dossiers',
        name: 'FacFolders',
        component: () => import('@/apps/fac/folders/list'),
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
    },
    {
        path: '/fac/parcours',
        name: 'FacProjectList',
        component: () => import('@/apps/fac/project_editor/project_edit'),
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'superadvisor'],
        },
    },
];

export default routes;
