<template>
<div class="professional-homepage">
  <layout-container>

    <strong>Bienvenue sur l'annuaire des pros du 69 !</strong>

    <p>L’Agence Locale de la Transition Énergétique du Rhône et l’Agence Locale
    de l’Energie et du Climat de la Métropole de Lyon animent un réseau de
    professionnels qui mettent en œuvre et favorisent des solutions de
    rénovation énergétique qui s'inscrivent dans l'objectif de division par 4
    des consommations d’énergies et d’émissions de CO2 pour atteindre la
    neutralité carbone en 2050.</p>
    
    <div class="items">
      
      <div class="item-card" @click="gotoBAP">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'link']" />
        </div>
        <div class="title">
          Bourse aux projets
        </div>
        <div class="helptext">
          Trouvez et accompagnez de nouveaux projets de rénovation globale
          détectés par l’ALEC Lyon et l’ALTE 69.<br>
          Pour chaque projet, vous disposez d'un cahier de recommandations avec
          les éléments techniques du logement et un scénario de rénovation
          globale et performant.
        </div>
      </div>
      
      <div class="item-card" @click="gotoListeProPro">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'user-hard-hat']" />
        </div>
        <div class="title">
          Annuaire des pros
        </div>
        <div class="helptext">
          Accédez à la liste des professionnels de la rénovation performante dans le Rhône
          et la Métropole de Lyon référencés par l'ALEC Lyon et l'ALTE 69 : architectes,
          maîtres d'oeuvre, bureaux d'études, groupements d'entreprises, contractants
          généraux, banques et courtiers, agences immo…
        </div>
      </div>

      <div v-if="listeproNewsIsActive" class="item-card" @click="gotoNews">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'newspaper']" />
        </div>
        <div class="title">
          Actualités
        </div>
        <div class="helptext">
          Retrouvez l'actualité nationale et régionale de la rénovation
          performante décryptée et l'actualité du réseau des professionnels du
          Rhône et de la Métropole de Lyon : événements, témoignages, vidéos…
        </div>
      </div>


      
      <div class="item-card" @click="gotoForm">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'file-alt']" />
        </div>
        <div class="title">
          Éditer ma fiche
        </div>
        <div class="helptext">
          Modifiez votre fiche contact : les informations de votre structure, vos références et réalisations.<br>
          Modifier mes informations de connexions.
        </div>
      </div>
      
    </div>

  </layout-container>
</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
        
export default {
    name: 'ProfessionalHomepage',
    data: function() {
        return {
        };
    },
    computed: {
        ...mapFields(['whiteLabelling']),
        listeproNewsIsActive() {
            return this.whiteLabelling.listeproNewsIsActive;
        },
    },
    watch: {
    },
    methods: {
        gotoBAP() {
            this.$router.push({ name: 'BapProProjects' });
        },
        gotoListeProPro() {
            this.$router.push({ name: 'ListeProPro' });
        },
        gotoNews() {
            this.$router.push({ name: 'ListeProPublicNews' });
        },
        gotoForm() {
            this.$router.push({ name: 'ListeProProfessionalForm' });
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../stylesheet/typography'
@require '../../stylesheet/variables'

.items
    display: flex
    margin-bottom: 200px

    // flex-direction: column
    // justify-content: space-between
    .item-card
        cursor: pointer
        flex: 1
        text-align: center
        margin-left: 50px
        margin-right: 50px

        .icon
            font-size: 80px
            color: primary-color

        .title
            font-size: text-xxl
            color: primary-color

        .helptext
            text-align: left
</style>
