/*
    Autogenerated class model from django Activity
    -- 2023-03-13 18:02:20.822853
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class ActivityClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'order', rawData.order);
        Vue.set(this, 'name', rawData.name);
        Vue.set(this, 'icon', rawData.icon);
    }

    getBaseUrl() {
        return '/listepro/activity/';
    }

    get isValid() {
        return true;
    }
}

class ActivityCollection extends BaseCollection {
    initModel(rawData) {
        return new ActivityClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/activity/';
    }
}

class ActivityStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new ActivityClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/activity/';
    }

    getStorePath() {
        return 'listepro/activityCollection';
    }
}

export {
    ActivityClass,
    ActivityCollection,
    ActivityStoreCollection,
};
