<template>
    <field-wrapper
      class="multiselect-field"
      :field-data="fieldData"
      ref="wrapper"
    >
        <multiselect
        :hide-selected="hideSelected"
        :label="optionLabel"
        :placeholder="placeholder"
        :tag-placeholder="tagPlaceholder"
        :select-label="selectLabel"
        :select-group-label="selectGroupLabel"
        :selected-label="selectedLabel"
        :deselect-label="deselectLabel"
        :deselect-group-label="deselectGroupLabel"
        :show-labels="showLabels"
        :track-by="realTrackBy"
        v-bind="$attrs"
        v-model="localValue"
        v-on="inputListeners"
        :id="id"
        :name="id"
        :options="options"
        :group-values="groupValues"
        @search-change="searchChange"
        >
        <template v-slot:noResult>Aucun élément trouvé.</template>

        <template v-slot:noOptions>Il n'y a pas d'entrées.</template>

        <template v-slot:maxElements>Nombre d'options maximum ({{ max }}) atteint.</template>

        <!-- Pass all slots to child component, erasing default content -->
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
        </multiselect>
    </field-wrapper>
</template>

<script>
import FieldWrapper from '@/components/field_wrapper';
import Multiselect from 'vue-multiselect';
import fieldMixin from './field_mixin';

import _ from 'lodash';

export default {
    name: 'MultiselectField',
    components: {
        FieldWrapper,
        Multiselect,
    },

    mixins: [fieldMixin],
    props: {
        // Component props
        max: Number,
        // Default child props. If not overriden here, the defaults from
        // vue-multiselect will apply.
        hideSelected: {
            default() {
                return false;
            },
        },
        optionLabel: {
            default() {
                return 'label';
            },
        },
        placeholder: {
            default() {
                return '';
            },
        },
        tagPlaceholder: {
            default() {
                return 'Appuyez sur Entrée pour créer un tag.';
            },
        },
        selectLabel: {
            default() {
                return 'Appuyez sur Entrée pour sélectionner.';
            },
        },
        selectGroupLabel: {
            default() {
                return 'Appuyez sur Entrée pour sélectionner tout le groupe.';
            },
        },
        selectedLabel: {
            default() {
                return 'Sélectionné';
            },
        },
        deselectLabel: {
            default() {
                return 'Appuyez sur Entrée pour désélectionner.';
            },
        },
        deselectGroupLabel: {
            default() {
                return 'Appuyez sur Entrée pour désélectionner tout le groupe.';
            },
        },
        showLabels: {
            default() {
                return false;
            },
        },
        groupValues: {
            default() {
                return null;
            },
        },
        trackBy: {
            default() {
                return null;
            },
        },
        options: { type: Array, default: () => [] },
        value: { },
        reduce: { type: Function, default: (option) => option },
    },
    data() {
        return {
            useMultiple: false,
        };
    },
    computed: {
        realTrackBy() {
            return this.trackBy !== null ? this.trackBy : 'label';
        },
        inputListeners: function() {
            const vm = this;  // eslint-disable-line consistent-this
            return Object.assign(
                {},
                this.$listeners,
                {
                    input: function(value) {
                        vm.$emit('input', vm.mapValue(value));
                    },
                }
            );
        },
        localValue: {
            get() {
                let returnValue = null;
                if (this.options === undefined || this.options === null || this.value === null || this.value === undefined) {
                    if (this.useMultiple) {
                        return [];
                    } else {
                        return null;
                    }
                }
                if (this.useMultiple) {
                    const newValue = [];
                    for (const option of this.options) {
                        for (const val of this.value) {
                            if (this.groupValues === null) {
                                if (this.isEqual(option, val)) {
                                    newValue.push(option);
                                }
                            } else {
                                for (const subOption of option[this.groupValues]) {
                                    if (this.isEqual(subOption, val)) {
                                        newValue.push(subOption);
                                    }
                                }
                            }
                        }
                    }
                    returnValue = newValue;
                } else {
                    for (const option of this.options) {
                        if (this.groupValues === null) {
                            if (this.isEqual(option, this.value)) {
                                returnValue = option;
                                break;
                            }
                        } else {
                            for (const subOption of option[this.groupValues]) {
                                if (this.isEqual(subOption, this.value)) {
                                    returnValue = subOption;
                                    break;
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
            set(value) {
                this.$emit('input', this.mapValue(value));
            },
        },
    },
    mounted() {
        this.initUseMultiple();
    },
    methods: {
        searchChange(text) {
            this.$emit('search',text);
        },
        initUseMultiple() {
            if (this.$attrs.multiple === undefined || this.$attrs.multiple === false) {
                this.useMultiple = false;
            } else {
                this.useMultiple =  true;
            }
        },
        isEqual(value1, value2) {
            if ((value1 === null || value2 === null) && value1 !== value2) {
                return false;
            }
            if (this.trackBy) {
                return value1[this.trackBy] === value2[this.trackBy];
            } else {
                return _.isEqual(this.reduce(value1), value2);
            }
        },
        mapValue(value) {
            let retVal = null;
            if (this.useMultiple) {
                if (value.length > 0) {
                    retVal = value.map(x => { return this.reduce(x); });
                } else {
                    retVal = [];
                }
            } else {
                if (value != null) {
                    retVal = this.reduce(value);
                }
            }
            return retVal;
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="stylus">
@require '../stylesheet/variables'

.multiselect__tag
.multiselect__option--highlight
    &
    &.multiselect__option--selected
        background: gray-600

.multiselect__tag-icon
    &:focus
    &:hover
        background: gray-200

    &:after
        color: white

.multiselect__input
    &
    &:focus
        border: none
        box-shadow: unset
</style>
