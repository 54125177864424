<template>
  <div class="history">
    <mixeur-table
      v-if="value"
      :columns="columns"
      :rows="value"
      :sort-options="{ enabled: false, initialSortBy: {field: 'date', type: 'desc'} }"
      :search-options="{ enabled: false }"
      :pagination-options="paginationOptions"
      >
      <template v-slot:table-row="props">
        <span v-if="props.column.field == 'date'">
          {{ formatDate(new Date(props.formattedRow[props.column.field])) }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
  
    </mixeur-table>

    <a v-if="workflow && !isProfessional" :href="workflowUrl" target="_blank" class="float-right">
      <font-awesome-icon
        icon="project-diagram"
        />

      voir le parcours
    </a>

    <br clear="all">

  </div>
</template>

<script>
import MixeurTable from '@/components/mixeur_table';
import { utils } from '@/utils';
import { userMixin } from '@/mixins/user';
        
export default {
    name: 'History',
    mixins: [ userMixin ],
    components: {
        MixeurTable,
    },
    props: {
        state: { default: null, type: String },
        value: { default: () => { return []; }, type: Array },
        workflow: { default: null, type: String },
    },
    data: function() {
        return {
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 5,
                perPageDropdown: [5, 10, 25, 200],
                dropdownAllowAll: true,
                nextLabel: 'suivant',
                prevLabel: 'précédent',
                rowsPerPageLabel: 'Lignes par page',
                ofLabel: 'de',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'Toutes',
            },
            columns: [
                {
                    label: 'Date',
                    field: 'date',
                    sortable: true,
                },
                {
                    label: 'État final',
                    field: 'labelTo',
                    sortable: false,
                },
                {
                    label: 'État de départ',
                    field: 'labelFrom',
                    sortable: false,
                },
                {
                    label: 'Acteur',
                    field: 'by',
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        isProfessional() {
            return this.userUtilities.isProfessional;
        },
        workflowUrl() {
            if (this.workflow === null) {
                return null;
            }
            if (this.state === null) {
                return {
                    "publication": "/static/img/listepro/workflows/publication_workflow.png",
                    "presentation": "/static/img/listepro/workflows/presentation_workflow.png",
                    "reference": "/static/img/listepro/workflows/reference_workflow.png",
                }[this.workflow];
            }
            return {
                "publication": `/static/img/listepro/workflows/publication_workflow-${this.state}.png`,
                "presentation": `/static/img/listepro/workflows/presentation_workflow-${this.state}.png`,
                "reference": `/static/img/listepro/workflows/reference_workflow-${this.state}.png`,
            }[this.workflow];
            
        },
    },
    watch: {
    },
    created: function() {
    },
    mounted: function() {
    },
    methods: {
        formatDate(date) {
            return utils.formatDateTime(date);
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'
</style>
