<template>
  <validation-provider
    :rules="fieldData.validateRules"
    :name="fieldData.validationLabel || fieldData.label"
    ref="validator"
    v-slot="{ errors, failed }"
    class="input-field grid-x"
  >
    <div
      class="cell label-container grid-x align-middle"
      :class="{'align-justify': fieldData.tooltipConfig && fieldData.tooltipConfig.iconAlign === 'right'}"
    >
      <label v-if="fieldData.inputLabel" :for="fieldData.id" class="cell shrink">
        <span v-html="fieldData.inputLabel"/>
        <span v-if="fieldData.mandatory" class="text-italic text-unbold text-sm">- obligatoire</span>
      </label>
      <div
        v-if="fieldData.tooltipConfig"
        class="tooltip-icon cell shrink"
        v-tooltip="fieldData.tooltipConfig"
      >
        <font-awesome-icon :icon="fieldData.tooltipConfig.icon" />
      </div>
    </div>

    <div class="cell" :class="{ errorbox: failed || fieldData.isError }"><slot /></div>
    <div class="errors" v-if="failed || fieldData.isError">
      <div
        class="error"
        v-for="(errorMessage, index) in errors"
        :key="index"
        v-html="errorMessage"
      />
      <div
        class="error"
        v-if="fieldData.customError"
        v-html="fieldData.customError"
      />
    </div>
    <div class="helptext" v-if="fieldData.helptext">{{ fieldData.helptext }}</div>
  </validation-provider>
</template>

<script>
export default {
    props: {
        fieldData: Object,
    },
};
</script>

<style lang="stylus" scoped>
.input-field
    margin-bottom: 1rem

.errorbox
    border: solid 2px var(--error-color-500)

.tooltip-icon
    cursor: help
    opacity: 0.9
    font-size: 80%

    &:hover
        opacity: 1
</style>
