import { getField } from 'vuex-map-fields';
import _ from 'lodash';

export default {
    getField,

    solutionsTotalCosts(state) {
        return state.solutionsResults.map((_, index) => {
            return Object.keys(state.periodTotalCostSeries)
                .filter((serie) => ['combustibleCost', 'maintenanceCost', 'provisionsCost', 'investment'].includes(serie))
                .reduce((total, serie) => total + state.periodTotalCostSeries[serie][index], 0);
        });
    },

    isMultiUnit(state) {
        return (state.needs || {}).housingCategory === 'multi_unit';
    },

    housingCategory(state) {
        if (!state.needs) return {};
        return state.needs.housingCategories[_.camelCase(state.needs.housingCategory)];
    },

    metroGrenobleEnabled(state) {
        return (state.metroGrenoble || {}).enabled;
    },

    metroGrenobleTownName(state) {
        return state.metroGrenoble.towns[state.metroGrenoble.inseeCode]?.name;
    },
};
