!<template>
  <layout-container>
    <div>
      <spinner />
    </div>
  </layout-container>
</template>

<script>
import Spinner from '@/components/spinner';
import LayoutContainer from '@/components/layout_container';

export default {
    name: 'Home',
    components: {
        LayoutContainer,
        Spinner,
    },
    mounted: function() {
        if (this.$store.getters['userUtilities/isAdvisor']) {
            console.log('DashboardAdvisor');
            this.$router.push({ name: 'DashboardAdvisor' });
        } else if (this.$store.getters['userUtilities/isManager']) {
            console.log('DashboardManager');
            this.$router.push({ name: 'DashboardManager' });
        } else if (this.$store.getters['userUtilities/isAdministrator']) {
            console.log('DashboardAdministrator');
            this.$router.push({ name: 'DashboardAdministrator' });
        } else {
            console.log('DashboardHome - Anonymous');
            // this.$router.push({ name: 'Home' });
            this.$router.push({ name: 'DialogwattHome' });
        }
    },
    methods: {},
};
</script>
