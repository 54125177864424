export default {
    heating: '',
    hotWater: '',
    housingCategory: 'individual',
    dwellingsNumber: 15,
    housingCategories: {
        individual: {
            label: 'Logement individuel',
            helptext: 'Concerne un appartement ou une maison',
            icon: 'home-lg',
        },
        multi_unit: {
            label: 'Logements collectifs',
            helptext: 'Concerne un immeuble entier',
            icon: 'building',
        },
    },
    renovationType: 'new_building',
    renovationTypes: {
        new_building: {
            label: 'Construction neuve',
            icon: 'home-lg',
        },
        global_renovation: {
            label: 'Rénovation des systèmes et du bâti',
            icon: 'pencil-ruler',
        },
        systems_renovation: {
            label: 'Rénovation des systèmes seuls',
            icon: 'tools',
        },
    },
};
