<template>    
    <div class="content-gray" >
         <header class="modal-header">
            <div class="grid-x grid-margin-x">
                <div class="cell small-10">
                    <h2 class="modal-title">{{production.productionName}}</h2>
                </div>
                <div class="cell small-2">
                    <a @click="hideModal"><font-awesome-icon class="close-modal" :icon="['fal', 'times-circle']" /></a>
                </div>
            </div>
        </header>
        <layout-container>
            <div class="grid-x grid-margin-x">
                <div class="cell small-4" v-if="production.productionImages.length!==0">
                    <carousel 
                        :per-page="1" 
                        :mouse-drag="false"
                        :navigationEnabled="true"
                        :adjustableHeight="false"
                        :loop="true"
                        :paginationPadding="4"
                        :paginationSize=10
                        :paginationActiveColor="'#9a258f'"
                        :paginationColor="'#d2d2d2'"
                        :centerMode="true"
                    >
                      <slide v-bind:key="picKey(i.cropped)" v-for="i in production.productionImages">
                        <img v-bind:src="i.cropped" class="image">
                      </slide>
                    </carousel>
                </div>
                <div class="cell small-1">
                </div>
                <div class="cell small-6 basic-info">
                    <span class="bold">Lieu : </span>
                    {{production.place}}<br>
                    <span class="bold">Année : </span>
                    {{production.year}}<br>
                    <template v-if="production.label">
                      <span class="bold">Label : </span>
                      {{production.label}}<br>
                    </template>
                    <span class="bold">Consommation énergétique primaire : </span><br>


                    <table class="consumption">
                      <tr>
                        <td>
                          Avant travaux : {{production.consumptionBefore}} kWh/m2/an  <br>
                          Après travaux : {{production.consumptionAfter}} kWh/m2/an <br>
                        </td>
                        <td>
                          <font-awesome-icon
                            class="tooltip-icon-consumptions"
                            :icon="['far', 'question-circle']"
                            v-tooltip="{content: efficiencyTooltip, html: true}"
                            />
                        </td>
                      </tr>
                    </table>
                    
                    <br>
                </div>
            </div>
        </layout-container>
        <layout-container class="content-white">
            <div class="grid-x grid-margin-x">
                <div class="cell">
                  <div class="title-content">Historique/Contexte</div>
                  <div class="formatted-content" v-html="production.history"></div>
                  <div class="title-content">Missions réalisées</div>
                  <div class="formatted-content" v-html="production.completedMission"></div>
                  <div v-if="production.thermalEnvelope" class="title-content">Enveloppe Thermique</div>
                  <div v-if="production.thermalEnvelope" class="formatted-content" v-html="production.thermalEnvelope"></div>
                  <div v-if="production.system" class="title-content">Système</div>
                  <div v-if="production.system" class="formatted-content" v-html="production.system"></div>
                  <div v-if="production.airtightnessTestResult" class="title-content">Résultat du test d'étanchéité à l'air</div>
                  <div v-if="production.airtightnessTestResult" class="formatted-content" v-html="production.airtightnessTestResult"></div>
                  <div v-if="production.otherInformation" class="title-content">Autres informations</div>
                  <div v-if="production.otherInformation" class="formatted-content" v-html="production.otherInformation"></div>
                </div>
            </div>
        </layout-container>
    </div>
</template>

<script>

import LayoutContainer from '@/components/layout_container';
import Vue from 'vue';
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);

export default {
    name: 'ModalDetailsReference',
    mixins: [ ],
    components: {
        LayoutContainer,
    },
    props: {
        production: Object,
        prodPictures : Array,
    },
    computed: {
        efficiencyTooltip() {
            let text = `<b>Méthode de calcul :</b><br><ul><li> ${this.production.calculationMethod.name}</li></ul>\n`;
            if (this.production.consumptionUsages && this.production.consumptionUsages.length > 0) {
                text += '<b>Usages&nbsp;:</b>\n<ul>';
                for (const i in this.production.consumptionUsages) {
                    text += `<li>${this.production.consumptionUsages[i].name}</li>`;
                }
                text += '</ul>';
            }
            return text;
        },
    },
    methods: {
        hideModal() {
            this.$emit('hide-modal');
        },
        picKey(pic) {
            return 'pic' + pic.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a;},0);
        },
        tooltip(text) {
            if (!text) return null;
            return {
                content: text,
                icon: ['far', 'info-circle'],
                iconAlign: 'right',
                delay: {
                    show: 300,
                    hide: 500,
                },
            };
        },
    },
};


</script>
<style lang='stylus' scoped>
@require '../../../../stylesheet/variables'

table.consumption
    width: auto

.tooltip-icon-consumptions
    // float: right
    font-size: 32px
    margin-left: 20px

.content-gray
    background-color: gray-100

.content-white
    background-color: white

.modal-header
    padding: 0rem 0.3rem 0rem 0.3rem
    background-color: primary-color-500
    margin-bottom: 1rem

.modal-title
    color: #fff
    text-transform: uppercase
    padding: 3%

.close-modal
    color: #fff
    float: right
    margin-right: 2 rem
    margin-top: 2.1rem
    font-size: 1.5rem

.title
.title-content
    font-color: primary-color-500
    color: primary-color-500
    font-size: 1.2rem
    text-transform: uppercase

.formatted-content
    // white-space: pre
    // overflow-x: scroll
    text-align: left
    margin-bottom: space-md

.image
    margin-right: 2rem
    border: 0.15rem solid gray-300
    width: 23rem

.basic-info
    margin-left: 2, 3rem

.bold
    font-weight: bold
</style>
