<template>
<div class="panel-contact-detail">

<h2 class="title">COORDONNÉES DE L'ENTREPRISE</h2>

<div class="grid-x grid-margin-x admin-controls">
  <div class="cell medium-6">
    <select-field
      id="job"
      inputLabel="Sélectionnez votre métier"
      optionLabel="name"
      :options="jobsAsList"
      :reduce="x => x.pk"
      v-model="localProfessional.job"
      :isError="formErrors.job"
      validateRules="required"
      mandatory
    >
      <template v-slot:option="job">
        <template v-if="localProfessional.job == job.pk">
          <font-awesome-icon icon="check" class="icon-check" />
          <span class="category">{{job.name}}</span>
        </template>
        <template v-else>
          <span>&emsp;&ensp;{{job.name}}</span>
        </template>
      </template>
    </select-field>
  </div>
  <div class="cell medium-6">
    <label class="form-label">Logo de l'entreprise :</label>
    <img v-bind:src="localProfessional.logo" width="100" height="150" class="logo" v-show="localProfessional.logo">
    <regular-button
      v-show="!localProfessional.logo"
      @click="showModalLogo"
      class="button-logo"
      >
      AJOUTER VOTRE LOGO
    </regular-button>
    <regular-button
      v-show="localProfessional.logo"
      @click="showModalLogo"
      class="button-logo"
    >
      MODIFER VOTRE LOGO
    </regular-button>
  </div>

</div>


<div class="grid-x grid-margin-x job-selection">
  <div class="cell small-12">
  </div>
</div>


<div class="grid-x grid-margin-x">
  <div class="cell small-12">
    <div class="error" v-if="formErrorsMessages.all">
      {{formErrorsMessages.all}}
    </div>
  </div>

  <div class="cell medium-6">
    <text-field
      id="name"
      label="Raison sociale"
      helptext="Maximum 50 caractères"
      mandatory
      v-model="localProfessional.name"
      :isError="formErrors.name"
      :customError="formErrorsMessages.name"
      validateRules="required"
      :scope="'localProfessional'"
    />
    <text-field
      id="url"
      label="Site web"
      v-model="localProfessional.url"
      :isError="formErrors.url"
      :customError="formErrorsMessages.url"
    />
    <text-field
      id="email"
      label="Courriel"
      v-model="localProfessional.email"
      type="email"
      :isError="formErrors.email"
      :customError="formErrorsMessages.email"
      validateRules="email"
      :scope="'localProfessional'"
    />
    <text-field
      id="phoneNumber"
      label="Téléphone"
      v-model="localProfessional.phoneNumber"
      :isError="formErrors.phoneNumber"
      :customError="formErrorsMessages.phoneNumber"
    />
  </div>
  <div class="cell medium-6 ">
    <address-field
      id="address"
      v-model="localProfessional.address"
      label="Adresse"
      helptext=""
      :forceSelectOnBlur=true
      :customError="formErrorsMessages.address"
      :isError="formErrors.address"
      @city="localProfessional.town = $event"
      @postcode="localProfessional.postcode = $event"
      @position="localProfessional.geom = $event"
      mandatory
      validateRules="required"
      :scope="'localProfessional'"
    />

    <div class="grid-x grid-margin-x helptext">
      <div class="cell medium-4">
        Code postal : <span class="value" v-html="localProfessional.postcode" />
      </div>
      <div class="cell medium-8">
        Ville : <span class="value" v-html="localProfessional.town" />
      </div>
    </div>

    <map-field
      v-if="localProfessional?.geom?.lat != 0 && localProfessional?.geom?.lon != 0"
      class="map"
      ref="map"
      :lat="localProfessional?.geom?.lat"
      :lon="localProfessional?.geom?.lon"
      :markers="currentAddressAsMarker"
      :zoom="16"
    />

  </div>
</div>


<div class="grid-x grid-margin-x">
  <div class="cell small-12">
    <additional-addresses
      :professional="localProfessional"
      :canModify="true"
      />
  </div>
</div>

<DialogWrapper
  ref="modalLogo"
  :adaptive="false"
  :width="900"
  :scrollable="true"
  height="auto"
  clickToClose
>
  <div>
    <header class="modal-header">
      <h2 class="modal-title">Choisissez le logo de votre entreprise</h2>
    </header>

    <div class="grid-x grid-padding-x popup-content">
      <div class="cell">
        <cropper
          :url="localProfessional.originalLogo"
          :cropSize="{ width: 150, height: 150}"
          ref="cropper"
          >
        </cropper>
      </div>
    </div>

    <form-buttons
      class="buttons"
      @primaryAction="saveCropper();hideModalLogo()"
      :hideSecondary="true"
      @tertiaryAction="hideModalLogo"
    >
      <template v-slot:primary>
        Valider
      </template>
    </form-buttons>
  </div>
</DialogWrapper>

</div>
</template>

<script>
import L from 'leaflet';

import AdditionalAddresses from '@/apps/listepro/additional_addresses/additional_addresses';
import AddressField from '@/components/address_field';
import Cropper from '@/components/cropper';
import FormButtons from '@/components/form_buttons';
import MapField from '@/components/map_field';
import RegularButton from '@/components/regular_button';
import SelectField from '@/components/select_field';
import TextField from '@/components/text_field';

import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';

import { JobStoreCollection } from '@/models/listepro/job';

import { htmlEncode } from '@/helpers/encode';

export default {
    name: 'PanelContactDetail',
    mixins: [handleFormErrorsMixin],
    components: {
        AdditionalAddresses,
        AddressField,
        Cropper,
        FormButtons,
        MapField,
        RegularButton,
        TextField,
        SelectField,
    },
    props: {
        value: Object,
        isProfessional: { default: false, type: Boolean, required: false },
        formErrorsUp: { default: () => {}, type: [Array, Object], required: false },
        formErrorsMessagesUp: { default: () => {}, type: [Array, Object], required: false },
    },
    data: function() {
        return {
            localProfessional: '',
            jobs: new JobStoreCollection(),
        };
    },
    computed: {
        jobsAsList() {
            const list = this.jobs.asList();
            list.sort((x,y) => {
                if (x.order === y.order) {
                    return x.name > y.name;
                }
                return x.order > y.order;
            });
            return list;
        },
        currentJob() {
            return this.jobsAsList.find(x => x.pk === this.localProfessional.job);
        },
        allowToUseBap() {
            return this.currentJob?.allowToUseBap;
        },
        currentAddressAsMarker() {
            return [{
                latlng: L.latLng(this.localProfessional.geom.lat, this.localProfessional.geom.lon),
                tooltip: htmlEncode(this.localProfessional.name),
                icon: new L.Icon({
                    iconUrl: '/static/img/marker-icon-2x-blue.png',
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                }),
            }];
        },
        showBapConfig() {
            return this.localProfessional.formType === 'generic';
        },
    },
    watch: {
        formErrorsUp: {
            handler(value) {
                this.formErrors = value;
            },
            immediate: true,
            deep: true,
        },
        formErrorsMessagesUp: {
            handler(value) {
                this.formErrorsMessages = value;
            },
            immediate: true,
            deep: true,
        },
        value: {
            handler(value) {
                this.localProfessional = value;
            },
            immediate: true,
            deep: true,
        },
        localProfessional: {
            handler(localProfessional) {
                this.$emit('input', localProfessional);
            },
            immediate: true,
            deep: true,
        },
        allowToUseBap: {
            handler(value) {
                this.$emit('allow-to-use-bap', value);
            },
            immediate: true,
        },
    },
    mounted: function() {
        this.jobs.load();
    },
    methods: {
        panelDisplayed() {
            if (this.$refs.map) {
                this.$refs.map.refreshMap();
            }
        },
        checkOption(userChoice, optionName) {
            if(userChoice && optionName === userChoice.name){
                return true;
            }
            return false;
        },
        saveCropper(){        
            const pic = this.$refs.cropper.getImage();
            if (pic != null) {
                if (pic.startsWith('data:')) {
                    this.localProfessional.logo = pic;
                }
            } else {
                this.localProfessional.logo= null;
            }
            
            const originalPic = this.$refs.cropper.getOriginalImage();
            if (originalPic != null) {
                if (originalPic.startsWith('data:')) {
                    this.localProfessional.originalLogo = originalPic;
                }
            } else {
                this.localProfessional.originalLogo= null;
            }
        },
        hideModalLogo() {
            this.$refs.modalLogo.hide();
        },
        showModalLogo() {
            this.$refs.modalLogo.show();
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

.panel-contact-detail
    margin-top: space-xl

    .job-selection
        margin-top: space-md
        margin-bottom: space-xl

        .category
            font-weight: bold

    .form-label
        font-weight: bold

    .admin-controls
        margin-bottom: space-xl

    .modal-header
        padding: 1rem 0.3rem 1rem 0.3rem
        background-color: primary-color-500
        margin-bottom: 1rem

    .modal-title
        color: #fff
        text-transform: uppercase
        padding: 3%

    .buttons
        padding: space-md

    .button-logo
        display: inline-block
        margin: space-md

    .map
        height: 260px
</style>
