import _ from 'lodash';
import { computed, ref } from 'vue';

import apiClient from '@/api/api';

export function collectionProperties(name, baseUrl) {
    const objects = ref(new Map());
    const objectsIsLoading = ref(false);
    const objectsOrdering = ref([]);
    const objectsFilter = ref({});
    const objectsLastFilter = ref({});
    const objectsLastUpdate = ref(0);

    const objectsList = computed(() => {
        return objectsOrdering.value.map(key => objects.value.get(key));
    });

    async function objectsLoad(filter) {
        let url = baseUrl;
        if (filter) {
            objectsFilter.value = filter;
        }
        url = `${url}?${new URLSearchParams(objectsFilter.value).toString()}`;

        if (!_.isEqual(objectsLastFilter.value, objectsFilter.value)) {
            objectsLastUpdate.value = 0;
        }

        objectsLastFilter.value = objectsFilter.value;

        try {
            objectsIsLoading.value = true;
            const response = await apiClient.genericStoreList(url, objectsLastUpdate.value);

            for (const key of objects.value.keys()) {
                if (!response.all.includes(key)) {
                    objects.value.delete(key);
                }
            }

            for (const item of response.collection) {
                objects.value.set(item.pk, item);
            }

            objectsOrdering.value = response.all;
            objectsLastUpdate.value = response.lastUpdatedAt;
        } finally {
            objectsIsLoading.value = false;
        }
    }

    return {
        [name]: objects,
        [`${name}IsLoading`]: objectsIsLoading,
        [`${name}Load`]: objectsLoad,
        [`${name}List`]: objectsList,
        [`${name}LastUpdate`]: objectsLastUpdate,
    };
}
