<template>
<validation-observer ref="validator" v-slot="{ valid }" slim>
  <div>
    <layout-container>
      <spinner :active="waitSaving" />
  
      <div class="informations">
        Édition du compte utilisateur qui permet au professionnel de se connecter à l'annuaire.
      </div>
      
      <div class="form-layout align-center">
  
        
        <div class="fields">
          <text-field
            v-if="!isProfessional"
            class="form-elem"
            v-model="localUser.email"
            id="email"
            label="Email"
            placeholder="Email…"
            icon="envelope"
            v-on:input="email = $event;"
            mandatory
            v-bind:isError="formErrors.email"
            v-bind:title="formErrorsMessages.email"
            validateRules="required|email"
            />

          <div
            v-if="isProfessional"
            class="email-non-modifiable"
            >
            <b>Email</b> : {{ localUser.email }}
          </div>
          
          <text-field
            class="form-elem"
            v-model="localUser.firstName"
            id="firstName"
            label="Prénom"
            placeholder="Prénom…"
            icon="id-card"
            v-on:input="firstName = $event;"
            v-bind:isError="formErrors.firstName"
            v-bind:title="formErrorsMessages.firstName"
            />
          
          <text-field
            class="form-elem"
            v-model="localUser.lastName"
            id="lastName"
            label="Nom"
            placeholder="Nom…"
            icon="id-card"
            v-on:input="lastName = $event;"
            v-bind:isError="formErrors.lastName"
            v-bind:title="formErrorsMessages.lastName"
            mandatory
            validateRules="required"
            />
          
          <text-field
            class="form-elem"
            v-model="localUser.title"
            id="title"
            label="Titre"
            placeholder="Titre…"
            icon="tag"
            v-on:input="title = $event;"
            v-bind:isError="formErrors.title"
            v-bind:title="formErrorsMessages.title"
            />
          
          <text-field
            class="form-elem"
            v-model="localUser.phone"
            id="phone"
            label="Téléphone"
            placeholder="Téléphone…"
            icon="phone"
            v-on:input="phone = $event;"
            v-bind:isError="formErrors.phone"
            v-bind:title="formErrorsMessages.phone"
            />
          
        </div>
        
        <div class="second-row"
             v-if="!isProfessional"
             >
          <checkbox-field
            class="form-elem checkbox-active"
            v-model="localUser.isActive"
            id="isActive"
            label="Est actif ?"
            v-on:input="isActive = $event;"
            />

          <p class="helptext">
            Un professionnel dont le compte utilisateur est inactif ne pourra
            pas se connecter ni à l'annuaire, ni recevoir de projets BAP. Il ne
            sera pas non plus visible dans l'annuaire.
          </p>
        </div>
  
        
        <div class="error" v-if="formErrorsMessages.all">
          {{formErrorsMessages.all}}
        </div>
  
        <div class="buttons">
          <button
            v-if="localUser.pk && !isProfessional"
            :disabled="!valid"
            title="Envoyer un lien pour que le professionnel  puisse ressaisir son mot de passe"
            type="button"
            class="warning button password"
            @click.prevent="resetPassword"
            >
            <font-awesome-icon icon="lock-open-alt" />Renvoyer un mail de réinitialisation du mot de passe
          </button>
  
          <button
            type="button"
            class="success button"
            :disabled="!valid"
            @click.prevent="save"
            >
            <font-awesome-icon icon="file-import" />
            <span v-if="!createMode">Enregistrer les modifications</span>
            <span v-else>Créer le compte</span>
          </button>
              
        </div>
        
      </div>
      
    </layout-container>
  </div>
</validation-observer>
</template>

<script>
import { store } from '@/store/store';
import apiClient from '@/api/api';
import Spinner from '@/components/spinner';
import TextField from '@/components/text_field';
import CheckboxField from '@/components/checkbox_field';
import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';
// import { userMixin } from '@/mixins/user';
import LayoutContainer from '@/components/layout_container';

import { ListeproProxyUserClass } from '@/models/listepro/listepro_proxy_user';


export default {
    name: 'UserForm',
    store,
    // mixins: [userMixin, handleFormErrorsMixin],
    mixins: [handleFormErrorsMixin],
    components: {
        CheckboxField,
        LayoutContainer,
        Spinner,
        TextField,
    },
    props: {
        pk: { default: undefined, type: [String, Number], required: false },
        user: { default: undefined, type: Object, required: false },
        isProfessional: { default: false, type: Boolean, required: false },
    },

    data() {
        return {
            localUser: new ListeproProxyUserClass(),
            firstName: '',
            lastName: '',
            email: '',
            title: '',
            phone: '',
            managedByGroup: null,
            color: '#888888',
            userType: { label: 'Professionnel', value: 'professional', icon: 'hard-hat' },
            isActive: true,
            dateJoined: null,
            waitSaving: false,
            profilePic: null,
            confirmDeletion: false,
        };
    },
    computed: {
        createMode() {
            return this.user?.pk === undefined;
        },
        fullName() {
            if (this.firstName && this.lastName) {
                return `${this.firstName} ${this.lastName}`;
            }
            if (this.lastName) {
                return `${this.lastName}`;
            }
            return '';
        },
    },
    mounted() {
        this.resetErrorFields();
        if (this.pk) {
            apiClient
                .listeproProxyUserDetail(this.pk)
                .then(response => {
                    this.localUser = response;
                })
                .catch(error => {
                    this.handleFormErrors(error);
                });
        } else if (this.user) {
            this.localUser = new ListeproProxyUserClass(this.user);
        }
    },
    methods: {
        validate() {
            try {
                return this.$refs.validator.validate();
            } catch {
                return false;
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        resetPassword() {
            apiClient
                .accountResetPassword(this.localUser.email)
                .then(response => {
                    this.$notify({
                        group: 'root',
                        type: 'success',
                        title: 'Lien envoyé',
                        text: 'Un lien pour réinitialiser le mot de passe vient d\'être envoyé au professionnel.',
                    });
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        this.$notify({
                            group: 'root',
                            type: 'error',
                            title: 'Utilisateur inactif',
                            text: 'Pas d\'envoi car l\'utilisateur est inactif.',
                        });
                    } else if (error.response.status === 404) {
                        this.$notify({
                            group: 'root',
                            type: 'error',
                            title: 'Utilisateur non trouvé',
                            text: 'Pas d\'envoi car l\'utilisateur est introuvable.',
                        });
                    } else {
                        this.handleFormErrors(error);
                    }
                    
                });
        },
        save() {
            this.waitSaving = true;
            this.localUser.managedByGroup = this.$store.get('user').group.pk;
            this.localUser.domain = this.$store.state.domainName;

            this.resetErrorFields();
            this.localUser.save()
                .then((response) => {
                    this.$notify({
                        group: 'root',
                        type: 'success',
                        title: 'Utilisateur enregistré',
                        text: 'Le compte utilisateur a bien été enregistré',
                    });
                    if (this.createMode) {
                        this.$router.push({ name: 'ListeProProfessionalAdminForm', params: { pk: response.pk } });
                    }
                })
                .catch(error => {
                    this.handleFormErrors(error);
                })
                .finally(() => {
                    this.waitSaving = false;
                });
        },
    },
};
</script>

<style lang='stylus' scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/typography'
@require '../../../stylesheet/listepro_font'

.email-non-modifiable
    margin-bottom: space-md

.informations
    text-align: center
    margin-bottom: space-md
    font-style: italic

.form-layout
    display: grid
    grid-template-columns: minmax(300px, 400px) auto
    grid-gap: 20px
    max-width: 700px

    .fields
        grid-column: 1
        grid-row: 1 / 3

    .second-row
        grid-row: 1
        grid-column: 2
        margin-top: 36px

    .error
        grid-row: 2
        grid-column: 1 / 3

    .buttons
        grid-row: 2
        grid-column: 2
        align-self: end
        margin-top: space-md

        button
            display: block
            width: 100%

    @media (max-width: 600px)
        .fields
            grid-column: 1
            grid-row: 2

        .second-row
            grid-column: 1
            grid-row: 1

        .error
            grid-column: 1
            grid-row: 3

        .buttons
            grid-column: 1
            grid-row: 4
</style>
