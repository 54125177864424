import { store } from '@/store/store';

const VisitReport = () => import(/* webpackChunkName: "visit_report" */ '@/apps/visit_report/visit_report');
const VisitReportGenerator = () =>
    import(/* webpackChunkName: "visit_report" */ '@/apps/visit_report/visit_report_generator');
const IdentitiesTab = () =>
    import(/* webpackChunkName: "visit_report" */ '@/apps/visit_report/identities/identities_tab');
const HouseEvaluationTab = () =>
    import(/* webpackChunkName: "visit_report" */ '@/apps/visit_report/house_evaluation/house_evaluation_tab');
const RecommendationsTab = () =>
    import(/* webpackChunkName: "visit_report" */ '@/apps/visit_report/recommendations/recommendations_tab');
const FinancingTab = () => import(/* webpackChunkName: "visit_report" */ '@/apps/visit_report/financing/financing_tab');
const ConclusionTab = () =>
    import(/* webpackChunkName: "visit_report" */ '@/apps/visit_report/conclusion/conclusion_tab');
const ContactEntitySearch = () =>
    import(/* webpackChunkName: "contact_entity_search" */ '@/apps/contact_entity_search/contact_entity_search');
const ContactEntityResults = () =>
    import(/* webpackChunkName: "contact_entity_search" */ '@/apps/contact_entity_search/contact_entity_results');

const visitReportAppPrefix = 'VisitReport';
const visitReportModuleRoute = '/compte-rendu-entretien';
const visitReportDetailRoute = visitReportModuleRoute + '/rapport/:reportPk';
const visitReportContactsAllowed = true;
const visitReportOrganizationsAllowed = false;

const precoImmoAppPrefix = 'PrecoImmo';
const precoImmoModuleRoute = '/preco-immo';
const precoImmoDetailRoute = precoImmoModuleRoute + '/rapport/:reportPk';
const precoImmoContactsAllowed = false;
const precoImmoOrganizationsAllowed = true;

var routes = [
    // VISIT REPORT ROUTES : can only edit contacts
    {
        name: 'VisitReportContactsSearch',
        path: visitReportModuleRoute + '/recherche-dossier',
        component: ContactEntitySearch,
        meta: {
            requiresAuth: true,
            requiresUserType: ['advisor', 'superadvisor'],
            contactsAllowed: visitReportContactsAllowed,
            organizationsAllowed: visitReportOrganizationsAllowed,
            appPrefix: visitReportAppPrefix,
        },
    },
    {
        name: 'VisitReportContactsResults',
        path: visitReportModuleRoute + '/:contactType/:contactEntityPk',
        component: ContactEntityResults,
        props: true,
        meta: {
            requiresAuth: true,
            requiresUserType: ['advisor', 'superadvisor'],
            contactsAllowed: visitReportContactsAllowed,
            organizationsAllowed: visitReportOrganizationsAllowed,
            appPrefix: visitReportAppPrefix,
        },
    },
    {
        path: visitReportDetailRoute,
        name: 'VisitReportDetail',
        component: VisitReport,
        props: true,
        meta: {
            requiresAuth: true,
            requiresUserType: ['advisor', 'superadvisor'],
            contactsAllowed: visitReportContactsAllowed,
            organizationsAllowed: visitReportOrganizationsAllowed,
            appPrefix: visitReportAppPrefix,
        },
        children: [
            {
                name: 'VisitReportIdentities',
                path: visitReportDetailRoute + '/identites',
                component: IdentitiesTab,
                meta: {
                    tabLabel: 'Identités',
                    contactsAllowed: visitReportContactsAllowed,
                    organizationsAllowed: visitReportOrganizationsAllowed,
                    appPrefix: visitReportAppPrefix,
                },
            },
            {
                name: 'VisitReportEvaluation',
                path: visitReportDetailRoute + '/evaluation',
                component: HouseEvaluationTab,
                meta: {
                    tabLabel: 'Évaluation',
                    contactsAllowed: visitReportContactsAllowed,
                    organizationsAllowed: visitReportOrganizationsAllowed,
                    appPrefix: visitReportAppPrefix,
                },
            },
            {
                name: 'VisitReportRecommandations',
                path: visitReportDetailRoute + '/recommandations',
                component: RecommendationsTab,
                meta: {
                    tabLabel: 'Recommandations',
                    contactsAllowed: visitReportContactsAllowed,
                    organizationsAllowed: visitReportOrganizationsAllowed,
                    appPrefix: visitReportAppPrefix,
                },
            },
            {
                name: 'VisitReportFinancing',
                path: visitReportDetailRoute + '/financement',
                component: FinancingTab,
                meta: {
                    tabLabel: 'Financement',
                    contactsAllowed: visitReportContactsAllowed,
                    organizationsAllowed: visitReportOrganizationsAllowed,
                    appPrefix: visitReportAppPrefix,
                },
            },
            {
                name: 'VisitReportConclusion',
                path: visitReportDetailRoute + '/conclusion',
                component: ConclusionTab,
                meta: {
                    tabLabel: 'Conclusion',
                    contactsAllowed: visitReportContactsAllowed,
                    organizationsAllowed: visitReportOrganizationsAllowed,
                    appPrefix: visitReportAppPrefix,
                },
            },
        ],
    },

    // PRECO IMMO ROUTES : can only edit organizations
    {
        name: 'PrecoImmoContactsSearch',
        path: precoImmoModuleRoute + '/recherche-dossier',
        component: ContactEntitySearch,
        meta: {
            requiresAuth: true,
            requiresUserType: ['advisor', 'superadvisor'],
            contactsAllowed: precoImmoContactsAllowed,
            organizationsAllowed: precoImmoOrganizationsAllowed,
            appPrefix: precoImmoAppPrefix,
        },
    },
    {
        name: 'PrecoImmoContactsResults',
        path: precoImmoModuleRoute + '/:contactType/:contactEntityPk',
        component: ContactEntityResults,
        props: true,
        meta: {
            requiresAuth: true,
            requiresUserType: ['advisor', 'superadvisor'],
            contactsAllowed: precoImmoContactsAllowed,
            organizationsAllowed: precoImmoOrganizationsAllowed,
            appPrefix: precoImmoAppPrefix,
        },
    },
    {
        name: 'PrecoImmoDetail',
        path: precoImmoDetailRoute,
        component: VisitReport,
        props: true,
        meta: {
            requiresAuth: true,
            requiresUserType: ['advisor', 'superadvisor'],
            contactsAllowed: precoImmoContactsAllowed,
            organizationsAllowed: precoImmoOrganizationsAllowed,
            appPrefix: precoImmoAppPrefix,
        },
        children: [
            {
                name: 'PrecoImmoIdentities',
                path: precoImmoDetailRoute + '/identites',
                component: IdentitiesTab,
                meta: {
                    tabLabel: 'Identités',
                    contactsAllowed: precoImmoContactsAllowed,
                    organizationsAllowed: precoImmoOrganizationsAllowed,
                    appPrefix: precoImmoAppPrefix,
                },
            },
            {
                name: 'PrecoImmoEvaluation',
                path: precoImmoDetailRoute + '/evaluation',
                component: HouseEvaluationTab,
                meta: {
                    tabLabel: 'Évaluation',
                    contactsAllowed: precoImmoContactsAllowed,
                    organizationsAllowed: precoImmoOrganizationsAllowed,
                    appPrefix: precoImmoAppPrefix,
                },
            },
            {
                name: 'PrecoImmoRecommandations',
                path: precoImmoDetailRoute + '/recommandations',
                component: RecommendationsTab,
                meta: {
                    tabLabel: 'Recommandations',
                    contactsAllowed: precoImmoContactsAllowed,
                    organizationsAllowed: precoImmoOrganizationsAllowed,
                    appPrefix: precoImmoAppPrefix,
                },
            },
            {
                name: 'PrecoImmoFinancing',
                path: precoImmoDetailRoute + '/financement',
                component: FinancingTab,
                meta: {
                    tabLabel: 'Financement',
                    contactsAllowed: precoImmoContactsAllowed,
                    organizationsAllowed: precoImmoOrganizationsAllowed,
                    appPrefix: precoImmoAppPrefix,
                },
            },
            {
                name: 'PrecoImmoConclusion',
                path: precoImmoDetailRoute + '/conclusion',
                component: ConclusionTab,
                meta: {
                    tabLabel: 'Conclusion',
                    contactsAllowed: precoImmoContactsAllowed,
                    organizationsAllowed: precoImmoOrganizationsAllowed,
                    appPrefix: precoImmoAppPrefix,
                },
            },
        ],
    },

    // FOR BOTH : Pdf edition
    {
        name: 'VisitReportGenerator',
        path: visitReportModuleRoute + '/rapport/:uuid/pdf',
        component: VisitReportGenerator,
        meta: {
            requiresAuth: false,
        },
        beforeEnter(to, from, next) {
            store.dispatch('visitReport/getPdfTempStore', to.params.uuid).then(response => {
                next();
            });
        },
    },
];

export default routes;
