<template>
<div>
  
  <div class="container">
    <div class="grid-x grid-margin-x">
      <div class="small-12 medium-12 cell align-center">
        <button class="button" type="button" @click.prevent="$refs.myFile.click()">Télécharger une image</button>
        <input type="file"
               @change="previewFile"
               ref="myFile"
               accept="image/png, image/jpeg"
               style="display: none;"
               />
      </div>
      <div class="small-12 medium-6 cell" v-show="imgIsValid || imgLocalIsValid">
        <div class="croppie">
          <h3>Image originale</h3>
          <vue-croppie
             ref="croppieRef"
             :enable-orientation="true"
             :enable-exif="true"
             :enable-resize="true"
             :viewport="viewport"
             :show-zoomer="true"
             :mouse-wheel-zoom="false"
             :enforce-boundary="true"
             @result="result"
             @update="crop"
             >
          </vue-croppie>
        </div>
      </div>
      <div class="small-12 medium-6 cell" v-if="imgIsValid || imgLocalIsValid">
        <div class="float-center">
          <h3>Aperçu</h3>
          <img id="result" v-bind:src="cropped" @error="imgLoadError" v-if="imgIsValid && cropped">
          <img id="resultUrl" v-bind:src="localUrl" @error="imgLocalLoadError" v-if="imgLocalIsValid && localUrl && !cropped">
          <div v-show="!localUrl && !cropped" class="no-picture">Pas d'image disponible</div>

          <div class="actions"
               v-show="localUrl || cropped"
               >
            <action-link
              class="delete-link"
              @click="deleteImage"
              label="Effacer l'image"
              icon="trash"
              />
            <action-link
              class="rotate-link"
              @click="rotate(90)"
              label="Pivoter vers la droite"
              icon="sync-alt"
              />
          </div>
          
        </div>
      </div>

    </div>

  </div>
</div>
</template>
<script>
import Vue from 'vue';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css'; // import the croppie css manually

import ActionLink from '@/components/action_link';

Vue.use(VueCroppie);

export default {
    name: 'Cropper',
    components: {
        ActionLink,
    },
    mounted() {
    },
    props: {
        url: { default:'', type: String },
        layout: { default: 'square', type: String },
        cropSize: { default: () => { return { width: 450, height: 450 }; }, type: Object },
    },
    data() {
        return {
            width: 450,
            height: 450,
            cropped: null,
            localUrl: '',
            dataImg: '',
            originalImg:'',
            imgIsValid: true,
            imgLocalIsValid: true,
        };
    },
    computed: {
        viewport() {
            return { width: this.cropSize.width, height: this.cropSize.height, type: this.layout };
        },
        croppie() {
            return this.$refs.croppieRef;
        },
    },
    watch: {
        url: {
            immediate: true,
            handler() {
                this.$nextTick(() => {                
                    this.setImage();
                });
            },
        },
        
        cropped() {
            if(this.cropped && !this.originalImg){
                this.localUrl = this.cropped;
                this.originalImg=this.localUrl;
            }

        },
    },
    methods: {
        setImage() {
            if(this.url){
                this.localUrl = this.url;

                this.resize();

                this.croppie.bind({
                    url: this.localUrl,
                })
                    .catch(() => {
                        this.imgIsValid = false;
                        this.imgLocalIsValid = false;
                        this.$notify({
                            group: 'root',
                            type: 'error',
                            title: 'Erreur',
                            text: "L'image n'a pas pu être chargée.",
                        });
                    });
            }
        },
        resetErrors() {
            this.imgIsValid = true;
            this.imgLocalIsValid = true;
        },
        imgLoadError() {
            this.imgIsValid = false;
        },
        imgLocalLoadError() {
            this.imgLocalIsValid = false;
        },
        getImageName() {
            return this.$refs.myFile?.files[0]?.name;
        },
        getImage() {
            // return image data if new image has been selected
            // return initialUrl if image has not been changed
            // return null if image was deleted
            this.resetErrors();
            if (this.cropped) {
                return this.cropped;
            } else if (this.localUrl) {
                return this.url;
            }
            return null;
        },
        getOriginalImage() {
            // return initialUrl
            // return null if image was deleted
            return this.dataImg;
            // return this.originalImg;
        },
        deleteImage() {
            this.resetErrors();
            this.$emit('image-deleted');
            this.dataImg = '';
            this.localUrl = '';
            this.cropped = null;
            this.bind();
            this.originalImg='';
        },
        resize() {
            this.width = this.cropSize.width;
            this.height = this.cropSize.height;
        },
        crop() {
            this.resetErrors();
            this.croppie.result({
                format: 'png',
                circle: this.layout === 'circle',
                type: 'base64',
                size: 'viewport',
            });
        },
        rotate(rotationAngle) {
            // Rotates the image
            this.croppie.croppie.rotate(rotationAngle);
        },
        async result(output) {
            this.width = this.croppie.croppie.elements.viewport.clientWidth;
            this.height = this.croppie.croppie.elements.viewport.clientHeight;
            this.cropped = output;
        },
        async bind() {
            this.croppie.bind({
                url: this.dataImg,
                zoom: 1,
            })
                .then(() => {
                    this.crop();
                })
                .catch(() => {
                    this.$notify({
                        group: 'root',
                        type: 'error',
                        title: 'Erreur',
                        text: "L'image n'a pas pu être chargée.",
                    });
                    this.imgIsValid = false;
                    this.imgLocalIsValid = false;
                });
             
            // this.crop();
        },
        previewFile(ev) {
            this.originalImg='';
            const files = ev.target.files;
            if (files && files[0]) {
                const reader = new FileReader();
                reader.onload = async e => {
                    this.dataImg = e.target.result;
                    this.bind();
                    this.crop();
                };
                reader.readAsDataURL(files[0]);
            }
        },
    },
};
</script>

<style  lang="stylus" scoped>
@require '../stylesheet/typography'
@require '../stylesheet/variables'

.no-picture
    border: 1px solid gray-300
    background-color: gray-100
    height: 250px
    width: 250px
    color: gray-500
    text-align: center
    padding-top: 112px

#result
#resultUrl
    border: 1px solid gray-700

.actions
    margin-top: space-md

    .delete-link
    .rotate-link
        display: block

:deep(div.cr-boundary)
    border: 1px solid gray-300
    height: 450px
    width: 450px
    margin: 0

    img
        color: #fff

:deep(div.cr-slider-wrap)
    margin: 0

.float-center
    display: block
    margin-left: auto
    margin-right: auto
    padding-left: 30px
    padding-right: 30px
</style>

