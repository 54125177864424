export default {
    enableMetroGrenobleMode({ state, commit, dispatch }, { area, inseeCode }) {
        /*
        Make thermix to enter metro-grenoble mode.
        => Override some energies data to match metro grenoble requirements
        => Enable heating network production system
        => Force and pre-fill metro-grenoble recommended solutions
        */

        if (!['A', 'B', 'C', 'D', 'E'].includes(area)) return;
        commit('updateField', { path: 'enabled', value: true });
        commit('updateField', { path: 'area', value: area });
        commit('updateField', { path: 'inseeCode', value: inseeCode });

        dispatch('overrodeData');
        dispatch('thermix/solutions/enableHeatingNetwork', null, { root: true });
        dispatch('forceMetroGrenobleSolutions');
    },

    forceMetroGrenobleSolutions({ getters, dispatch }) {
        /* Update solutions to match metro-grenoble recommended solutions */
        const forcedSolutions = getters.forcedSolutions;
        if (!forcedSolutions) return;
        dispatch('thermix/solutions/updateSolutions', forcedSolutions, { root: true });
    },

    overrodeData({ state, commit, getters }) {
        /*
        Override new building data  for metro-grenoble
        Note that energies data or not overrode here, but in
        energiesOverrodeData which is used in productionSystemDefs.productionSystemEnergyDataValue getter
        */

        commit('thermix/needsEstimation/updateField', { path: 'newBuilding.baseEfficiency', value: state.needsEstimation.newBuilding.baseEfficiency }, { root: true });

        const town = getters.town;
        if (town) {
            let altitudeCat = '0';
            if (town.altitude > 800) altitudeCat = '800';
            else if (town.altitude >= 400) altitudeCat = '400';
            commit('thermix/needsEstimation/updateField', { path: 'newBuilding.altitude', value: altitudeCat }, { root: true });
        }
    },

};
