import Simulaides from '@/apps/simulaides/simulaides';

var routes = [
    {
        path: '/simulaides',
        name: 'Simulaides',
        component: Simulaides,
    },
];

export default routes;
