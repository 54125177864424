import _ from 'lodash';

import appointments from '@/apps/dialogwatt/api/appointments';
import catchmentAreas from '@/apps/dialogwatt/api/catchment_areas';
import createClientAndContactWithRgpd from '@/apps/dialogwatt/api/create_client_and_contact_with_rgpd';
import findAppointment from '@/apps/dialogwatt/api/find_appointment';
import notifications from '@/apps/dialogwatt/api/notifications';
import places from '@/apps/dialogwatt/api/places';
import reasons from '@/apps/dialogwatt/api/reasons';
import slots from '@/apps/dialogwatt/api/slots';
const api = {};

_.assign(
    api,
    appointments,
    catchmentAreas,
    createClientAndContactWithRgpd,
    findAppointment,
    notifications,
    places,
    reasons,
    slots,
);

export default api;
