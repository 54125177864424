<template>
<div class="select-referents">
  <spinner
    :active="isLoading"
    />
  
  <multiselect-field
    v-model="localValue"
    :label="label"
    :options="referentsOptions"
    :reduce="x => x.value"
    :multiple="multiple"
    class="referents-list"
    />

  <span @click="clearSelection" class="reset-button">
    <font-awesome-icon icon="times-circle" />
  </span>
</div>
</template>

<script>
import apiClient from '@/api/api';
import MultiselectField from '@/components/multiselect_field';
import Spinner from '@/components/spinner';

import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';
        
export default {
    name: 'SelectReferents',
    mixins: [handleFormErrorsMixin],
    components: {
        MultiselectField,
        Spinner,
    },
    props: {
        'label': {type: String, default: 'Référents :'},
        'multiple': {type: Boolean, default: true},
        'value': {type: [Array, Number], default: () => []},
    },
    data: function() {
        return {
            isLoading: false,
            referents: [],
            localValue: undefined,
        };
    },
    watch: {
        value() {
            this.localValue = this.value;
        },
        localValue() {
            this.$emit('input', this.localValue);
        },
    },
    computed: {
        referentsOptions() {
            const options = this.referents.filter(
                ({ isActive, pk }) => isActive // || currentReferents.includes(pk)
            ).map(({ groupName, firstName, lastName, email, pk }) => ({
                label: `${firstName} ${lastName} (${email}) - ${groupName}`,
                value: pk,
                pk: pk,
            }));
            options.sort();
            return options;
        },
    },
    mounted: function() {
        this.load();
    },
    methods: {
        clearSelection() {
            this.localValue = null;
        },
        load() {
            this.isLoading = true;
            apiClient.genericList('listepro/advisor/')
                .then(advisors => {
                    this.referents = advisors;
                    this.localValue = this.value;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../stylesheet/typography'
@require '../../stylesheet/variables'

.referents-list
    display: inline-block
    min-width: 400px
    max-width: 490px

.reset-button
    cursor: pointer
    color: primary-color
</style>

