<template>
  <vue-element-loading
    :active="showSpinner"
    :spinner="options.spinner"
    :color="options.color"
    :size="options.size"
    :backgroundColor="options.backgroundColor"
    :duration="options.duration"
  />
</template>

<script>
import VueElementLoading from 'vue-element-loading';
// import {mapState} from 'vuex';

export default {
    name: 'Spinner',
    components: {
        VueElementLoading,
    },
    props: {
        active: { default: false, type: Boolean },
        size: { default: '40', type: String },
        backgroundColor: { default: 'rgba(255, 255, 255, .9) ', type: String },
        spinner: { default: 'mini-spinner', type: String },
        color: { default: '#FF6700', type: String },
        showTimeout: { default: 0, type: Number },
        duration: { default: '0.6', type: String },
        fromStore: { type: Boolean, default: false },
    },
    data() {
        return {
            timeout: null,
            showSpinner: false,
        };
    },
    computed: {
        options() {
            const storeOptions = this.fromStore ? this?.$store?.state?.spinnerOptions || {} : {};
            return {
                spinner: storeOptions.spinner || this.spinner,
                color: storeOptions.color || this.color,
                backgroundColor: storeOptions.backgroundColor || this.backgroundColor,
                size: storeOptions.size || this.size,
                showTimeout: storeOptions.showTimeout || this.showTimeout,
                duration: storeOptions.duration || this.duration,
            };
        },
    },
    watch: {
        active: {
            handler(newVal, oldVal) {
                clearTimeout(this.timeout);
                if (newVal) {
                    if (this.options.showTimeout) {
                        this.timeout = setTimeout(() => {
                            this.showSpinner = true;
                        }, this.options.showTimeout);
                    } else {
                        this.showSpinner = true;
                    }
                }
                else this.showSpinner = false;
            },
            immediate: true,
        },
    },
};
</script>
