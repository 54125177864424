import { store } from '@/store/store';

import Details from '@/apps/listepro/public/details/details';
import ListeAll from '@/apps/listepro/public/liste_all';
import Menu from '@/apps/listepro/menu';
import News from '@/apps/listepro/news/news';
import PdfReport from '@/apps/listepro/pdf/listepro_report';
import Results from '@/apps/listepro/public/search/results';
import Search from '@/apps/listepro/public/search/search';

import ProfessionalHomepage from '@/apps/listepro/professional_homepage';

import ProfessionalForm from '@/apps/listepro/professional_form/professional_form';
import ProfessionalUserList from '@/apps/listepro/professionals/professional_user_list';
import UserForm from '@/apps/listepro/professional_form/user_form';

function menuRedirection() {
    if (store.getters['userUtilities/userUtilities'].isProfessional) {
        return 'ProfessionalHomepage';
    } else if (
        store.getters['userUtilities/userUtilities'].isAdvisor ||
            store.getters['userUtilities/userUtilities'].isListeProAdmin
    ) {
        return 'ListeProAdminProfessionalList';
    } else {
        return 'ListeProPublicSearch';
    }
}

const routes = [
    // Old unused route, just to make redirections
    {
        path: '/listepro',
        name: 'DeprecatedListeProPublic',
        redirect: '/annuaire-des-pros/',
        meta: {
            requiresAuth: false,
        },
        children: [
            {
                name: 'DeprecatedListeProPublicSearch',
                path: 'recherche',
                redirect: '/annuaire-des-pros/recherche',
                meta: {
                    requiresAuth: false,
                },
            },
        ],
    },

    // New routes
    // Public views
    {
        path: '/annuaire-des-pros',
        name: 'ListeProPublic',
        component: Menu,
        redirect: { name: menuRedirection() },
        meta: {
            requiresAuth: false,
        },
        children: [
            {
                name: 'ListeProPublicSearch',
                path: 'recherche',
                component: Search,
                props: true,
                meta: {
                    requiresAuth: false,
                },
            },
            {
                name: 'ListeProPublicShowResults',
                path: 'resultats/:givenActivity/:givenMission/:givenSubMission',
                component: Results,
                props: true,
            },
            {
                name: 'ListeProPublicDetail',
                path: 'detail-professionnel/:pk',
                component: Details,
                props: true,
            },
            {
                name: 'ListeProPublicNews',
                path: 'actualites',
                component: News,
                meta: {
                    requiresAuth: false,
                },
            },
        ],
    },

    // Professional views
    {
        path: '/annuaire-des-professionnels',
        name: 'ListeProPro',
        component: Menu,
        redirect: { name: menuRedirection() },
        meta: {
            requiresAuth: true,
            requiresUserType: ['professional'],
        },
        children: [
            {
                name: 'ProfessionalHomepage',
                path: 'accueil/',
                component: ProfessionalHomepage,
                props: false,
            },
            {
                name: 'ListeProProfessionalForm',
                path: 'fiche/:pk?',
                component: ProfessionalForm,
                props: true,
            },
        ],
    },

    // Admin views
    {
        path: '/annuaire-des-professionnels-admin',
        name: 'ListeProAdmin',
        component: Menu,
        redirect: { name: menuRedirection() },
        meta: {
            requiresAuth: true,
            requiresUserType: ['listeproadmin', 'advisor', 'superadvisor'],
        },
        children: [
            {
                name: 'ListeProAdminProfessionalList',
                path: 'comptes',
                component: ProfessionalUserList,
            },
            {
                name: 'ListeProAccountCreate',
                path: 'comptes/creation',
                component: UserForm,
            },
            {
                name: 'ListeProProfessionalAdminForm',
                path: 'fiche/:pk?',
                component: ProfessionalForm,
                props: true,
            },
            {
                name: 'ListeProListeAll',
                path: 'liste',
                component: ListeAll,
            },
        ],
    },

    {
        name: 'ListeProPdfReport',
        path: '/annuaire-des-pros-pdf/:uuid/pdf',
        component: PdfReport,
        beforeEnter(to, from, next) {
            store
                .dispatch('listepro/getPdfTempStore', to.params.uuid)
                .then((response) => {
                    next();
                });
        },
    },
];

export default routes;
