import { getField } from 'vuex-map-fields';
import _ from 'lodash';

export default {
    getField,

    get(state, getters, rootState) {
        return (id) => {
            return state.defs[id];
        };
    },
    lastId(state) {
        return Math.max(...Object.keys(state.defs), 0);
    },
    nextId(state, getters) {
        return getters.lastId + 1;
    },
    lastGroupId(state) {
        return Math.max(...Object.values(state.defs).map(def => def.group || 0), 0);
    },
    nextGroupId(state, getters) {
        return getters.lastGroupId + 1;
    },
    productionSystem(state, getters, rootState, rootGetters) {
        return (defId) => {
            const psDef = state.defs[defId];
            const identifier = psDef.identifier === 'synced-with-heating' ? state.defs[psDef.syncedId].identifier : psDef.identifier;

            return rootState.energies.productionSystemCollectionByIdentifier[identifier];
        };
    },
    productionSystemDataValueName(state, getters, rootState, rootGetters) {
        return (defId, dataName) => {
            const system = state.defs[defId];
            if (dataName === 'efficiency') return _.camelCase(`efficiency_${system.type}`);
            return _.camelCase(`${dataName}_${rootGetters['thermix/needs/housingSize']}_${system.type}`);
        };
    },
    productionSystemDataValue(state, getters) {
        return (defId, dataName) => {
            const productionSystem = getters.productionSystem(defId);
            if (!productionSystem) return null;
            return productionSystem[getters.productionSystemDataValueName(defId, dataName)];
        };
    },
    productionSystemEnergyDataValue(state, getters, rootState, rootGetters) {
        return (defId, dataName, greenEnergy = false) => {
            const productionSystem = getters.productionSystem(defId);
            if (!productionSystem) return null;
            let energyIdentifier = productionSystem.energyIdentifier;
            if (greenEnergy) {
                if (!productionSystem.greenEnergyIdentifier) return null;
                energyIdentifier = productionSystem.greenEnergyIdentifier;
            }

            const energyType = rootState.energies.energyCollectionByIdentifier[energyIdentifier];
            if (!energyType) return null;

            // Check for an overrode energy value by metro grenoble
            if (rootState.thermix.metroGrenoble.enabled) {
                const metroGrenobleOverride = rootState.thermix.metroGrenoble.energiesOverrodeData[energyIdentifier];
                if (metroGrenobleOverride) {
                    const metroGrenobleValue = metroGrenobleOverride[dataName];
                    if (metroGrenobleValue !== undefined) return metroGrenobleValue;
                }
            }

            return energyType[dataName];
        };
    },
    productionSystemSolarPanelsSurface(state, getters, rootState, rootGetters) {
        return (defId) => {
            const psDef = state.defs[defId];
            if (rootGetters['thermix/needs/isMultiUnit']) {
                if (psDef.identifier === 'solar_water_heater') return 2 * rootState.thermix.needs.dwellingsNumber;
                return 5 * rootState.thermix.needs.dwellingsNumber;
            }
            if (psDef.identifier === 'solar_water_heater') return 4;
            return 10;
        };
    },

    productionSystemInitialDataValues(state, getters, rootState, rootGetters) {
        return (defId) => {
            const def = state.defs[defId];
            const isMultiUnit = rootGetters['thermix/needs/isMultiUnit'];
            const values = {
                efficiency: getters.productionSystemDataValue(defId, 'efficiency'),
                investment: getters.productionSystemDataValue(defId, 'investment'),
                maintenance: getters.productionSystemDataValue(defId, 'maintenance'),
                provisions: getters.productionSystemDataValue(defId, 'provisions'),
                energyPrice: getters.productionSystemEnergyDataValue(defId, isMultiUnit ? 'currentPriceMultiUnit' : 'currentPrice'),
                greenEnergyPrice: getters.productionSystemEnergyDataValue(defId, isMultiUnit ? 'currentPriceMultiUnit' : 'currentPrice', true),
                energyPriceVariation: getters.productionSystemEnergyDataValue(defId, 'priceVariation'),
                ghgRatio: getters.productionSystemEnergyDataValue(defId, 'ghgRatio'),
                greenEnergyGhgRatio: getters.productionSystemEnergyDataValue(defId, 'ghgRatio', true),
                solarPanelsSurface: getters.productionSystemSolarPanelsSurface(defId),
            };
            if (def.identifier === 'synced-with-heating') {
                values.investment -= getters.productionSystemDataValue(def.syncedId, 'investment');
                values.maintenance -= getters.productionSystemDataValue(def.syncedId, 'maintenance');
                values.provisions -= getters.productionSystemDataValue(def.syncedId, 'provisions');
                // solarPanelsSurface is synced with a watcher
            };
            return values;
        };
    },

    userEnergyPrice(state, getters, rootState) {
        return (defId) => {
            const productionSystem = getters.productionSystem(defId);
            if (!productionSystem) return undefined;
            const group = rootState.thermix.userEnergyPrices[state.defs[defId].group];
            if (!group) return undefined;
            const userEnergyPrice = group[productionSystem.energyIdentifier];
            if (!userEnergyPrice) return undefined;
            const data = {
                energyPrice: userEnergyPrice.price,
                energyPriceVariation: userEnergyPrice.priceVariation,
            };
            const userGreenEnergyPrice = group[productionSystem.greenEnergyIdentifier];
            if (userGreenEnergyPrice) data.greenEnergyPrice = userGreenEnergyPrice.price;
            return data;
        };
    },

};
