const defaultState = {
    initialDataLoaded: false,
    pk: null,
    user: null,
    advisor: null,
    year: new Date().getFullYear(),
    thermalSolarProdByM2: 450,
    thermalSolarMaxProdRatioHeating: 0.6,
    thermalSolarMaxProdRatioHotWater: 0.7,
    hotWaterNeedsByPerson: 650,
    hotWaterAndHeatingConsumptionRatio: 0.9,
    customCarbonTax: {
        year: 2030,
        amount: 100,
    },
    userEnergyPrices: {},
};

export default defaultState;
