<template>
<span class="gtm-wrapper">
  <action-link
    v-show="!showSlot"
    @click="clickToShowSlot"
    :label="label"
    :icon="icon"
    :icon-position="iconPosition"
    :color="color"
    :disabled="disabled"
    >
  </action-link>
  <span
    v-show="showSlot"
    >
    <slot name="target"></slot>
  </span>
</span>
</template>

<script>

import ActionLink from '@/components/action_link';
  
export default {
    /**
     * Sub-title styling
     */
    name: 'GtmWrapper',
    components: {
        ActionLink,
    },
    props: {
        // for ActionLink
        label: { default: '', type: String },
        icon: { default: '', type: [String, Array] },
        iconPosition: { default: 'left', type: String },
        color: { default: '', type: String },
        disabled: { default: false, type: Boolean },
        // for Google Tag Manager
        gtmEvent: { default: null, type: String },
        gtmCategory: { default: '', type: String },
        gtmAction: { default: 'click', type: String },
        gtmLabel: { default: '', type: String },
        gtmValue: { default: '', type: [String, Number] },
    },
    data() {
        return {
            hasBeenClicked: false,
            useGTM: true,
        };
    },
    computed: {
        showSlot() {
            return this.hasBeenClicked === true || this.useGTM === false;
        },
    },
    mounted: function() {
        this.useGTM = this.$store.get('googleTagAccepted');
    },
    methods: {
        clickToShowSlot() {
            this.hasBeenClicked = true;

            this.$gtm.trackEvent({
                event: this.gtmEvent,
                category: this.gtmCategory,
                action: this.gtmAction,
                label: this.gtmLabel,
                value: this.gtmValue,
                noninteraction: false,
            });            
        },
    },
};
</script>
