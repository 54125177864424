const api = {
    // HOUSING
    getAdvisorsForHousing(pk) {
        return this.genericDetail('/visit-report/advisors-for-housing/', pk);
    },

    // REPORT
    getVisitReportPdf(data) {
        return new Promise((resolve, reject) => {
            this.apiClient({ responseType: 'arraybuffer' })
                .post('/visit-report/report/pdf', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    getReport(pk) {
        return this.genericDetail('/visit-report/report/', pk);
    },
    saveReport(report, housing) {
        const saveHousingPromise = this.genericSave('/visit-report/housing/', housing);
        const saveReportPromise = this.genericSave('/visit-report/report/', report);
        return Promise.all([saveHousingPromise, saveReportPromise]);
    },
};

export default api;
