const api = {
    getPdfSimulation(data) {
        return new Promise((resolve, reject) => {
            this.apiClient({ responseType: 'arraybuffer' })
                .post('/ecorenover/report/pdf', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
