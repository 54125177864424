/*
    Autogenerated class model from django CalculationMethod
    -- 2023-03-13 18:02:20.823130
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class CalculationMethodClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'order', rawData.order);
        Vue.set(this, 'name', rawData.name);
    }

    getBaseUrl() {
        return '/listepro/calculation-method/';
    }

    get isValid() {
        return true;
    }
}

class CalculationMethodCollection extends BaseCollection {
    initModel(rawData) {
        return new CalculationMethodClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/calculation-method/';
    }
}

class CalculationMethodStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new CalculationMethodClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/calculation-method/';
    }

    getStorePath() {
        return 'listepro/calculationMethodCollection';
    }
}

export {
    CalculationMethodClass,
    CalculationMethodCollection,
    CalculationMethodStoreCollection,
};
