<template>

<DialogWrapper
  ref="noteEditModal"
  :min-width="1000"
  :min-height="500"
  :scrollable="true"
  :adaptive="true"
  :resizable="true"
  >
  <div class="modal-content-edit">
    <font-awesome-icon icon="edit" class="help-icon" />
    <h2>Edition d'une note</h2>      
    
    <template>
      <spinner
        :active="isLoading"
        />
      
      
      <wysiwyg-field
        v-model="note.note"
        label="Note"
        XvalidateRules="required|max:50000"
        mandatory
        :isError="formErrors.note"
        :custom-error="formErrorsMessages.note"
        :allowRawEdit="false"
        />

      <checkbox-field
        v-model="note.pinned"
        :label="note.pinned ? 'Note épinglée' : 'Note standard'"
        />


      <form-buttons
        class="buttons"
        @primaryAction="save"
        @secondaryAction="deleteNote"
        @tertiaryAction="cancel"
        :hideSecondary="false"
        :confirmActionSecondary="true"
        >
      </form-buttons>
      
      </template>
    </div>
  </DialogWrapper>
  
</template>

<script>
import CheckboxField from '@/components/checkbox_field';
import FormButtons from '@/components/form_buttons';
import Spinner from '@/components/spinner';
import WysiwygField from '@/components/wysiwyg_field';

import { NoteClass } from '@/models/listepro/note';
import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';
        
export default {
    name: 'NoteEdit',
    mixins: [handleFormErrorsMixin],
    components: {
        CheckboxField,
        FormButtons,
        Spinner,
        WysiwygField,
    },
    data: function() {
        return {
            isLoading: false,
            note: new NoteClass(),
            professionalPk: null,
        };
    },
    computed: {
    },
    watch: {
    },
    created: function() {
    },
    mounted: function() {
    },
    methods: {
        load(pk, professionalPk) {
            this.professionalPk = professionalPk;
            this.resetErrorFields();
            this.isLoading = true;
            if (pk) {
                this.note.load(pk)
                    .then(() => this.showModal())
                    .catch(error => {
                        this.handleFormErrors(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            } else {
                this.note = new NoteClass();
                this.showModal();
                this.isLoading = false;
            }
        },
        hideModal() {
            this.$refs.noteEditModal.hide();
        },
        showModal() {
            this.$refs.noteEditModal.show();
        },
        cancel() {
            this.hideModal();
        },
        deleteNote() {
            this.isLoading = true;
            this.note.professional = this.professionalPk;
            this.note.delete()
                .then(() => {
                    this.$emit('reload-notes');
                    this.hideModal();
                })
                .catch(error => {
                    this.handleFormErrors(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        save() {
            this.isLoading = true;
            this.note.professional = this.professionalPk;
            this.note.save()
                .then(() => {
                    this.$emit('reload-notes');
                    this.hideModal();
                })
                .catch(error => {
                    this.handleFormErrors(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

.panels-component
    .modal-content-edit
        min-height: 400px
        margin: space-unit

        .help-icon
            color: primary-color
            float: right
            font-size: 3.5rem

        h3
            color: primary-color
            margin-bottom: space-xl

        .button
            margin-bottom: space-xl
</style>
