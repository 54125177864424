import _ from 'lodash';
import { store } from '@/store/store';

import axios from 'axios';

import accounts from '@/apps/accounts/api';
import appInfos from '@/api/app_infos';
import cti from '@/apps/cti/api';
import customForms from '@/apps/custom_forms/api';
import dialogwatt from '@/apps/dialogwatt/api';
import ecorenover from '@/apps/ecorenover/api';
import generic from '@/api/generic';
import groupsAndUsers from '@/apps/groups_and_users/api';
import listepro from '@/apps/listepro/api/api';
import login from '@/api/login';
import messaging from '@/apps/messaging/api';
import pdfTempStore from '@/api/pdf_temp_store';
import territories from '@/api/territories';
import thermix from '@/apps/thermix/api';
import visitReport from '@/apps/visit_report/api/api';

// Make Axios play nice with Django CSRF
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function axiosResponseInterceptor(response) {
    store.commit('setServerVersion', response.headers['x-server-version']);
    return response;
}

function axiosErrorInterceptor(error) {
    return Promise.reject(error);
}

/// ////////////////////////////////////////////////////////////////////

const api = {
    apiClient(options) {
        let base = {
            baseURL: '/api/v1/',
            xhrFields: {
                withCredentials: true,
            },
            data: {},
        };

        const jwt = store.getters.getField('jwt');
        if (jwt) {
            base.headers = { Authorization: `JWT ${jwt}` };
        }

        if (typeof options === 'object') {
            base = { ...base, ...options };
        }
        const axiosApi = axios.create(base);
        axiosApi.interceptors.response.use(axiosResponseInterceptor, axiosErrorInterceptor);
        return axiosApi;
    },
};

_.assign(
    api,
    accounts,
    appInfos,
    cti,
    customForms,
    dialogwatt,
    ecorenover,
    generic,
    groupsAndUsers,
    listepro,
    login,
    messaging,
    pdfTempStore,
    territories,
    thermix,
    visitReport,
);

export default api;
