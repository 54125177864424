/*
    Autogenerated class model from django Label
    -- 2023-03-13 18:02:20.828091
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class LabelClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'order', rawData.order || 0);
        Vue.set(this, 'name', rawData.name || '');
        Vue.set(this, 'description', rawData.description || '');
        Vue.set(this, 'imageForBank', rawData.imageForBank || '');
        Vue.set(this, 'imageForBroker', rawData.imageForBroker || '');
        Vue.set(this, 'imageForRealEstate', rawData.imageForRealEstate || '');
    }

    getBaseUrl() {
        return '/listepro/label/';
    }

    get isValid() {
        return true;
    }
}

class LabelCollection extends BaseCollection {
    initModel(rawData) {
        return new LabelClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/label/';
    }
}

class LabelStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new LabelClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/label/';
    }

    getStorePath() {
        return 'listepro/labelCollection';
    }
}

export {
    LabelClass,
    LabelCollection,
    LabelStoreCollection,
};
