function vpm(taux, duree, montant) {
    // http://www.excel-downloads.com/forum/100205-decortiquer-la-fonction-vpm-ou-la-fonction-operation-multiple.html
    if (duree <= 0) return 0;
    if (taux === 0) return montant / duree;
    return montant * taux / (1.0 - Math.pow(1.0 + taux, -duree));
}

function avg(arr) {
    if (arr.length === 0) { return 0; }
    return sum(arr) / arr.length;
}

function sum(arr) {
    return arr.reduce((acc, val) => acc + val, 0);
}

function partialSum(arr, start, stop) {
    return sum(arr.slice(start, stop));
}

function partialAvg(arr, start, stop) {
    return avg(arr.slice(start, stop));
}

function sumExceptFirst(arr) {
    return sum(arr.slice(1));
}

function computeAnnualEnergyInvoicesWithoutHouseWork(state) {
    const annualEnergyInvoicesWithoutHouseWorkSmoothed = [];
    const annualEnergyInvoicesWithoutHouseWork = []; // old name: facture_energetique_annuelle

    // First, loop to calculate "facture_energetique_annuelle" for each year (with variation AND smoothed)
    for (let year = 0; year < state.annualVariation.length; year++) {
        const increasePrice = 1.0 + state.inputs.costOfEnergyIncrease;
        const increasePriceWithVariation = state.annualVariation[year] * increasePrice;
        if (year < 2) {
            annualEnergyInvoicesWithoutHouseWork.push(state.inputs.annualInvoice);
            annualEnergyInvoicesWithoutHouseWorkSmoothed.push(state.inputs.annualInvoice);
        } else {
            annualEnergyInvoicesWithoutHouseWork.push(increasePriceWithVariation * annualEnergyInvoicesWithoutHouseWork[year - 1]);
            annualEnergyInvoicesWithoutHouseWorkSmoothed.push(increasePrice * annualEnergyInvoicesWithoutHouseWorkSmoothed[year - 1]);
        }
    }

    // Compute smoothed annualEnergyInvoices sum
    const cumulatedSmoothedInvoice = sumExceptFirst(annualEnergyInvoicesWithoutHouseWorkSmoothed);

    // Manually Fix annualEnergyInvoice for year 19 and 20 to make sum match smoothed' one
    const sumUpTo18 = partialSum(annualEnergyInvoicesWithoutHouseWork, 1, 19);
    annualEnergyInvoicesWithoutHouseWork[19] = ((cumulatedSmoothedInvoice - sumUpTo18) / 2.0) * 0.99;
    const sumUpTo19 = sumUpTo18 + annualEnergyInvoicesWithoutHouseWork[19];
    annualEnergyInvoicesWithoutHouseWork[20] = cumulatedSmoothedInvoice - sumUpTo19;

    return annualEnergyInvoicesWithoutHouseWork;
}

function computeExpensesYearByYear(
    state,
    annualEnergyInvoicesWithoutHouseWork,
    annualEnergyInvoicesWithHouseWork,
    annualSavedOnEnergy,
    annualEcoLoanPayments,
    annualRegularLoanPayments,
    totalYearlyAmountsToPay,
    totalYearlyLoansToPay,
    yearlyDifferenceAgainstDoingNothing,
    monthlyDifferenceAgainstDoingNothing
) {
    const heatingCoefficient = state.energyWanted.currentPrice * state.inputs.futureEnergyConsumption * state.inputs.livingSpace / 100; // old name: coeff_chauffage
    const heatingPriceVariation = state.energyWanted.priceVariation; // old name: coeff_chauffage
    const ecoLoanRemainingToPay = []; // old name: AA_capital_restant_du_ptz
    const regularLoanRemainingToPay = []; // old name: AA_capital_restant_du_pc

    for (let year = 0; year < state.annualVariation.length; year++) {
        const annualEnergyInvoiceWithoutHouseWork = annualEnergyInvoicesWithoutHouseWork[year];
        const annualVariation = state.annualVariation[year];

        if (year === 0) {
            annualEnergyInvoicesWithHouseWork.push(annualEnergyInvoiceWithoutHouseWork);
        } else if (year === 1) {
            annualEnergyInvoicesWithHouseWork.push(heatingCoefficient * annualVariation);
        } else {
            // Is there not the same issue which lead to the annualEnergyInvoicesWithoutHouseWorkSmoothed hack?
            annualEnergyInvoicesWithHouseWork.push(annualVariation * (1.0 + heatingPriceVariation) * annualEnergyInvoicesWithHouseWork[year - 1]);
        }
        const annualEnergyInvoiceWithHouseWork = annualEnergyInvoicesWithHouseWork[year];

        annualSavedOnEnergy.push(annualEnergyInvoiceWithoutHouseWork - annualEnergyInvoiceWithHouseWork);

        annualEcoLoanPayments[year] = 0;
        if (year > 0) {
            if (ecoLoanRemainingToPay[year - 1] <= 0) {
                annualEcoLoanPayments[year] = 0;
            } else if (year === 1) {
                annualEcoLoanPayments[year] = state.inputs.ecoLoanAnnualPayment;
            } else {
                annualEcoLoanPayments[year] = vpm(
                    state.inputs.ecoLoanRate, state.inputs.ecoLoanLength - (year - 1), ecoLoanRemainingToPay[year - 1]
                );
            }
        }

        let reimboursmentRegularLoanInterests = 0; // old name: AA_remboursement_interets_pc
        if (year > 0) {
            reimboursmentRegularLoanInterests = Math.max(0, state.inputs.regularLoanRate * regularLoanRemainingToPay[year - 1]);
        }

        annualRegularLoanPayments[year] = 0;
        if (year > 0) {
            if (regularLoanRemainingToPay[year - 1] <= 0) {
                annualRegularLoanPayments[year] = 0;
            } else {
                annualRegularLoanPayments[year] = vpm(
                    state.inputs.regularLoanRate, state.inputs.regularLoanLength - (year - 1), regularLoanRemainingToPay[year - 1]
                );
            }
        }

        let reimboursmentRegularLoan = 0; // old name: AA_remboursement_capital_pc
        if (year > 0) {
            reimboursmentRegularLoan = annualRegularLoanPayments[year] - reimboursmentRegularLoanInterests;
        }

        regularLoanRemainingToPay[year] = state.inputs.regularLoanAmount;
        if (year > 0) {
            regularLoanRemainingToPay[year] = regularLoanRemainingToPay[year - 1] - reimboursmentRegularLoan;
            if (year === 1) {
                regularLoanRemainingToPay[year] -= state.inputs.estimatedAmountOfHelps;
            }
        }

        ecoLoanRemainingToPay[year] = state.inputs.ecoLoanAmount;
        if (year > 0) {
            ecoLoanRemainingToPay[year] = ecoLoanRemainingToPay[year - 1] - annualEcoLoanPayments[year];
            if (year === 1) {
                // Why regularLoanRemainingToPay??? We're doing calculus on the PTZ (eco loan)
                ecoLoanRemainingToPay[year] += Math.min(0, regularLoanRemainingToPay[year]);
            }
        }

        totalYearlyAmountsToPay[year] = state.inputs.annualInvoice;
        if (year > 0) {
            totalYearlyAmountsToPay[year] = annualEnergyInvoiceWithHouseWork + annualEcoLoanPayments[year] + annualRegularLoanPayments[year];
        }

        totalYearlyLoansToPay[year] = 0;
        if (year > 0) {
            totalYearlyLoansToPay[year] = annualEcoLoanPayments[year] + annualRegularLoanPayments[year];
        }

        yearlyDifferenceAgainstDoingNothing[year] = 0;
        if (year > 0) {
            yearlyDifferenceAgainstDoingNothing[year] = totalYearlyAmountsToPay[year] - annualEnergyInvoiceWithoutHouseWork;
        }

        monthlyDifferenceAgainstDoingNothing[year] = yearlyDifferenceAgainstDoingNothing[year] / 12;
    }
}

function computeNewState(state) {
    const annualEnergyInvoicesWithHouseWork = [];
    const annualSavedOnEnergy = [];
    const annualEcoLoanPayments = []; // old name: AA_annuites_ptz
    const annualRegularLoanPayments = []; // old name: AA_annuites_pc
    const totalYearlyAmountsToPay = []; // old name: AA_total
    const totalYearlyLoansToPay = []; // old name: AA_emprunt_travaux
    const yearlyDifferenceAgainstDoingNothing = []; // old name: AA_situation_tendentielle_annuelle
    const monthlyDifferenceAgainstDoingNothing = []; // old name: AA_situation_tendentielle_mensuelle

    // old name: facture_energetique_annuelle
    const annualEnergyInvoicesWithoutHouseWork = computeAnnualEnergyInvoicesWithoutHouseWork(state);
    computeExpensesYearByYear(
        state,
        annualEnergyInvoicesWithoutHouseWork,
        annualEnergyInvoicesWithHouseWork,
        annualSavedOnEnergy,
        annualEcoLoanPayments,
        annualRegularLoanPayments,
        totalYearlyAmountsToPay,
        totalYearlyLoansToPay,
        yearlyDifferenceAgainstDoingNothing,
        monthlyDifferenceAgainstDoingNothing,
    );

    const totalInvoiceWithoutHouseWork = sumExceptFirst(annualEnergyInvoicesWithoutHouseWork); // old name: total_facture_sans_travaux
    const totalInvoiceWithHouseWork = sumExceptFirst(annualEnergyInvoicesWithHouseWork); // old name: total_facture_avec_travaux
    const totalCost = sumExceptFirst(totalYearlyLoansToPay); // old name: total_cout
    // const totalSaved = totalInvoiceWithoutHouseWork - (totalInvoiceWithHouseWork + totalCost); // old name: economie_totale

    state.monthlySavedAmount = (totalInvoiceWithoutHouseWork / (20 * 12)) - ((totalInvoiceWithHouseWork + totalCost) / (20 * 12)); // old name: economie_par_mois

    state.earnIn20Years = sum(annualEnergyInvoicesWithoutHouseWork) - sum(totalYearlyAmountsToPay);
    if (state.earnIn20Years >= 0) {
        state.renovationString = 'rapporté';
    } else {
        state.earnIn20Years = -state.earnIn20Years;
        state.renovationString = 'coûté';
    }

    const start = 2;
    const stop = 7;

    state.monthlyCost5nextYears = partialAvg(monthlyDifferenceAgainstDoingNothing, start, stop);
    state.monthlyEnergyEarned5nextYears = partialAvg(annualSavedOnEnergy, start, stop) / 12;
    state.monthlyRenovationCost5nextYears = (partialAvg(annualEcoLoanPayments, start, stop) + partialAvg(annualRegularLoanPayments, start, stop)) / 12;
    state.differenceAgainstDoingNothing = sum(yearlyDifferenceAgainstDoingNothing);

    state.currentEPEnergyConsumption = (
        (state.inputs.annualInvoice / (state.energyUsed.currentPrice / 100)) * state.energyUsed.primaryEnergyRatio
    ) / state.inputs.livingSpace;
    state.futureEPEnergyConsumption = state.inputs.futureEnergyConsumption * state.energyWanted.primaryEnergyRatio;

    state.futureInvoicesWithoutHouseWork = annualEnergyInvoicesWithoutHouseWork;
    state.futureInvoicesWithHouseWork = annualEnergyInvoicesWithHouseWork;
    state.loanRepayment = totalYearlyLoansToPay;
}

export {
    avg,
    computeNewState,
    partialAvg,
    partialSum,
    sumExceptFirst,
    sum,
    vpm,
};
