const api = {
    housingList() {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get('/visit-report/housing/')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    housingListForUser(contactType, contactId) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/visit-report/housing/?contact_type=${contactType}&contact_id=${contactId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    housingDetail(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/visit-report/housing/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    housingDelete(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .delete(`/visit-report/housing/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    housingUpdate(data) {
        const pk = data.pk;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`/visit-report/housing/${pk}/`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    housingCreate(data) {
        data.pk = null;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/visit-report/housing/', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
