const CatchmentAreaEdit = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/catchment_areas/catchment_area_edit');
const CatchmentAreaList = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/catchment_areas/catchment_area_list');
const PlaceEdit = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/places/place_edit');
const PlaceList = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/places/place_list');
const ReasonEdit = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/reasons/reason_edit');
const ReasonList = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/reasons/reason_list');
const NotificationEdit = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/notifications/notification_edit');
const NotificationList = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/notifications/notification_list');
const SlotEdit = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/slots/slot_edit');
const AppointmentEdit = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/appointments/appointment_edit');
const ExchangeList = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/exchanges/exchange_list');
const dialogwattMenu = () => import(/* webpackChunkName: "dialogwatt" */ '@/apps/dialogwatt/menu');
const Statistics = () => import(/* webpackChunkName: "dialogwatt-statistics" */ '@/apps/dialogwatt/statistics/layout');

const routes = [
    {
        path: '/dialogwatt/',
        name: 'DialogwattMenu',
        component: dialogwattMenu,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
        children: [
            {
                path: 'mes-rendez-vous/',
                name: 'DialogwattAppointmentEdit',
                component: AppointmentEdit,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-creneaux/',
                name: 'DialogwattSlotList',
                component: SlotEdit,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-lieux/',
                name: 'DialogwattPlaceList',
                component: PlaceList,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-lieux/edition/:pk',
                name: 'DialogwattPlaceEdit',
                component: PlaceEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-zones-de-chalandise/',
                name: 'DialogwattCatchmentAreaList',
                component: CatchmentAreaList,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-zones-de-chalandise/creation/',
                name: 'DialogwattCatchmentAreaEditNew',
                component: CatchmentAreaEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-zones-de-chalandise/edition/:pk',
                name: 'DialogwattCatchmentAreaEdit',
                component: CatchmentAreaEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-motifs-de-rendez-vous/',
                name: 'DialogwattReasonList',
                component: ReasonList,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-motifs-de-rendez-vous/creation/',
                name: 'DialogwattReasonEditNew',
                component: ReasonEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-motifs-de-rendez-vous/edition/:pk',
                name: 'DialogwattReasonEdit',
                component: ReasonEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-notifications/',
                name: 'DialogwattNotificationList',
                component: NotificationList,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-notifications/creation/',
                name: 'DialogwattNotificationEditNew',
                component: NotificationEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'gestion-des-notifications/edition/:pk',
                name: 'DialogwattNotificationEdit',
                component: NotificationEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'echanges/',
                name: 'DialogwattExchangeList',
                component: ExchangeList,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
            {
                path: 'statistics/',
                name: 'DialogwattStatistics',
                component: Statistics,
                meta: {
                    requiresAuth: true,
                    requiresUserType: ['administrator', 'advisor', 'superadvisor'],
                },
            },
        ],
    },
];

export default routes;
