import defaultState from './_defaultState';
import getters from './_getters';
import mutations from './_mutations';
import actions from './_actions';

import productionSystemDefs from './production_system_defs';
import needs from './needs';
import needsEstimation from './needs_estimation';
import solutions from './solutions';
import results from './results';
import metroGrenoble from './metro_grenoble';

const state = Object.assign({}, (defaultState));

export default {
    namespaced: true,
    modules: {
        productionSystemDefs,
        needs,
        needsEstimation,
        solutions,
        results,
        metroGrenoble,
    },
    state,
    getters,
    mutations,
    actions,
};
