import { store } from '@/store/store';
const Ecorenover = () => import(/* webpackChunkName: "ecorenover" */ '@/apps/ecorenover/ecorenover');
const CurrentSituation = () => import(/* webpackChunkName: "ecorenover" */ '@/apps/ecorenover/current_situation');
const EnvisagedSituation = () => import(/* webpackChunkName: "ecorenover" */ '@/apps/ecorenover/envisaged_situation');
const Loans = () => import(/* webpackChunkName: "ecorenover" */ '@/apps/ecorenover/loans');
const Results = () => import(/* webpackChunkName: "ecorenover" */ '@/apps/ecorenover/results');
const ResultsPdf = () => import(/* webpackChunkName: "ecorenover" */ '@/apps/ecorenover/report_pdf');

const routes = [
    {
        path: '/ecorenover',
        name: 'Ecorenover',
        component: Ecorenover,
        redirect: { name: 'EcorenoverCurrentSituation' },
        props: true,
        meta: {
            requiresAuth: false,
        },
        children: [
            {
                name: 'EcorenoverCurrentSituation',
                path: 'situation-actuelle',
                component: CurrentSituation,
                meta: {
                    tabLabel: 'Situation\u00A0actuelle',
                },
            },
            {
                name: 'EcorenoverEnvisagedSituation',
                path: 'situation-envisagee',
                component: EnvisagedSituation,
                meta: {
                    tabLabel: 'Situation\u00A0envisagée',
                },
            },
            {
                name: 'EcorenoverLoans',
                path: 'emprunts',
                component: Loans,
                meta: {
                    tabLabel: 'Emprunts',
                },
            },
            {
                name: 'EcorenoverResults',
                path: 'resultats',
                component: Results,
                meta: {
                    tabLabel: 'Résultats',
                },
            },
            {
                name: 'EcorenoverReportPdf',
                path: 'rapport/:uuid/pdf',
                component: ResultsPdf,
                meta: {
                    requiresAuth: false,
                    tabLabel: false,
                },
            },
        ],
    },
];

export default routes;
