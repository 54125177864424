import { getField } from 'vuex-map-fields';

import { cases as forcedSolutionsCases, caseA } from './_forcedSolutions';

export default {
    getField,

    boilerPowerLabel(state, getters, rootState, rootGetters) {
        if (state.enabled && state.area === 'A' && rootGetters['thermix/needs/isMultiUnit']) {
            const renovationType = rootState.thermix.needs.renovationType.includes('renovation') ? 'renovation' : 'new_building';
            return state.boilerPowers[renovationType][state.boilerPower];
        }
        return null;
    },

    forcedSolutions(state, getters, rootState) {
        const area = state.area;
        let solutions = [];
        const housingCategory = rootState.thermix.needs.housingCategory;
        const renovationType = rootState.thermix.needs.renovationType;

        if (!area) return solutions;

        if (area === 'A') {
            // console.log(state.boilerPower);
            if (housingCategory === 'multi_unit' && state.boilerPower === 'high') {
                solutions = caseA;
            } else {
                solutions = forcedSolutionsCases.B[housingCategory][renovationType] || [];
            }
        } else {
            solutions = forcedSolutionsCases[area][housingCategory][renovationType] || [];
        }

        const applyDefaults = (sys) => {
            // Ensure prod system defs has values we want to be erased when changing metro grenoble forced solutions
            // even if not defined in forced solutions
            Object.assign(sys, {
                forcedForNonWoodHeating: !!sys.forcedForNonWoodHeating,
                limitedChoices: sys.limitedChoices || [],
                messageHint: sys.messageHint || '',
            });
        };
        const applyLocking = (sys) => {
            const locking = { locked: false, lockedReason: '' };
            if (sys.identifier) {
                locking.locked = true;
                locking.lockedReason = sys.lockedReason || 'Système préconisé';
            }
            Object.assign(sys, locking);
        };
        solutions.forEach(solution => {
            if (solution.heatingSystems) solution.heatingSystems.forEach(sys => { applyDefaults(sys); applyLocking(sys); });
            if (solution.hotWaterSystems) solution.hotWaterSystems.forEach(sys => { applyDefaults(sys); applyLocking(sys); });
            if (typeof solution.isMetroGrenoble === 'undefined') solution.isMetroGrenoble = true;
        });
        return solutions;
    },

    town(state) {
        return state.towns[state.inseeCode];
    },

};
