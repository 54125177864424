/*
    Autogenerated class model from django
    -- 2020-04-16 15:07:18.864579
*/

import { make } from 'vuex-pathify';

const defaultState = {
    isLoading: 0,
    assignmentTagCollection: {},
    assignmentTagCollectionFilter: null,
    assignmentTagCollectionLastUpdate: 0,
    experienceCollection: {},
    experienceCollectionFilter: null,
    experienceCollectionLastUpdate: 0,
    experienceTagCollection: {},
    experienceTagCollectionFilter: null,
    experienceTagCollectionLastUpdate: 0,
    jobTagCollection: {},
    jobTagCollectionFilter: null,
    jobTagCollectionLastUpdate: 0,
    partnerTagCollection: {},
    partnerTagCollectionFilter: null,
    partnerTagCollectionLastUpdate: 0,
    publicTagCollection: {},
    publicTagCollectionFilter: null,
    publicTagCollectionLastUpdate: 0,
    sponsorTagCollection: {},
    sponsorTagCollectionFilter: null,
    sponsorTagCollectionLastUpdate: 0,
    yearTagCollection: {},
    yearTagCollectionFilter: null,
    yearTagCollectionLastUpdate: 0,
    selectedExperiences: [],
};

const state = Object.assign({}, (defaultState));
const getters = make.getters(state);

const mutations = {
    ...make.mutations(state),

    resetState(state) {
        Object.assign(state, defaultState);
    },

    incrementLoading(state) {
        state.isLoading++;
    },

    decrementLoading(state) {
        state.isLoading--;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
