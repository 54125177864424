<template>    
    <div class="content-gray" >
        <header class="header">
            <div class="grid-x grid-margin-x">
                <div class="cell modal-title">
                   Sélectionnez les mots clés principaux de votre activité  
                   <a @click="hideModal"><font-awesome-icon class="close-modal" :icon="['fal', 'times-circle']"/></a>
                </div>
            </div>
        </header>
        <layout-container>
            <h3 class="subtitle">Sélectionnez {{nbMax}} mots clés maximum : </h3>
            <p class="form-helper">{{helper}}</p>
            <div class="cell warning-card" v-if="keyWordModified.length < nbMax">
                <div>Vous avez sélectionné <strong>{{keyWordModified.length}}</strong> mots-clés principaux sur <strong>{{nbMax}}</strong>.</div>
                <div>Vous pouvez encore sélectionner <strong>{{nbMax-keyWordModified.length}}</strong> mots-clés.</div>
            </div>
            <div class="cell info-card" v-if="!(keyWordModified.length < nbMax)">
                <div>Félicitations vous avez sélectionné vos <strong>{{nbMax}}</strong> mots-clés.</div>
            </div>
        </layout-container>
        <div class="list">
            <div  class="category-group" v-bind:key=object.category v-for="object in keyWordArray" >
                <span class="subtitle">{{object.category}}</span>
                <div class="grid-x">
                    <div class="cell small-3" v-bind:key=kw.id v-for="kw in object.keyword" >
                        <div class="cell key-word">
                            <div>
                                <input v-model="keyWordModified" type="checkbox" :id="kw.id" :value="kw" :disabled="keyWordModified.length===nbMax && !kwInArray(kw.pk)">
                                <label class="label-checkbox" :for="kw.id">{{kw.name}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <layout-container>
            <regular-button class="button-valid" @click="validKeyWords(); hideModal();">
                Valider <font-awesome-icon icon="chevron-circle-right" />
            </regular-button>
            <regular-button class="button-cancel" @click="hideModal">
                Annuler
            </regular-button>
        </layout-container>
    </div>
</template>

<script>

import RegularButton from '@/components/regular_button';

export default {
    name: 'ModalKeyWords',
    data() {
        return {
            displayHelpMission : false,
            keyWordModified : [],
        };
    },
    created: function() {},
    mounted: function() {
        this.keyWordModified=this.keyWordCurrent;
    },
    methods: {
        validKeyWords(){
            this.$emit('valid-kw', this.keyWordModified);
        },
        hideModal() {
            this.$emit('hide-modal');
        },
        kwInArray(pk){
            for(const index in this.keyWordModified){ // cannot use function indexOf() directly
                if(this.keyWordModified[index].pk===pk){
                    return true;
                }
            }
            return false;
        },
    },
    components: {
        RegularButton,
    },
    computed: {
        nbKW() {
            return  this.keyWordModified.length;
        },
    },
    props: {
        keyWordArray:Array,
        helper:String,
        nbMax:Number,
        keyWordCurrent : Array,
    },
};


</script>
<style lang='stylus' scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/listepro_font'

.key-word
    input[type='checkbox']
        margin-bottom: 0.5rem
        -webkit-appearance: none
        height: 15px
        width: 15px
        background: #fff
        border: 1px solid gray
        border-radius: 4px
        vertical-align: middle
        transition-duration: 0.5s
        margin-top: 0.5rem
        cursor: pointer

    input[type='checkbox']:checked
        background-color: primary-color-500
        transition-duration: 0.5s
        border: 1px solid primary-color-500

        &:before
            // font-family: 'Font Awesome'
            // content: '\f138'
            content: 'X'
            display: block
            color: #fff
            font-size: 12px
            margin-left: 0.2rem
            font-weight: bold

.check-box
    background-color: red

.header
    padding: 1.5rem 1.5rem 1.5rem 0.5rem
    background-color: primary-color-500
    margin-bottom: 1rem

.close-modal
    color: #fff
    float: right
    margin-right: 1rem
    font-size: 1.5rem

.modal-title
    font-weight: bold
    font-size: 1.4rem
    color: #fff
    text-transform: uppercase
    margin-left: 2rem
    display: inline-block

.category-group
    margin: 1rem
    padding-left: 1.5rem
    padding-right: 0rem
    background-color: gray-100

.subtitle
    font-weight: bold
    font-size: 1rem
    text-transform: uppercase
    color: primary-color-500

.form-helper
    font-size: 0.8125rem
    font-style: italic
    color: #6e6e6e

.label-checkbox
    font-size: 1rem
    display: inline

.info-card
    background-color: success-color-100
    border-left: 8px solid success-color-300

.content-gray
    background-color: #fff

.button-valid
    float: right
    margin: 1rem 0rem
    padding: 1.2rem 1.2rem
    font-size: 1.1rem

.button-cancel
    float: right
    margin: 1rem 0.5rem
    padding: 1.1rem 1.2rem
    font-size: 1.1rem
    background-color: #fff
    border: solid 0.2rem primary-color-500
    color: primary-color-500
</style>
