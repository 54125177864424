import EcorenoverIframe from '@/apps/ecorenover_iframe/ecorenover_iframe';

const routes = [
    {
        path: '/ecorenover-iframe',
        name: 'EcorenoverIframe',
        component: EcorenoverIframe,
    },
];

export default routes;
