<template>
  <div class="field checkbox-field">
    <div class="grid-x grid-padding-x">
      <div :class="{cell: true, shrink: true, 'small-order-2': isLabelPositionLeft}">
        <div :class="['switch', this.size]">
          <input
            :class="{ 'switch-input':true, error: isError }"
            type="checkbox"
            :id="id"
            :ref="id"
            :name="id"
            v-model="localValue"
            :disabled="disabled"
          >
          <label
            class="switch-paddle"
            v-bind:for="id"
          >
            <span
              class="show-for-sr"
              v-html="label"
            ></span>
          </label>
        </div>
      </div>
      <div :class="{cell: true, auto: true, 'small-order-1': isLabelPositionLeft}">
        <div
          v-if="helptext"
          class="toggle-helptext float-right"
          @click="toggleHelpDisplay"
          title="Voir le détail"
        >
          <font-awesome-icon :icon="iconForHelptext" />
        </div>
        <slot>
          <label
             class="checkbox-label"
             v-html="label"
             :for="id"
             >
          </label>
        </slot>
      </div>
    </div>

    <div class="errors" v-if="isError">
      <div
        class="error"
        v-for="(errorMessage, index) in fieldData.allErrors"
        :key="index"
        v-html="errorMessage"
      />
    </div>
    
    <div
      class="helptext-box grid-x"
      v-if="helptext"
      v-show="showHelptext"
    >
      <div class="small-3 cell"></div>
      <div class="small-9 cell">
        <span class="checkbox-helptext">
          <div class="helptext" v-html="helptext"></div>
        </span>
      </div>
    </div>
  </div>

</template>

<script>
import fieldMixin from './field_mixin';

export default {
    name: 'CheckboxField',
    mixins: [ fieldMixin ],
    props: {
        value: { default: true, type: Boolean },
        helptext: { default: '', type: String },
        icon: { default: '', type: [String, Array] },
        labelPosition: {
            type: String,
            default: 'right',
            validator: value => ['left', 'right'].includes(value),
        },
        placeholder: { default: '', type: String },
        disabled: { default: false, type: Boolean },
        size: { default: 'tiny', type: String },
    },
    data() {
        return {
            showHelptext: false,
        };
    },
    watch: {
        value: function() {
            this.localValue = this.value;
        },
    },
    computed: {
        iconForHelptext: function() {
            if (this.showHelptext) {
                return 'angle-up';
            } else {
                return 'angle-down';
            }
        },
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        isLabelPositionLeft() {
            return this.labelPosition === 'left';
        },
    },
    mounted: function() {
        this.localValue = this.value;
    },
    methods: {
        toggleHelpDisplay: function() {
            this.showHelptext = !this.showHelptext;
        },
    },
};
</script>

<style lang="stylus" scoped>
.checkbox-label
    cursor: pointer
    line-height: 1.3

.error
    color: var(--error-color-500)

.switch.micro
    height: 1rem

    .switch-paddle
        height: 1rem
        font-size: 0.417rem
        width: 2rem    
        &::after
            top: 0.167rem
            left: 0.167rem
            width: 0.667rem
            height: 0.667rem
    input:checked 
        ~ .switch-paddle::after
            left: 1.167rem
        


</style>
