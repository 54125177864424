<template>
    <div v-if="professional">
        <layout-container class="content">
            <div class="grid-x grid-margin-x">
                <div class="cell">
                    <regular-button class="button back" @click="routerBack" v-if="showBackButton">
                        <font-awesome-icon icon="chevron-left" />Retour 
                    </regular-button>
                    <regular-button class="button back" @click="closeWindow" v-else>
                        <font-awesome-icon icon="times" />Fermer
                    </regular-button>
                </div>
                <div class="cell medium-6">
                    <div class="grid-y">  
                      <div class="cell">

                        <div class="favorite-box">
                          <a class="checkbox" title="Mettre dans la sélection">
                            <font-awesome-icon
                              v-show="!selected"
                              :icon="['far', 'square']"
                              class="favorite"
                              @click="toggleFavoritePro"
                              />
                          </a>
                          <a class="checkbox" title="Retirer de la sélection">
                            <font-awesome-icon
                              v-show="selected"
                              icon="check-circle"
                              class="favorite"
                              @click="toggleFavoritePro"
                              />
                          </a>
                        </div>
                        
                        <div class="grid-x grid-margin-x"> 
                                <div class="cell large-5">
                                    <div v-if="professional.logo" class=" logo"><img v-bind:src="professional.logo"></div>
                                </div>
                                <div class="cell large-7" :class="{ 'large-12': !professional.logo}">
                                    <div class=" right ">

                                      <div v-if="professional.isMonAccompagnateurRenov" class="mar-logo">
                                        <img src="/static/img/listepro/mon-accompagnateur-renov.png" class="icon-mar">
                                      </div>

                                      <h2 class="bold title">{{additionalData.name}}</h2>
                                      
                                      <gtm-wrapper
                                        label="Voir l'adresse du site"
                                        gtm-event="url-on-detail-view"
                                        gtm-category="ListePro"
                                        gtm-action="click-to-show"
                                        :gtm-label="professional.name"
                                        :gtm-value="additionalData.url"
                                        >
                                        <template v-slot:target>
                                          <a :href="additionalData.url" target="_blank"><strong>{{additionalData.url}}</strong></a>
                                        </template>
                                      </gtm-wrapper>
                                      <br>
                                      
                                      <gtm-wrapper
                                        label="Voir le courriel"
                                        gtm-event="email-on-detail-view"
                                        gtm-category="ListePro"
                                        gtm-action="click-to-show"
                                        :gtm-label="professional.name"
                                        :gtm-value="additionalData.email"
                                        >
                                        <template v-slot:target>
                                          <a :href="'mailto:'+additionalData.email" target="_blank">{{additionalData.email}}</a>
                                        </template>
                                      </gtm-wrapper>
                                      <br>
                                      
                                        <span v-if="professional.job" class="pro-job">{{professional.job}}</span><br>
                                        {{additionalData.address}}<!--, {{additionalData.postcode}} {{additionalData.town}}--><br>
                                        Tél : <gtm-wrapper
                                                label="Voir le téléphone"
                                                gtm-event="phone-on-detail-view"
                                                gtm-category="ListePro"
                                                gtm-action="click-to-show"
                                                :gtm-label="professional.name"
                                                :gtm-value="additionalData.phoneNumber"
                                                >
                                          <template v-slot:target>
                                            <a :href="'tel:'+additionalData.phoneNumber">{{additionalData.phoneNumber}}</a>
                                          </template>
                                        </gtm-wrapper>
                                        <br><br>


                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="cell ">

                            <h2 class="bold title">En quelques mots</h2>
                            <div class="detail" v-html="professional.presentationData.description"></div>
                        </div>
                    </div>
                    
                </div>
                <div class="cell medium-6 ">
                    <div class="grid-y">  
                      <div class="cell">
                        
                          <div class="grid-x" v-if="professional.publishedBankOffers.length!==0 && formType=='bank'">
                            <div class="medium-12 carousel bank-carousel">
                              <carousel
                                class="carousel-bank"
                                :per-page="1" 
                                :mouse-drag="false"
                                :navigationEnabled="true"
                                :adjustableHeight="false"
                                :loop="true"
                                :paginationPadding="4"
                                :paginationSize=10
                                :paginationActiveColor="'#9a258f'"
                                :paginationColor="'#d2d2d2'"
                                :centerMode="true"
                                :autoplay="true"
                                :autoplayTimeout="5000"
                                >
                                
                                <slide v-bind:key="'bankOffer' + prod.id" v-for="prod in professional.publishedBankOffers">
                                  <div class=" img-container">
                                    <div :class="[offerDescriptionClass, backgroundClass(prod.id)]" v-html="prod.offerDescription" />
                                    <div class="img-footer">
                                      <span class="img-title" >{{prod.offerName}}</span>
                                      <regular-button class="button right img-button" @click="showModalDetailBankOffer(prod);">
                                        Voir cette offre
                                      </regular-button>
                                    </div>
                                  </div> 
                                </slide>
                              </carousel>
                                                            
                            </div>
                          </div>
                          <div class="grid-x" v-if="professional.publishedProductions.length!==0 && formType=='generic'"> 
                            <div class="medium-12 carousel">
                              <carousel
                                class="carousel-production"
                                :per-page="1" 
                                :mouse-drag="false"
                                :navigationEnabled="true"
                                :adjustableHeight="false"
                                :loop="true"
                                :paginationPadding="4"
                                :paginationSize=10
                                :paginationActiveColor="'#9a258f'"
                                :paginationColor="'#d2d2d2'"
                                :centerMode="true"
                                :autoplay="true"
                                :autoplayTimeout="5000"
                                >
                                <slide v-bind:key="'production' + prod.id" v-for="prod in publishedProductions">
                                  <div class=" img-container">
                                    <img v-if="prod.productionImages.length" v-bind:src="prod.productionImages[0].cropped" class="img">
                                    <div v-if="!prod.productionImages.length" class="no-img"> 
                                      <div class="no-img-txt"> 
                                        Pas d'image pour ce projet
                                      </div>
                                    </div>
                                    <div class=" img-footer">
                                      <span class="img-title" >{{prod.productionName}}</span>
                                      <regular-button class="button right img-button" @click="showModalDetailRef(prod);">
                                        Voir ce projet
                                      </regular-button>
                                    </div>
                                  </div> 
                                </slide>
                              </carousel>
                              
                            </div>
                          </div>

                          <div class="grid-x" v-if="professional.labels.length!==0 && hasLabel">
                            <div class="medium-12 carousel">
                              <carousel
                                class="carousel-real-estate-agency"
                                :per-page="1" 
                                :mouse-drag="false"
                                :navigationEnabled="true"
                                :adjustableHeight="false"
                                :loop="true"
                                :paginationPadding="4"
                                :paginationSize=10
                                :paginationActiveColor="'#9a258f'"
                                :paginationColor="'#d2d2d2'"
                                :centerMode="true"
                                :autoplay="true"
                                :autoplayTimeout="5000"
                                >
                                <slide v-bind:key="'label' + label.id" v-for="label in professional.labels">
                                  <div class="pro-label">
                                    <!--
                                    <div class="name">{{ label.name }}</div>
                                    <div class="description">{{ label.description }}</div>
                                    -->
                                    <img v-if="formType=='real_estate_agency'" :src="label.imageForRealEstate" :title="label.description">
                                    <img v-if="formType=='broker'" :src="label.imageForBroker" :title="label.description">
                                  </div> 
                                </slide>
                              </carousel>
                              
                            </div>
                            
                          </div>
                        </div>

                    </div>
                </div>
                        
                <div class="cell small-12 medium-6">
                  <h2 class="bold title">Domaine de compétences</h2>
                  <span class="bold">Principaux domaines de compétences </span><br>
                  <div class="pro-words" v-bind:key="'primaryKeyWord' + word" v-for="word in professional.presentationData.primaryKeyWords" >
                    <span class="word">{{ word }}</span>
                  </div>
                </div>

                <div class="cell small-12 medium-6">
                  <div v-if="userIsAllowed" >
                    <h2 class="bold title">Domaines d'activités</h2>
                    <div class="pro-words">
                      <span v-for="activity in professional.presentationData.activities" class="word" :key="activity">{{ activity }}</span>
                    </div>
                    <div v-if="useMission">
                      <h2 class="bold title">Missions proposées</h2>
                      <div class="pro-words" v-bind:key="'subMission' + sub.id" v-for="sub in professional.presentationData.subMissions" >
                        <span class="word">{{sub.name}}</span>
                      </div>
                    </div>
                  </div>
                  
                </div>

                <div class="cell medium-6 labels" v-if="userIsAllowed && hasLabel">
                  
                  <h2 class="bold title">Labels accordés</h2>
                  <double-list-select
                    v-model="professional.presentationData.labels"
                    :data="labels.asList()"
                    id="labels"
                    name="labels"
                    ref="labels"
                    :showFilter="false"
                    height="9rem"
                    :reduce-key="null"
                    :label="(x) => { return x.name }"
                    >
                  </double-list-select>
                  
                  <regular-button @click="saveLabels" class="label-button">
                    Sauvegarder les labels <font-awesome-icon icon="chevron-circle-right" />
                  </regular-button>
                  
                </div>

                <additional-addresses
                  v-if="userIsAllowed"
                  class="cell"
                  :canModify="false"
                  :professional="professional"
                  >
                </additional-addresses>

            </div>


            <DialogWrapper
              ref="modalDetailRef"
            >
                <div> 
                    <modal-details-reference
                      :production="prodModal"
                      @hide-modal="hideModalDetailRef"
                      >
                    </modal-details-reference>

                    <layout-container>
                        <div class="grid-x grid-margin-x">
                            <div class="cell">
                                <regular-button @click="hideModalDetailRef">
                                    <font-awesome-icon icon="chevron-left" /> Retour
                                </regular-button>
                            </div>
                        </div>
                    </layout-container>
                </div>
            </DialogWrapper>

            <DialogWrapper
                ref="modalDetailBankOffer"
                :adaptive="false"
                :width="1200"
                height="auto"
                clickToClose
                :scrollable="true"
            >   
                <div> 
                    <modal-details-bank-offer
                      :production="prodModal"
                      @hide-modal="hideModalDetailBankOffer"
                      >
                    </modal-details-bank-offer>
                    
                    <layout-container>
                        <div class="grid-x grid-margin-x">
                            <div class="cell">
                                <regular-button @click="hideModalDetailBankOffer">
                                    <font-awesome-icon icon="chevron-left" /> Retour
                                </regular-button>
                            </div>
                        </div>
                    </layout-container>
                </div>
            </DialogWrapper>
            
        </layout-container>
        
    </div>
</template>

<script>

import RegularButton from '@/components/regular_button';
import { LabelCollection } from '@/models/listepro/label.js';
import ModalDetailsReference from '@/apps/listepro/public/details/modal_details_reference';
import ModalDetailsBankOffer from '@/apps/listepro/public/details/modal_details_bank_offer';
import professionalMixin from '@/apps/listepro/professional_form/professional_mixin';
import DoubleListSelect from '@/components/double_list_select';
import AdditionalAddresses from '@/apps/listepro/additional_addresses/additional_addresses';
import GtmWrapper from '@/components/gtm_wrapper';

import apiClient from '@/api/api';

// import searchMixin from '@/apps/listepro/search/search_mixin';
import { dispatch, sync } from 'vuex-pathify';
import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import { mapFields } from 'vuex-map-fields';
import { userMixin } from '@/mixins/user';

Vue.use(VueCarousel);



export default {
    name: 'Details',
    mixins: [ professionalMixin, userMixin ],
    components: {
        AdditionalAddresses,
        DoubleListSelect,
        GtmWrapper,
        RegularButton,
        ModalDetailsReference,
        ModalDetailsBankOffer,
    },
    props: [
        'pk',
    ],
    provide() {
        return {
            // dumb injections for AdditionalAddresses component 
            formErrors: {},
            formErrorsMessages: {},
            reloadProfessional: null,
        };
    },
    data() {
        return {
            id: null,
            idAdditionalAddress: null,
            professional: null,
            labels: new LabelCollection(),
            linkImageProd:[],
            currentProdPictures :[],
            prodModal : null,
            formErrors: {},
            additionalAddressesColumns: [
                {
                    label: 'Nom',
                    field: 'name',
                    sortable: true,
                },
                {
                    label: 'Couriel',
                    field: 'email',
                    sortable: true,
                },
                {
                    label: 'Url',
                    field: 'url',
                    sortable: true,
                },
                {
                    label: 'Adresse',
                    field: 'address',
                    sortable: true,
                },
                {
                    label: 'Ville',
                    field: 'town',
                    sortable: true,
                },
            ],

        };
    },
    computed: {
        ...mapFields([ 'user' ]),
        favoritePro: sync("listepro/favoritePro"),
        
        formType() {
            return this.professional?.formType;
        },

        publishedProductions() {
            return this.professional?.publishedProductions.filter(x => x.status === 'published');
        },
        
        selected() {
            const index = this.favoritePro.findIndex(x => x === this.professional.pk);
            return index !== -1;
        },

        userIsAllowed() {
            return this.userUtilities.isListeProAdmin || this.userUtilities.isAdvisor;
        },
        
        offerDescriptionClass: function() {
            return 'offer-description';
        },
        textButtonValidation : function(){
            if(this.professional.proIsValid){
                return "Invalider";
            }
            return "Valider";
        },

        hasLabel() {
            return this.formType === 'real_estate_agency' || this.formType === 'broker';
        },
        realPk() {
            const pk = String(this.pk);
            const dashPos = pk.indexOf('-');
                
            if (dashPos !== -1) {
                return parseInt(pk.substr(0, dashPos));
            }
            return parseInt(pk);
        },
        useMission() {
            const flag = {
                'generic': true,
                'bank': false,
                'broker': false,
                'real_estate_agency': false,
            };
            let value = flag[this.formType];
            if (value === undefined) {
                console.error(`useMission: missing key "${this.formType}"`);
                value = true;
            }
            return value;
        },

        additionalData() {
            if (this.idAdditionalAddress) {
                const data = this.professional.additionalAddresses.filter(x => { return x.id === this.idAdditionalAddress;})[0];
                if (!data) {
                    return {
                        name: '',
                        url: '',
                        email: '',
                        job: '',
                        address: '',
                        postcode: '',
                        town: '',
                        phoneNumber: '',
                    };
                }
                return data;
            }
            return this.professional;
        },

        showBackButton() {
            return window.history.length > 1;
        },
         
        mission:sync("listepro/mission"),
        subMission:sync("listepro/subMission"),
        activity:sync("listepro/activity"),
        segment:sync("listepro/segment"),
        resultsWithSelection:sync("listepro/resultsWithSelection"),
    },
    watch: {
        pk: {
            immediate: true,
            handler: 'handlePk',
        },
    },
    mounted: function() {
        this.labels.load();
    },
    methods: {

        toggleFavoritePro() {
            dispatch("listepro/setProfessionalAsFavorite", {professionalPk: this.pk, state: !this.selected});
        },
        
        handlePk() {
            if (this.pk) {
                const pk = String(this.pk);
                const dashPos = pk.indexOf('-');
                
                if (dashPos !== -1) {
                    this.id = parseInt(pk.substr(0, dashPos));
                    this.idAdditionalAddress = parseInt(pk.substr(dashPos + 1));
                } else {
                    this.id = parseInt(pk);
                    this.idAdditionalAddress = null;
                }


                apiClient.genericDetail("/listepro/public/professional/", this.id, true, { serializer: "full" })
                    .then(response => {
                        this.professional = response;
                    })
                    .catch(error => {
                        console.debug(error);
                        this.$notify({
                            group: 'root',
                            type: 'error',
                            title: 'Erreur serveur',
                            text: 'La liste des professionnels n\'a pas put être chargée.',
                        });
                    });
                
            }
        },
        
        backgroundClass: function(offerPk) {
            const allowedIndex = [3, 4];
            // Make a pseudo random assignation 
            const offset = this.professional.pk;
            const allOffers = this.professional.publishedBankOffers.map(x => x.pk);
            const item = allowedIndex[(allOffers.indexOf(offerPk) + offset) % allowedIndex.length];
            return `background${item}`;
        },
        
        showModalDetailRef(production) {
            this.prodModal=production;
            this.$refs.modalDetailRef.show();
        },
        hideModalDetailRef() {
            this.$refs.modalDetailRef.hide();
        },
        showModalDetailBankOffer(bankOffer) {
            this.prodModal=bankOffer;
            this.$refs.modalDetailBankOffer.show();
        },
        hideModalDetailBankOffer() {
            this.$refs.modalDetailBankOffer.hide();
        },
        routerBack() {
            this.$router.go(-1);
        },
        closeWindow() {
            window.open('','_self').close();
        },
        getStatus(isValid, isInProgress) {
            if(isValid){
                return "valid";
            }
            else if(isInProgress){
                return "current";
            } else {
                return "nonValid";
            }
        },
        saveLabels() {
            this.professional.save()
                .then(professionalSaved => {
                    this.$emit(
                        'professional-saved',
                        professionalSaved,
                    );
                    this.$notify({
                        group: 'root',
                        type: 'success',
                        title: 'Succès',
                        text: 'Les labels ont bien été sauvegardés !',
                    });
                    
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeStatus(){
            if(this.professional.proIsValid){
                this.professional.proIsValid=false;
            } else {
                this.professional.proIsValid=true;
            }
            this.professional.save()
                .then(professionalSaved => {
                    this.$emit(
                        'professional-saved',
                        professionalSaved,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../../stylesheet/variables'
@require '../../../../stylesheet/typography'

.favorite-box
    float: right

.labels
    margin-top: space-xxl

    .label-button
        float: right

.pro-label
    text-align: center

    .name
        font-size: 24px
        color: gray-400

    .description
        font-size: 32px

.carousel-real-estate-agency
    width: 250px
    float: right

.carousel-macaron
    // float: right
    width: 250px

.bank-carousel
    width: 428px

.carousel-production
    width: 428px
    max-width: 428px
    overflow-x: hidden

.carousel-bank
    max-width: 428px
    overflow-x: hidden

    .offer-description
        padding: space-sm

:deep(.VueCarousel-slide)
    max-width: 428px

.offer-description
    color: black
    height: 300px
    overflow-y: hidden
    text-align: left
    overflow-wrap: break-word
    word-wrap: break-word
    max-width: 428px
    overflow-x: clip

    :deep(li)
        p
            margin-bottom: 0px !important
            overflow-wrap: break-word
            word-wrap: break-word

.offer-description::after
.background1::after
.background2::after
.background3::after
.background4::after
    // background: url('/static/img/listepro/references/bank-reference-4.jpg')
    background-repeat: no-repeat
    background-size: 428px 300px
    content: ''
    opacity: 0.35
    top: 0
    left: 0
    bottom: 0
    right: 0
    position: absolute
    z-index: -1

.background1::after
    background: url('/static/img/listepro/references/bank-reference-1.jpg')
    background-size: 428px 300px

.background2::after
    background: url('/static/img/listepro/references/bank-reference-2.jpg')
    background-size: 428px 300px

.background3::after
    background: url('/static/img/listepro/references/bank-reference-3.jpg')
    background-size: 428px 300px

.background4::after
    background: url('/static/img/listepro/references/bank-reference-4.jpg')
    background-size: 428px 300px

.content
    background-color: gray-100
    padding-top: 2rem

.breadcrumbs
    padding-bottom: 0.7rem
    padding-top: 0.7rem
    margin-bottom: 0rem
    background-color: gray-100
    position: relative
    border: 1px solid gray-300

.button
    color: #fff

.back
    border-color: primary-color-500
    background-color: #fff
    border: 0.2rem solid
    color: primary-color-500

.bold
    font-weight: bold

.title
    margin-top: space-md
    color: primary-color-500
    text-transform: uppercase
    font-weight: bold

.pro-job
    font-size: 0.75rem
    text-transform: uppercase
    background-color: #000
    padding: 0.2rem
    color: #fff

.pro-words
    display: inline-block

.word
    padding: 5px
    display: inline-block
    background-color: #fff
    border: 1px solid gray
    color: gray
    margin: 0.1rem 0.1rem 0.1rem 0.1rem

.logo
    margin-bottom: 1rem

.carousel
    margin-bottom: 2rem

.img
    border: 1px solid gray
    max-width: 100%
    max-height: 16.3rem

.no-img
    width: 100%
    // width: 23.06rem
    border: solid 1px gray
    height: 16.3rem
    text-align: center

.no-img-txt
    margin-top: 6rem

.img-container
    position: relative
    display: inline-block
    vertical-align: top
    text-align: center
    width: 100%

    .img-footer
        position: absolute
        left: 0rem
        bottom: 0rem

        .img-button
            // position: absolute
            // right: 1rem
            // top: 0.4rem
            float: right
            margin-right: 0.5rem
            margin-top: 0.5rem

        .img-title
            position: absolute
            left: 0.9rem
            top: 0.6rem

.img-footer
    background-color: rgba(69, 69, 69, 0.5)
    height: 3rem
    width: 100%
    position: relative

.img-title
    color: #fff
    width: 70%
    // max-width: 250px
    display: inline-block
    vertical-align: middle
    height: 25px
    overflow: hidden
    text-align: left
    text-overflow: ellipsis
    white-space: nowrap

.go-form
    padding: 1.2rem 1.2rem
    font-size: 1.1rem
    margin-top: 2rem
    margin-right: 1rem

.go-valid
    padding: 1.2rem 1.2rem
    font-size: 1.1rem
    margin-top: 2rem
    margin-right: 1rem
    background-color: success-color-400

.pro-status-container
    margin-bottom: 1rem

.pro-status
    text-transform: uppercase
    padding: 0.2rem
    font-size: 0.85rem
    color: #fff
    position: relative
    top: -0.5rem
    margin-left: 1.5rem

.valid
    background-color: success-color-400

.non-valid
    background-color: error-color-400

.current
    background-color: warning-color-400

pre.detail
    font-family: var(--body-font-family)
    color: var(--body-font-color)
    font-size: 1.1rem
    margin: 0 0 0.625rem 0
    padding: unset
    background: unset
    border-style: unset
    border-color: unset
    border-width: unset
    white-space: pre-wrap

.mar-logo
    float: right
    width: 100px
    padding-left: 15px
</style>
