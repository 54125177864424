import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';

import { store } from '@/store/store';

import onErrorHandler from './errors_handler';

import accountsRoutes from '@/apps/accounts/router';
import actimmoMapRoutes from '@/apps/actimmo_map/router';
import ctiRoutes from '@/apps/cti/router';
import rgpdRoutes from '@/apps/rgpd_customization/router';
import groupsRoutes from '@/apps/groups_and_users/router';
import dashboardsRoutes from '@/router/dashboards';
import dialogwattRoutes from '@/apps/dialogwatt/router';
import dialogwattClientRoutes from '@/apps/dialogwatt_client/router';
import simulaidesRoutes from '@/router/simulaides';
import messagingRoutes from '@/apps/messaging/router';
import thermixRoutes from '@/apps/thermix/router';
import therminixRoutes from '@/apps/therminix/router';
import visitReportRoutes from '@/apps/visit_report/router';
import ecorenoverIframeRoutes from '@/router/ecorenover_iframe';
import ecorenoverRoutes from '@/apps/ecorenover/router';
import oldThermixRoutes from '@/router/old_thermix';
import facRoutes from '@/apps/fac/router';
import newslettersRoutes from '@/apps/newsletters/router';
import eventsRoutes from '@/apps/events/router';
import experiencesRoutes from '@/apps/experiences/router';
import listeproRoutes from '@/apps/listepro/router';
import bapRoutes from '@/apps/bap/router';

Vue.use(Router);

const Home = () => import('@/apps/home');

let routes = [
    {
        path: '/',
        name: 'Homepage',
        component: Home,
    },
];

// Import other routes modules and add to routes
routes = routes.concat(accountsRoutes);
routes = routes.concat(actimmoMapRoutes);
routes = routes.concat(ctiRoutes);
routes = routes.concat(rgpdRoutes);
routes = routes.concat(groupsRoutes);
routes = routes.concat(dashboardsRoutes);
routes = routes.concat(dialogwattRoutes);
routes = routes.concat(dialogwattClientRoutes);
routes = routes.concat(simulaidesRoutes);
routes = routes.concat(messagingRoutes);
routes = routes.concat(thermixRoutes);
routes = routes.concat(therminixRoutes);
routes = routes.concat(visitReportRoutes);
routes = routes.concat(ecorenoverIframeRoutes);
routes = routes.concat(ecorenoverRoutes);
routes = routes.concat(oldThermixRoutes);
routes = routes.concat(facRoutes);
routes = routes.concat(newslettersRoutes);
routes = routes.concat(eventsRoutes);
routes = routes.concat(experiencesRoutes);
routes = routes.concat(listeproRoutes);
routes = routes.concat(bapRoutes);

// ADD OTHERS ROUTES HERE :-)

const router = new Router({
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash && document.querySelector(to.hash)) {
            return {
                selector: to.hash,
            };
        } else {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({ x: 0, y: 0 });
                }, 100);
            });
        }
    },
});

router.beforeEach((to, from, next) => {
    NProgress.start();
    if (to.path.match(/\/pdf$/gi) !== null) {
        store.commit('updateLayout', 'pdf');
    } else if (to.path.match(/^\/newsletters-public/gi) !== null) {
        store.commit('updateLayout', 'public-newsletters');
    } else {
        store.commit('updateLayout', 'web');
    }
    if (to.matched.some(record => record.meta.requiresAuth) && to.matched.some(record => record.meta.requiresUserType)) {
        if (store.state.user != null && to.matched[0].meta.requiresUserType.includes(store.state.user.userType)) {
            next();
        } else {
            if (store.state.isAuthenticated === false) {
                console.log('redirect-to-login');
                next({
                    name: 'AccountLoginAndNext',
                    params: { nextUrl: to.fullPath },
                });
            } else {
                next(from);
            }
        }
    } else {
        next();
    }
});

router.afterEach(() => {
    NProgress.done();
});

router.onError(onErrorHandler);

export default router;
