import { make } from 'vuex-pathify';
import { getField, updateField } from 'vuex-map-fields';
import api from '@/api/api';

const defaultState = {
    isLoading: 0,
    actionModelCollection: {},
    actionModelCollectionFilter: null,
    actionModelCollectionLastUpdate: 0,
    contactScreenState: {},
    contactCollection: {},
    contactCollectionFilter: null,
    contactCollectionLastUpdate: 0,
    contactTypeCollection: {},
    contactTypeCollectionFilter: null,
    contactTypeCollectionLastUpdate: 0,
    contactsDuplicateCollection: {},
    contactsDuplicateCollectionFilter: null,
    contactsDuplicateCollectionLastUpdate: 0,
    ecorenoverSimulationCollection: {},
    ecorenoverSimulationCollectionFilter: null,
    ecorenoverSimulationCollectionLastUpdate: 0,
    fileCollection: {},
    fileCollectionFilter: null,
    fileCollectionLastUpdate: 0,
    fileImportCollection: {},
    fileImportCollectionFilter: null,
    fileImportCollectionLastUpdate: 0,
    folderCollection: {},
    folderCollectionFilter: null,
    folderCollectionLastUpdate: 0,
    folderModelCollection: {},
    folderModelCollectionFilter: null,
    folderModelCollectionLastUpdate: 0,
    listCollection: {},
    listCollectionFilter: null,
    listCollectionLastUpdate: 0,
    listV2Collection: {},
    listV2CollectionFilter: null,
    listV2CollectionLastUpdate: 0,
    memberOfOrganizationCollection: {},
    memberOfOrganizationCollectionFilter: null,
    memberOfOrganizationCollectionLastUpdate: 0,
    noteCollection: {},
    noteCollectionFilter: null,
    noteCollectionLastUpdate: 0,
    organizationScreenState: {},
    organizationCollection: {},
    organizationCollectionFilter: null,
    organizationCollectionLastUpdate: 0,
    organizationTypeCollection: {},
    organizationTypeCollectionFilter: null,
    organizationTypeCollectionLastUpdate: 0,
    projectStatsCollection: {},
    projectStatsCollectionFilter: null,
    projectStatsCollectionLastUpdate: 0,
    relationBetweenOrganizationCollection: {},
    relationBetweenOrganizationCollectionFilter: null,
    relationBetweenOrganizationCollectionLastUpdate: 0,
    reminderCollection: {},
    reminderCollectionFilter: null,
    reminderCollectionLastUpdate: 0,
    rgpdConsentForContactsCollection: {},
    rgpdConsentForContactsCollectionFilter: null,
    rgpdConsentForContactsCollectionLastUpdate: 0,
    tagCollection: {},
    tagCollectionFilter: null,
    tagCollectionLastUpdate: 0,
    typeValorizationCollection: {},
    typeValorizationCollectionFilter: null,
    typeValorizationCollectionLastUpdate: 0,

    selectedOrganizationProject: null,
    selectedContactProject: null,

    selectedOrganizationReferents: null,
    selectedContactReferents: null,

    statisticsFormData: {
        selectedGroups: [],
        selectedPeriod: null,
        selectedProject: null,
    },

    // TODO get rid of this
    users: [],
    persons: [],
    usersLoaded: false,
};

const state = Object.assign({}, (defaultState));
const getters = {
    ...make.getters(state),
    getField,
};

function updateList(collection, element, deleted) {
    if (deleted) {
        const indexElement = collection.pkOrdering.indexOf(element.pk);
        delete collection[element.pk];
        collection.pkOrdering.splice(indexElement, 1);
    } else {
        const exist = collection[element.pk];
        if (collection.hasOwnProperty('pkOrdering')) {
            if (exist === undefined) {
                collection.pkOrdering.push(element.pk);
            }
        }
        collection[element.pk] = element;
    }
}

const mutations = {
    ...make.mutations(state),
    updateField,

    resetState(state) {
        Object.assign(state, defaultState);
    },

    incrementLoading(state) {
        state.isLoading++;
    },

    decrementLoading(state) {
        state.isLoading--;
    },

    remindersUpdate(state, args) {
        updateList(state.reminderCollection, args.element, args.deleted);
    },

    notesUpdate(state, args) {
        updateList(state.noteCollection, args.element, args.deleted);
    },

    setOrganizationProjectSelected(state, projectSelected) {
        state.selectedOrganizationProject = projectSelected;
    },

    setContactProjectSelected(state, projectSelected) {
        state.selectedContactProject = projectSelected;
    },

    setOrganizationReferentsSelected(state, referentsSelected) {
        state.selectedOrganizationReferents = referentsSelected;
    },

    setContactReferentsSelected(state, referentsSelected) {
        state.selectedContactReferents = referentsSelected;
    },

    setContactScreen(state, screenState) {
        state.contactScreenState = screenState;
    },

    setOrganizationScreen(state, screenState) {
        state.organizationScreenState = screenState;
    },

    setStatisticsFormData(state, data) {
        state.statisticsFormData = { ...state.statisticsFormData, ...data };
    },

    setFolders(state, folders) {
        state.folderCollection = folders;
    },
};

const actions = {
    // FIXME getPersons looks kinda useless and redundent with other referents stuff
    getPersons({ state, commit }, groupPk) {
        return api.genericDetail('accounts/group/', groupPk)
            .then(({ users }) => {
                commit('updateField', {
                    path: 'users',
                    value: users,
                });

                const persons = users.map(({ name, email, pk }) => ({
                    label: `${name} (${email})`,
                    value: pk,
                }));
                commit('updateField', {
                    path: 'persons',
                    value: persons,
                });

                commit('updateField', {
                    path: 'usersLoaded',
                    value: true,
                });
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
