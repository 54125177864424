let api = {
    reasonList() {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get('/dialogwatt/reason/')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    reasonDetail(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/dialogwatt/reason/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    reasonDelete(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .delete(`/dialogwatt/reason/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    reasonSave(data) {
        let pk = data.pk;
        if (pk === null) {
            return new Promise((resolve, reject) => {
                this.reasonCreate(data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        } else {
            return new Promise((resolve, reject) => {
                this.reasonUpdate(data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    },
    reasonUpdate(data) {
        let pk = data.pk;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`/dialogwatt/reason/${pk}/`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    reasonCreate(data) {
        data.pk = null;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post(`/dialogwatt/reason/`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
