<template>    
  <div class="content-gray" >
    <header class="modal-header">
      <div class="grid-x grid-margin-x">
        <div class="cell small-10">
          <h2 class="modal-title">{{production.offerName}}</h2>
        </div>
        <div class="cell small-2">
          <a @click="hideModal"><font-awesome-icon class="close-modal" :icon="['fal', 'times-circle']" /></a>
        </div>
      </div>
    </header>
    <layout-container>
      <div class="grid-x grid-margin-x">
        <div class="cell">
          <div v-if="production.offerDescription" class="title-content">Description de l'offre</div>
          <div v-if="production.offerDescription" class="formatted-content" v-html="production.offerDescription"></div>
          <div v-if="production.offerCharacteristics" class="title-content">Caractéristiques de l'offre</div>
          <div v-if="production.offerCharacteristics" class="formatted-content" v-html="production.offerCharacteristics"></div>
          <a v-if="production.link" class="link-content" :href="production.link" :title="production.link" target="_blank">Lien vers l'offre</a>
        </div>
      </div>
    </layout-container>
  </div>
</template>

<script>

import LayoutContainer from '@/components/layout_container';

export default {
    name: 'ModalDetailsBankOffer',
    mixins: [ ],
    components: {
        LayoutContainer,
    },
    props: {
        production: Object,
    },
    methods: {
        hideModal() {
            this.$emit('hide-modal');
        },
    },
};


</script>
<style lang='stylus' scoped>
@require '../../../../stylesheet/variables'
@require '../../../../stylesheet/typography'

.content-gray
    background-color: gray-100

.content-white
    background-color: white

.modal-header
    padding: 0rem 0.3rem 0rem 0.3rem
    background-color: primary-color-500
    margin-bottom: 1rem

.modal-title
    color: #fff
    text-transform: uppercase
    padding: 3%

.close-modal
    color: #fff
    float: right
    margin-right: 2 rem
    margin-top: 2.1rem
    font-size: 1.5rem

.title
.title-content
    font-color: primary-color-500
    color: primary-color-500
    font-size: 1.2rem
    text-transform: uppercase

.formatted-content
    // white-space: pre
    // overflow-x: scroll
    text-align: left
    margin-bottom: space-md

    :deep(li)
        p
            margin-bottom: 0px !important

.image
    margin-right: 2rem
    border: 0.15rem solid gray-300
    width: 23rem

.basic-info
    margin-left: 2, 3rem

.bold
    font-weight: bold
</style>
