<template>
  <div
    class="layout-container"
    :class="{'section-content': grey}"
    :style="style"
  >
    <div class="grid-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    name: 'LayoutContainer',
    components: {},
    props: {
        grey: { default: false, type: Boolean },
        color: { default: '', type: String },
    },
    computed: {
        style() {
            if (this.color) {
                return `background-color: ${this.color};`;
            }
            return '';
        },
    },
    
};
</script>

<style lang='stylus' scoped></style>
