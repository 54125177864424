import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import router from '@/router/router';
import { store } from '@/store/store';
import { createPinia, PiniaVuePlugin } from 'pinia';
import NProgress from 'nprogress';
import Notifications from 'vue-notification';
import VTooltip from 'v-tooltip';

import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate';
import {
    email,
    integer,
    length,
    max,
    max_value,
    min_value,
    numeric,
    required,
} from 'vee-validate/dist/rules';
import fr from 'vee-validate/dist/locale/fr.json';

import DialogWrapper from '@/components/dialog_wrapper';
import Layout from '@/apps/layout';
import LayoutContainer from '@/components/layout_container';

import loadFontAwesomeIcons from './fa_icons';
import 'vue-good-table/dist/vue-good-table.css';
import 'nprogress/nprogress.css';
import VueMeta from 'vue-meta';

import apiClient from '@/api/api';

import PortalVue from 'portal-vue';

import VueClipboard from 'vue-clipboard2';
import VueMatomo from 'vue-matomo';

import 'date-input-polyfill';

// VueLeaflet
import 'leaflet/dist/leaflet.css';

import '@/stylesheet/vendors.scss';
import '@/stylesheet/project.styl';

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
});

NProgress.configure({ showSpinner: false });

Vue.use(Notifications);

Vue.use(VTooltip);

Vue.use(VueClipboard);

Vue.use(PortalVue);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.component('DialogWrapper', DialogWrapper);
Vue.component('layout-container', LayoutContainer);

loadFontAwesomeIcons();

// vee-validate
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
localize('fr', fr);

extend('email', email);
extend('integer', integer);
extend('length', length);
extend('max', max);
extend('max_value', max_value);
extend('min_value', min_value);
extend('numeric', numeric);
extend('required', {
    ...required,
    message: 'Ce champ est obligatoire.',
});
extend('url', {
    validate(value) {
        if (value) {
            return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|ftps:\/\/|ftp:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
        }

        return false;
    },
    message: 'Ce champ doit être une URL valide.',
});

// --- Sentry ----------------------------------------

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: 'https://81ea634a4eb943f68c598d91c301453c@sentry.hespul.org/3',
        integrations: [],
    });
} else {
    console.warn('Sentry not activated (process.env.NODE_ENV !== "production")');
}

// --- /Sentry ----------------------------------------

Vue.config.productionTip = false;

/* eslint-disable no-unused-vars */
let tryCounter = 0;

function displayVueApp() {
    /* eslint-disable no-new */
    new Vue({
        el: '#app',
        store,
        router,
        components: { Layout },
        template: '<Layout/>',
        pinia,
    });
}

function getUrlVars() {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[key] = value;
    });
    return vars;
}

function initMatomoTracking() {
    if (window.location.href.includes('mixeur.docker.local')) {
        console.warn('Matomo not launched for mixeur.docker.local');
        return;
    }

    const host = store.state.whiteLabelling.matomoUrl;
    const siteId = store.state.whiteLabelling.matomoSiteId;
    const trackerFileName = store.state.whiteLabelling.matomoTrackerFileName;

    Vue.use(VueMatomo, {
        // Configure your matomo server and site by providing
        host: host, // 'https://statspiwik.hespul.org',
        siteId: siteId, // 34

        // Changes the default .js and .php endpoint's filename
        // Default: 'matomo'
        // trackerFileName: 'matomo',
        trackerFileName: trackerFileName, // 'piwik',

        // Overrides the autogenerated tracker endpoint entirely
        // Default: undefined
        // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

        // Enables automatically registering pageviews on the router
        router: router,

        // Enables link tracking on regular links. Note that this won't
        // work for routing links (ie. internal Vue router links)
        // Default: true
        enableLinkTracking: true,

        // Require consent before sending tracking information to matomo
        // Default: false
        requireConsent: false,

        // Whether to track the initial page view
        // Default: true
        trackInitialView: true,

        // Run Matomo without cookies
        // Default: false
        disableCookies: false,

        // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
        // Default: false
        enableHeartBeatTimer: false,

        // Set the heartbeat timer interval
        // Default: 15
        heartBeatTimerInterval: 15,

        // Whether or not to log debug information
        // Default: false
        debug: false,

        // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
        // Default: undefined
        userId: undefined,
    });
}

function getWhiteLabellingJwtAndDisplay() {
    store
        .dispatch('getWhiteLabelling', getUrlVars().domain)
        .then(() => {
            handleJwt()
                .then(() => {
                    getProfileAndConsent()
                        .then(() => {
                            initMatomoTracking();
                            displayVueApp();
                        })
                        .catch(() => {
                            initMatomoTracking();
                            displayVueApp();
                        });
                })
                .catch(() => {
                    initMatomoTracking();
                    displayVueApp();
                });
        })
        .catch(error => {
            tryCounter += 1;
            if (tryCounter < 10) {
                setTimeout(() => { getWhiteLabellingJwtAndDisplay(); }, 1000 * tryCounter);
            } else {
                alert("Le serveur n'est pas actuellement joignable. L'équipe technique va intervenir.\nVeuillez réessayer dans quelques minutes\n" + error);
            }
            // TODO: Better image or message
            // window.location.href = 'https://owl.excelsior.edu/wp-content/uploads/sites/2/2016/03/errorpencil.jpg';
        });
}

function handleJwt() {
    const jwt = localStorage.getItem('token');
    return new Promise((resolve, reject) => {
        if (jwt != null) {
            apiClient
                .refreshTokenWithPromise(jwt)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.log('Erreur main with reconnectFromToken' + error);
                    reject(error);
                });
        } else {
            reject(new Error('No jwt token'));
        }
    });
}

function getProfileAndConsent() {
    console.log('jwt.getProfileAndConsent');
    return new Promise((resolve, reject) => {
        apiClient
            .getProfileAndConsent()
            .then(() => {
                console.log('jwt.getProfileAndConsent.OK');
                resolve();
            })
            .catch(error => {
                console.log('jwt.getProfileAndConsent.Error');
                console.debug(error);
                reject(error);
            });
    });
}

getWhiteLabellingJwtAndDisplay();
