/*
    Autogenerated class model from django
    -- 2021-10-15 09:47:10.510372
*/

import { make } from 'vuex-pathify';

const defaultState = {
    isLoading: 0,
    eventCollection: {},
    eventCollectionFilter: null,
    eventCollectionLastUpdate: 0,
    registrationCollection: {},
    registrationCollectionFilter: null,
    registrationCollectionLastUpdate: 0,

};

const state = Object.assign({}, (defaultState));
const getters = make.getters(state);

const mutations = {
    ...make.mutations(state),

    resetState(state) {
        Object.assign(state, defaultState);
    },

    incrementLoading(state) {
        state.isLoading++;
    },

    decrementLoading(state) {
        state.isLoading--;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
