export default {
    inject: ['showGenericDialog', 'hideGenericDialog'],
    methods: {
        confirmAction(actionDescription, subject, action) {
            this.showGenericDialog({
                title: 'Attention !',
                text: `<p>Vous êtes sur le point de ${actionDescription} <b>${subject}</b></p><p>Souhaitez-vous poursuivre ?</p>`,
                buttons: [
                    {
                        title: 'Oui',
                        default: false,
                        handler: () => {
                            this.hideGenericDialog();
                            action();
                        },
                    },
                    {
                        title: 'Non',
                        default: true,
                    },
                ],
            });
        },
    },
};
