const api = {
    slotList(forAWeekFrom) {
        let url = '/dialogwatt/slot/';
        if (forAWeekFrom !== undefined) {
            url = `/dialogwatt/slot/?for_a_week_from=${forAWeekFrom}`;
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    slotListForDate(forDate) {
        let url = '/dialogwatt/slot/';
        if (forDate !== undefined) {
            url = `/dialogwatt/slot/?for_date=${forDate}`;
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    slotDetail(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/dialogwatt/slot/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    slotHasAppointments(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/dialogwatt/slot-has-appointments/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    slotDelete(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .delete(`/dialogwatt/slot/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    slotSave(data) {
        const pk = data.pk;
        if (pk === null) {
            return new Promise((resolve, reject) => {
                this.slotCreate(data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        } else {
            return new Promise((resolve, reject) => {
                this.slotUpdate(data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    },
    slotUpdate(data) {
        const pk = data.pk;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`/dialogwatt/slot/${pk}/`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    slotCreate(data) {
        data.pk = null;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/dialogwatt/slot/', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
