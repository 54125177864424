<template>
<div class="reference-list">
  <div v-if="formType && references">
    <h2
      v-if="formType==='generic'"
      >
      Réalisations
    </h2>
    
    <h2
      v-else-if="formType==='bank'"
      >
      Offres
    </h2>
    
    <div class="grid-x grid-margin-x">
      <div class="cell medium-6 ">
        
      </div>
      <div class="cell medium-6 text-right">
        <action-link
          class="action text-right"
          :label="addLabel"
          icon="plus"
          @click="addReference"
          />
      </div>
    </div>
    
    <mixeur-table
      v-if="references && referencesColumns"
      :columns="referencesColumns"
      :rows="references"
      :sort-options="{ enabled: true }"
      :search-options="{ enabled: false }"
      >
  
      <template v-slot:table-row="props">
        <span v-if="props.column.field == 'after'">
          <action-link label="Editer" icon="edit" icon-position="left" v-on:click="editReference(props.row.pk)"/>
        </span>
        <span v-else-if="props.column.field == 'isValidationPending'">
          <true-false
            :value="props.row.isValidationPending"
            class="validation-pending"
            title-true="Référence est en attente de validation"
            :icon-true="['fal', 'hourglass-half']"
            :icon-false="null"
            />
        </span>
        <span v-else>
          {{ getValue(props.column, props.row) }}
        </span>
      </template>
  
    </mixeur-table>


    <div class="reference-edit-panel">
      <panel-reference
        v-if="editedReference"
        v-model="editedReference"
        :form-type="formType"
        :additional-addresses="additionalAddresses"
        v-on:reload-references="reloadReferences"
        />
                      
    </div>
      
    
  </div>
</div>
</template>

<script>
import ActionLink from '@/components/action_link';
import MixeurTable from '@/components/mixeur_table';
import PanelReference from '@/apps/listepro/professional_form/panel_reference';
import TrueFalse from '@/components/true_false';

import { utils } from '@/utils';

export default {
    name: 'ReferenceList',
    mixins: [],
    components: {
        ActionLink,
        MixeurTable,
        PanelReference,
        TrueFalse,
    },
    props: {
        references: { default: (x) => { return []; }, type: Array },
        formType: { default: null, type: String },
        additionalAddresses: { default: () => [], type: Array },
    },
    computed: {
        addLabel() {
            const labels = {
                'generic': 'Ajouter une référence',
                'bank': 'Ajouter une banque',
            };
            try {
                return labels[this.formType];
            } catch {
                return 'Ajouter une entrée';
            }
            
        },
        referencesColumns() {
            const columns = {
                'generic': [
                    {
                        label: 'Dernière modification',
                        field: '.updatedAt',
                        sortable: false,
                    },
                    {
                        label: 'Nom',
                        field: '.productionName',
                        sortable: false,
                    },
                    {
                        label: 'Année',
                        field: '.year',
                        sortable: false,
                    },
                    {
                        label: 'Lieu',
                        field: '.place',
                        sortable: false,
                    },
                    {
                        label: 'État',
                        field: 'publicationStatusAsLabel',
                        sortable: false,
                    },
                    {
                        label: 'À valider ?',
                        field: 'isValidationPending',
                        tdClass: 'text-center',
                        sortable: false,
                    },
                    {
                        label: 'Action',
                        field: 'after',
                        sortable: false,
                    },
                ],
                'bank': [
                    {
                        label: 'Dernière modification',
                        field: '.updatedAt',
                        sortable: false,
                    },
                    {
                        label: 'Nom',
                        field: '.offerName',
                        sortable: false,
                    },
                    {
                        label: 'État',
                        field: 'publicationStatusAsLabel',
                        sortable: false,
                    },
                    {
                        label: 'À valider ?',
                        field: 'isValidationPending',
                        tdClass: 'text-center',
                        sortable: false,
                    },
                    {
                        label: 'Action',
                        field: 'after',
                        sortable: false,
                    },
                ],
            };
            try {
                return columns[this.formType];
            } catch {
                return [];
            }
            
        },
    },
    data: function() {
        return {
            editedReference: null,
        };
    },
    methods: {
        reloadReferences() {
            this.editedReference = null;
            this.$emit("reload-references");
        },
        addReference() {
            // this.$emit('add-reference');
            this.editedReference = {
                differencesBetweenDraftAndPublished: [],
                bankOfferDraft: {},
                bankOfferPublished: null,
                productionDraf: {},
                productionPublished: null,
            };
        },
        editReference(pk) {
            try {
                this.editedReference = this.references.filter(x => x.pk === pk)[0];
            } catch {
                this.editedReference = null;
            }
            // this.$emit('edit-reference', pk);
        },
        formatDate(date) {
            return utils.formatDateTime(date);
        },
        getValue(column, row) {
            if (!column) {
                return '-';
            }
            if (column.field.startsWith(".")) {
                const field = column.field.slice(1);
                let production = null;
                if (this.formType === 'generic') {
                    production = row.productionPublished ? row.productionPublished : row.productionDraft;
                } else if (this.formType === 'bank') {
                    production = row.bankOfferPublished ? row.bankOfferPublished : row.bankOfferDraft;
                } else {
                    return 'TBD';
                }

                if (field === 'updatedAt') {
                    return this.formatDate(production[field]);
                }
                return production[field];
            }
            return row[column.field];
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

.reference-edit-panel
    margin-top: space-xxl

.validation-pending
    :deep(.success-icon)
        color: warning-color
</style>
