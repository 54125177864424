/*
    Autogenerated class model from django Energy
    -- 2020-02-10 10:50:39.384549
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';
import { loadThenStoreByIdentifier } from './_helpers';

class EnergyClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'identifier', rawData.identifier);
        Vue.set(this, 'primaryEnergyRatio', rawData.primaryEnergyRatio);
        Vue.set(this, 'ghgRatio', rawData.ghgRatio);
        Vue.set(this, 'carbonTax', rawData.carbonTax);
        Vue.set(this, 'pciRatio', rawData.pciRatio);
        Vue.set(this, 'densityRatio', rawData.densityRatio);
        Vue.set(this, 'combustibleCategory', rawData.combustibleCategory);
        Vue.set(this, 'priceVariation', rawData.priceVariation);
        Vue.set(this, 'priceMultiUnitDiscount', rawData.priceMultiUnitDiscount);

        Vue.set(this, 'currentPrice', rawData.currentPrice);
        Vue.set(this, 'currentPriceMultiUnit', rawData.currentPriceMultiUnit);
    }

    getBaseUrl() {
        return '/energies/energy/';
    }

    get isValid() {
        return true;
    }
}

class EnergyCollection extends BaseCollection {
    initModel(rawData) {
        return new EnergyClass(rawData);
    }

    getBaseUrl() {
        return '/energies/energy/';
    }
}

class EnergyStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new EnergyClass(rawData);
    }

    getBaseUrl() {
        return '/energies/energy/';
    }

    getStorePath() {
        return 'energies/energyCollection';
    }

    load(filter = null) {
        return loadThenStoreByIdentifier(this, super.load, 'identifier', filter);
    }
}

export {
    EnergyClass,
    EnergyCollection,
    EnergyStoreCollection,
};
