<template>
  <div class="menu-context">
    <template v-for="category in menuCategories">
      <div :key="category.label + '-label'" class="menu-category">{{ category.label }}</div>
      <span v-for="option in category.options" :key="getKeyFromLabel(option.label)">
        <router-link
          v-if="!option.hasOwnProperty('options')"
          :to="(typeof option.route === 'function') ? option.route() : option.route"
        >
          <div
            class="grid-x align-top menu-item"
            @click="itemClicked"
            :class="{ 'menu-item-active': isActiveMenuItem(option) }"
          >
            <span class="menu-item-icon cell shrink">
              <font-awesome-icon :icon="option.icon" />
            </span>
            <span class="cell auto">
              <div class="text-bold menu-item-label">{{ getRealLabel(option.label) }}</div>
              <div class="menu-item-description">{{ getRealLabel(option.description) }}</div>
            </span>
          </div>
        </router-link>
        <Accordion class="submenu" v-else>
          <AccordionItem :lineHeight="40" :align-top="true">
            <div class="grid-x align-top" slot="title">
              <span class="menu-item-icon cell shrink">
                <font-awesome-icon :icon="option.icon" />
              </span>
              <span class="cell auto">
                <div class="text-bold menu-item-label">{{ getRealLabel(option.label) }}</div>
                <div class="menu-item-description">{{ getRealLabel(option.description) }}</div>
              </span>
            </div>
            <div slot="content">
              <router-link
                v-for="suboption in option.options"
                v-show="!suboption.hasOwnProperty('hasPermission') || (suboption.hasOwnProperty('hasPermission') && suboption.hasPermission())"
                :key="getRealLabel(suboption.label)"
                :to="(typeof suboption.route === 'function') ? suboption.route() : suboption.route"
              >
                <div
                  class="grid-x align-top menu-item"
                 :class="{ 'menu-item-active': isActiveMenuItem(suboption) }"
                  @click="itemClicked"
                >
                  <span class="cell auto grid-y">
                    <span class="menu-item-label">
                      <font-awesome-icon v-if="suboption.icon" :icon="suboption.icon" />
                      {{ getRealLabel(suboption.label) }}
                    </span>
                  </span>
                </div>
              </router-link>
            </div>
          </AccordionItem>
        </Accordion>
      </span>
    </template>
  </div>
</template>

<script>
import Accordion from '@/components/accordion';
import AccordionItem from '@/components/accordion_item';

export default {
    name: 'MenuContent',
    components: {
        Accordion,
        AccordionItem,
    },
    data() {
        return {
            accordionExpanded: '',
        };
    },
    props: {
        menuCategories: {
            type: Array,
            default: () => [],
        },
    },
    computed: {},
    watch: {},
    created: function() {},
    destroyed: function() {},
    methods: {
        getRealLabel: function(label) {
            if (typeof label === 'string') {
                return label;
            } else if (typeof label === 'function') {
                return label();
            }
        },
        getKeyFromLabel: function(label) {
            if (typeof label === 'string') {
                return label.replace(' ', '');
            } else if (typeof label === 'function') {
                return label().replace(' ', '');
            }
        },
        isActiveMenuItem(item) {
            return item.matchingRoutes.includes(this.$route.name);
        },
        itemClicked() {
            this.$emit('item-click');
        },
    },
};
</script>

<style lang="stylus">
@require '../stylesheet/typography'

.menu-context
    padding-bottom: 1rem

    .accordion-item
        a
            display: inline-block
            width: 100%

    .menu-item
        padding: 0.5rem 1rem

    .menu-category
        padding: 1rem 1rem 0.5rem 1rem

    .submenu .accordion-item
        padding: 0.5rem 1rem
        cursor: pointer

.menu-item-icon
.menu-item-icon svg
    max-width: 25px
    min-width: 25px
    margin-right: 0.5rem

.menu-item-active
    background: primary-color-100
    outline: none
</style>
