<template>    
    <div class="content-white" >
        <header class="header">
            <div class="grid-x grid-margin-x">
                <div class="cell small-10">
                    <i class="ico_pie69 icon-logo-pie69 logo"></i>
                    <span class="global-title">Professionnels réno performante 69<br>
                    <span class="title">Votre annuaire des professionnels du bâtiment</span></span>
                </div>
                <div class="cell small-2">
                    <a @click="hideModal"><font-awesome-icon class="close-modal" :icon="['fal', 'times-circle']" /></a>
                </div>
            </div>
        </header>
        <layout-container>
            <div class="grid-x grid-padding-x">
                <div class="cell">
                    <h2 class="question">Quelle mission est adaptée à mon projet ?</h2>
                </div>
            </div>
        </layout-container>
        <layout-container class="content-gray" v-if="!displayHelpMission">
            <div class="grid-x grid-padding-x">
              <div class="cell line">

                <div class="indicator">Vous avez besoin d'aide pour : </div>
                <div>
                  <action-link
                    label="Présentation de l'annuaire"
                    icon="angle-right"
                    icon-position="left"
                    color="text-color"
                    @click="showModalHelpUser"
                    ></action-link>
                </div>
                
                <div>
                  <action-link
                    label="Connaître les acteurs du bâtiment"
                    icon="angle-right"
                    icon-position="left"
                    color="text-color"
                    @click="downloadKnowActors"
                    ></action-link>
                </div>
              
                <div>
                  <action-link
                    label="Comprendre les missions"
                    icon="angle-right"
                    icon-position="left"
                    color="text-color"
                    @click="downloadHelpMissions"
                    ></action-link>
                </div>

              </div>
            </div>
        </layout-container>
        <layout-container v-if="displayHelpMission" class="content-gray" >
            <div class="grid-x grid-padding-x">
                <div class="cell mission-container" v-for="item in missions.asList()" :key="item.id">
                    <h2 class="mission-title">{{item.name}}</h2>
                    <div v-html="item.helpText" class="help"/> 
                    <div class="grid-y">
                        <div class="grid-x grid-padding-x">
                            <div class="cell small-3 sub-mission-container" v-for="itemSM in filterSubMissions(item, false)" :key="itemSM.id">
                                <div class="sub-mission-title">{{itemSM.name}}</div>
                                <div v-html="itemSM.helpText" class="help"/> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <regular-button class="button-close" @click="hideModal">
                Fermer
            </regular-button>
        </layout-container>
        <DialogWrapper
          ref="modal"
          :width="1000"
        >   
          <div> 
            <help-user-and-pro
              @hide-modal="hideModalHelpUser"
              helpType="user"
            >
            </help-user-and-pro>
          </div>
        </DialogWrapper> 
    </div>
</template>

<script>

// import searchMixin from '@/apps/listepro/search/search_mixin';

import ActionLink from '@/components/action_link';
import RegularButton from '@/components/regular_button';

import HelpUserAndPro from '@/apps/listepro/modal_help/help_user_and_pro';


export default {
    name: 'ModalHelpSearch',
    // mixins: [ searchMixin ],

    data() {
        return {
            displayHelpMission : false,
        };
    },
    created: function() {},
    mounted: function() {
    },
    methods: {
        hideModal() {
            this.$emit('hide-modal');
            this.displayHelpMission=false;
        },
        downloadKnowActors() {
            // Fiche "les acteurs et les contrats dans le batiments.pdf"
            window.open(this.$store.state.whiteLabelling.listeproHelpActorsUrl);
        },
        downloadHelpMissions() {
            // Fiche "Aide au choix des missions proposées"
            window.open(this.$store.state.whiteLabelling.listeproHelpMissionsUrl);
        },
        showModalHelpUser() {
            this.$refs.modal.show();
        },
        hideModalHelpUser() {
            this.$refs.modal.hide();    
        },
    },
    components: {
        ActionLink,
        RegularButton,
        HelpUserAndPro,
    },
    computed: {
    },
    props: {
    },
};


</script>
<style lang='stylus' scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/typography'
@require '../../../stylesheet/listepro_font'

.header
    padding: 1.5rem 0.5rem 1.5rem 0.5rem
    background-color: primary-color-500
    margin-bottom: 1rem

.close-modal
    color: #fff
    float: right
    margin-right: 1rem
    font-size: 1.5rem

.logo
    font-size: 6rem
    padding: 0 20px 0 0
    vertical-align: middle
    color: #fff
    margin-left: 1rem

.logo-help
    font-size: 2.5rem

.line:hover
    color: primary-color-300

.global-title
    text-transform: uppercase
    font-size: 2.2rem
    line-height: 0.7
    font-weight: 700
    color: #fff
    margin-bottom: -15px
    vertical-align: middle
    display: inline-block

.title
    font-size: 1.25rem
    font-weight: normal
    text-transform: uppercase

.indicator
    font-size: 2rem
    font-weight: normal
    text-transform: uppercase
    color: primary-color-500
    padding-bottom: 1rem
    padding-top: 1rem

.helpers
    font-size: 1.4rem
    vertical-align: middle
    padding-bottom: 2rem

.question
    font-weight: bold
    font-size: 1.4rem

.mission-container
    margin: 1rem
    border: 3px solid primary-color-500
    border-radius: 10px
    width: 97%

.mission-title
    text-transform: uppercase
    font-weight: bold
    font-size: 1.4rem
    text-align: center

.sub-mission-title
    font-size: 1.125rem
    font-weight: 400
    line-height: 1
    width: 100%
    margin: 0 0 2rem
    padding: 0.9rem 1.2rem
    text-align: center
    vertical-align: middle
    text-transform: uppercase
    color: #fff
    background-color: primary-color-500
    border-radius: 3px

.help
    margin-bottom: 1rem

.icon-help
    font-size: 3rem

.content-gray
    background-color: gray-100
    margin-bottom: space-xl

.content-white
    background-color: white

.button-close
    float: right
    margin-top: 2rem
</style>
