import { store } from '@/store/store';

const AccountLogin = () => import(/* webpackChunkName: "accounts_login" */ '@/apps/accounts/account_login');
const AccountProfile = () => import(/* webpackChunkName: "accounts" */ '@/apps/accounts/account_profile');
const AccountReset = () => import(/* webpackChunkName: "accounts_password" */ '@/apps/accounts/account_reset');
const AccountResetPasswordChange = () =>
    import(/* webpackChunkName: "accounts_password" */ '@/apps/accounts/account_reset_password_change');
const AccountPasswordChange = () =>
    import(/* webpackChunkName: "accounts_password" */ '@/apps/accounts/account_password_change');
const AccountDelete = () => import(/* webpackChunkName: "accounts" */ '@/apps/accounts/account_delete');

const routes = [
    {
        path: '/mon-compte/profil-admin',
        name: 'AccountProfileAdmin',
        component: AccountProfile,
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator'],
        },
    },
    {
        path: '/mon-compte/profil-advisor',
        name: 'AccountProfileAdvisor',
        component: AccountProfile,
        meta: {
            requiresAuth: true,
            requiresUserType: ['advisor', 'superadvisor', 'manager'],
        },
    },
    {
        path: '/mon-compte/profil-manager',
        name: 'AccountProfileManager',
        component: AccountProfile,
        meta: {
            requiresAuth: true,
            requiresUserType: ['manager'],
        },
    },
    {
        path: '/mon-compte/connection/:startEmail',
        name: 'AccountLoginWithEmailOld',
        props: true,
        redirect: to => {
            return { path: `/mon-compte/connexion/${to.params.startEmail}` };
        },
    },
    {
        path: '/mon-compte/connexion/:startEmail',
        name: 'AccountLoginWithEmail',
        component: AccountLogin,
        props: true,
    },
    {
        path: '/mon-compte/connexion',
        name: 'AccountLogin',
        component: AccountLogin,
        meta: {
            guest: true,
        },
        beforeEnter(to, from, next) {
            if (!store.state.user) {
                next();
            } else {
                next({ name: 'Homepage' });
            }
        },
    },
    {
        path: '/mon-compte/connection-and-go/:nextUrl',
        name: 'AccountLoginAndNextOld',
        redirect: to => {
            return { path: `/mon-compte/connexion-et-redirection/${to.params.nextUrel}` };
        },
    },
    {
        path: '/mon-compte/connexion-et-redirection/:nextUrl',
        name: 'AccountLoginAndNext',
        component: AccountLogin,
        props: true,
        meta: {
            guest: true,
        },
    },
    {
        path: '/mon-compte/profil',
        name: 'AccountProfile',
        component: AccountProfile,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/mon-compte/reinitialisation-mot-de-passe/:startEmail',
        name: 'AccountResetPassword',
        component: AccountReset,
        props: true,
        meta: {
            guest: true,
        },
    },
    {
        path: '/mon-compte/reinitialisation-mot-de-passe-changement::uidb64::token',
        name: 'AccountResetPasswordChange',
        component: AccountResetPasswordChange,
        props: true,
        meta: {
            guest: true,
        },
    },
    {
        path: '/mon-compte/changement-de-mot-de-passe',
        name: 'AccountPasswordChange',
        component: AccountPasswordChange,
        props: true,
        meta: {
            guest: true,
        },
    },
    {
        path: '/mon-compte/supprimer',
        name: 'AccountDelete',
        component: AccountDelete,
        meta: {
            requiresAuth: true,
        },
    },
];

export default routes;
