import _ from 'lodash';
import initProductionSystemDefWatchers from './watchers.js';

export default {

    reset({ commit }) {
        commit('RESET_STATE');
    },

    create({ commit, getters, state }, properties) {
        return new Promise((resolve, reject) => {
            const def = Object.assign({}, state.emptyDef, properties || {});
            if (!def.id) def.id = getters.nextId;
            commit('CREATE', def);
            initProductionSystemDefWatchers(def.id);
            resolve(def.id);
        });
    },

    createCouple({ dispatch, commit }, properties) {
        return new Promise((resolve, reject) => {
            dispatch('create', { share: 1, ...properties[0] }).then((id1) => {
                dispatch('create', { share: 0, firstSiblingId: id1, ...properties[1] }).then((id2) => {
                    commit('UPDATE', { defId: id1, values: { secondSiblingId: id2 } });
                    resolve([id1, id2]);
                });
            });
        });
    },

    createCouplesSet({ dispatch, getters, commit }, properties = {}) {
        return new Promise((resolve, reject) => {
            const group = getters.nextGroupId;
            dispatch('createCouple', [
                { type: 'heating', group: group, ...properties },
                { type: 'heating', group: group, ...properties },
            ]).then((heatingIds) => {
                dispatch('createCouple', [
                    { type: 'hot_water', syncedId: heatingIds[0], group: group, ...properties },
                    { type: 'hot_water', syncedId: heatingIds[1], group: group, ...properties },
                ]).then((hotWaterIds) => {
                    commit('UPDATE', { defId: heatingIds[0], values: { syncedHotWaterId: hotWaterIds[0] } });
                    commit('UPDATE', { defId: heatingIds[1], values: { syncedHotWaterId: hotWaterIds[1] } });
                    resolve({ heatingIds, hotWaterIds });
                });
            });
        });
    },

    refreshSystemDefs({ state, dispatch }) {
        for (const defId in state.defs) {
            dispatch('ensureProductionSystemPossible', defId);
            dispatch('resetSystemInitialValues', defId);
        }
    },

    resetSystemInitialValues({ commit, getters, rootState }, defId) {
        let values = getters.productionSystemInitialDataValues(defId);
        commit('UPDATE_INITIAL_VALUES', { defId, values });

        // As this is a reset, we also erase fields values with initial values
        // Except for energy price which is take from user defined energy prices if exists
        values = {
            ...values,
            ...getters.userEnergyPrice(defId) || {},
        };
        commit('UPDATE', { defId, values });
    },

    ensureDefaultSystemInitialValues({ commit, state }, defId) {
        const def = state.defs[defId];
        const values = {};
        for (const prop in def.initialValues) {
            const zeroAsEmptyField = (prop === 'efficiency' || prop === 'energyPriceVariation') && !def[prop];
            if (!_.isNumber(def[prop]) || zeroAsEmptyField) {
                values[prop] = def.initialValues[prop];
            }
        }
        commit('UPDATE', { defId, values });
    },

    ensureProductionSystemPossible({ state, commit, rootState, rootGetters, getters }, defId) {
        const def = state.defs[defId];

        // Do nothing if already no identifier set
        if (!def.identifier) return;

        const excludedChoices = _.clone(def.excludedChoices);
        if (!rootGetters['thermix/needs/isManagingHotWater'] || !rootGetters['thermix/needs/isManagingHeating']) {
            excludedChoices.push('solar_system_combined');
        }

        const productionSystems = rootGetters['energies/possibleProductionSystems'](rootState.thermix.needs.housingCategory, def.type, def.limitedChoices, excludedChoices);
        const productionSystem = getters.productionSystem(defId);

        // Do nothing if system possible
        if (productionSystem && productionSystems.some((ps) => ps.identifier === productionSystem.identifier)) return;

        commit('UPDATE', { defId, values: { identifier: '', locked: false } });
    },

    syncUserEnergyPrice({ commit, getters, rootState }, defId) {
        const userEnergyPrice = getters.userEnergyPrice(defId);
        if (!userEnergyPrice) return;
        commit('UPDATE', { defId, values: userEnergyPrice });
    },

    syncUserEnergyPriceForEnergyIdentifier({ state, dispatch, getters }, { group, energyIdentifier, greenEnergyIdentifier }) {
        for (const defId in state.defs) {
            if (state.defs[defId].group !== group) continue;
            const productionSystem = getters.productionSystem(defId);
            if (!productionSystem) continue;

            if (
                productionSystem.energyIdentifier === energyIdentifier ||
                (productionSystem.greenEnergyIdentifier &&
                    productionSystem.greenEnergyIdentifier === (greenEnergyIdentifier || energyIdentifier))
            ) {
                dispatch('syncUserEnergyPrice', defId);
            }
        }
    },

};
