<template>
<div class="trigger-buttons">
  <regular-button
    :disabled="disabled"
    v-for="(desc, key) of filteredTriggers"
    :key="key"
    @click="clicked(key)"
    >
    {{ desc["label"] }}
  </regular-button>

  <mail-edit
    v-if="pk"
    ref="mailEditor"
    :object-type="objectType"
    :pk="pk"
    v-on:validated-email="validatedEmail"
    v-on:validated-without-email="validatedWithoutEmail"
    />
  
</div>
</template>

<script>
import { userMixin } from '@/mixins/user';
  
import MailEdit from '@/apps/listepro/professional_form/mail_edit';
import RegularButton from '@/components/regular_button';
  
export default {
    name: 'TriggerButtons',
    mixins: [userMixin],
    components: {
        MailEdit,
        RegularButton,
    },
    props: {
        objectType: { type: String, required: true },
        pk: { type: [String, Number], required: false },
        triggers: { default: () => {
            return {
                fill: {
                    label: "Remplir",
                    roles: ["professional", "listeproadmin", "advisor", "superadvisor"],
                    sendEditableEmailToProfessional: false,
                },
            };
        }, type: Object },
        disabled: { default: false, type: Boolean },
    },
    data: function() {
        return {
        };
    },
    computed: {
        filteredTriggers() {
            const filtered = {};
            if (this.triggers) {
                for (const [key, value] of Object.entries(this.triggers)) {
                    if (this.showForUser(value.roles)) {
                        filtered[key] = this.triggers[key];
                    }
                }
            }
            return filtered;
        },
    },
    methods: {
        clicked(key) {
            if (this.triggers[key].sendEditableEmailToProfessional) {
                this.displayEmailTemplate(key);
            } else {
                this.$emit("trigger-clicked", key);
            }
        },
        
        displayEmailTemplate(key) {
            this.$refs.mailEditor.load(key);
        },
        
        validatedEmail(message) {
            this.$emit("trigger-clicked-with-message", message);
        },

        validatedWithoutEmail(key) {
            this.$emit("trigger-clicked-without-message", key);
        },
        
        showForUser(roles) {
            if (this.userUtilities.isListeProAdmin) {
                return roles.includes("listeproadmin");
            }
            if (this.userUtilities.isAdvisor) {
                return roles.includes("advisor");
            }
            if (this.userUtilities.isSuperAdvisor) {
                return roles.includes("superadvisor");
            }
            if (this.userUtilities.isProfessional) {
                return roles.includes("professional");
            }
            return false;
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

.trigger-buttons
    margin-top: space-xl

.regular-button
    display: block
</style>
