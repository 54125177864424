<template>
<div class="additional-addresses">
  <div v-if="professional.canUseMultipleAddresses">
    <h2 class="title">Addresses supplémentaires</h2>
      
    <div class="grid-x grid-margin-x">
      <div class="cell medium-6 ">
        Ces adresse apparaîtront sur la carte.
      </div>
      <div class="cell medium-6 text-right" v-if="canModify">
        <action-link
          class="action text-right"
          label="Ajouter une adresse"
          icon="plus"
          @click="addAdditionalAddress"
          />
      </div>
    </div>
    <mixeur-table
      class="additional-addresses-table"
      :columns="additionalAddressesColumns"
      :rows="professional.additionalAddresses"
      :sort-options="{ enabled: true, initialSortBy: {field: 'name', type: 'asc'} }"
      :search-options="{ enabled: true }"
      >

      <template v-slot:table-row="props">
        <span v-if="props.column.field == 'after'">
          <action-link label="Editer" icon="edit" icon-position="left" v-on:click="editAdditionalAddress(props.row.pk)"/>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

    </mixeur-table>
  </div>
  <DialogWrapper
    ref="modal"
    width="100%"
    :maxWidth="1000"
  >
    <div class="address-modal-content">
      <div style="padding:1em">
        <spinner
          :active="isLoading"
          />
        
        <h2 class="title">Addresse supplémentaire</h2>
        
        <text-field
          id="name"
          label="Nom"
          mandatory
          v-model="localAddress.name"
          :isError="formErrors.name"
          validateRules="required"
          :scope="'localAddress'"
        />
        <text-field
          id="email"
          label="Couriel"
          v-model="localAddress.email"
          :isError="formErrors.email"
          validateRules="required"
          :scope="'localAddress'"
        />
        <text-field
          id="url"
          label="Url"
          v-model="localAddress.url"
          :isError="formErrors.url"
          validateRules="required"
          :scope="'localAddress'"
          />
        <text-field
          id="phone"
          label="Téléphone"
          v-model="localAddress.phoneNumber"
          :isError="formErrors.phoneNumber"
          validateRules="required"
          :scope="'localAddress'"
          />
        <address-field
          id="address"
          v-model="localAddress.address"
          label="Adresse"
          :mandatory=true
          :forceSelectOnBlur=true
          :isError="formErrors.address"
          @city="localAddress.town = $event"
          @position="localAddress.geom = $event"
          @postcode="localAddress.postcode = $event"
          validateRules="required"
          :scope="'localAddress'"
        />

        <div class="grid-x grid-margin-x">
          <div class="cell medium-4 ">
            Code postal : <span class="value" v-html="localAddress.postcode" />
          </div>
          <div class="cell medium-4 ">
            Ville : <span class="value" v-html="localAddress.town" />
          </div>
          <div class="cell medium-4 ">
            Lat : <span class="value" v-html="localAddress.geom.lat" /> / Lon : <span class="value" v-html="localAddress.geom.lon" />
          </div>
        </div>

        <form-buttons
          class="additional-address-buttons"
          @primaryAction="additionalAddressSave"
          @secondaryAction="additionalAddressDelete"
          @tertiaryAction="additionalAddressCancel"
          :disableSecondary="disableDelete"
          >
        </form-buttons>
        
      </div>
    </div>
  </DialogWrapper>

</div>
</template>

<script>
import ActionLink from '@/components/action_link';
import MixeurTable from '@/components/mixeur_table';

import AddressField from '@/components/address_field';
import FormButtons from '@/components/form_buttons';
import Spinner from '@/components/spinner';
import TextField from '@/components/text_field';

import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';
import confirmActionMixin from '@/mixins/confirm_action_mixin';

import { ProfessionalAddressClass } from '@/models/listepro/professional_address.js';

export default {
    name: 'AdditionalAddresses',
    provide() {
        return {
            formErrors: this.formErrors,
            formErrorsMessages: this.formErrorsMessages,
        };
    },
    inject: ['formErrors', 'formErrorsMessages', 'reloadProfessional'],
    mixins: [handleFormErrorsMixin, confirmActionMixin],
    components: {
        ActionLink,
        AddressField,
        FormButtons,
        MixeurTable,
        Spinner,
        TextField,
    },
    props: {
        professional: Object,
        canModify: {
            required: false,
            validator: prop => typeof prop === 'boolean',
            default: false,
        },

    },
    data: function() {
        return {
            isLoading: false,
            localAddress: new ProfessionalAddressClass(),
        };
    },
    computed: {
        additionalAddressesColumns() {
            const additionalAddressesColumns = [
                {
                    label: 'Nom',
                    field: 'name',
                    sortable: true,
                },
                {
                    label: 'Couriel',
                    field: 'email',
                    sortable: true,
                },
                {
                    label: 'Url',
                    field: 'url',
                    sortable: true,
                },
                {
                    label: 'Adresse',
                    field: 'address',
                    sortable: true,
                },
                {
                    label: 'Ville',
                    field: 'town',
                    sortable: true,
                },
            ];
            if (this.canModify) {
                additionalAddressesColumns.push(
                    {
                        label: 'Actions',
                        field: 'after',
                        sortable: false,
                    }
                );
            }
            return additionalAddressesColumns;
        },
        disableDelete() {
            return this.localAddress.pk == null;
        },
        
    },
    watch: {
    },
    created: function() {
    },
    mounted: function() {
    },
    methods: {
        addAdditionalAddress() {
            this.resetErrorFields();
            this.initLocalAddress();
            this.$refs.modal.show();
        },
        editAdditionalAddress(id) {
            this.resetErrorFields();
            this.initLocalAddress(id);
            this.$refs.modal.show();
        },
        initLocalAddress(id) {
            if (id === undefined) {
                this.localAddress = new ProfessionalAddressClass();
                this.localAddress.professional = this.professional.pk;
            } else {
                this.isLoading = true;
                this.localAddress = new ProfessionalAddressClass();
                this.localAddress.load(id)
                    .then(() => {
                        this.isLoading = false;
                    })
                    .catch(error => {
                        this.isLoading = false;
                        alert(error);
                    });
            }
        },
        hideModalAdditionalAddress() {
            this.$refs.modal.hide();
        },
        additionalAddressSave() {
            this.resetErrorFields();
            this.isLoading = true;
            this.localAddress.professional = this.professional.pk;
            this.localAddress.save()
                .then(localAddressSaved => {
                    this.reloadProfessional();
                    this.isLoading = false;
                    this.hideModalAdditionalAddress();
                })
                .catch(error => {
                    this.handleFormErrors(error);
                    this.isLoading = false;
                });
        },
        additionalAddressDelete() {
            this.confirmAction("supprimer l'adresse", this.localAddress.name, () =>
                this.localAddress.delete()
                    .then(() => {
                        this.reloadProfessional();
                        this.hideModalAdditionalAddress();
                    })
                    .catch(error => {
                        this.handleFormErrors(error);
                        this.isLoading = false;
                    })
            );
                
        },
        additionalAddressCancel() {
            this.hideModalAdditionalAddress();
        },

    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

.additional-address-buttons
    margin-top: space-xl

.title
    text-transform: uppercase
    font-weight: bold
    margin-top: 3rem
    margin-bottom: 2rem

.value
    color: black
</style>
