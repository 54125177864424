import { computed } from 'vue';

import { useDialogwattStore } from '@/stores/dialogwatt';

export const schedulerMixin = {
    setup() {
        const store = useDialogwattStore();

        return {
            reasons: computed(() => store.reasons),
            reasonsIsLoading: computed(() => store.reasonsIsLoading),
            getReasonFromStore(pk) {
                return store.reasons.get(pk);
            },
            getCatchmentAreaFromStore(pk) {
                return store.catchmentAreas.get(pk);
            },
        };
    },

    methods: {
        getAdvisorFromStore(pk) {
            return this.advisorsAsList.find((x) => { return pk === x.pk; });
        },
        getAppointmentFromStore(pk) {
            return this.appointments.find((x) => { return pk === x.pk; });
        },
    },
};
