<template>
<div class="news">
  <layout-container class="main-section" grey>

    <section-title class="title">Les actualités des professionnels de la rénovation</section-title>
    
    <div class="grid-x align-right">
      <action-link label="Retour à l'annuaire" icon="undo" icon-position="left" v-on:click="goBack()" />
    </div>
    
    <iframe id="inlineFrameExample"
            title="Inline Frame Example"
            width="100%"
            height="1000"
            :src="url">
    </iframe>

    <div class="grid-x align-right">
      <action-link label="Retour à l'annuaire" icon="undo" icon-position="left" v-on:click="goBack()" />
    </div>
    
  </layout-container>
  </div>
</template>

<script>
import ActionLink from '@/components/action_link';
import SectionTitle from '@/components/section_title';

import { userMixin } from '@/mixins/user';
        
export default {
    name: 'News',
    mixins: [userMixin],
    components: {
        ActionLink,
        SectionTitle,
    },
    computed: {
        url() {
            return this.$store.state.whiteLabelling.listeproNewsUrl;
        },
    },
    methods: {
        goBack() {
            if (this.userUtilities.isProfessional) {
                this.$router.push({ name: "ProfessionalHomepage"});
            }
            else if (this.userUtilities.isAdvisor) {
                this.$router.push({ name: "ListeProAdminProfessionalList"});
            }
            else {
                this.$router.push({ name: "ListeProPublicSearch"});
            };
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

iframe
    border: none
    margin-top: space-md
    margin-bottom: space-md

.close
    font-size: 2rem
</style>
