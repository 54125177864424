import { store } from '@/store/store';

const api = {
    userList() {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get('/accounts/user-with-group/')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    userSearch(query, userType = null, everywhere = false, withHousing = false) {
        let url = `/accounts/user/?q=${query}`;
        if (everywhere) {
            url += '&everywhere=t';
        }
        if (withHousing) {
            url += '&with_housing=t';
        }
        if (userType) {
            url += `&user_type=${userType}`;
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    userDetail(pk, withHousing = false) {
        let url = `/accounts/user/${pk}/`;
        if (withHousing) {
            url += '?with_housing=t';
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    userDelete(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .delete(`/accounts/user/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    userUpdate(data) {
        const pk = data.pk;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`/accounts/user/${pk}/`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error.response.data.error ? error.response.data.error : error);
                });
        });
    },
    userCreate(data) {
        data.pk = null;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/accounts/user/', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    userUpdateProfilePic(data) {
        const pk = data.pk;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`/accounts/user-profile-pic/${pk}/`, data)
                .then(response => {
                    store.commit('updateProfilePic', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
