const api = {
    async appointmentList(forAWeekFrom) {
        let url = '/dialogwatt/appointment/';
        if (forAWeekFrom !== undefined) {
            url = `/dialogwatt/appointment/?for_a_week_from=${forAWeekFrom}`;
        }

        try {
            const { data } = await this.apiClient().get(url);
            return data;
        } catch (error) {
            console.debug(error);
            throw error;
        }
    },
    appointmentDetail(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/dialogwatt/appointment/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    appointmentDelete(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .delete(`/dialogwatt/appointment/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    appointmentSave(data) {
        const pk = data.pk;
        if (pk === null) {
            return new Promise((resolve, reject) => {
                this.appointmentCreate(data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        } else {
            return new Promise((resolve, reject) => {
                this.appointmentUpdate(data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    },
    appointmentUpdate(data) {
        const pk = data.pk;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`/dialogwatt/appointment/${pk}/`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    appointmentCreate(data) {
        data.pk = null;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/dialogwatt/appointment/', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
