
function getRouteParameter(pkToParse, routeParams) {
    let pk = Number.parseInt(pkToParse, 10);
    if (Number.isNaN(pk)) {
        pk = -1;
    }
    return Object.assign(routeParams || {}, { pk });
}

const routes = [
    {
        path: '/rgpd/:pk',
        name: 'RgpdEdit',
        component: () => import('@/apps/rgpd_customization/rgpd_edit'),
        props({ params }) {
            return getRouteParameter(params.pk);
        },
        meta: {
            requiresAuth: true,
            requiresUserType: ['administrator', 'manager', 'advisor', 'superadvisor'],
        },
    },
    {
        path: '/traitement-des-donnees/:pk',
        name: 'RgpdPublicView',
        props: true,
        component: () => import('@/apps/rgpd_customization/rgpd_public_view'),
        meta: {
            requiresAuth: true,
        },
    },
];

export default routes;
