/*
    Autogenerated class model from django
    -- 2022-08-29 09:19:12.328895
*/

import { make } from 'vuex-pathify';

const defaultState = {
    isLoading: 0,
    expressionOfInterestCollection: {},
    expressionOfInterestCollectionFilter: null,
    expressionOfInterestCollectionLastUpdate: 0,
    projectCollection: {},
    projectCollectionFilter: null,
    projectCollectionLastUpdate: 0,
    registeredProfessionalCollection: {},
    registeredProfessionalCollectionFilter: null,
    registeredProfessionalCollectionLastUpdate: 0,

};

const state = Object.assign({}, (defaultState));
const getters = make.getters(state);

const mutations = {
    ...make.mutations(state),

    resetState(state) {
        Object.assign(state, defaultState);
    },

    incrementLoading(state) {
        state.isLoading++;
    },

    decrementLoading(state) {
        state.isLoading--;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
