<template>
<DialogWrapper
  ref="mailEditModal"
  :min-width="1000"
  :min-height="800"
  :scrollable="true"
  :adaptive="true"
  :resizable="true"
  >
  <div class="modal-content-edit">
    <font-awesome-icon icon="edit" class="help-icon" />
    <h2>Edition d'un courriel</h2>
    
    <div class="mail-edit">
      <spinner
        :active="isLoading"
        />

      <text-field
        v-model="mailSubject"
        label="Sujet"
        />
      
      <wysiwyg-field
        v-model="mailContent"
        label="Courriel"
        XvalidateRules="required|max:50000"
        mandatory
        :isError="formErrors.mailContent"
        :custom-error="formErrorsMessages.mailContent"
        :allowRawEdit="false"
        class="mail-content"
        />

      <form-buttons
        class="buttons"
        @primaryAction="save"
        @secondaryAction="saveWithoutSendingMail"
        @tertiaryAction="cancel"
        primaryIcon="envelope"
        secondaryIcon="ban"
        tertiaryIcon="angle-left"
        >
        <template v-slot:primary>
          Envoyer
        </template>
        <template v-slot:secondary>
          Enregistrer sans envoyer le mail
        </template>
      </form-buttons>
      
    </div>
  </div>
</DialogWrapper>
</template>


<script>
import apiClient from '@/api/api';
  
import FormButtons from '@/components/form_buttons';
import Spinner from '@/components/spinner';
import TextField from '@/components/text_field';
import WysiwygField from '@/components/wysiwyg_field';

import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';
        
export default {
    name: 'MailEdit',
    mixins: [handleFormErrorsMixin],
    components: {
        FormButtons,
        Spinner,
        TextField,
        WysiwygField,
    },
    props: {
        objectType: { type: String, required: true },
        pk: { type: [String, Number], required: true },
    },
    data: function() {
        return {
            isLoading: false,
            action: null,
            mailContent: "",
            mailSubject: "",
        };
    },
    methods: {
        load(action) {
            if (!this.pk) {
                return;
            }
            this.resetErrorFields();
            this.isLoading = true;
            const url = `listepro/get-mail-template/?action=${action}&pk=${this.pk}&object=${this.objectType}`;
            apiClient.genericGet(url)
                .then(response => {
                    this.mailContent = response.mailContent;
                    this.mailSubject = response.mailSubject;
                    this.showModal();
                    this.action = action;
                })
                .catch(error => {
                    this.handleFormErrors(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        hideModal() {
            this.$refs.mailEditModal.hide();
        },
        showModal() {
            this.$refs.mailEditModal.show();
        },
        save() {
            this.isLoading = true;
            this.$emit('validated-email', {'action': this.action, 'subject': this.mailSubject, 'content': this.mailContent});
            this.hideModal();
        },
        saveWithoutSendingMail() {
            this.isLoading = true;
            this.$emit('validated-without-email', {'action': this.action});
            this.hideModal();
        },        
        cancel() {
            this.hideModal();
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

.panels-component
    .modal-content-edit
        // min-height: 400px
        margin: space-unit

        .help-icon
            color: primary-color
            float: right
            font-size: 3.5rem

        h3
            color: primary-color
            margin-bottom: space-xl

        .mail-content
            :deep(.editor__content)
                max-height: 25rem

        .mail-edit
            margin-top: space-xl

        .buttons
            margin-bottom: 0
</style>
