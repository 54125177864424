<template>
  <div class="panels-component">
      <nav v-if="withSelectors" class="panel-selectors">
        <span v-for="panel in panels" :key="`tabPanel${panel.name}`">
          <div
            :class="setActiveSelector(panel)"
            @click="panelSelectorClicked(panel, $event)" v-html="panel.name"
            />
            <div v-show="panel.errorCount > 0" class="nb-errors">{{ nbErrors(panel.errorCount) }}</div>
        </span>
      </nav>
      <div class="panels-component-panels">
          <slot />
      </div>
      
      <div class="navigation clearfix">
        <slot name="customNavigationLeft"></slot>
        <button
          type="button"
          :class="{button:true, prev:true, disabled:!displayPrevButton && shouldDisableButtons}"
          @click="prevClicked"
          v-show="displayPrevButton || shouldDisableButtons"
        >
          <slot name="prevButton">
            <font-awesome-icon icon="angle-left" />{{prevButtonLabel}}
          </slot>
        </button>
        <div class="spacer"></div>
        <button
          type="button"
          :class="{button:true, next:true, disabled:!displayNextButton && shouldDisableButtons}"
          @click="nextClicked"
          v-show="displayNextButton || shouldDisableButtons"
        >
          <slot name="nextButton">
            {{nextButtonLabel}}
            <font-awesome-icon icon="angle-right" />
          </slot>
        </button>
        
        <button
          type="button"
          :class="{button:true, next:true, disabled:!displayNextButton && shouldDisableButtons, success:true && endButtonSuccessColor}"
          @click.prevent="endClicked"
          v-show="displayEndButton"
        >
          <slot name="endButton">
            <font-awesome-icon :icon="endButtonIcon" v-if="endButtonIconBefore"/>
            {{endButtonLabel}}
            <font-awesome-icon :icon="endButtonIcon" v-if="!endButtonIconBefore"/>
          </slot>
        </button>
        <slot name="customNavigationRight"></slot>
      </div>
  </div>
</template>

<script>
import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';

export default {
    name: 'Panels',
    mixins: [handleFormErrorsMixin],
    components: {},
    props: {
        // TODO: To delete ?
        cacheLifetime: {
            default: 5,
        },
        // TODO: To delete ?
        options: {
            type: Object,
            required: false,
            default: () => ({
                useUrlFragment: true,
                defaultPanelHash: null,
            }),
        },        
        withNavigationButtons: { type: Boolean, default: true },
        nextButtonLabel: { type: String, default: "Suivant"},
        endButtonLabel: { type: String, default: "Terminé"},
        endButtonIcon: { type: String, default: "check"},
        endButtonIconBefore: { type: Boolean, default: true},
        endButtonSuccessColor: { type: Boolean, default: true},
        prevButtonLabel: { type: String, default: "Précédent"},
        withEndButton: { type: Boolean, default: false },
        withSelectors: { type: Boolean, default: false },
        forceActivePanel :{ type: String, default: null },
        navigationButtonsHideMode: {
            type: String,
            default: 'hide',
            validator: value => ['hide', 'disable'].includes(value),
        },
        autoScrollToTop: { type: Boolean, default: true },
        doNotValidatePanel: { type: Boolean, default: false },
    },
    data() {
        return {
            childrens: [],
            panels: [],
            activePanelId: '',
            activePanelIndex: 0,
            lastActivePanelId: '',
        };
    },
    computed: {
        displayPrevButton() {
            let hasActivePanelBefore = false;
            for (let i = 0; i < this.activePanelIndex; i++) {
                if (!this.panels[i].disabled) {
                    hasActivePanelBefore = true;
                    break;
                }
            }
            return this.activePanelIndex > 0 && hasActivePanelBefore && this.withNavigationButtons;
        },
        displayNextButton() {
            let hasActivePanelAfter = false;
            for (let i = this.activePanelIndex + 1; i < this.panels.length; i++) {
                if (!this.panels[i].disabled) {
                    hasActivePanelAfter = true;
                    break; 
                }
            }
            return this.activePanelIndex < this.panels.length - 1 && hasActivePanelAfter && this.withNavigationButtons;
        },
        displayEndButton() {
            return (this.withEndButton && this.activePanelIndex === this.panels.length - 1) || (this.shouldDisableButtons && this.withEndButton) ;
        },
        shouldDisableButtons() {
            return this.navigationButtonsHideMode==='disable';
        },
    },
    watch: {
        childrens: function(values) {
            this.panels = values.filter(children => children.$options.name === 'Panel');
            if (this.panels.length > 0 && this.activePanelId === '') {
                let firstActivePanel = false;
                for (let i = 0; i < this.panels.length; i++) {
                    const panel = this.panels[i];
                    if (!panel.disabled) {
                        firstActivePanel = panel;
                        break;
                    }
                }
                this.selectPanel(firstActivePanel);
            }
        },
        forceActivePanel: function() {
            if(this.forceActivePanel){
                this.selectPanelById(this.forceActivePanel);
            }
        },
        activePanelId: function() {
            try {
                this.$emit("active-panel", this.activePanelId);
            } catch {
            }
        },
    },
    created() {
        this.childrens = this.$children; // HACK: to be able to watch when slots are added dynamically
    },
    mounted() {},
    methods: {
        nbErrors(nbErrors) {
            return nbErrors > 1 ? nbErrors + ' erreurs' : nbErrors + ' erreur';
        },
        prevClicked() {
            if (this.panels.length && this.activePanelIndex > 0) {
                let prevPanel = null;
                for (let i = this.activePanelIndex - 1; i >= 0; i--) {
                    const panel = this.panels[i];
                    if (!this.panels[i].disabled) {
                        prevPanel = panel;
                        break;
                    }
                }
                if (!this.doNotValidatePanel) {
                    this.validateActivePanel().then(valid => {
                        if (valid) {
                            this.$emit('previous', { prevPanel: prevPanel, currentPanel: this.panels[this.activePanelIndex] });
                            this.selectPanel(prevPanel);
                            if (this.autoScrollToTop) this.scrollToTop();
                        }
                    });
                } else {
                    this.$emit('previous', { prevPanel: prevPanel, currentPanel: this.panels[this.activePanelIndex] });
                    this.selectPanel(prevPanel);
                    if (this.autoScrollToTop) this.scrollToTop();
                }
            }
        },
        async nextClicked() {
            if (!this.panels.length) { return; }

            const currentPanel = this.panels[this.activePanelIndex];

            const nextPanel = this.panels.find((panel, index) =>
                index > this.activePanelIndex && !panel.disabled
            );

            if (!this.doNotValidatePanel) {
                if (await this.validateActivePanel()) {
                    this.$emit('next', { nextPanel, currentPanel });
                    
                    if (nextPanel) {
                        this.selectPanel(nextPanel);
                        if (this.autoScrollToTop) this.scrollToTop();
                    }
                }
            } else {
                this.$emit('next', { nextPanel, currentPanel });
                    
                if (nextPanel) {
                    this.selectPanel(nextPanel);
                    if (this.autoScrollToTop) this.scrollToTop();
                }
            }
        },
        async endClicked() {
            if (!this.doNotValidatePanel) {
                if (await this.validateActivePanel()) {
                    this.$emit('end-button-clicked', {
                        nextPanel: null,
                        currentPanel: this.panels[this.activePanelIndex],
                    });
                }
            } else {
                this.$emit('end-button-clicked', {
                    nextPanel: null,
                    currentPanel: this.panels[this.activePanelIndex],
                });
            }
        },
        panelSelectorClicked(panel) {
            if (!this.doNotValidatePanel) {
                this.validateActivePanel().then(valid => {
                    if (valid) this.selectPanel(panel);
                });
            } else {
                this.selectPanel(panel);
            }
        },
        findPanel(id) {
            return this.panels.find(panel => panel.id === id);
        },
        selectPanel(selectedPanel) {
            this.panels.forEach(panel => {
                panel.isActive = panel.id === selectedPanel.id;
            });

            this.activePanelId = selectedPanel.id;
            this.activePanelIndex = this.getPanelIndex(selectedPanel.id);

            this.$emit('changed', { panel: selectedPanel, panelIndex: this.activePanelIndex });
            this.$root.$emit('panel-changed', { panel: selectedPanel, panelIndex: this.activePanelIndex });

            this.lastActivePanelId = this.activePanelId = selectedPanel.id;
        },
        selectPanelById(panelId) {
            const panel = this.findPanel(panelId);
            if (panel) this.selectPanel(panel);
        },
        async validatePanel(panelIndex) {
            const panel = this.panels[panelIndex];
            const result = await panel.validate(`${panel.id}.*`);
            if (!result) {
                this.showError(
                    `Le formulaire "${panel.name}" comporte des erreurs, merci de les corriger.`,
                );
            }
            return result;
        },
        validateActivePanel() {
            return this.validatePanel(this.activePanelIndex);
        },
        async validateAllPanels() {
            const validationPromises = this.panels.map((_panel, index) => this.validatePanel(index));
            const results = await Promise.all(validationPromises);
            return !results.includes(false);
        },
        getPanelIndex(id) {
            const panel = this.findPanel(id);
            return this.panels.indexOf(panel);
        },
        getPanelId(index) {
            const panel = this.panels.find(panel => this.panels.indexOf(panel) === index);
            if (!panel) return;
            return panel.id;
        },
        getActivePanel() {
            return this.findPanel(this.activePanelId);
        },
        getActivePanelIndex() {
            return this.getPanelIndex(this.activePanelId);
        },
        setActiveSelector(panelSelector) {
            let selectorClass = 'panel-selector';
            if (panelSelector.disabled) {
                selectorClass += ' disabled';
                return selectorClass;
            }
            if (panelSelector.id === this.activePanelId) {
                selectorClass += ' active';
            }
            return selectorClass;
        },
        scrollToTop() {
            this.$nextTick(()=>{
                this.$el.scrollIntoView(); 
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../stylesheet/variables'

.panels-component
    .panel-selectors
        display: flex
        flex-direction: row
        justify-content: center
        text-align: center
        margin: 1rem 0

        > span
            margin: 0 1rem 0 0

            > .panel-selector
                flex: 1
                border-radius: 0.4rem
                padding: 0.1rem 0.3rem
                color: primary-color-400
                border: 3px solid primary-color-400

                &:last-child
                    margin-right: 0

                &.disabled
                    pointer-events: none
                    color: medium-gray

                &.disabled
                    border-color: transparent

                &:not(.active)
                    cursor: pointer
                    background-color: transparent

                    &:hover
                        color: contrast-primary-color
                        background-color: primary-color-400

                &.active
                &:focus
                    pointer-events: none
                    color: contrast-primary-color
                    border: 3px solid primary-color
                    background-color: primary-color

            > .nb-errors
                margin-top: 0.1rem
                color: error-color
                font-size: 0.9rem

    .navigation
        display: flex
        width: 100%
        margin-top: 2em
        border: none

        // border-top: 1px solid #ccc
        .spacer
            flex-grow: 1

        .button
            margin: 1rem
</style>
