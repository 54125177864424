const api = {
    getListeProPdf(data) {
        return new Promise((resolve, reject) => {
            this.apiClient({ responseType: 'arraybuffer' })
                .post('/listepro/report/pdf/', data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    listeproProxyUserDetail(pk) {
        const url = `/listepro/user/${pk}/`;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    listeproProxyUserUpdate(data) {
        const pk = data.pk;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`/listepro/user/${pk}/`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error.response.data.error ? error.response.data.error : error);
                });
        });
    },
    listeproProxyUserCreate(data) {
        data.pk = null;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/listepro/user/', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
