<template>
<div class="pdf">
  <router-view />
</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'PdfLayout',
    mixins: [],
    components: {},
    metaInfo() {
        return {
            style: [
                {
                    cssText: this.whiteLabelling.style,
                    type: 'text/css',
                },
            ],
        };
    },
    mounted: function() {
        this.$meta().refresh();
    },
    watch: {
        whiteLabelling: function() {
            this.$meta().refresh();
        },
    },
    computed: { ...mapFields(['whiteLabelling']) },
};
</script>

<style lang="stylus" scoped>
.pdf
    width: 21cm
</style>
