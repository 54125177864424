<template>
  <layout-container grey>
  </layout-container>
</template>

<script>
import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';
import { schedulerMixin } from '@/apps/dialogwatt/mixins/scheduler_mixin';

export default {
    name: 'DashboardAdvisor',
    mixins: [schedulerMixin, handleFormErrorsMixin],
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="stylus" scoped>
@require '../../stylesheet/typography'
@require '../../stylesheet/variables'

label
    font-weight: bold

h2
    margin-top: 2rem

.appointment-resume
    padding: 0 0.5rem
    margin: 1rem 0

    .info-card
        background: gray-200

.highlighted-title
    margin-bottom: 1rem

.postcode
    margin-left: space-md
    color: gray-400
</style>
