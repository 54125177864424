/*
    Autogenerated class model from django
    -- 2023-03-13 18:03:00.811670
*/

import apiClient from '@/api/api';
import { make } from 'vuex-pathify';
import _ from 'lodash';

const defaultState = {
    isLoading: 0,
    activityCollection: {},
    activityCollectionFilter: null,
    activityCollectionLastUpdate: 0,
    calculationMethodCollection: {},
    calculationMethodCollectionFilter: null,
    calculationMethodCollectionLastUpdate: 0,
    consumptionUsageCollection: {},
    consumptionUsageCollectionFilter: null,
    consumptionUsageCollectionLastUpdate: 0,
    helperCollection: {},
    helperCollectionFilter: null,
    helperCollectionLastUpdate: 0,
    jobCollection: {},
    jobCollectionFilter: null,
    jobCollectionLastUpdate: 0,
    keyWordCollection: {},
    keyWordCollectionFilter: null,
    keyWordCollectionLastUpdate: 0,
    keyWordCategoryCollection: {},
    keyWordCategoryCollectionFilter: null,
    keyWordCategoryCollectionLastUpdate: 0,
    labelCollection: {},
    labelCollectionFilter: null,
    labelCollectionLastUpdate: 0,
    listeproProxyUserCollection: {},
    listeproProxyUserCollectionFilter: null,
    listeproProxyUserCollectionLastUpdate: 0,
    managingGroupCollection: {},
    managingGroupCollectionFilter: null,
    managingGroupCollectionLastUpdate: 0,
    missionCollection: {},
    missionCollectionFilter: null,
    missionCollectionLastUpdate: 0,
    noteCollection: {},
    noteCollectionFilter: null,
    noteCollectionLastUpdate: 0,
    presentationCollection: {},
    presentationCollectionFilter: null,
    presentationCollectionLastUpdate: 0,
    presentationDataCollection: {},
    presentationDataCollectionFilter: null,
    presentationDataCollectionLastUpdate: 0,
    professionalCollection: {},
    professionalCollectionFilter: null,
    professionalCollectionLastUpdate: 0,
    professionalAddressCollection: {},
    professionalAddressCollectionFilter: null,
    professionalAddressCollectionLastUpdate: 0,
    professionalBankOfferCollection: {},
    professionalBankOfferCollectionFilter: null,
    professionalBankOfferCollectionLastUpdate: 0,
    professionalImageCollection: {},
    professionalImageCollectionFilter: null,
    professionalImageCollectionLastUpdate: 0,
    professionalProductionCollection: {},
    professionalProductionCollectionFilter: null,
    professionalProductionCollectionLastUpdate: 0,
    referenceCollection: {},
    referenceCollectionFilter: null,
    referenceCollectionLastUpdate: 0,
    segmentActivitySubMissionLinkCollection: {},
    segmentActivitySubMissionLinkCollectionFilter: null,
    segmentActivitySubMissionLinkCollectionLastUpdate: 0,
    subMissionCollection: {},
    subMissionCollectionFilter: null,
    subMissionCollectionLastUpdate: 0,

    mission: '',
    subMission: '',
    activity: '',
    segment: '',

    favoritePro: [],
    commentPdf: '',
    downloadAll: 'nothing',
};

const state = Object.assign({}, (defaultState));
const getters = make.getters(state);

const mutations = {
    ...make.mutations(state),

    resetState(state) {
        Object.assign(state, defaultState);
    },

    incrementLoading(state) {
        state.isLoading++;
    },

    decrementLoading(state) {
        state.isLoading--;
    },
};

const actions = {

    setProfessionalAsFavorite({ commit, state }, payload) {
        console.log(payload);
        const fav = _.clone(state.favoritePro);
        const index = fav.findIndex(x => x === payload.professionalPk);
        if (payload.state === true) {
            if (index === -1) {
                fav.push(payload.professionalPk);
            }
        } else {
            if (index !== -1) {
                fav.splice(index, 1);
            }
        }
        commit('SET_FAVORITE_PRO', fav);
    },

    clearSearchParameters({ commit }) {
        commit('SET_MISSION', '');
        commit('SET_SUB_MISSION', '');
        commit('SET_ACTIVITY', '');
        commit('SET_SEGMENT', '');
    },

    getPdfReport({ state, rootState }) {
        // Données dont on aura besoin pour générer le pdf (PdfTempStore)
        const data = {
            mission: state.mission,
            subMission: state.subMission,
            segment: state.segment,
            activity: state.activity,
            favoritePro: state.favoritePro,
            commentPdf: state.commentPdf,
            downloadAll: state.downloadAll,
        };
        // const data = state.report;
        return new Promise(function(resolve, reject) {
            // Appel à mon URL de génération de rapport
            apiClient
                .getListeProPdf({
                    data: data,
                    domain: rootState.whiteLabelling.domain,
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getPdfTempStore({ commit, store }, uuid) {
        return new Promise(function(resolve, reject) {
            apiClient
                .getPdfTempStore(uuid)
                .then(response => {
                    const data = response.data;
                    console.log('sub', data.subMission);
                    commit('SET_MISSION', { path: 'mission', value: data.mission });
                    commit('SET_SUB_MISSION', { path: 'subMission', value: data.subMission });
                    // commit('SET_SEGMENT', { path: 'segment', value: data.segment });
                    commit('SET_ACTIVITY', { path: 'activity', value: data.activity });
                    commit('SET_FAVORITE_PRO', { path: 'favoritePro', value: data.favoritePro });
                    commit('SET_COMMENT_PDF', { path: 'commentPdf', value: data.commentPdf });
                    commit('SET_DOWNLOAD_ALL', { path: 'downloadAll', value: data.downloadAll });
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    loadForm() {
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
