import { store } from '@/store/store';

const api = {
    accountResetPassword(email) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/accounts/password/reset/', {
                    email: email,
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },

    accountResetPasswordChange(payload) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post(
                    `/accounts/password/reset/${payload.uidb64}/${payload.token}/`,
                    payload,
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },

    accountPasswordChange(payload) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post(
                    '/accounts/password/change/',
                    payload,
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },

    getProfileAndConsent(userID) {
        let url = '';
        if (userID !== undefined) {
            url = `/accounts/profile/${userID}/`;
        } else {
            url = '/accounts/profile/';
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    store.commit('updateProfileAndConsent', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },

    getWhiteLabelling(domainName) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/white_labelling/${domainName}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },

    updateProfileAndConsent(data) {
        const userID = data.user.pk;
        // Do not set user.group
        data.user.group = null;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`/accounts/profile/${userID}/`, data)
                .then(response => {
                    store.commit('updateProfileAndConsent', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },

    updateMyProfileAndConsent(data) {
        return new Promise((resolve, reject) => {
            this.updateProfileAndConsent(data)
                .then(response => {
                    store.commit('updateProfileAndConsent', response);
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },

    createProfileAndConsent(data) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/accounts/profile/', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },

    createProfileAndConsentAsAnonymous(data) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/accounts/profile/create/', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
