const api = {
    catchmentAreaList() {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get('/dialogwatt/catchment-area/')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    catchmentAreaDetail(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/dialogwatt/catchment-area/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    catchmentAreaDelete(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .delete(`/dialogwatt/catchment-area/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    catchmentAreaUpdate(data) {
        const pk = data.pk;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`/dialogwatt/catchment-area/${pk}/`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    catchmentAreaCreate(data) {
        data.pk = null;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/dialogwatt/catchment-area/', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
