import _ from 'lodash';

const solarExceptForWoodHeating = {
    heatingSystems: [{ identifier: '', share: 1 }, { identifier: '', share: 0 }],
    hotWaterSystems: [
        { identifier: '', share: 1 },
        {
            identifier: 'solar_water_heater',
            share: 0,
            forcedForNonWoodHeating: true,
            lockedReason: "La production d'eau chaude solaire est préconisé par Grenoble-Alpes Métropole pour votre localisation pour un système de chaleur non bois.",
        },
    ],
};

const granulatedWoodBoiler = {
    heatingSystems: [{ identifier: 'granulated_wood_boiler', share: 1 }, { identifier: '', share: 0 }],
    hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }, { identifier: '', share: 0 }],
};

const granulatedWoodStove = {
    heatingSystems: [{ identifier: 'granulated_wood_stove', share: 1 }, { identifier: '', share: 0 }],
    hotWaterSystems: [{ identifier: '', share: 1 }, { identifier: '', share: 0 }],
};

const gazBoilerCondensingWithSolarHotWater = {
    heatingSystems: [{ identifier: 'gaz_boiler_condensing', share: 1 }, { identifier: '', share: 0 }],
    hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }, { identifier: 'solar_water_heater', share: 0 }],
};

const gazBoilerCondensingWithSolarHeating = {
    heatingSystems: [{ identifier: 'solar_system_combined', share: 0 }, { identifier: 'gaz_boiler_condensing', share: 1 }],
    hotWaterSystems: [{ identifier: 'synced-with-heating', share: 0 }, { identifier: 'synced-with-heating', share: 1 }],
};

const heatPumpGeothermal = {
    heatingSystems: [
        {
            identifier: '',
            share: 1,
            limitedChoices: ['heat_pump_geothermal_lte', 'heat_pump_geothermal_vlte'],
            messageHint: 'Les systèmes de type "pompe à chaleur géothermal" sont préconisés par Grenoble-Alpes Métropole pour votre localisation',
        },
        { identifier: '', share: 0 },
    ],
    hotWaterSystems: [
        {
            identifier: '',
            share: 1,
            limitedChoices: ['heat_pump_geothermal_lte', 'heat_pump_geothermal_vlte'],
            messageHint: 'Les systèmes de type "pompe à chaleur géothermal" sont préconisés par Grenoble-Alpes Métropole pour votre localisation',
        },
        { identifier: '', share: 0 },
    ],
};

const heatingNetwork = {
    heatingSystems: [{ identifier: 'heating_network', share: 1 }, { identifier: '', share: 0 }],
    hotWaterSystems: [{ identifier: 'synced-with-heating', share: 1 }, { identifier: '', share: 0 }],
};

const freeSolution = {
    isMetroGrenoble: false,
    heatingSystems: [{ identifier: '', share: 1 }, { identifier: '', share: 0 }],
    hotWaterSystems: [{ identifier: '', share: 1 }, { identifier: '', share: 0 }],
};

// EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE

export const caseE = {
    multi_unit: {
        global_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
        systems_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
        new_building: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
    },
    individual: {
        global_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
        systems_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
        new_building: [
            _.cloneDeep(granulatedWoodStove),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
    },
};
// DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
export const caseD = {
    multi_unit: {
        global_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(gazBoilerCondensingWithSolarHotWater),
        ],
        systems_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(gazBoilerCondensingWithSolarHotWater),
        ],
        new_building: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(gazBoilerCondensingWithSolarHotWater),
        ],
    },
    individual: {
        global_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(gazBoilerCondensingWithSolarHeating),
        ],
        systems_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(gazBoilerCondensingWithSolarHotWater),
        ],
        new_building: [
            _.cloneDeep(granulatedWoodStove),
            _.cloneDeep(gazBoilerCondensingWithSolarHeating),
        ],
    },
};
// CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC
export const caseC = {
    multi_unit: {
        global_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(heatPumpGeothermal),
        ],
        systems_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(gazBoilerCondensingWithSolarHotWater),
        ],
        new_building: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(heatPumpGeothermal),
        ],
    },
    individual: {
        global_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
        systems_renovation: [
            _.cloneDeep(granulatedWoodBoiler),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
        new_building: [
            _.cloneDeep(granulatedWoodStove),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
    },
};
// BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB
export const caseB = {
    multi_unit: {
        global_renovation: [
            _.cloneDeep(heatPumpGeothermal),
            _.cloneDeep(gazBoilerCondensingWithSolarHotWater),
        ],
        systems_renovation: [
            _.cloneDeep(gazBoilerCondensingWithSolarHotWater),
            _.cloneDeep(freeSolution),
        ],
        new_building: [
            _.cloneDeep(heatPumpGeothermal),
            _.cloneDeep(gazBoilerCondensingWithSolarHotWater),
        ],
    },
    individual: {
        global_renovation: [
            _.cloneDeep(gazBoilerCondensingWithSolarHeating),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
        systems_renovation: [
            _.cloneDeep(gazBoilerCondensingWithSolarHotWater),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
        new_building: [
            _.cloneDeep(gazBoilerCondensingWithSolarHeating),
            _.cloneDeep(solarExceptForWoodHeating),
        ],
    },
};

export const caseA = [
    _.cloneDeep(heatingNetwork),
    _.cloneDeep(freeSolution),
];

export const cases = {
    E: caseE,
    D: caseD,
    C: caseC,
    B: caseB,
};
