const ActimmoMap = () => import(/* webpackChunkName: "actimmo_map" */ '@/apps/actimmo_map/actimmo_map');

const routes = [
    {
        path: '/actimmo-map',
        name: 'ActimmoMap',
        component: ActimmoMap,
    },
];

export default routes;
