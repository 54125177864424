 <template>
  <div>
    <div class="version-mismatch text-center" v-show="versionMismatch">
      <div class="content">
        <p><font-awesome-icon icon="exclamation-circle" /><b>Nouvelle version !</b> Une mise à jour de a été installée, certaines sections peuvent ne plus fonctionner correctement. <a href="https://mixeur.solutions/fr/changelog/" target="_blank">Voir les modifications.</a><br>
          Pour continuer veuillez <a href="#" onclick="window.location.reload()"> recharger l'application</a>.</p>
      </div>
    </div>

    <div class="layout" id="app" v-if="isLoaded">
      <!-- MOBILE MENU -->
      <div
        ref="mobileMenu"
        class="mobile-menu text-sm"
        :class="{ 'mobile-menu-opened': isMenuOpen }"
      >
        <div class="mobile-menu-content">
          <div class="grid-x align-right align-middle menu-item" @click="closeMenu">
            <font-awesome-icon :icon="['fal', 'times']" />Fermer
          </div>
          <div v-show="userUtilities.isAuthenticated">
            <span class="menu-item">
              <user-avatar
                class="user-avatar"
                :username="userUtilities.fullName"
                :profilePic="userUtilities.picProfileUrl"
              ></user-avatar>
              {{ userUtilities.fullName }}
            </span>
          </div>
          <menu-content :menuCategories="displayedMenuCategories" @item-click="closeMenu"></menu-content>
          <div class="version"><a href="https://mixeur.solutions/fr/changelog/" target="_blank">v.{{ versionNumber }}</a></div>
        </div>
      </div>

      <main id="page-wrap" :class="{ blurred: isMenuOpen }" @click="closeMenu">
        <div
          class="fixed-header grid-x align-justify align-middle"
          v-if="whiteLabelling.hasMainHeader"
        >
          <div class="cell shrink hide-for-large menu-btn" v-if="whiteLabelling.hasMenu">
            <button @click.stop="openMenu()">
              <font-awesome-icon icon="bars" />Menu
            </button>
          </div>
          <div class="cell shrink" v-html="whiteLabelling.siteBaseline"></div>
          <div class="cell shrink show-for-medium grid-x grid-padding-x align-middle connect-items">
            <div class="cell shrink" v-if="userUtilities.isAuthenticated">
              {{ userUtilities.fullName }}
              <span v-if="user.group !== null">({{user.group.name}})</span>
            </div>
            <div class="cell shrink grid-x align-middle global-messages" :title="globalMessagesTitle" @click="displayGlobalMessagesClicked" :class="{'global-messages-hidden': !hasMessages}">
              <font-awesome-icon v-if="hasMessages" :icon="['fas', 'envelope']" />
              <div :class="{'global-messages-list-hidden': !showGlobalMessageList, 'global-messages-list-show': showGlobalMessageList}" v-if="hasMessages">
                <ul v-if="globalMessages.newMessages.length > 0">
                  <li v-for="theMessage in globalMessages.newMessages" :key="theMessage.pk" @click="displayTheGlobalMessage(theMessage)"><font-awesome-icon :icon="['fas', 'envelope-open-text']" /> {{ theMessage.title }}</li>
                </ul>
                <ul v-if="globalMessages.seenMessages.length > 0">
                   <li v-for="theMessage in globalMessages.seenMessages" :key="theMessage.pk" @click="displayTheGlobalMessage(theMessage)"><font-awesome-icon :icon="['fas', 'envelope']" /> {{ theMessage.title }}</li>
                </ul>
              </div>
            </div>
            <div class="cell shrink grid-x align-middle connect-button">
              <div
                v-if="userUtilities.isAuthenticated"
                v-show="userUtilities.isAuthenticated"
                @click.prevent="disconnect"
              >
                <font-awesome-icon :icon="['fas', 'power-off']" />
              </div>
              <router-link
                :to="{ name: 'AccountLogin' }"
                v-else-if="whiteLabelling.usermanagementIsActive"
              >
                <font-awesome-icon :icon="['fal', 'user']" />Se connecter
              </router-link>
            </div>
          </div>
        </div>
        <div class="header" id="header" v-html="whiteLabelling.header" v-if="whiteLabelling.hasHeaderImg"></div>

        <div class="grid-x">
          <!-- PERMANENT MENU -->
          <div
            class="cell show-for-large large-2 permanent-menu text-sm"
            v-if="whiteLabelling.hasMenu && !isDesktopMenuFolded"
          >
            <font-awesome-icon
              :icon="['fal', 'angle-double-left']"
              class="menu-close"
              @click="foldDesktopMenu"
              title="Réduire le menu"
            />
            <menu-content :menuCategories="displayedMenuCategories" v-show="!isDesktopMenuFolded"></menu-content>
            <div class="version" v-show="!isDesktopMenuFolded"><a href="https://mixeur.solutions/fr/changelog/" target="_blank">v.{{ versionNumber }}</a></div>
          </div>
          <div
            class="cell show-for-large shrink permanent-menu text-sm"
            v-show="isDesktopMenuFolded"
          >
            <font-awesome-icon
              :icon="['fal', 'angle-double-right']"
              class="menu-close desktop-menu-closed"
              @click="unfoldDesktopMenu"
              title="Montrer le menu"
            />
          </div>

          <!-- CONTENT -->
          <div class="cell auto">
            <div class="breadcrumb-bar grid-x align-justify align-middle" v-if="whiteLabelling.hasBreadcrumb">
              <ul class="cell auto breadcrumbs">
                <li
                  v-for="breadcrumbStep in breadcrumb"
                  :key="getKeyFromLabel(breadcrumbStep.label)"
                >{{ breadcrumbStep.label }}</li>
              </ul>
              <a v-if="whiteLabelling.hasOnlineHelp" class="cell shrink grid-x align-middle text-sm action-link" href="http://mixeur.solutions/faq" target="_blank">
                <font-awesome-icon class="action-link-icon" icon="question-circle" />
                <span class="action-link-label">Aide en ligne</span>
              </a>
              
            </div>
            <div class="app-header" v-if="whiteLabelling.hasBreadcrumb">
              <span class="app-header-title text-xxl">{{ breadcrumbLabel.label }}</span>
              <span
                class="app-header-description text-lg"
                v-if="breadcrumbLabel.description"
              >&nbsp;|&nbsp;{{ breadcrumbLabel.description }}</span>
            </div>
            
            <div class="main-content">
              <transition name="fade">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <div class="footer" id="footer" v-html="whiteLabelling.footer"></div>
      </main>
    </div>

    <dialog
      ref="genericDialog"
      class="generic-dialog"
    >
      <h1>
        {{ genericDialogTitle }}
      </h1>
      <div v-html="genericDialogText" />
      <div class="buttons">
        <button
          v-for="button in genericDialogButtons"
          :key="button.title"
          @click="() => { button.handler && button.handler(); genericDialog.close() }"
          :autofocus="button.default"
        >
          {{ button.title }}
        </button>
      </div>
    </dialog>

    <dialog
      ref="confirmDialog"
      class="confirm-dialog"
      @close="closeConfirmDialog"
    >
      <form method="dialog">
        <h1>
          Attention !
        </h1>
        <p>
          Vous êtes sur le point de {{ confirmDialogDescription }} <b>{{ confirmDialogSubject }}</b>.
        </p>
        <p>
          Souhaitez-vous poursuivre ?
        </p>
        <div class="buttons">
          <button value="confirm">Oui</button>
          <button value="cancel" autofocus>Non</button>
        </div>
      </form>
    </dialog>
    <notifications group="root" position="bottom right" />
  </div>
</template>

<script>
import { provide, ref } from 'vue';
import { mapFields } from 'vuex-map-fields';

import apiClient from '@/api/api';
import { store } from '@/store/store';
import { utils } from '@/utils';
import { version } from '@/version_number';
import { userMixin } from '@/mixins/user';
import UserAvatar from '@/components/user_avatar';
import MenuContent from '@/apps/menu_content';


export default {
    name: 'WebLayout',
    mixins: [userMixin],
    components: {
        MenuContent,
        UserAvatar,
    },
    setup() {
        const confirmDialog = ref();
        const confirmDialogResolve = ref(() => {});
        const confirmDialogDescription = ref("");
        const confirmDialogSubject = ref("");
        provide('confirmDialog', confirmDialog);
        provide('confirmDialogResolve', confirmDialogResolve);
        provide('confirmDialogDescription', confirmDialogDescription);
        provide('confirmDialogSubject', confirmDialogSubject);

        const genericDialog = ref();
        const genericDialogTitle = ref("");
        const genericDialogText = ref("");
        const genericDialogButtons = ref([]);
        provide('genericDialog', genericDialog);

        function closeConfirmDialog() {
            if (confirmDialog.value.returnValue === "confirm") {
                confirmDialogResolve.value(true);
            }
            confirmDialog.value.returnValue = "cancel";
            confirmDialogResolve.value(false);
        }

        function showGenericDialog({ title, text, buttons }) {
            genericDialogTitle.value = title;
            genericDialogText.value = text;
            genericDialogButtons.value = buttons;
            genericDialog.value.showModal();
        }
        function hideGenericDialog() {
            genericDialog.value.close();
        }
        provide('showGenericDialog', showGenericDialog);
        provide('hideGenericDialog', hideGenericDialog);

        return {
            confirmDialog,
            confirmDialogDescription,
            confirmDialogSubject,
            closeConfirmDialog,

            genericDialog,
            genericDialogTitle,
            genericDialogText,
            genericDialogButtons,
            showGenericDialog,
        };
    },

    data() {
        return {
            isDesktopMenuFolded: false, // for desktop menu
            isMenuOpen: false, // for mobile menu
            window: {
                width: 0,
                height: 0,
            },
            url: '',
            jwtRefreshFunction: null,
            globalMessageUpdateFunction: null,
            globalMessages: {newMessages: [], seenMessages: []},
            showGlobalMessageList: false,
        };
    },
    metaInfo() {
        const title = this.constructPageTitle();
        const metaInfo = {
            title,
            style: [
                {
                    cssText: this.css,
                    type: 'text/css',
                },
            ],
            link: [{ rel: 'favicon shortcut icon', type: "image/png", href: `/wl-cdn/${this.whiteLabelling.domain}/favicon.png` }],
        };
        return metaInfo;
    },
    props: [],
    computed: {
        ...mapFields(['user', 'whiteLabelling', 'versionNumber', 'serverVersion']),
        isAuthenticated() {
            return this.userUtilities.isAuthenticated;
        },
        versionMismatch() {
            // checks if server and frontend version are rame
            if (this.serverVersion === undefined || this.versionNumber === '') {
                return false;
            }
            return this.serverVersion !== this.versionNumber;
        },
        globalMessagesTitle() {
            return this.globalMessages.newMessages ? `${this.globalMessages.newMessages.length} messages non lus` : `${this.globalMessages.seenMessages.length} messages`;
        },
        hasMessages() {
            return this.globalMessages.seenMessages.length > 0 || this.globalMessages.newMessages.length > 0;
        },
        css() {
            if (this.whiteLabelling.actimmoMapRemoveMargins) {
                return (
                    this.whiteLabelling.style +
                        '.grid-container {padding: 0 !important;margin: 0 !important;} .layout-container {padding: 0 !important;margin: 0 !important;}'
                );
            } else {
                return this.whiteLabelling.style;
            }
        },
        breadcrumb() {
            return this.allMenuCategories
                .reduce((result, category) => result.concat(this.findBreadcrumbPath(category, this.$route.name)), [])
                .filter(step => step && step.label)
                .reverse();
        },
        breadcrumbLabel() {
            if (this.isLoaded) {
                if (this.breadcrumb.length === 0) {
                    return { label: '', description: this.$route.name };
                }
                return this.breadcrumb.length > 1 ? this.breadcrumb[1] : this.breadcrumb[0];
            } else {
                return { label: '', description: '' };
            }
        },
        groupPk() {
            if (this.user !== null && this.user.group !== null) {
                return this.user.group.pk;
            }
            return null;
        },
        styleHeight() {
            return `margin-top:${parseInt(this.window.height / 2) - 16}px;`;
        },
        anonymousMenu() {
            return [
                {
                    label: '',
                    options: [
                        {
                            // For anonymous users to see "Me connecter"
                            label: () => 'Me connecter',
                            icon: ['fal', 'user'],
                            route: { name: 'AccountLogin' },
                            matchingRoutes: [
                                'AccountLogin',
                                'AccountLoginWithEmail',
                                'AccountLoginAndNext',
                                'AccountResetPassword',
                                'AccountResetPasswordChange',
                            ],
                            isVisible: () => false,
                            hasPermission: () => true,
                        },
                        {
                            label: () => 'Prendre un rendez-vous',
                            icon: ['far', 'calendar-plus'],
                            route: { name: 'DialogwattHome' },
                            matchingRoutes: [
                                'DialogwattHome',
                                'DialogwattPlacesList',
                                'DialogwattPlaceInfos',
                                'DialogwattAppointmentBook',
                                'DialogwattAppointmentBookSelect',
                                'DialogwattAppointmentBookQuestionnaire',
                                'DialogwattAppointmentBookLogin',
                                'DialogwattAppointmentBookRecap',
                            ],
                            isVisible: () => this.whiteLabelling.dialogwattIsActive,
                            hasPermission: () => true,
                        },
                        {
                            label: () => 'Gérer mes rendez-vous',
                            icon: ['far', 'calendar-check'],
                            route: { name: 'DialogwattGetAppointmentsList' },
                            matchingRoutes: [
                                'DialogwattGetAppointmentsList',
                                'DialogwattAppointmentCancel',
                                'DialogwattAppointmentConfirmation',
                                'DialogwattAppointmentManagement',
                            ],
                            isVisible: () => this.whiteLabelling.dialogwattIsActive,
                            hasPermission: () => true,
                        },
                        {
                            label: () => 'Liste des inscriptions',
                            matchingRoutes: ['AttendeesPublicView'],
                            isVisible: () => false,
                            hasPermission: () => true,
                        },
                    ],
                },
                {
                    id: 'client-services',
                    label: 'Services aux particuliers',
                    options: [
                        {
                            label: () => this.whiteLabelling.ecorenoverName,
                            description: () => this.whiteLabelling.ecorenoverBaseline,
                            icon: ['far', 'home'],
                            route: { name: 'Ecorenover' },
                            matchingRoutes: [
                                'Ecorenover',
                                'EcorenoverCurrentSituation',
                                'EcorenoverEnvisagedSituation',
                                'EcorenoverLoans',
                                'EcorenoverResults',
                            ],
                            isVisible: () => this.whiteLabelling.ecorenoverIsActive,
                            hasPermission: () => true,
                        },
                        {
                            label: () => this.whiteLabelling.ecorenoverIframeName,
                            description: () => this.whiteLabelling.ecorenoverIframeBaseline,
                            icon: ['far', 'home'],
                            route: { name: 'EcorenoverIframe' },
                            matchingRoutes: ['EcorenoverIframe'],
                            isVisible: () => this.whiteLabelling.ecorenoverIframeIsActive,
                            hasPermission: () => true,
                        },
                        {
                            label: () => this.whiteLabelling.therminixName,
                            description: () => this.whiteLabelling.therminixBaseline,
                            icon: ['far', 'fireplace'],
                            route: { name: 'Therminix' },
                            matchingRoutes: ['Therminix'],
                            isVisible: () => this.whiteLabelling.therminixIsActive,
                            hasPermission: () => true,
                        },
                        {
                            label: () => this.whiteLabelling.thermixName,
                            description: () => this.whiteLabelling.thermixBaseline,
                            icon: ['far', 'thermometer-half'],
                            route: { name: 'Thermix' },
                            matchingRoutes: [
                                'Thermix',
                                'ThermixNeeds',
                                'ThermixSolutions',
                                'ThermixResults',
                                'ThermixBasicNeeds',
                                'ThermixNeedsEstimationThermalCalculation',
                                'ThermixNeedsEstimationNewBuilding',
                                'ThermixNeedsEstimationGlobalRenovation',
                                'ThermixNeedsEstimationSystemsRenovationBuilding',
                                'ThermixNeedsEstimationSystemsRenovationInvoiceEuro',
                                'ThermixNeedsEstimationSystemsRenovationInvoiceQuantity',
                            ],
                            isVisible: () => this.whiteLabelling.thermixIsActive,
                            hasPermission: () => true,
                        },
                        {
                            label: () => this.whiteLabelling.oldThermixName,
                            description: () => this.whiteLabelling.oldThermixBaseline,
                            icon: ['far', 'thermometer-half'],
                            route: { name: 'OldThermix' },
                            matchingRoutes: ['OldThermix'],
                            isVisible: () => this.whiteLabelling.oldThermixIsActive,
                            hasPermission: () => true,
                        },
                        {
                            label: () => this.whiteLabelling.listeproName,
                            description: () => this.whiteLabelling.listeproBaseline,
                            icon: ['far', 'user-hard-hat'],
                            route: { name: 'ListeProPublicSearch' },
                            matchingRoutes: [
                                'ListeProPublicDetail',
                                'ListeProPublicNews',
                                'ListeProPublicSearch',
                                'ListeProPublicShowResults',
                            ],
                            isVisible: () => this.whiteLabelling.listeproIsActive,
                            hasPermission: () => true,
                            /* Temporary */
                            options: [
                                {
                                    route: { name: 'ListeProPublicSearch' },
                                    matchingRoutes: [
                                        'ListeProPublicDetail',
                                        'ListeProPublicSearch',
                                        'ListeProPublicShowResults',
                                    ],
                                    label: () => 'Recherche',
                                    icon: ['far', 'search'],
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'AccountLogin' },
                                    matchingRoutes: [],
                                    label: () => 'Actualités',
                                    icon: ['far', 'newspaper'],
                                    isVisible: () => this.whiteLabelling.listeproNewsIsActive,
                                    hasPermission: () => true,
                                },
                            ],
                        },
                        {
                            label: () => this.whiteLabelling.actimmoMapName,
                            description: () => this.whiteLabelling.actimmoMapBaseline,
                            icon: ['far', 'map-marked-alt'],
                            route: { name: 'ActimmoMap' },
                            matchingRoutes: ['ActimmoMap'],
                            isVisible: () => this.whiteLabelling.actimmoMapIsActive,
                            hasPermission: () => true,
                        },
                    ],
                },
                {
                    id: 'autres',
                    label: 'Autres',
                    options: [
                        {
                            label: () => this.whiteLabelling.newslettersPublicName,
                            description: () => this.whiteLabelling.newslettersPublicBaseline,
                            icon: ['far', 'newspaper'],
                            route: { name: 'PublicNewslettersHome' },
                            matchingRoutes: ['PublicNewslettersHome', 'PublicNewslettersList', 'PublicNewsletterView'],
                            // isVisible: () => this.whiteLabelling.newslettersIsActive,
                            isVisible: () => false,
                            hasPermission: () => true,
                        },
                        {
                            label: () => 'Consultation des traitements RGPD',
                            icon: ['far', 'user-lock'],
                            route: { name: 'RgpdPublicView' },
                            matchingRoutes: ['RgpdPublicView'],
                            isVisible: () => false,
                            hasPermission: () => true,
                        },
                    ],
                },
            ];
        },
        professionalMenu() {
            return [
                {
                    id: 'professional-tools',
                    label: 'Outils du réseau',
                    options: [
                        {
                            label: 'Accueil',
                            icon: ['far', 'home'],
                            route: { name: 'ProfessionalHomepage' },
                            matchingRoutes: [
                                'ProfessionalHomepage',
                            ],
                            isVisible: () => true,
                            hasPermission: () => this.userUtilities.isProfessional,
                        },
                        {
                            label: () => this.whiteLabelling.listeproName,
                            description: () => this.whiteLabelling.listeproBaseline,
                            icon: ['far', 'user-hard-hat'],
                            route: { name: 'ListeProPro' },
                            matchingRoutes: [
                                'ProfessionalHomepage',
                                'ListeProPro',
                                'ListeProProfessionalForm',
                                'ListeProPublic',
                                'ListeProPublicDetail',
                                'ListeProPublicNews',
                                'ListeProPublicSearch',
                                'ListeProPublicShowResults',
                            ],
                            isVisible: () => this.whiteLabelling.listeproIsActive,
                            hasPermission: () => true,
                            options: [
                                {
                                    route: { name: 'ListeProProfessionalForm' },
                                    matchingRoutes: [
                                        'ListeProProfessionalForm',
                                    ],
                                    label: () => 'Éditer ma fiche',
                                    icon: ['far', 'file-alt'],
                                    isVisible: () => true,
                                    hasPermission: () => this.userUtilities.isProfessional,
                                },
                                {
                                    route: { name: 'ListeProPublicSearch' },
                                    matchingRoutes: [
                                        'ListeProPublicSearh',
                                        'ListeProPublicShowResults',
                                        'ListeProPublicDetail',
                                    ],
                                    label: () => 'Consulter l\'annuaire',
                                    icon: ['far', 'search'],
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                            ],
                        },
                        // BAP
                        {
                            label: () => this.whiteLabelling.bapName,
                            description: () => this.whiteLabelling.bapBaseline,
                            icon: ['far', 'link'],
                            route: { name: 'BapProProjects' },
                            matchingRoutes: [
                                'BAP',
                                'BapProMenu',
                                'BapProProjects',
                                'BapProRegister',
                                'BapProProjectDetail',
                            ],
                            isVisible: () => this.whiteLabelling.bapIsActive && this.userUtilities.allowedToSeeBapMenu,
                            hasPermission: () => this.userUtilities.isProfessional,
                        },
                        {
                            label: 'Actualités du réseau',
                            description: 'Consulter les derniers articles',
                            icon: ['far', 'newspaper'],
                            route: { name: 'ListeProPublicNews' },
                            matchingRoutes: [
                                'ListeProPublicNews',
                            ],
                            isVisible: () => this.whiteLabelling.listeproNewsIsActive,
                            hasPermission: () => this.userUtilities.isProfessional,
                        },
                    ],
                },
            ];
        },
        menuCategories() {
            return [
                {
                    id: 'client-space',
                    label: 'Espace conseiller',
                    options: [
                        {
                            label: () => 'Editer mon profil',
                            icon: ['far', 'user'],
                            route: { name: 'AccountProfile' },
                            matchingRoutes: ['AccountProfile'],
                            isVisible: () => this.whiteLabelling.usermanagementIsActive,
                            hasPermission: () => !this.userUtilities.isAnonymous,
                        },
                    ],
                },
                {
                    id: 'advisor-tools',
                    label: 'Outils conseillers',
                    options: [
                        {
                            label: () => 'CTI',
                            description: () => 'Couplage téléphonie informatique',
                            icon: ['fas', 'phone'],
                            route: { name: 'CTI' },
                            matchingRoutes: [
                                'CTI',
                                'CTIphone',
                            ],
                            isVisible: () => false,
                            hasPermission: () => this.userUtilities.isAdvisor,
                        },
                        {
                            label: () => this.whiteLabelling.dialogwattName,
                            description: () => this.whiteLabelling.dialogwattBaseline,
                            icon: ['far', 'handshake'],
                            route: { name: 'DialogwattMenu' },
                            matchingRoutes: [
                                'DialogwattMenu',
                                'DialogwattAppointmentEdit',
                                'DialogwattSlotList',
                                'DialogwattPlaceList',
                                'DialogwattPlaceEdit',
                                'DialogwattCatchmentAreaList',
                                'DialogwattCatchmentAreaEdit',
                                'DialogwattReasonList',
                                'DialogwattReasonEdit',
                                'DialogwattNotificationList',
                                'DialogwattNotificationEdit',
                                'DialogwattExchangeList',
                                'DialogwattStatistics',
                            ],
                            isVisible: () => this.whiteLabelling.dialogwattIsActive && this.userUtilities.isAdvisor,
                            hasPermission: () => this.userUtilities.isAdvisor,
                            options: [
                                {
                                    route: { name: 'DialogwattAppointmentEdit' },
                                    matchingRoutes: ['DialogwattAppointmentEdit'],
                                    label: () => 'Planning des rendez-vous',
                                    icon: ['far', 'calendar-alt'],
                                    isVisible: () => true,
                                    hasPermission: () => this.userUtilities.isAdvisor,
                                },
                                {
                                    route: { name: 'DialogwattSlotList' },
                                    matchingRoutes: ['DialogwattSlotList'],
                                    icon: ['far', 'calendar-day'],
                                    label: () => 'Planifier les créneaux',
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'DialogwattReasonList' },
                                    matchingRoutes: [
                                        'DialogwattReasonList',
                                        'DialogwattReasonEdit',
                                        'DialogwattReasonEditNew',
                                    ],
                                    icon: ['far', 'question-circle'],
                                    label: () => 'Motifs',
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'DialogwattCatchmentAreaList' },
                                    matchingRoutes: [
                                        'DialogwattCatchmentAreaList',
                                        'DialogwattCatchmentAreaEdit',
                                        'DialogwattCatchmentAreaEditNew',
                                    ],
                                    icon: ['far', 'globe-europe'],
                                    label: () => 'Zones de chalandise',
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'DialogwattPlaceList' },
                                    matchingRoutes: ['DialogwattPlaceList'],
                                    icon: ['far', 'map-marker-alt'],
                                    label: () => "Lieux d'accueil",
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'DialogwattNotificationList' },
                                    matchingRoutes: [
                                        'DialogwattNotificationList',
                                        'DialogwattNotificationEdit',
                                        'DialogwattNotificationEditNew',
                                    ],
                                    icon: ['far', 'bell'],
                                    label: () => 'Notifications',
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'DialogwattExchangeList' },
                                    matchingRoutes: ['DialogwattExchangeList'],
                                    icon: ['far', 'exchange-alt'],
                                    label: () => 'Échanges',
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'DialogwattStatistics' },
                                    matchingRoutes: [ 'DialogwattStatistics' ],
                                    icon: ['far', 'chart-area'],
                                    label: () => 'Statistiques',
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                            ],
                        },
                        {
                            label: () => this.whiteLabelling.visitReportName,
                            description: () => this.whiteLabelling.visitReportBaseline,
                            icon: ['far', 'file-signature'],
                            route: { name: 'VisitReportContactsSearch' },
                            matchingRoutes: [
                                'VisitReportContactsSearch',
                                'VisitReportContactsResults',
                                'VisitReportDetail',
                                'VisitReportIdentities',
                                'VisitReportEvaluation',
                                'VisitReportRecommandations',
                                'VisitReportFinancing',
                                'VisitReportConclusion',
                            ],
                            isVisible: () => this.whiteLabelling.visitReportIsActive,
                            hasPermission: () => this.userUtilities.isAdvisor,
                        },
                        
                        {
                            label: () => this.whiteLabelling.precoImmoName,
                            description: () => this.whiteLabelling.precoImmoBaseline,
                            icon: ['far', 'file-certificate'],
                            route: { name: 'PrecoImmoContactsSearch' },
                            matchingRoutes: [
                                'PrecoImmoContactsSearch',
                                'PrecoImmoContactsResults',
                                'PrecoImmoDetail',
                                'PrecoImmoIdentities',
                                'PrecoImmoEvaluation',
                                'PrecoImmoRecommandations',
                                'PrecoImmoFinancing',
                                'PrecoImmoConclusion',
                            ],
                            isVisible: () => this.whiteLabelling.precoImmoIsActive,
                            hasPermission: () => this.userUtilities.isAdvisor,
                        },
                        {
                            label: () => this.whiteLabelling.facName,
                            description: () => this.whiteLabelling.facBaseline,
                            icon: ['far', 'industry-alt'],
                            route: { name: 'fac' },
                            matchingRoutes: [
                                'fac',
                                'FacContactsList',
                                'FacContactsEdit',
                                'FacIncompleteDataContainer',
                                'FacOrganizationsList',
                                'FacOrganizationsEdit',
                                'FacTagsList',
                                'FacTagsEdit',
                                'FacReminderListContainer',
                                'FacListsEdit',
                                'FacListsNew',
                                'FacListsV2List',
                                'FacStatistics',
                            ],
                            isVisible: () => this.whiteLabelling.facIsActive && this.userUtilities.isAdvisor,
                            hasPermission: () => this.userUtilities.isAdvisor,
                            options: [
                                {
                                    route: { name: 'fac' },
                                    matchingRoutes: [
                                        'fac',
                                        'FacContactsList',
                                        'FacContactsEdit',
                                        'FacOrganizationsList',
                                        'FacOrganizationsEdit',
                                        'FacTagsList',
                                        'FacTagsEdit',
                                        'FacReminderListContainer',
                                        'FacListsEdit',
                                        'FacListsNew',
                                        'FacListsV2List',
                                    ],
                                    label: () => 'Contacts',
                                    icon: ['far', 'users'],
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'FacFolders' },
                                    matchingRoutes: ['FacFolders'],
                                    label: () => 'Dossiers',
                                    icon: ['far', 'folder-open'],
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'FacDuplicates' },
                                    matchingRoutes: ['FacDuplicates', 'FacContactsDuplicatesList', 'FacOrganizationsDuplicatesList'],
                                    label: () => 'Gestion des doublons',
                                    icon: ['far', 'users-cog'],
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'FacStatisticsContainer' },
                                    matchingRoutes: ['FacStatisticsContainer', 'FacStatistics', 'FacPivot'],
                                    label: () => 'Suivi et statistiques',
                                    icon: ['far', 'chart-area'],
                                    isVisible: () => this.whiteLabelling.facStatisticsIsActive,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'FacIncompleteDataContainer' },
                                    matchingRoutes: ['FacIncompleteDataContainer'],
                                    label: () => 'Saisies incomplètes',
                                    icon: ['far', 'exclamation-circle'],
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                                {
                                    route: { name: 'FacTBSFeedback' },
                                    matchingRoutes: ['FacTBSFeedback', 'FacTBSErrorList'],
                                    label: () => 'Retours TBS',
                                    icon: ['far', 'globe-europe'],
                                    isVisible: () => !!this.user.group.ademeId,
                                    hasPermission: () => true,
                                },
                            ],
                        },
                        {
                            label: () => this.whiteLabelling.actimmoMapName,
                            description: () => this.whiteLabelling.actimmoMapBaseline,
                            icon: ['far', 'map-marked-alt'],
                            route: { name: 'ActimmoMap' },
                            matchingRoutes: ['ActimmoMap'],
                            isVisible: () => this.whiteLabelling.actimmoMapIsActive,
                            hasPermission: () => true,
                        },
                    ],
                },

                {
                    id: 'client-services',
                    label: 'Services aux particuliers',
                    options: [
                        {
                            label: () => this.whiteLabelling.ecorenoverName,
                            description: () => this.whiteLabelling.ecorenoverBaseline,
                            icon: ['far', 'home'],
                            route: { name: 'Ecorenover' },
                            matchingRoutes: [
                                'Ecorenover',
                                'EcorenoverCurrentSituation',
                                'EcorenoverEnvisagedSituation',
                                'EcorenoverLoans',
                                'EcorenoverResults',
                            ],
                            isVisible: () => this.whiteLabelling.ecorenoverIsActive,
                            hasPermission: () => true,
                        },
                        {
                            label: () => this.whiteLabelling.ecorenoverIframeName,
                            description: () => this.whiteLabelling.ecorenoverIframeBaseline,
                            icon: ['far', 'home'],
                            route: { name: 'EcorenoverIframe' },
                            matchingRoutes: ['EcorenoverIframe'],
                            isVisible: () => this.whiteLabelling.ecorenoverIframeIsActive,
                            hasPermission: () => this.userUtilities.isAdvisor || this.userUtilities.isClient,
                        },
                        {
                            label: () => this.whiteLabelling.simulaidesName,
                            description: () => this.whiteLabelling.simulaidesBaseline,
                            icon: ['far', 'piggy-bank'],
                            route: { name: 'Simulaides' },
                            matchingRoutes: ['Simulaides'],
                            isVisible: () => this.whiteLabelling.simulaidesIsActive,
                            hasPermission: () => this.userUtilities.isAdvisor || this.userUtilities.isClient,
                        },
                        {
                            label: () => this.whiteLabelling.therminixName,
                            description: () => this.whiteLabelling.therminixBaseline,
                            icon: ['far', 'fireplace'],
                            route: { name: 'Therminix' },
                            matchingRoutes: ['Therminix'],
                            isVisible: () => this.whiteLabelling.therminixIsActive,
                            hasPermission: () => true,
                        },
                        {
                            label: () => this.whiteLabelling.thermixName,
                            description: () => this.whiteLabelling.thermixBaseline,
                            icon: ['far', 'thermometer-half'],
                            route: { name: 'Thermix' },
                            matchingRoutes: [
                                'Thermix',
                                'ThermixNeeds',
                                'ThermixSolutions',
                                'ThermixResults',
                                'ThermixBasicNeeds',
                                'ThermixNeedsEstimationThermalCalculation',
                                'ThermixNeedsEstimationNewBuilding',
                                'ThermixNeedsEstimationGlobalRenovation',
                                'ThermixNeedsEstimationSystemsRenovationBuilding',
                                'ThermixNeedsEstimationSystemsRenovationInvoiceEuro',
                                'ThermixNeedsEstimationSystemsRenovationInvoiceQuantity',
                            ],
                            isVisible: () => this.whiteLabelling.thermixIsActive,
                            hasPermission: () => true,
                        },
                        {
                            label: () => this.whiteLabelling.oldThermixName,
                            description: () => this.whiteLabelling.oldThermixBaseline,
                            icon: ['far', 'thermometer-half'],
                            route: { name: 'OldThermix' },
                            matchingRoutes: ['OldThermix'],
                            isVisible: () => this.whiteLabelling.oldThermixIsActive,
                            hasPermission: () => true,
                        },
                    ],
                },
                
                {
                    id: 'listepro-advisors',
                    label: 'Annuaire des pros',
                    options: [
                        {
                            label: () => this.whiteLabelling.listeproName,
                            description: () => this.whiteLabelling.listeproBaseline,
                            icon: ['far', 'user-hard-hat'],
                            route: { name: 'ListeProPro' },
                            matchingRoutes: [
                                
                                'ListeProAccountCreate',
                                'ListeProAdmin',
                                'ListeProAdminProfessionalList',
                                'ListeProListeAll',
                                'ListeProPdfReport',
                                'ListeProPro',
                                'ListeProProfessionalAdminForm',
                                'ListeProProfessionalForm',
                                'ListeProPublic',
                                'ListeProPublicDetail',
                                'ListeProPublicNews',
                                'ListeProPublicSearch',
                                'ListeProPublicShowResults',
                            ],
                            isVisible: () => this.whiteLabelling.listeproIsActive,
                            hasPermission: () => true,
                            options: [
                                {
                                    route: { name: 'ListeProAdminProfessionalList' },
                                    matchingRoutes: [
                                        'ListeProAdminProfessionalList',
                                    ],
                                    label: () => 'Professionnels',
                                    icon: ['far', 'users'],
                                    isVisible: () => true,
                                    hasPermission: () => this.userUtilities.isListeProAdmin  || this.userUtilities.isAdvisor,
                                },
                                {
                                    route: { name: 'ListeProListeAll' },
                                    matchingRoutes: [
                                        'ListeProListeAll',
                                    ],
                                    label: () => 'Liste',
                                    icon: ['far', 'list'],
                                    isVisible: () => true,
                                    hasPermission: () => this.userUtilities.isListeProAdmin || this.userUtilities.isAdvisor,
                                },
                                {
                                    route: { name: 'ListeProProfessionalForm' },
                                    matchingRoutes: [
                                        'ListeProProfessionalForm',
                                    ],
                                    label: () => 'Ma fiche',
                                    icon: ['far', 'file-alt'],
                                    isVisible: () => true,
                                    hasPermission: () => this.userUtilities.isProfessional,
                                },
                                {
                                    route: { name: 'ListeProPublicNews' },
                                    matchingRoutes: [
                                        'ListeProPublicNews',
                                    ],
                                    label: () => 'Actualités',
                                    icon: ['far', 'newspaper'],
                                    isVisible: () => this.whiteLabelling.listeproNewsIsActive,
                                    hasPermission: () => this.userUtilities.isProfessional || this.userUtilities.isListeProAdmin || this.userUtilities.isAdvisor,
                                },
                                {
                                    route: { name: 'ListeProPublicSearch' },
                                    matchingRoutes: [
                                        'ListeProPublicSearch',
                                        'ListeProPublicShowResults',
                                        'ListeProPublicDetail',
                                    ],
                                    label: () => 'Recherche',
                                    icon: ['far', 'search'],
                                    isVisible: () => true,
                                    hasPermission: () => true,
                                },
                            ],
                        },
                        // BAP
                        {
                            label: () => this.whiteLabelling.bapName,
                            description: () => this.whiteLabelling.bapBaseline,
                            icon: ['far', 'link'],
                            route: { name: 'BAP' },
                            matchingRoutes: [
                                'BapMenu',
                                'BapProject',
                                'BapProjectEdit',
                                'BapProjectNew',
                                'BapStatistics',
                            ],
                            isVisible: () => this.whiteLabelling.bapIsActive && (this.userUtilities.allowedToSeeBapMenu || this.userUtilities.isAdvisor),
                            hasPermission: () => true,
                            options: [
                                {
                                    route: { name: 'BapProjects' },
                                    matchingRoutes: [
                                        'BapProjects',
                                        'BapProjectNew',
                                        'BapProjectEdit',
                                    ],
                                    label: () => 'Projets',
                                    icon: ['far', 'home'],
                                    isVisible: () => true,
                                    hasPermission: () => this.userUtilities.isListeProAdmin || this.userUtilities.isAdvisor,
                                },
                                {
                                    route: { name: 'BapStatistics' },
                                    matchingRoutes: [
                                        'BapStatistics',
                                    ],
                                    label: () => 'Statistiques',
                                    icon: ['far', 'chart-area'],
                                    isVisible: () => true,
                                    hasPermission: () => this.userUtilities.isListeProAdmin || this.userUtilities.isAdvisor,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'communicationTools',
                    label: 'Outils de communication',
                    options: [
                        {
                            label: () => this.whiteLabelling.newslettersName,
                            description: () => this.whiteLabelling.newslettersBaseline,
                            icon: ['far', 'newspaper'],
                            route: { name: 'GroupOfNewslettersList' },
                            matchingRoutes: [
                                'NewslettersHome',
                                'GroupOfNewslettersList',
                                'GroupOfNewslettersEdit',
                                'GroupOfNewslettersCreate',
                                'NewsletterList',
                                'NewsletterEdit',
                                'NewsletterCreate',
                                'PublicNewslettersHome',
                                'PublicNewslettersList',
                                'PublicNewsletterView',
                            ],
                            isVisible: () => this.whiteLabelling.newslettersIsActive,
                            hasPermission: () => this.userUtilities.isSuperAdvisor,
                        },
                        {
                            label: () => this.whiteLabelling.eventsName,
                            description: () => this.whiteLabelling.eventsBaseline,
                            icon: ['far', 'calendar-alt'],
                            route: { name: 'EventList' },
                            matchingRoutes: [
                                'EventList',
                                'EventEdit',
                                'EventCreate',
                            ],
                            isVisible: () => this.whiteLabelling.eventsIsActive,
                            hasPermission: () => this.userUtilities.isSuperAdvisor || this.userUtilities.isAdvisor,
                        },
                        {
                            label: () => this.whiteLabelling.experiencesName,
                            description: () => this.whiteLabelling.experiencesBaseline,
                            icon: ['far', 'network-wired'],
                            route: { name: 'ExperienceList' },
                            matchingRoutes: [
                                'ExperiencesHome',
                                'ExperienceMenu',
                                'ExperienceList',
                                'ExperienceView',
                                'ExperienceEdit',
                                'ExperienceCreate',
                                'ExperienceStartAProject',
                                'ExperienceUsefullDocs',
                            ],
                            isVisible: () => this.whiteLabelling.experiencesIsActive,
                            hasPermission: () => this.userUtilities.isSuperAdvisor || this.userUtilities.isAdvisor,
                        },
                        {
                            label: () => 'Liste des inscriptions',
                            matchingRoutes: ['AttendeesPublicView'],
                            isVisible: () => false,
                            hasPermission: () => true,
                        },
                    ],
                },
                {
                    id: 'configuration',
                    label: 'Configuration',
                    options: [
                        {
                            label: () => 'Structures',
                            icon: ['far', 'building'],
                            route: { name: 'GroupList' },
                            matchingRoutes: ['GroupList', 'GroupEdit'],
                            isVisible: () => this.whiteLabelling.usermanagementIsActive,
                            hasPermission: () => this.userUtilities.isAdministrator || this.userUtilities.isManager,
                        },
                        {
                            label: () => 'Utilisateurs',
                            icon: ['far', 'users-class'],
                            route: { name: 'UserList' },
                            matchingRoutes: ['UserList', 'UserEdit'],
                            isVisible: () => this.whiteLabelling.usermanagementIsActive,
                            hasPermission: () => this.userUtilities.isAdministrator,
                        },
                        {
                            label: () => 'Lieux',
                            icon: ['far', 'map-marker-alt'],
                            route: { name: 'DialogwattPlaceList' },
                            matchingRoutes: ['DialogwattPlaceList', 'DialogwattPlaceEdit'],
                            isVisible: () => this.whiteLabelling.usermanagementIsActive,
                            hasPermission: () => this.userUtilities.isAdministrator || this.userUtilities.isManager,
                        },
                        {
                            label: () => 'Ma structure',
                            icon: ['far', 'building'],
                            route: {
                                name: 'MaStructure',
                                params: { pk: this.groupPk },
                            },
                            matchingRoutes: ['GroupEdit', 'UserEdit', 'UserCreate','RgpdPublicView'],
                            isVisible: () => this.whiteLabelling.usermanagementIsActive,
                            hasPermission: () => this.userUtilities.isSuperAdvisor,
                        },
                        {
                            label: () => 'Partage de projets',
                            icon: ['far', 'share-alt'],
                            route: { name: 'FacProjectShareList' },
                            matchingRoutes: ['FacProjectShareList', 'FacProjectShareNew', 'FacProjectShareEdit'],
                            isVisible: () => this.whiteLabelling.facStatisticsIsActive,
                            hasPermission: () => this.userUtilities.isAdministrator,
                        },
                        {
                            label: () => 'Comparaison',
                            description: () => 'Comparer les données incomplètes de FAC et les retours TBS',
                            icon: ['far', 'not-equal'],
                            route: { name: 'FacCompareTBSAndIncompleteData' },
                            matchingRoutes: ['FacCompareTBSAndIncompleteData'],
                            isVisible: () => this.whiteLabelling.facIsActive,
                            hasPermission: () => this.userUtilities.isAdministrator,
                        },
                        {
                            label: () => 'Courriel',
                            icon: ['far', 'mail-bulk'],
                            route: {
                                name: 'SmtpAccountEdit',
                                params: { pk: this.groupPk },
                            },
                            matchingRoutes: ['SmtpAccountEdit'],
                            isVisible: () => this.whiteLabelling.usermanagementIsActive,
                            hasPermission: () => this.userUtilities.isSuperAdvisor,
                        },
                        {
                            label: () => 'SMS',
                            icon: ['far', 'sms'],
                            route: {
                                name: 'SmsAccountEdit',
                                params: { pk: this.groupPk },
                            },
                            matchingRoutes: ['SmsAccountEdit'],
                            isVisible: () => this.whiteLabelling.dialogwattIsActive,
                            hasPermission: () => this.userUtilities.isSuperAdvisor,
                        },
                        {
                            label: () => 'Éditeur de projets',
                            icon: ['far', 'folder-open'],
                            route: {
                                name: 'FacProjectList',
                            },
                            matchingRoutes: ['FacProjectList', 'FacProjectEdit'],
                            isVisible: () => this.userUtilities.isAdministrator,
                            hasPermission: () => this.userUtilities.isAdministrator,
                        },
                    ],
                },
            ];
        },

        isLoaded() {
            return this.$store.state.whiteLabelling.id !== undefined && this.$store.state.whiteLabelling.id > 0;
        },
        
        displayedMenuCategories() {
            const menu = this.getMenuDict();
            
            const displayedCategories = menu.map(category => {
                let options = category.options.filter(option => option.isVisible() && option.hasPermission());
                options = options.map(subCategory => {
                    if (!subCategory.hasOwnProperty('options')) {
                        return subCategory;
                    }
                    return {
                        ...subCategory,
                        options: subCategory.options.filter(option => option.isVisible() && option.hasPermission()),
                    };
                });
                return {
                    ...category,
                    options: options,
                };
            });
            return displayedCategories.filter(category => category.options.length > 0);
        },
        allMenuCategories() {
            const menu = this.getMenuDict();
            
            const displayedCategories = menu.map(category => {
                let options = category.options.filter(option => option.hasPermission());
                options = options.map(subCategory => {
                    if (!subCategory.hasOwnProperty('options')) {
                        return subCategory;
                    }
                    return {
                        ...subCategory,
                        options: subCategory.options.filter(option => option.isVisible() && option.hasPermission()),
                    };
                });
                return {
                    ...category,
                    options: options,
                };
            });
            return displayedCategories.filter(category => category.options.length > 0);
        },
    },
    watch: {
        whiteLabelling() {
            this.$meta().refresh();
        },
        isAuthenticated: {
            handler(value) {
                if (value) {
                    this.fetchGlobalMessages();
                    this.setGlobalMessageUpdate();
                }
            },
            immediate: true,
        },
        globalMessages: {
            handler(value) {
                this.displayGlobalMessages();
            },
            immediate: false,
            deep: true,
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.url = document.URL.split('#')[1];
        this.handleResize();

        this.$store.commit('setVersionNumber', version);
        this.setJwtUpdate();
    },
    mounted() {
        this.$meta().refresh();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        setJwtUpdate() {
            this.jwtRefreshFunction = window.setInterval(this.refreshTokenIntervalTimer.bind(this), 3 * 60 * 1000);
        },

        setGlobalMessageUpdate() {
            this.globalMessageUpdateFunction = window.setInterval(this.fetchGlobalMessages.bind(this), 15 * 60 * 1000);
        },

        refreshTokenIntervalTimer() {
            console.log("refreshTokenIntervalTimer-weblayout");
            if (this.$store.state.jwt !== null) {
                apiClient.refreshTokenWithPromise(store.state.jwt)
                    .catch(error =>{
                        switch (error.response.status) {
                            case 426:
                            // Special ACTION
                                if (error.response.data.action === 'RELOAD') {
                                    console.log("FORCE RELOAD");
                                    document.location.href = `https://${store.state.whiteLabelling.domain}`;
                                } else if (error.response.data.action.startsWith('MESSAGE')) {
                                    const message = error.response.data.action.substr('MESSAGE'.length + 1);
                                    console.log("Message:", message);
                                    alert(message);
                                }

                                break;
                            case 502:
                                console.log("Temporary error, do nothing");
                                break;
                            default:
                                store.commit('removeToken');
                                this.$router.push('AccountLogin');                            
                        }
                    });
            }
        },

        constructPageTitle() {
            /* Page title is constructed like this :
               current page – application – mixeur
               The store `pageTitle` property overrides the current page name,
               which is otherwhise taken from the menu.
            */
            
            const pageTitle = this.getPageTitle();
            const breadcrumb = this.getBreadcrumb();
            const siteTitle = this.getSiteTitle();
            
            return this.whiteLabelling.reverseTabTitle ? this.orderTitleElements(siteTitle, breadcrumb, pageTitle):this.orderTitleElements(pageTitle, breadcrumb, siteTitle);
        },

        getMenuDict() {
            let menu = this.anonymousMenu;
            if (!this.userUtilities.isAuthenticated) {
                menu = this.anonymousMenu;
            }
            else if (this.userUtilities.isProfessional) {
                menu = this.professionalMenu;
            }
            else {
                menu = this.menuCategories;
            }
            return menu;
        },
        
        orderTitleElements(firstElement, secondElement, thirdElement) {
            let title = firstElement;
            if (secondElement.length > 0 && title.length > 0) {
                title += " - ";
            }
            title += secondElement;
            if (thirdElement.length > 0 && title.length > 0) {
                title += " - ";
            }
            title += thirdElement;
            return title;
        },

        getPageTitle() {
            let title = '';
            if (this.$store.state.pageTitle) {
                title += this.$store.state.pageTitle;
            } else {
                const label = (route => {
                    for (const category of this.menuCategories) {
                        for (const subCategory of category.options || []) {
                            for (const subSubCategory of subCategory.options || []) {
                                if (subSubCategory.matchingRoutes.includes(route)) {
                                    return subSubCategory.label();
                                }
                            }
                        }
                    }
                    return '';
                })(this.$route.name);
                if (label) {
                    title += label;
                }
            }
            return title;
        },

        getBreadcrumb() {
            if (this.breadcrumb.length > 1) {
                return this.breadcrumb[1].label;
            }
            return '';
        },

        getSiteTitle() {
            return this.whiteLabelling.siteTitle;
        },
        
        foldDesktopMenu() {
            this.isDesktopMenuFolded = true;
        },
        unfoldDesktopMenu() {
            this.isDesktopMenuFolded = false;
        },
        openMenu() {
            // to prevent content shrinking when closing, width must be same width as in .mobile-menu-content
            this.$refs.mobileMenu.style.width = '300px';
            this.isMenuOpen = true;
        },
        closeMenu() {
            this.$refs.mobileMenu.style.width = '0';
            this.isMenuOpen = false;
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        getRealLabel(label) {
            if (typeof label === 'string') {
                return label;
            } else if (typeof label === 'function') {
                return label();
            }
        },
        getKeyFromLabel(label) {
            if (typeof label === 'string') {
                return label.replace(' ', '');
            } else if (typeof label === 'function') {
                return label().replace(' ', '');
            }
        },
        getDomain() {
            return utils.getDomainFromUrl();
        },
        fetchGlobalMessages() {
            if (!this.isAuthenticated) {
                return;
            }
            apiClient.genericGet('/global-messages/messages-for-user/')
                .then(response => {
                    this.globalMessages = response;
                })
                .catch(() => {
                    // Ignore silently
                });
        },
        async displayGlobalMessagesClicked() {
            this.showGlobalMessageList = !this.showGlobalMessageList;
        },
        async displayGlobalMessages() {
            if (this.globalMessages.newMessages.length > 0) {
                for (const message of this.globalMessages.newMessages) {
                    // eslint-disable-next-line no-unused-vars
                    const x = await this.displayTheGlobalMessage(message);
                }
            }
        },
        displayTheGlobalMessage(message) {
            return new Promise((resolve) => {
                this.showGenericDialog({
                    title: message.title,
                    text: message.messageHtml,
                    buttons: [
                        {
                            title: 'Ok',
                            default: true,
                            handler: () => {
                                this.showGlobalMessageList=false;
                                this.markMessageAsSeen(message.pk);
                                resolve(message.pk);
                            },
                        },
                    ],
                });
            });

        },
        markMessageAsSeen(messagePk) {
            apiClient.genericPost('/global-messages/mark-message-as-read-by-user/0/', {pk: messagePk})
                .then(response => {
                    const seenMessages = this.globalMessages.newMessages.filter(x => x.pk === messagePk);
                    if (seenMessages.length > 0) {
                        this.globalMessages.seenMessages.push(
                            seenMessages[0]
                        );
                        this.globalMessages.newMessages = this.globalMessages.newMessages.filter(x => x.pk !== messagePk);
                    }
                });            
        },
        disconnect() {
            // reset stores to initial state
            store.dispatch('resetStore');

            this.globalMessageUpdateFunction = null;
            this.globalMessages = {newMessages: [], seenMessages: []};
            this.showGlobalMessageList = false;
            

            apiClient.logout();
            this.$router.push({ name: 'AccountLogin' });
        },
        findBreadcrumbPath(node, routeName) {
            const result = (node.matchingRoutes || []).find(route => route === routeName);
            if (result) {
                return [
                    {
                        label: this.getRealLabel(node.label),
                        description: this.getRealLabel(node.description),
                    },
                ];
            }
            const childrenResults = (node.options || []).reduce(
                (tab, childNode) => tab.concat(this.findBreadcrumbPath(childNode, routeName)),
                [],
            );
            /* eslint-disable indent */
            return childrenResults.length
                ? childrenResults.concat([
                      {
                          label: this.getRealLabel(node.label),
                          description: this.getRealLabel(node.description),
                      },
                  ])
                : [];
            /* eslint-enable indent */
        },
    },
};
</script>

<style lang="stylus">
@require '../stylesheet/variables'
@require '../stylesheet/typography'
@require '../stylesheet/listepro_font'

.version-mismatch
    background: error-color
    margin: auto

    .content
        padding-top: space-md
        padding-bottom: space-xxs
        font-size: text-sm
        color: white

        a
        a:visited
        a:hover
            color: white
            text-decoration: underline
            font-weight: bold

#page-wrap
    transition: opacity 0.4s

    &.blurred
        opacity: 0.4

.login-icon-menu
    margin-right: 2rem
    margin-top: 0.5rem

.login-menu-item-icon svg
    font-size: 2rem
    color: #fff

.menu-btn button
    padding-left: 0

div.menu
    z-index: 3000
    margin-right: 3rem

.fixed-header
    height: 2.5rem
    background: primary-color-500
    color: white
    padding: 0 1rem

    a
        color: alert-color

    .connect-items
        height: 100%

        .global-messages
        .connect-button
            height: 100%
            cursor: pointer
            background: primary-color-600
            color: primary-color-800

            a
                color: white

            &:hover
                background: primary-color-700
                color: primary-color-900

        .global-messages
            color: white
            background: primary-color-500

            &:hover
                background: primary-color-600
                color: white

            .global-messages-list-hidden
                display: none

            .global-messages-list-show
                position: absolute
                top: 40px
                right: 56px
                cursor: auto
                color: text-color
                background: white
                padding: 20px 20px 10px 10px
                z-index: 1000
                box-shadow: rgba(27, 33, 58, 0.4) 0px 20px 60px -2px

                ul
                    margin-left: 0
                    list-style-type: none

                    li
                        color: primary-color
                        cursor: pointer

                    li:hover
                        color: primary-color-600

        .global-messages-hidden
            display: none

.breadcrumb-bar
    margin: 1rem 1rem 0 1rem
    padding-bottom: 0.4rem
    border-bottom: 1px solid line-color

    ul.breadcrumbs
        border: none
        padding-bottom: 0
        margin-bottom: 0

.app-header
    padding: 1rem 2rem 2rem 2rem

.app-header-title
    color: primary-color

.menu-item
    padding: 0.5rem 1rem

.permanent-menu
    border-right: 1px solid medium-gray

    .menu-item
        color: body-font-color

        &:hover
            border-right: 5px solid primary-color
            background-color: primary-color-100

    .menu-category
        color: primary-color
        border-top: 1px solid medium-gray

        .menu-item
            color: primary-color

.mobile-menu
    &.mobile-menu-opened
        border-right: 1px solid medium-gray

    height: 100%
    width: 0
    position: fixed
    z-index: 1
    top: 0
    left: 0
    overflow-x: hidden
    transition: 0.5s
    background-color: #F9F9F9

    .mobile-menu-content
        width: 300px // prevent content shrinking when closing - must be same width as in openMenu()

    .accordion
        background-color: #F9F9F9

    .menu-item
        color: body-font-color

    .menu-category
        color: body-font-color
        border-top: 1px solid medium-gray

        .menu-item
            color: primary-color

.fade-enter-active
.fade-leave-active
    transition: opacity 0.15s

.fade-enter
.fade-leave-to
    opacity: 0

.version
    color: #ccc
    font-size: 0.7rem
    text-align: right
    display: block
    margin-top: 1rem
    margin-right: 0.5rem

hr
    border-bottom: 1px solid #555

svg
    min-width: 25px

a.admin-tools span
    color: #aaa

div.vue-avatar--wrapper
    display: inline-block !important
    vertical-align: middle

.pic-profile
    margin-top: 1rem

.user-avatar
    display: inline-block
    padding-bottom: 0.5rem

.menu-close
    float: right
    margin-right: space-xxs
    margin-top: space-md
    color: gray-200
    font-size: text-xl

.menu-close:hover
    color: primary-color-500

dialog
    border: none
    box-shadow: rgba(27, 33, 58, 0.4) 0px 20px 60px -2px
    text-align: left

.confirm-dialog
.generic-dialog
    .buttons
        width: 100%
        display: flex
        gap: 1rem
        justify-content: flex-end

.generic-dialog
    width: 600px
</style>
