const api = {
    regionsList() {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get('/territories/regions/')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    departementsList(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/territories/departements/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    epcisList(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/territories/epcis/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    epcisForGroupList(group) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/territories/epcis_for_group/?for_group=${group}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    communesByDepartementList(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/territories/communes_by_dep/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    communesByEpciList(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/territories/communes_by_epci/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    communesForGroupList(pk) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`/territories/communes_for_group/${pk}/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
