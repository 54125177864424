x<template>
  <a
    class="button regular-button"
    @click="$emit('click')"
    :disabled="isLoading || disabled"
  >
    <spinner
       :active="isLoading"
       size="30"
       />
    <slot class="slot"></slot>
  </a>
</template>

<script>
import Spinner from '@/components/spinner';

export default {
    name: 'RegularButton',
    components: {
        Spinner,
    },
    props: {
        isLoading: { default: false, type: Boolean },
        disabled: { default: false, type: Boolean },
    },
};
</script>

<style lang='stylus' scoped>
@require '../stylesheet/variables'

.regular-button
    border: none

.regular-button
.regular-button:hover
    color: contrast-primary-color
</style>
