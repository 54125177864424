/*
    Autogenerated class model from django Helper
    -- 2023-03-13 18:02:20.826977
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class HelperClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'name', rawData.name);
        Vue.set(this, 'helpText', rawData.helpText);
    }

    getBaseUrl() {
        return '/listepro/helper/';
    }

    get isValid() {
        return true;
    }
}

class HelperCollection extends BaseCollection {
    initModel(rawData) {
        return new HelperClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/helper/';
    }
}

class HelperStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new HelperClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/helper/';
    }

    getStorePath() {
        return 'listepro/helperCollection';
    }
}

export {
    HelperClass,
    HelperCollection,
    HelperStoreCollection,
};
