export default {
    thermalCalculation: {
        heatedSurface: null,
        cepHeatingSurface: null,
        cepHotWaterSurface: null,
        heatingSystems: [],
        hotWaterSystems: [],
    },
    newBuilding: {
        altitude: '0',
        baseEfficiency: 50,
        targetedEfficiency: null,
        defaultTargetedEfficiency: null,
        heatedSurface: null,
        occupants: null,
    },
    globalRenovation: {
        renovationPerformanceGoal: null,
        heatedSurface: null,
        occupants: null,
    },
    systemsRenovationBuilding: {
        buildingHeatingConsumption: null,
        heatedSurface: null,
        occupants: null,
    },
    systemsRenovationInvoiceEuro: {
        occupants: null,
        energyTypes: [],
        heatingSystems: [],
        hotWaterSystems: [],
        invoices: [],
        emptyInvoice: {
            energyTypeIdentifier: null,
            initialEnergyPrice: null,
            energyPrice: null,
            amount: null,
            heatingSystems: [],
            hotWaterSystems: [],
        },
    },
    systemsRenovationInvoiceQuantity: {
        occupants: null,
        energyTypes: [],
        heatingSystems: [],
        hotWaterSystems: [],
        invoices: [],
        emptyInvoice: {
            energyTypeIdentifier: null,
            quantity: null,
            possibleVectors: [],
            vector: null,
            heatingSystems: [],
            hotWaterSystems: [],
        },
    },
    altitudePerformanceBonus: {
        0: { label: 'Inférieure à 400m', bonus: '0' },
        400: { label: 'Entre 400m et 800m', bonus: '10' },
        800: { label: 'Supérieure à 800m', bonus: '20' },
    },

};
