<template>
  <div class="public-newsletters">
  <layout-container>
    <router-view />
  </layout-container>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import LayoutContainer from '@/components/layout_container';

export default {
    name: 'PublicNewslettersLayout',
    mixins: [],
    components: {
        LayoutContainer,
    },
    metaInfo() {
        return {
            style: [
                {
                    cssText: this.whiteLabelling.style,
                    type: 'text/css',
                },
            ],
        };
    },
    mounted: function() {
        this.$meta().refresh();
    },
    data() {
        return {};
    },
    watch: {
        whiteLabelling: function() {
            this.$meta().refresh();
        },
    },
    props: [],

    computed: { ...mapFields(['whiteLabelling']) },
};
</script>

<style lang="stylus" scoped></style>
