import defaultState from './_defaultState';
import { updateField } from 'vuex-map-fields';

export default {
    RESET_STATE(state) {
        Object.assign(state, defaultState);
    },
    UPDATE(state, values) {
        Object.assign(state, values);
    },
    updateField,
};
