export default {
    solutions: Array.from(Array(4), () => {
        return {
            heatingSystems: [],
            hotWaterSystems: [],
            renewableElectricity: false,
            renewableElectricityProd: '',
            isMetroGrenoble: false,
            financialSupport: false,
            financialSupportAmount: '',
        };
    }),
};
