/*
    Autogenerated class model from django
    -- 2019-11-06 17:36:23.383650
*/

import { make } from 'vuex-pathify';

const defaultState = {
    isLoading: 0,
    communeCollection: {},
    communeCollectionFilter: null,
    communeCollectionLastUpdate: 0,
    departementCollection: {},
    departementCollectionFilter: null,
    departementCollectionLastUpdate: 0,
    epciCollection: {},
    epciCollectionFilter: null,
    epciCollectionLastUpdate: 0,
    regionCollection: {},
    regionCollectionFilter: null,
    regionCollectionLastUpdate: 0,

};

const state = Object.assign({}, (defaultState));
const getters = make.getters(state);

const mutations = {
    ...make.mutations(state),

    resetState(state) {
        Object.assign(state, defaultState);
    },

    incrementLoading(state) {
        state.isLoading++;
    },

    decrementLoading(state) {
        state.isLoading--;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
