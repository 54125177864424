const faceNatures = ['roof', 'floor', 'wall', 'window'];
const systemNatures = [
    'heating-main',
    'emitter',
    'controler',
    'hot-water',
    'heating-extra',
    'ventilation',
    'photovoltaic',
];
const recommendationsCategoriesNatures = {
    enveloppe: ['roof-insulation', 'wall-insulation', 'floor-insulation', 'carpentry-replacement'],
    systems: [
        'ventilation',
        'heating-production',
        'hot-water-production',
        'heating-control',
        'heating-emitter',
        'photovoltaic',
    ],
    others: ['calorifuge', 'water-tank-insulation', 'additional-costs'],
    'eco-gestures': ['eco-gestures'],
};
const financialAids = [
    'anah',
    'cee-regular',
    'cee-more',
    'action-logement',
    'prime-renov',
    'local-help-1',
    'local-help-2',
    'local-help-3',
    'local-help-4',
    'local-help-5',
];
const financings = ['ecoptz', 'loan-1', 'loan-2', 'loan-3'];
const primaryScenarioSummaries = [
    'primary-1',
    'primary-2',
    'primary-3',
    'primary-4',
    'primary-5',
    'primary-6',
    'primary-7',
];
const secondaryScenarioSummaries = ['secondary-1', 'secondary-2', 'secondary-3'];
const stepsCategoriesMilestonesNatures = {
    contacts: {
        advises: ['project-owner', 'caue', 'adil', 'anah'],
        estimation: ['craftsperson', 'administration'],
        'work-beginning': ['prepare-work-end'],
        'work-end': ['check-work-end'],
    },
    financing: {
        estimation: ['ecoptz', 'cee-fill', 'anah-help', 'prime-renov', 'local-helps-fill'],
        'estimation-signature': [],
        'work-beginning': [],
        'work-end': ['local-helps-send', 'prime-renov-send', 'cee-send'],
    },
};
const appendixNames = ['ecorenover', 'thermix', 'financial', 'liste-pro'];

function today() {
    const todayDate = new Date(Date.now());
    const month = todayDate.getMonth() + 1 >= 10 ? todayDate.getMonth() + 1 : '0' + (todayDate.getMonth() + 1);
    const day = todayDate.getDate() >= 10 ? todayDate.getDate() : '0' + todayDate.getDate();
    return `${todayDate.getFullYear()}-${month}-${day}`;
}

const defaultState = {
    isReportLoaded: false,
    pdfParams: {
        type: 'generic',
    },
    contactEntity: {},
    housing: {
        address: '',
        area: 0,
        heatArea: 0,
        city: '',
        housingType: '',
        inseecode: '',
        groups: [],
        isMainAddress: false,
        note: '',
        occupantsNumber: 0,
        ownership: '',
        pk: 0,
        postcode: '',
        user: undefined,
        ownershipOtherLabel: '',
        housingTypeOtherLabel: '',
        year: '1900',
        contactEntity: {},
    },
    report: {
        advisor: 0,
        consumptionElectricity: 0,
        consumptionHeating: 0,
        consumptionHeatingHotWater: 0,
        consumptionHotWater: 0,
        consumptionLighting: 10,
        consumptionAuxiliary: 9,
        consumptionAirConditionning: 0,
        consumptionInformationSource: '',
        customAppendix: '',
        dpe: '',
        fiscalIncome: 0,
        fiscalIncomeYear: '',
        group: 0,
        housing: 0,
        isCustomAppendixSelected: false,
        isSecondaryScenarioDisplayed: true,
        visitDate: today(),
        // comments
        identityComment: '',
        housingComment: '',
        consumptionComment: '',
        conclusionComment: '',
        houseInventoryComment: '',
        workRecommendationsComment: '',
        faces: [
            ...faceNatures.map(faceNature => {
                return {
                    nature: faceNature,
                    evaluation: 2,
                    comment: '',
                    data: '{}',
                };
            }),
        ],
        systems: [
            ...systemNatures.map(systemNature => {
                return {
                    nature: systemNature,
                    data: '{}',
                };
            }),
        ],
        workRecommendations: [
            ...Object.keys(recommendationsCategoriesNatures)
                .map(category =>
                    recommendationsCategoriesNatures[category].map(nature => {
                        return {
                            category,
                            nature,
                            name: '',
                            comment: '',
                            cost: 0,
                            data: '{}',
                            selected: false,
                            selectedScenarioSecondary: true,
                            selectedScenarioPrimary: true,
                        };
                    }),
                )
                .reduce((acc, currentValue) => acc.concat(currentValue)),
        ],
        scenarios: [
            {
                customSummary: '',
                label: 'Scénario prioritaire',
                isCustomSummarySelected: false,
                nature: 'primary',
                financialAids: [
                    ...financialAids.map(aid => {
                        return {
                            nature: aid,
                            amount: 0,
                            customLabel: '',
                            selected: false,
                        };
                    }),
                ],
                financings: [
                    ...financings.map(aid => {
                        return {
                            nature: aid,
                            amount: 0,
                            customLabel: '',
                            selected: false,
                        };
                    }),
                ],
                scenarioSummaries: primaryScenarioSummaries.map(summary => {
                    return {
                        nature: summary,
                        selected: true,
                    };
                }),
            },
            {
                customSummary: '',
                label: 'Scénario secondaire',
                nature: 'secondary',
                financialAids: [
                    ...financialAids.map(aid => {
                        return {
                            nature: aid,
                            amount: 0,
                            customLabel: '',
                            selected: false,
                        };
                    }),
                ],
                financings: [
                    ...financings.map(aid => {
                        return {
                            nature: aid,
                            amount: 0,
                            customLabel: '',
                            selected: false,
                        };
                    }),
                ],
                scenarioSummaries: [
                    ...secondaryScenarioSummaries.map(summary => {
                        return {
                            nature: summary,
                            selected: true,
                        };
                    }),
                ],
            },
        ],
        steps: Object.entries(stepsCategoriesMilestonesNatures)
            .map(([category, milestones]) =>
                Object.entries(milestones)
                    .map(([milestone, natures]) =>
                        natures.map((nature, index) => {
                            return {
                                category,
                                milestone,
                                nature,
                                stepType: 'simple',
                                data: '{}',
                                order: index + 1,
                                selected: true,
                            };
                        }),
                    )
                    .reduce((acc, currentValue) => acc.concat(currentValue), []),
            )
            .reduce((acc, currentValue) => acc.concat(currentValue), []),
        appendixTable: appendixNames.map(appendixName => {
            return {
                name: appendixName,
                selected: true,
            };
        }),
    },
};

export default defaultState;
