<template>
  <div class="simulaides">
    <iframe
       src="https://www.simulaides.ademe.fr/front-home/L6tr7k896UZUDx9fHdh5/FCC012/b871c5/b871c5/"
       title="Simul’Aides"
       width="100%" height="800px" style="overflow-y:visible;">
    </iframe>
  </div>
</template>

<script>
export default {
    name: 'Simulaides',
    data: function() {
        return {
        };
    },
};
</script>

<style scoped>
</style>
