<template>
<field-wrapper
  :class="{'inline': inline }"
  class="text-field"
  :field-data="fieldData"
  ref="wrapper"
>
  <div class="wrapper">
    <div class="input-group">
      <input
        class="input-group-field"
        v-model="localValue"
        :type="localType"
        :readonly="readonly"
        :ref="id"
        :name="id"
        :placeholder="placeholder"
        :id="id"
        @blur="emitEvent('blur')"
        @keyup="emitEvent('keyup',$event)"
        :step="step"
        :inputmode="inputMode"
        :min="min"
        :max="max"
        :maxlength="maxlength"
        :title="title"
      />
      <span class="input-group-label" v-if="unit">
        <span
          v-if="typeIsPassword"
          class="input-icon show-password"
          tabindex="-1"
          @mousedown.stop="displayPassword"
          @mouseup.stop="hidePassword"
          @touchstart.stop="displayPassword"
          @touchend.stop="hidePassword"
          v-html="unit"
        ></span>
        <span v-else class="input-unit" tabindex="-1" @click="setFocus" v-html="unit"></span>
      </span>
      <span class="input-group-label" v-if="icon">
        <span
          v-if="typeIsPassword"
          class="input-icon show-password"
          tabindex="-1"
          @mousedown.stop="displayPassword"
          @mouseup.stop="hidePassword"
          @touchstart.stop="displayPassword"
          @touchend.stop="hidePassword"
        >
          <font-awesome-icon :icon="icon" />
        </span>
        <span v-else class="input-icon" tabindex="-1" @click="emitEvent('icon-click')">
          <font-awesome-icon :icon="icon" />
        </span>
      </span>
    </div>
  </div>
</field-wrapper>
</template>

<script>
import fieldMixin from './field_mixin';

export default {
    name: 'TextField',
    mixins: [fieldMixin],
    props: {
        value: { default: '' },
        icon: { default: '', type: [String, Array] },
        unit: { default: '', type: String },
        inline: { default: false, type: Boolean },
        placeholder: { default: '', type: String },
        type: { default: 'text', type: String },
        readonly: { default: false, type: Boolean },
        step: { type: String, default: '1' },
        title: { type: String, default: '' },
        max: { type: String, default: null },
        maxlength: { type: String, default: 'false' },
        min: { type: String, default: null },
    },
    data() {
        return {
            localType: '',
            localValue: '',
        };
    },
    computed: {
        typeIsPassword: function() {
            return this.type === 'password';
        },
        typeIsNumber() {
            return this.type === 'number';
        },
        inputMode() {
            return this.typeIsNumber ? 'numeric' : '';
        },
    },
    methods: {
        setFocus: function() {
            this.$refs[this.id].focus();
        },
        displayPassword: function() {
            this.localType = 'text';
        },
        hidePassword: function() {
            this.localType = 'password';
        },
        emitEvent: function(eventName, event) {
            this.$emit(eventName, this.value, event);
        },
        parseFloat(value) {
            value = typeof value === 'string' ? value.replace(',', '.') : value;
            const number = parseFloat(value);
            return isNaN(number) ? value : number;
        },
    },
    watch: {
        type: {
            handler(type) {
                this.localType = type;
            },
            immediate: true,
        },
        value: {
            handler(value) {
                this.localValue = value;
            },
            immediate: true,
        },
        localValue: {
            handler(localValue) {
                const val = this.typeIsNumber ? this.parseFloat(localValue) : localValue;
                this.$emit('input', val);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../stylesheet/variables'

.input-group-label
.show-password:hover
    cursor: pointer

input[type=number]
    text-align: right

input[type='number']::-webkit-outer-spin-button
input[type='number']::-webkit-inner-spin-button
    margin-left: 10px
    cursor: pointer

.wrapper
    flex-basis: 100%

.inline
    margin-bottom: 0.6rem

    > .label-container > label
        height: 2.4375rem
        line-height: 2.4375rem
        padding-right: 1rem

    > .wrapper
        display: flex
        flex-basis: 0%
        flex-direction: column
        flex-grow: 1

.input-group
    margin: 0
</style>
