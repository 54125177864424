<template>
<div class="liste-card">
  <div class="favorite-box" v-if="!printMode" >
    <a class="checkbox" title="Mettre dans la sélection">
      <font-awesome-icon
        v-show="!selected"
        :icon="['far', 'square']"
        class="favorite"
        @click="toggleFavoritePro"
        />
    </a>
    <a class="checkbox" title="Retirer de la sélection">
      <font-awesome-icon
        v-show="selected"
        icon="check-circle"
        class="favorite"
        @click="toggleFavoritePro"
        />
    </a>
  </div>

  <div class="logo-name-and-job">
    <div v-if="showLogo" class="logo" >
      <img v-bind:src="professional.logo" width="100" height="150" @error="logoLoadError">
    </div>

    <div class="name" :class="{ 'name-with-logo': showLogo}">
      <div class="basic-info-without-logo" :class="{ 'basic-info-with-logo': showLogo}">
        <div class="title">
          {{professional.name}}
        </div>

        <div class="website" v-if="professional.url">
          <gtm-wrapper
            label="Voir le site web"
            gtm-event="url-on-detail-view"
            gtm-category="ListePro"
            gtm-action="click-to-show"
            :gtm-label="professional.name"
            :gtm-value="professional.url"
            >
            <template v-slot:target>
              <a :href="professional.url" target="_blank"><strong>{{professional.url}}</strong></a>
            </template>
          </gtm-wrapper>
        </div>
        
        <div class="email" v-if="professional.email">
          <gtm-wrapper
            label="Voir l'adresse mail"
            gtm-event="url-on-detail-view"
            gtm-category="ListePro"
            gtm-action="click-to-show"
            :gtm-label="professional.name"
            :gtm-value="professional.email"
            >
            <template v-slot:target>
              <a :href="'mailto:' + professional.email">{{professional.email}}</a>
            </template>
          </gtm-wrapper>
        </div>                                 
        
        <span class="job">
          {{professional.job}}
        </span>
      </div>        
    </div>
    
  </div>

  
  <div class="address-and-phone">

    <div v-if="professional.isMonAccompagnateurRenov">
      <img src="/static/img/listepro/mon-accompagnateur-renov.png" class="icon-mar">
    </div>

    <div class="address" v-if="professional.address">
      <gtm-wrapper
        label="Voir l'adresse"
        gtm-event="url-on-detail-view"
        gtm-category="ListePro"
        gtm-action="click-to-show"
        :gtm-label="professional.name"
        :gtm-value="professional.address"
        >
        <template v-slot:target>
          {{professional.address}}
        </template>
      </gtm-wrapper>
    </div>
    
    <div class="phone-number" v-if="professional.phoneNumber">
      <gtm-wrapper
        v-if="professional.phoneNumber"
        label="Voir le numéro de téléphone"
        gtm-event="url-on-detail-view"
        gtm-category="ListePro"
        gtm-action="click-to-show"
        :gtm-label="professional.name"
        :gtm-value="professional.phoneNumber"
        >
        <template v-slot:target>
          Tél : <a :href="'tel:'+professional.phoneNumber">{{professional.phoneNumber}}</a>
        </template>
      </gtm-wrapper>
    </div>
      
  </div>


  <div class="keywords">
    <div class="keyword" v-bind:key=keyword.id v-for="keyword in professional.primaryKeyWords">
      {{ keyword }}
    </div>
  </div>
  
  <div v-if="!printMode" class="actions">
    <a @click="locateProfessional(professional.id)" class="locate-professional">
      <strong>
        Localiser
        <font-awesome-icon icon="map-marker-alt"/>
      </strong>
    </a>
    <regular-button class="goto-professional" @click="$router.push({ name: 'ListeProPublicDetail', params: { pk: professional.id } })">
      voir la fiche
      <font-awesome-icon icon="chevron-circle-right" class="icon"/>
    </regular-button>
  </div>
</div>
</template>

<script>

import GtmWrapper from '@/components/gtm_wrapper';
import RegularButton from '@/components/regular_button';

import { dispatch, sync } from 'vuex-pathify';

export default {
    name: 'ProfessionalCardInList',
    mixins: [],
    components: {
        GtmWrapper,
        RegularButton,
    },
    props: {
        professional: { default: undefined, type: Object },
        allMode: { default: false, type: Boolean },
        printMode: { default: false, type: Boolean },
    },
    data: function() {
        return {
            all: false,
            showLogo: true,
        };
    },
    computed: {
        favoritePro: sync("listepro/favoritePro"),
        selected() {
            let index = [];
            try {
                index = this.favoritePro.value.findIndex(x => x === this.professional.pk);
            }
            catch {
                index = this.favoritePro.findIndex(x => x === this.professional.pk);
            }
            return index !== -1;
        },
    },
    methods: {
        locateProfessional(professionalPk) {
            this.$emit("locate-professional", professionalPk);
        },
        logoLoadError() {
            this.showLogo = false;
        },
        toggleFavoritePro() {
            dispatch("listepro/setProfessionalAsFavorite", {professionalPk: this.professional.pk, state: !this.selected});
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../../stylesheet/typography'
@require '../../../../stylesheet/variables'

.liste-card
    position: relative
    border: 1px solid #c6c6c6
    width: 370px
    min-height: 427px
    padding: space-xs space-md
    overflow-x: clip
    overflow-y: auto
    font-size: 1rem
    color: #1d1d1b
    display: flex
    flex-direction: column
    justify-content: space-between

    .icon-mar
        width: 80px
        float: right

    .favorite-box
        position: absolute
        right: 10px

    .logo-name-and-job
        display: grid
        grid-template-columns: 100px auto
        grid-template-rows: auto
        column-gap: space-xl
        row-gap: space-xl
        justify-items: start
        align-items: start
        justify-content: start
        align-content: start

        .logo
            grid-column: 1
            grid-row: 1

        .name
            grid-column: 1 / 3
            grid-row: 1

            .title
                font-size: text-md
                font-weight: bold
                text-transform: uppercase
                word-break: break-word
                width: 90%

            .website
                font-weight: normal
                word-break: break-word

            .email
                font-weight: normal
                word-break: break-word

            .job
                display: inline-block
                font-size: 0.75rem
                text-transform: uppercase
                background-color: #000
                padding: 0.2rem
                padding-left: space-xs
                padding-right: space-xs
                color: #fff

        .name-with-logo
            grid-column: 2
            grid-row: 1

    .keywords
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: flex-start
        padding: 5px

        .keyword
            display: inline-block
            border: 1px solid gray
            color: gray
            font-size: 0.75rem
            margin: 2px
            margin-right: space-xs
            padding: 6px space-xs

    .actions
        font-weight: normal
        margin-top: space-xs

        .locate-professional
            display: block
            float: left
            padding-top: 8px

        .goto-professional
            float: right

    @media (max-width: 600px)
        .locate-professional
            display: none !important
</style>
