import { initNeedsEstimationWatchers } from './needs_estimation/watchers';

import { store } from '@/store/store';

const unwatchFunctions = [];

export function addWatcher(watchFn, triggerFn) {
    unwatchFunctions.push(
        store.watch(watchFn, triggerFn)
    );
}

export function unwatchAll() {
    while (unwatchFunctions.length) {
        const unawatchFn = unwatchFunctions.shift();
        if (typeof unawatchFn === 'function') unawatchFn();
    }
}

export function initStoreWatchers() {
    // Fire when housing category or multi unit size change
    addWatcher(
        (state, getters) => [
            state.thermix.needs.housingCategory,
            getters['thermix/needs/multiUnitSize'],
            state.thermix.needs.renovationType,
            state.thermix.metroGrenoble.boilerPower,
            getters['thermix/needs/isManagingHeating'],
            getters['thermix/needs/isManagingHotWater'],
        ].join(''),
        () => {
            // if metro grenoble, force solutions before refreshing system defs values
            if (store.state.thermix.metroGrenoble.enabled) {
                store.dispatch('thermix/metroGrenoble/forceMetroGrenobleSolutions');
            }
            store.dispatch('thermix/productionSystemDefs/refreshSystemDefs');
        },
    );

    // Fire when a solution validity state change
    addWatcher(
        (state, getters) => getters['thermix/solutions/solutionsValidityString'],
        () => { store.dispatch('thermix/solutions/cleanSolutions'); },
    );

    // init system renovation system euro and quantity specific watchers
    unwatchFunctions.push(...initNeedsEstimationWatchers());
}
