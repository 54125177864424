// import { store } from '@/store/store';

const api = {
    customForm(model, anchor, actionModel, folderModel, group, project) {
        if (
            actionModel === null &&
                folderModel === null &&
                group === null &&
                project === null &&
                typeof (actionModel) === 'number' &&
                typeof (folderModel) === 'number' &&
                typeof (group) === 'number' &&
                typeof (project) === 'number'
        ) {
            return new Promise((resolve, reject) => {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ response: 'must set actionModel, folderModel, group or project' });
            });
        }
        let url = `/custom-forms/custom-form/${model}/${anchor}/?`;
        if (actionModel) {
            url += `&action_model=${actionModel}`;
        }
        if (folderModel) {
            url += `&folder_model=${folderModel}`;
        }
        if (group) {
            url += `&group=${group}`;
        }
        if (project) {
            url += `&project=${project}`;
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    // if (error.response.status !== 404) {
                    reject(error);
                    // }
                });
        });
    },
    customFormLastUpdate() {
        const url = '/custom-forms/custom-form/last-update/';
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export default api;
