<template>
<div class="status-as-icon">
  <font-awesome-icon
    v-if="value==='published'"
    title="publié"
    icon="check-circle"
    class="published"
    />
  <font-awesome-icon
    v-if="value==='unpublished'"
    title="non publié"
    icon="times-circle"
    class="unpublished"
    />
  <font-awesome-icon
    v-if="value==='draft'"
    title="brouillon"
    icon="edit"
    class="draft"
    />
</div>
</template>

<script>
export default {
    name: 'StatusAsIcon',
    components: {
    },
    props: [
        'value',
    ],
    data: function() {
        return {
        };
    },
    methods: {
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../stylesheet/typography'
@require '../../../stylesheet/variables'

:deep(.published)
    color: success-color

:deep(.draft)
    color: warning-color

:deep(.unpublished)
    color: error-color
</style>
