/*
    Autogenerated class model from django ConsumptionUsage
    -- 2023-03-13 18:02:20.826583
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class ConsumptionUsageClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'order', rawData.order);
        Vue.set(this, 'name', rawData.name);
    }

    getBaseUrl() {
        return '/listepro/consumption-usage/';
    }

    get isValid() {
        return true;
    }
}

class ConsumptionUsageCollection extends BaseCollection {
    initModel(rawData) {
        return new ConsumptionUsageClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/consumption-usage/';
    }
}

class ConsumptionUsageStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new ConsumptionUsageClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/consumption-usage/';
    }

    getStorePath() {
        return 'listepro/consumptionUsageCollection';
    }
}

export {
    ConsumptionUsageClass,
    ConsumptionUsageCollection,
    ConsumptionUsageStoreCollection,
};
