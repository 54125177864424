<template>
  <div class="double-list-select input-field">
    <div class="grid-x grid-margin-x">
      <div class="small-12 medium-5 cell">
        <span class="text-bold">Propositions</span>
        <div class="objects-list objects-proposal" :style="objectListStyle">
          <spinner
             :active="waitLoading"
             />
          <div v-if="!waitLoading"><div v-for="item in data" class="object-item" :key="reduce(item)" :class="{'already-selected': isObjectAlreadySelected(item), 'filtered': isFiltered(item)}">
            <div v-on:click="addObject(item)" class="icon-button" :title="item.name">
              <span class="item-text">{{ label(item) }}</span>
              <font-awesome-icon
                 icon="angle-right"
                 class="float-right item-icon item-icon-left"
                 />
            </div>
            </div>
          </div>
        </div>
        <div class="grid-x" v-show="showFilter">
          <div class="small-11 cell">
            <input v-model="objectsDataFilter" placeholder="Filtre…" title="Filtrer la liste des villes">
          </div>
          <div class="small-1 cell">
            <span v-on:click="resetFilter" class="icon-button" title="Supprimer le filtre">
              <font-awesome-icon
                 icon="times-square"
                 />
            </span>
          </div>
        </div>

      </div>
      <div class="small-12 medium-2 cell text-center">
        <h3 class="hide-for-small-only">&nbsp;</h3>
        <div>
          <button
             type="button"
             class="button center"
             @click.prevent="addAllObjects"
             title="Tout ajouter"
             >
            <font-awesome-icon
               icon="angle-double-right"
               />
          </button>
        </div>
        <div>
          <button
             type="button"
             class="button center"
             @click.prevent="removeAllObjects"
             title="Tout retirer"
             >
            <font-awesome-icon
               icon="trash-alt"
               />
          </button>
        </div>
      </div>
      <div class="small-12 medium-5 cell">
        <span class="text-bold">Sélection <span v-if="selectedObjectsCount > 0">({{selectedObjectsCount}})</span></span>
        <div class="objects-list objects-list-selected" :style="objectListStyle">
          <spinner
             :active="waitObjectselected"
             
                          />
          <div  v-if="!waitLoading"><div v-for="item in localValue" class="object-item" :key="reduce(item)">
            <div v-on:click="removeObject(item)" class="icon-button" :title="item.name">
              <font-awesome-icon
                 icon="times-square"
                 class="float-left item-icon item-icon-right"
                 />
              <span class="item-text">{{ label(item) }}</span>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/spinner';
// import { utils } from '@/utils';

// Usage:
//   <double-list-select
//      v-model="catchmentArea.territories"
//      :data="territoriesList"
//      id="territories"
//      name="territories"
//      ref="territories"
//      reduce="pk"
//      :label="(x) => { return x.name }"
//      >
//   </double-list-select>
// where v-model is selected data (array of {pk, name})
// and data in proposed data (array of {pk, name})

export default {
    name: 'DoubleListSelect',
    components: {
        Spinner,
    },
    props: {
        value: { default: () => { return []; }, type: Array },
        data: { default: () => { return []; }, type: Array },
        filter: { default: null, type: Function },
        showFilter: { default: true, type: Boolean },
        height: { default: '25rem', type: String },
        waitLoading: { default: false, type: Boolean },
        reduceKey: { default: null, type: String },
        label: { default: (x) => { return x.name; }, type: Function },
    },
    data() {
        return {
            localValue: [],
            waitObjectselected: false,
            objectsDataFilter: '',
            // selectedObjectsCount: 0,
        };
    },
    watch: {
        value: {
            handler(val) {
                let mapLocalValue = null;
                if (this.reduceKey == null) {
                    mapLocalValue = this.localValue;
                } else {
                    mapLocalValue = this.localValue.map((x) => { return x[this.reduceKey]; });
                }
                
                if (JSON.stringify(val) !== JSON.stringify(mapLocalValue)) {
                    this.setValue(val);
                    this.sortAndDeduplicateSelectedObjects();
                }
            },
            deep: true,
            immediate: true,
        },
        localValue: {
            handler(val) {
                if (this.reduceKey == null){
                    this.$emit('input', val);
                } else {
                    this.$emit(
                        'input',
                        val.map((x) => { return x[this.reduceKey]; })
                    );
                }
                
            },
            deep: true,
        },
    },
    computed: {
        objectListStyle: function() {
            return {
                height: this.height,
                'max-height': this.height,
            };
        },
        objectsData: {
            get() {
                return this.data;
            },
        },
        selectedObjectsCount() {
            return this.localValue.length;
        },
    },
    methods: {
        reduce: function(item) {
            if (this.reduceKey == null) {
                return item.pk;
            } else {
                return item[this.reduceKey];
            }
        },
        setValue: function(objects) {
            if (this.reduceKey == null) {
                this.$set(this, 'localValue', objects);
            } else {
                // Got keys here
                const fullObjects = this.objectsData.filter((x) => { return objects.findIndex((y) => { return y === x[this.reduceKey]; }) !== -1; });
                this.$set(this, 'localValue', fullObjects);
            }
        },
        clearSelection: function() {
            this.$set(this, 'localValue', []);
        },
        resetFilter: function() {
            this.objectsDataFilter = '';
        },
        isFiltered: function(item) {
            if (this.filter === null) {
                return this.localIsFiltered(item);
            } else {
                return this.filter(this.objectsDataFilter, item);
            }
        },
        localIsFiltered: function(item) {
            if (this.objectsDataFilter !== '') {
                if (item.name.toLowerCase().search(this.objectsDataFilter.toLowerCase()) === -1) {
                    return true;
                }
            }
            return false;
        },
        isObjectAlreadySelected: function(item) {
            if (this.reduceKey == null) {
                const itemId = item.pk;
                for (let i = this.localValue.length - 1; i >= 0; i--) {
                    if (this.localValue[i].pk === itemId) {
                        return true;
                    }
                }
            } else {
                const itemId = item[this.reduceKey];
                for (const value of this.localValue) {
                    if (value[this.reduceKey] === itemId) {
                        return true;
                    }
                }
            }
            return false;
        },
        sortAndDeduplicateSelectedObjects: function() {
            this.waitObjectselected = true;
            let local = this.localValue.reduce((unique, o) => {
                if (!unique.some(obj => this.reduce(obj) === this.reduce(o))) {
                    unique.push(o);
                }
                return unique;
            }, []);

            local.sort((a, b) => this.label(a).localeCompare(this.label(b)));
            if (this.reduceKey !== null) {
                local = local.map((x) => { return x[this.reduceKey]; });
            }
            this.setValue(local);
            this.waitObjectselected = false;
        },
        isObjectSelected(item) {
            for (const value of this.localValue) {
                if (this.reduceKey === null) {
                    if (item === value.pk) {
                        return true;
                    }
                } else {
                    if (item[this.reduceKey] === value[this.reduceKey]) {
                        return true;
                    }
                }
            }
            return false;
        },
        addObject: function(item) {
            this.waitObjectselected = true;
            if (!this.isObjectSelected(item)) {
                this.localValue.push(item);
            }
            
            this.sortAndDeduplicateSelectedObjects();
            this.waitObjectselected = false;
            // this.emitSelection();
        },
        removeObject: function(item) {
            this.waitObjectselected = true;
            for (let i = this.localValue.length - 1; i >= 0; i--) {
                if (this.localValue[i] === item) {
                    this.localValue.splice(i, 1);
                }
            }
            this.waitObjectselected = false;
            // this.emitSelection();
        },
        addAllObjects: async function() {
            this.waitObjectselected = true;

            const self = this;

            // give the spinner a chance to start
            this.$nextTick(() => {
                setTimeout(function() {
                    const items = self.localValue;
            
                    for (const item of self.objectsData) {
                        if (self.isFiltered(item) === false && items.findIndex((x) => { return JSON.stringify(x) === JSON.stringify(item); }) === -1) {
                            items.push(item);
                        }
                    }
                    if (self.reduceKey === null) {
                        self.setValue(items);
                    } else {
                        const data = items.map((x) => { return x[self.reduceKey]; });
                        self.setValue(data);
                    }
                    self.sortAndDeduplicateSelectedObjects();
                    self.waitObjectselected = false;
                }, 500);
            });
            
        },
        removeAllObjects: function() {
            this.waitObjectselected = true;
            this.setValue([]);
            this.localValue = [];
            this.waitObjectselected = false;
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../stylesheet/variables'
@require '../stylesheet/typography'

.filters
    margin-bottom: 2rem

    .dropdown
        font-size: text-sm

.objects-list
    overflow-y: scroll
    border: 1px solid #dcdfe6
    padding: 0.5rem
    margin-bottom: 2rem
    font-size: text-sm

    .object-item
        overflow-x: hidden
        overflow-y: hidden
        padding-left: 1rem
        padding-right: 1rem
        border: 1px solid #dcdfe6
        margin-top: 3px
        margin-bottom: 3px
        border-radius: 15px
        background-color: #f2f3f6

        .icon-button
            display: inline-block
            width: 100%
            padding-top: 5px
            padding-top: 5px
            line-height: 14px

            .item-text
                overflow-x: hidden
                display: inline-block
                white-space: nowrap
                text-overflow: ellipsis
                width: 80%

            .item-icon
                display: block
                float: right
                position: relative
                margin-top: 0px

            .item-icon-right
                margin-right: 0.2rem
                margin-left: 0

            .item-icon-left
                margin-left: 0.2rem
                margin-right: 0

    .object-item-selected
        background: grey

    .already-selected
        background-color: lighten(#f2f3f6, 48)
        color: lightgrey

        .icon-button:hover
            cursor: unset

            svg
                color: #d3d3d3

    .filtered
        display: none

    .item-icon
        margin-top: 3px

.objects-proposal
    margin-bottom: 0.5rem

input
    width: 100%
</style>
