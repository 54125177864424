import { store } from '@/store/store';

import fileSaver from 'file-saver';

const api = {
    genericList(url, filter = null) {
        if (filter) {
            let paramsString = '?';
            for (const prop in filter) {
                if (prop) {
                    paramsString += encodeURIComponent(prop) + '=' + encodeURIComponent(filter[prop]) + '&';
                }
            }
            url += paramsString.substr(0, paramsString.length - 1);
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`${url}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    genericListTimeout(url, filter = null, timeout = 60) {
        if (filter) {
            let paramsString = '?';
            for (const prop in filter) {
                if (prop) {
                    paramsString += encodeURIComponent(prop) + '=' + encodeURIComponent(filter[prop]) + '&';
                }
            }
            url += paramsString.substr(0, paramsString.length - 1);
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(`${url}`, { timeout: timeout })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    genericStoreList(url, updatedAt = '') {
        const jwt = store.getters.getField('jwt');

        return new Promise((resolve, reject) => {
            this.apiClient({
                headers: {
                    'limit-to-objects-after': updatedAt || '',
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${jwt}`,
                },
            })
                .get(`${url}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    genericGet(url, params = null) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url, { params: params })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    genericDetail(url, pk, finalSlash = true, filter = null) {
        url = url + pk + (finalSlash ? '/' : '');
        if (filter) {
            url += '?' + Object.keys(filter).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filter[k])}`).join('&');
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    genericDelete(url, pk, finalSlash = true) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .delete(url + pk + (finalSlash ? '/' : ''))
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    genericSave(url, data) {
        const pk = data.pk;
        if (pk === null || pk === undefined) {
            return new Promise((resolve, reject) => {
                this.genericCreate(url, data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        } else {
            return new Promise((resolve, reject) => {
                this.genericUpdate(url, data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    },
    genericUpdate(url, data, finalSlash = true) {
        const pk = data.pk;
        const updateUrl = url + pk + (finalSlash ? '/' : '');
        return this.genericPatch(updateUrl, data);
    },
    genericPost(url, data) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post(`${url}`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    genericPut(url, data, filter = null) {
        if (filter) {
            url += '?' + Object.keys(filter).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filter[k])}`).join('&');
        }
        return new Promise((resolve, reject) => {
            this.apiClient()
                .put(`${url}`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    genericCreate(url, data) {
        data.pk = null;
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post(`${url}`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    genericPatch(url, data = {}) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .patch(`${url}`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
    genericDownloadFile(url, filter = null) {
        if (filter) {
            let paramsString = '?';
            for (const prop in filter) {
                if (prop) {
                    paramsString += encodeURIComponent(prop) + '=' + encodeURIComponent(filter[prop]) + '&';
                }
            }
            url += paramsString.substr(0, paramsString.length - 1);
        }
        return new Promise((resolve, reject) => {
            this.apiClient({ responseType: 'blob' })
                .get(`${url}`)
                .then(response => {
                    const filename = response.headers['content-disposition'].split('filename=')[1];
                    const blob = new Blob([response.data], { type: response.headers['content-type'] });
                    fileSaver.saveAs(blob, filename);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export default api;
