import Vue, { computed, ref } from 'vue';

export const handleFormErrorsMixin = {
    setup() {
        const formErrors = ref({});
        const formErrorsMessages = ref({});
        const waitSaving = ref(false);

        // placeholder for <template>'s ref="validator"
        // https://vuejs.org/guide/essentials/template-refs.html#accessing-the-refs
        const validator = ref(null);
        const errors = computed(() => validator?.value?.errors);

        function validate() {
            return validator?.value?.validate();
        }

        function showError(options) {
            let params = {
                group: 'root',
                type: 'error',
                title: 'Erreur',
                text: 'Le formulaire comporte des erreurs, merci de les corriger avant d\'enregistrer vos modifications.<br>',
            };
            if (typeof options === 'object') params = { ...params, ...options };
            else if (typeof options === 'string') params.text = options;

            Vue.notify(params);
        }

        function showErrorForbidden() {
            showError({
                text: 'Vous n\'avez pas la permission d\'effectuer cette opération.',
            });
        }

        function showErrorNotFound() {
            showError({
                text: 'Enregistrement non trouvé.',
            });
        }

        function showErrorValidation() {
            showError({
                text: 'Erreur de validation. Au moins un champ est incorrect.',
            });
        }

        function showErrorServer() {
            showError({
                text: 'Erreur serveur. Réessayez plus tard et contactez votre administrateur si le problème persiste.',
            });
        }

        function showErrorMaxSizeExceeded() {
            this.showError({
                text: 'Erreur. Le document envoyé est trop volumineux.',
            });
        }

        function handleFormErrors(error) {
            if (error?.response === undefined) {
                return;
            }
            if (error.response.status === 400) {
                for (const [field, messages] of Object.entries(error.response.data)) {
                    formErrors.value[field] = true;
                    // deep clone + reassign to trigger reactivity
                    const errorMessages = structuredClone(formErrorsMessages.value);
                    errorMessages[field] = messages[0];
                    formErrorsMessages.value = errorMessages;
                    if (field === '__all__' || field === '_All__') {
                        showError(messages[0]);
                    }
                }
                showErrorValidation();
                return;
            }
            if (error.response.status === 403) {
                showErrorForbidden();
                return;
            }
            if (error.response.status === 404) {
                showErrorNotFound();
                return;
            }
            if (error.response.status === 431) {
                showErrorMaxSizeExceeded();
                return;
            }
            if (error.response.status === 413) {
                showErrorMaxSizeExceeded();
                return;
            }
            if (error.response.status === 500 || error.response.status === 502 || error.response.status === 504) {
                showErrorServer();
            }
        }

        function resetErrorFields() {
            formErrors.value = {};
            formErrorsMessages.value = {};
        }

        return {
            errors,
            formErrors,
            formErrorsMessages,
            waitSaving,

            validator,

            validate,
            handleFormErrors,
            showError,
            showErrorForbidden,
            showErrorNotFound,
            showErrorServer,
            showErrorMaxSizeExceeded,
            resetErrorFields,
        };
    },
};
