<template>
<div class="table-wrapper">
  <div class="search" v-if="showSearchBar">
    <font-awesome-icon icon="search" />
    <input
      type="text"
      v-model="searchQuery"
      class="search-input"
      placeholder="Filtrer les résultats"
    >
  </div>
  <div class="table-scroll">
  <table class="data-table">
    <thead>
      <th
        v-for="column of processedColumns"
        :key="column.id"
        :style="`width: ${column.width}`"
        class="table-header"
        :title="column.tooltip"
      >
        <div class="table-header-wrap">
          <slot
            name="table-column"
            :column="column"
          >
            <div>{{ column.label }}</div>
          </slot>

          <div
            class="sort-icon"
            v-if="column.sortable !== false"
            >
            <div
              class="sort-asc"
              title="Tri croissant"
              @click="sort(column.field, 'asc')"
              :class="{'sort-selected': sortField === column.field && sortType === 'asc'}"
              />
            <div
              class="sort-desc"
              title="Tri décroissant"
              @click="sort(column.field, 'desc')"
              :class="{'sort-selected': sortField === column.field && sortType === 'desc'}"
              />
          </div>
          </div>
      </th>
    </thead>

    <tbody
      v-if="paginatedRows.length === 0"
    >
      <tr>
        <td
          class="no-results"
          :colspan="processedColumns.length"
        >
        Pas de résultats.
        </td>
      </tr>
    </tbody>

    <template v-else-if="groupedTable">
      <tbody
        v-for="rowGroup, groupIndex of paginatedRows"
        :key="groupIndex"
        :class="{ collapsable }"
      >
        <th
          v-for="column, index of processedColumns"
          :key="column.id"
          class="group-th table-cell"
          :class="column.thClass"
        >
          <div class="flex">
            <font-awesome-icon
              v-if="collapsable && index === 0"
              @click="toggleRowGroup(formattedRows[groupIndex])"
              :icon="formattedRows[groupIndex].expanded ? 'caret-down' : 'caret-right'"
            />
            <slot
              name="table-header-row"
              :row="rowGroup"
              :column="column"
              :formattedRow="formattedRows[groupIndex]"
            >
              {{ formattedRows[groupIndex][column.field] }}
            </slot>
          </div>
        </th>

        <template
          v-if="!collapsable || formattedRows[groupIndex].expanded"
        >
        <tr
          v-for="row, index of rowGroup.children"
          :key="index"
        >
          <td
            v-for="column of processedColumns"
            :key="column.id"
            class="table-cell"
            :class="column.tdClass"
          >
            <slot
              name="table-row"
              :row="row"
              :column="column"
              :formattedRow="formattedRows[groupIndex].children[index]"
            >
              {{ formattedRows[groupIndex].children[index][column.field] }}
            </slot>
          </td>
        </tr>
        </template>
      </tbody>
    </template>

    <tbody v-else>
      <tr
        v-for="row, index of paginatedRows"
        :key="index"
      >
        <td
          v-for="column of processedColumns"
          :key="column.id"
          class="table-cell"
          :class="column.tdClass"
        >
          <slot
            name="table-row"
            :row="row"
            :column="column"
            :formattedRow="formattedRows[index]"
          >
            {{ formattedRows[index][column.field] }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
  </div>

  <div class="pages" v-show="paginatedRows.length > 0">
    <div>
      Lignes par page :
      <select
        v-model="perPage"
        class="per-page"
      >
        <option
          v-for="n of paginationOptions.perPageDropdown"
          :key="n"
          :value="n"
        >
          {{ n }}
        </option>
      </select>
    </div>

    <div class="pages-actions" v-show="totalPages > 1">
      <ActionLink
        icon="angle-left"
        label="Précédent"
        @click="previousPage"
        :disabled="currentPage === 1"
      />

      Page {{ currentPage }} sur {{ totalPages }}

      <ActionLink
        icon="angle-right"
        icon-position="right"
        label="Suivant"
        @click="nextPage"
        :disabled="currentPage === totalPages"
      />
    </div>
  </div>
</div>
</template>

<script>
import { computed, ref, watch } from 'vue';

import ActionLink from '@/components/action_link';

export default {
    props: {
        mode: {
            type: String,
            default: 'local',
        },

        columns: {
            type: Array,
            required: true,
        },

        rows: {
            type: Array,
            required: true,
        },

        groupOptions: {
            type: Object,
            default: () => ({
                enabled: false,
                collapsable: false,
            }),
        },

        paginationOptions: {
            type: Object,
            default: () => ({
                enabled: false,
                perPage: 10,
                perPageDropdown: [10, 20, 30, 40, 50],
            }),
        },

        searchOptions: {
            type: Object,
            default: () => ({
                enabled: false,
                externalQuery: null,
            }),
        },

        sortOptions: {
            type: Object,
            required: false,
        },

        totalRows: Number,
    },

    components: {
        ActionLink,
    },

    setup(props, { emit }) {
        const processedColumns = computed(() => {
            return props.columns.filter(
                column => column.hidden !== true
            ).map(
                column => {
                    return {
                        width: "auto",
                        ...column,
                    };
                });
        });

        const isRemote = computed(() => props.mode === "remote");

        const sortField = ref(null);
        const sortType = ref(null);

        const groupedTable = computed(() => props.groupOptions.enabled === true);
        const collapsable = computed(() => props.groupOptions.collapsable === true);

        if (props.sortOptions?.initialSortBy) {
            sortField.value = props.sortOptions.initialSortBy.field;
            sortType.value = props.sortOptions.initialSortBy.type || "desc";
        }

        const searchQuery = ref(props.searchOptions.externalQuery);
        const showSearchBar = computed(() => props.searchOptions.enabled && !props.searchOptions.hasOwnProperty("externalQuery"));

        const strToAscii = string => string.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        function getSortFunction(col) {
            if (col.SortFn) {
                return col.sortFn;
            }

            switch(col.type) {
                case 'number':
                case 'percentage': {
                    return (a, b) => a - b;
                }
                default: {
                    return (a, b) => (a || "").toString().localeCompare((b || "").toString(), "fr");
                }
            }
        }

        function filterRow(row) {
            return strToAscii(Object.values(row).join()).includes(strToAscii(searchQuery.value));
        }

        function sortRows(sortFn) {
            return (rowA, rowB) =>
                sortType.value === "asc" ?
                    sortFn(rowA[sortField.value], rowB[sortField.value]) :
                    sortFn(rowB[sortField.value], rowA[sortField.value]);
        }

        const filteredRows = computed(() => {
            if (isRemote.value) {
                return props.rows;
            }

            let rows = props.rows;

            if (searchQuery.value) {
                if (groupedTable.value) {
                    rows = rows.map(rowGroup => ({...rowGroup, children: rowGroup.children.filter(filterRow)}));
                } else {
                    rows = rows.filter(filterRow);
                }
            }

            if (sortField.value && sortType.value) {
                const col = props.columns.find(col => col.field === sortField.value);
                if (col) {
                    const sortFn = getSortFunction(col);

                    if (groupedTable.value) {
                        rows.forEach(rowGroup => {
                            rowGroup.children.sort(sortRows(sortFn));
                        });
                    } else {
                        rows.sort(sortRows(sortFn));
                    }
                }
            }

            return rows;
        });

        const currentPage = ref(1);
        const perPage = ref(props.paginationOptions.perPage);

        const totalRows = computed(() => props.totalRows || filteredRows.value.length);
        const totalPages = computed(() => Math.ceil(totalRows.value / perPage.value));

        const paginatedRows = computed(() => {
            if (isRemote.value) {
                return props.rows;
            }

            return filteredRows.value.slice((currentPage.value - 1) * perPage.value, currentPage.value * perPage.value);
        });

        function getFormatFunction(col) {
            if (col.formatFn) {
                return col.formatFn;
            }

            switch (col.type) {
                case "percentage": {
                    return x => Number(x).toLocaleString("fr", {
                        style: 'percent', minimumFractionDigits: 1,
                    });
                }
                case "date": {
                    return x => x ? new Date(x).toLocaleDateString("fr-FR") : "";
                }
                default: {
                    return x => x;
                }
            }
        }

        function formatRow(row) {
            const values = {};

            for (const col of processedColumns.value) {
                const format = getFormatFunction(col);
                values[col.field] = format(row[col.field]);
            }

            return values;
        }

        // TODO Make this a Set in Vue 3
        const expanded = ref([]);
        function toggleRowGroup(row) {
            const index = expanded.value.findIndex(rowId => rowId === row.rowId);
            if (index === -1) {
                expanded.value.push(row.rowId);
            } else {
                expanded.value.splice(index, 1);
            }
        }

        const formattedRows = computed(() => {
            if (groupedTable.value) {
                return paginatedRows.value.map(rowGroup => {
                    const rowId = Object.values({...rowGroup, children: null}).toString();
                    return {
                        ...rowGroup,
                        rowId,
                        expanded: expanded.value.includes(rowId),
                        children: rowGroup.children.map(formatRow),
                    };
                });
            } else {
                return paginatedRows.value.map(formatRow);
            }
        });

        function pageChangedEvent() {
            return {
                currentPage: currentPage.value,
                currentPerPage: perPage.value,
                total: totalPages.value,
            };
        }

        watch(perPage, () => {
            if (totalPages.value === 1) {
                currentPage.value = 1;
            }
            emit('on-per-page-change', pageChangedEvent());
        });

        watch(() => props.searchOptions, () => {
            if (props.searchOptions.hasOwnProperty("externalQuery") && searchQuery.value !== props.searchOptions.externalQuery) {
                searchQuery.value = props.searchOptions.externalQuery;
            }
        });

        watch(searchQuery, () => {
            emit('on-search', { searchTerm: searchQuery.value, externalQuery: searchQuery.value });
        });

        function sort(field, type) {
            sortField.value = field;
            sortType.value = type;
            emit('on-sort-change', [{ field, type }]);
        }

        function changePage(page) {
            const params = pageChangedEvent();
            params.prevPage = currentPage.value;
            currentPage.value = page;
            emit('on-page-change', params);
        }

        function nextPage() {
            changePage(Math.min(currentPage.value + 1, totalPages.value));
        }

        function previousPage() {
            changePage(Math.max(currentPage.value - 1, 1));
        }

        return {
            perPage,
            currentPage,
            totalPages,
            sortField,
            sortType,
            groupedTable,
            collapsable,
            showSearchBar,
            searchQuery,

            filteredRows,
            processedColumns,
            paginatedRows,
            formattedRows,

            sort,
            nextPage,
            previousPage,
            toggleRowGroup,
        };
    },
};
</script>

<style lang="stylus" scoped>
@require '../stylesheet/variables'

.table-wrapper
    font-size: smaller
    --border: 1px solid #cacaca

.table-scroll
    overflow: scroll

.data-table
    margin: 0
    border-collapse: collapse

.per-page
    width: auto
    margin: 0

.table-header
.table-cell
    border: var(--border)

.table-header-wrap
    display: flex
    justify-content: space-between

.sort-icon
    cursor: pointer
    margin-left: 10px
    float: right
    display: flex
    align-content: center
    flex-direction: column
    justify-content: center

    .sort-asc
    .sort-desc
        position: relative
        height: 0
        width: 0

    .sort-asc
        border-left: 5px solid transparent
        border-right: 5px solid transparent
        border-bottom: 5px solid #606266
        margin-top: 4px
        margin-bottom: 4px

        &:hover
            border-bottom: 5px solid primary-color

        &.sort-selected
            border-bottom: 5px solid primary-color

    .sort-desc
        border-left: 5px solid transparent
        border-right: 5px solid transparent
        border-top: 5px solid #606266

        &:hover
            border-top: 5px solid primary-color

        &.sort-selected
            border-top: 5px solid primary-color

.pages
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: 10px 0 10px 10px

.pages-actions
    display: flex
    flex-direction: row
    gap: 10px

.group-th:first-child
    text-align: left

    .flex
        display: flex
        align-items: center

.collapsable > .group-th:first-child
    padding-left: 0

.search
    display: flex
    align-items: center
    background: rgb(247.65, 247.65, 247.65)
    padding: 10px
    gap: 10px
    border: var(--border)
    border-bottom: 0

.search-input
    margin: 0

.no-results
    text-align: center
</style>
