/*
    Autogenerated class model from django Mission
    -- 2023-03-13 18:02:20.828510
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class MissionClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt));

        /* Define default values here if needed */
        Vue.set(this, 'order', rawData.order || 0);
        Vue.set(this, 'name', rawData.name || '');
        Vue.set(this, 'helpText', rawData.helpText || '');
    }

    getBaseUrl() {
        return '/listepro/mission/';
    }

    get isValid() {
        return true;
    }
}

class MissionCollection extends BaseCollection {
    initModel(rawData) {
        return new MissionClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/mission/';
    }
}

class MissionStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new MissionClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/mission/';
    }

    getStorePath() {
        return 'listepro/missionCollection';
    }
}

export {
    MissionClass,
    MissionCollection,
    MissionStoreCollection,
};
