/*
    Autogenerated class model from django ProfessionalAddress
    -- 2023-03-13 18:02:20.829505
*/
import Vue from 'vue';

// BaseObjectClass defines methods load, save, delete
import { BaseObjectClass, BaseCollection, BaseStoreCollection } from '@/base_object_class';

class ProfessionalAddressClass extends BaseObjectClass {
    constructor(rawData = {}) {
        super();
        this.populate(rawData);
    }

    populate(rawData = {}) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'pk', rawData.pk || null);
        Vue.set(this, 'id', rawData.pk || null);
        Vue.set(this, 'createdAt', new Date(rawData.createdAt || null));
        Vue.set(this, 'updatedAt', new Date(rawData.updatedAt || null));

        /* Define default values here if needed */
        Vue.set(this, 'professional', rawData.professional || null);
        Vue.set(this, 'name', rawData.name || '');
        Vue.set(this, 'email', rawData.email || '');
        Vue.set(this, 'url', rawData.url || '');
        Vue.set(this, 'address', rawData.address || '');
        Vue.set(this, 'town', rawData.town || '');
        Vue.set(this, 'postcode', rawData.postcode || '');
        Vue.set(this, 'geom', rawData.geom || { lat: 0, lon: 0 });
        Vue.set(this, 'phoneNumber', rawData.phoneNumber || '');
    }

    getBaseUrl() {
        return '/listepro/professional-address/';
    }

    get isValid() {
        return true;
    }
}

class ProfessionalAddressCollection extends BaseCollection {
    initModel(rawData) {
        return new ProfessionalAddressClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/professional-address/';
    }
}

class ProfessionalAddressStoreCollection extends BaseStoreCollection {
    initModel(rawData) {
        return new ProfessionalAddressClass(rawData);
    }

    getBaseUrl() {
        return '/listepro/professional-address/';
    }

    getStorePath() {
        return 'listepro/professionalAddressCollection';
    }
}

export {
    ProfessionalAddressClass,
    ProfessionalAddressCollection,
    ProfessionalAddressStoreCollection,
};
