export const downloadPdf = (stream, filename) => {
    let objUrl = false;
    let data = new Blob([stream], { type: 'application/pdf' });

    if (typeof data === 'object') {
        if (window.navigator.msSaveBlob) {
            return window.navigator.msSaveBlob(data, filename);
        } else {
            data = window.URL.createObjectURL(data);
            objUrl = true;
        }
    }
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(() => {
        if (objUrl) window.URL.revokeObjectURL(data);
        document.body.removeChild(link);
        link.remove();
    }, 1);
};
