import defaultState from './_defaultState';
import { updateField } from 'vuex-map-fields';
import Vue from 'vue';

export default {
    RESET_STATE(state) {
        Object.assign(state, defaultState);
    },
    CREATE(state, values) {
        if (!values.id) throw new Error('No id specified');
        Vue.set(state.defs, values.id, values);
    },
    UPDATE(state, { defId, values }) {
        const psDef = Object.assign({}, state.defs[defId], values);
        Vue.set(state.defs, defId, psDef);
    },
    UPDATE_INITIAL_VALUES(state, { defId, values }) {
        const psDef = Object.assign({}, state.defs[defId]);
        psDef.initialValues = Object.assign({}, psDef.initialValues, values);
        Vue.set(state.defs, defId, psDef);
    },
    updateField,
};
