import { store } from '@/store/store';

export function calculate(needs, systems) {
    const heatingValues = calculateSystem('heating', needs.heatingNeeds, systems.heatingSystem);

    let hotWaterValues = { combustibleCostFirstYear: 0, carbonEmissionByYear: 0, finalEnergyConsumption: 0 };
    if (store.state.therminix.renovationType !== 'heating_system_renovation') {
        const hotWaterSystemIdentifier = systems.hotWaterSystem.identifier === 'synced-with-heating' ? systems.heatingSystem : systems.hotWaterSystem;
        hotWaterValues = calculateSystem('hot_water', needs.hotWaterNeeds, hotWaterSystemIdentifier);
    }

    return {
        ...systems,
        combustibleCostFirstYear: heatingValues.combustibleCostFirstYear + hotWaterValues.combustibleCostFirstYear,
        carbonEmissionByYear: heatingValues.carbonEmissionByYear + hotWaterValues.carbonEmissionByYear,
    };
}

function calculateSystem(systemType, needs, productionSystemData) {
    if (typeof productionSystemData === 'string') productionSystemData = { identifier: productionSystemData };

    // TODO : calculate for thermal solar
    if (store.state.energies.thermalSolarIdentifiers.includes(productionSystemData.identifier)) {
        needs = 0;
    }

    const productionSystem = {
        ...store.state.energies.productionSystemCollectionByIdentifier[productionSystemData.identifier],
        // In case productionSystemData contain specifics data to be overrode (not used for now)
        ...productionSystemData,
    };
    let energy = store.state.energies.energyCollectionByIdentifier[productionSystem.energyIdentifier];

    const metroGrenobleEnergiesOverrodeData = store.state.thermix.metroGrenoble.energiesOverrodeData[energy.identifier];
    energy = {
        ...energy,
        // In case we override energy data specificaly with metro grenoble (only price variation for now)
        ...metroGrenobleEnergiesOverrodeData || {},
    };

    const secondaryEnergyRatio = store.getters['energies/secondaryEnergyRatio'](systemType, 'individual', productionSystem.isHydro) || 1;
    const systemOutputHeatingProduction = needs / secondaryEnergyRatio;
    const efficiency = systemType === 'heating' ? productionSystem.efficiencyHeating : productionSystem.efficiencyHotWater;
    const finalEnergyConsumption = systemOutputHeatingProduction / efficiency;

    const combustibleCostFirstYear = energy.currentPrice * finalEnergyConsumption / 100;

    const ghgRatio = energy.ghgRatio;
    const carbonEmissionByYear = finalEnergyConsumption * ghgRatio;

    return {
        finalEnergyConsumption,
        combustibleCostFirstYear,
        carbonEmissionByYear,
    };
}
