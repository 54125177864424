import Vue from 'vue';
import { store } from '@/store/store';
import apiClient from '@/api/api';
import _ from 'lodash';

/*
Base class for js models
It defines load, save and delete methods
*/

class BaseObjectClass {
    load(pk, filter = null) {
        return new Promise((resolve, reject) => {
            this.isLoading = true;
            apiClient
                .genericDetail(this.getBaseUrl(), pk, true, filter)
                .then(response => {
                    this.populate(response);
                    this.isLoading = false;
                    resolve(this);
                })
                .catch(error => {
                    this.isLoading = false;
                    reject(error);
                });
        });
    }

    save() {
        return new Promise((resolve, reject) => {
            this.isLoading = true;
            apiClient
                .genericSave(this.getBaseUrl(), this)
                .then(response => {
                    this.populate(response);
                    this.isLoading = false;
                    resolve(response);
                })
                .catch(error => {
                    this.isLoading = false;
                    reject(error);
                });
        });
    }

    put(data) {
        return new Promise((resolve, reject) => {
            this.isLoading = true;
            apiClient
                .genericPut(this.getBaseUrl(), data)
                .then(response => {
                    this.populate(response);
                    this.isLoading = false;
                    resolve(response);
                })
                .catch(error => {
                    this.isLoading = false;
                    reject(error);
                });
        });
    }

    delete() {
        return new Promise((resolve, reject) => {
            this.isLoading = true;
            apiClient
                .genericDelete(this.getBaseUrl(), this.pk)
                .then(response => {
                    this.isLoading = false;
                    resolve(this);
                })
                .catch(error => {
                    this.isLoading = false;
                    reject(error);
                });
        });
    }

    asJson() {
        // Return object as JSON
        const json = {};
        for (const prop of Object.getOwnPropertyNames(this)) {
            if (!prop.startsWith('_')) {
                json[prop] = this[prop];
            }
        }
        return JSON.parse(JSON.stringify(json));
    }
}

class BaseCollection {
    constructor(rawData = []) {
        this.populate(rawData);
    }

    clearCollection() {
        this.populate([]);
    }

    populate(rawData = []) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, '_collection', {});

        if (rawData) {
            const list = { pkOrdering: [] };
            for (const index in rawData) {
                list[rawData[index].pk] = this.initModel(rawData[index]);
                list.pkOrdering.push(rawData[index].pk);
            }
            Vue.set(this, '_collection', list);
        }
    }

    load(filter = null) {
        let url = this.getBaseUrl();
        if (filter) {
            url += '?' + Object.keys(filter).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filter[k])}`).join('&');
        }
        return new Promise((resolve, reject) => {
            this.isLoading = true;
            apiClient
                .genericList(url)
                .then(response => {
                    const list = [];
                    for (const item in response) {
                        list.push(
                            this.initModel(response[item])
                        );
                    }
                    this.populate(list);
                    this.isLoading = false;
                    resolve(this);
                })
                .catch(error => {
                    this.isLoading = false;
                    reject(error);
                });
        });
    }

    get(pk) {
        return this._collection[pk];
    }

    asList() {
        const list = [];
        for (const i in this._collection.pkOrdering) {
            const pk = this._collection.pkOrdering[i];
            if (pk in this._collection) {
                list.push(this._collection[pk]);
            }
        }
        return list;
    }

    update(pk, object) {
        this._collection[pk] = object;
        if (this._collection.pkOrdering.find(x => { return x === pk; }) === undefined) {
            this._collection.pkOrdering.push(pk);
        }
    }

    delete(pk) {
        Vue.delete(this._collection, pk);
        this._collection.pkOrdering = this._collection.pkOrdering.filter(x => { return x !== pk; });
    }
}

class BaseStoreCollection {
    constructor(rawData = [], lastUpdate = '', forceReload = false) {
        Vue.set(this, 'isLoading', false);
        Vue.set(this, 'forceReload', forceReload);

        if (store.get(this.getStorePath() + 'LastUpdate') === '') {
            const list = { pkOrdering: [] };
            if (rawData) {
                for (const index in rawData) {
                    list[rawData[index].pk] = this.initModelAndInjectMethods(rawData[index], this);
                    list.pkOrdering.push(rawData[index].pk);
                }
            }

            store.set(this.getStorePath(), list);
            store.set(this.getStorePath() + 'LastUpdate', lastUpdate);
        }
    }

    clearCollection() {
        store.set(this.getStorePath(), { pkOrdering: [] });
        store.set(this.getStorePath() + 'LastUpdate', 0);
    }

    asList() {
        const collection = store.get(this.getStorePath());
        const list = [];
        for (const i in collection.pkOrdering) {
            const pk = collection.pkOrdering[i];
            if (pk in collection) {
                list.push(collection[pk]);
            }
        }
        return list;
    }

    createItem() {
        return this.initModelAndInjectMethods({}, this);
    }

    async deleteItem(obj) {
        this.isLoading = true;
        store.commit(`${this.storeLoadingPath()}/incrementLoading`);
        const pk = obj.pk;
        try {
            const response = await obj._delete();
            const list = store.get(this.getStorePath());
            const newList = {};
            const currentKeys = Object.keys(list).filter(x => { return x !== 'pkOrdering'; }).map(x => { return parseInt(x); });

            // Delete obj
            for (const i in currentKeys) {
                if (currentKeys[i] !== 'pkOrdering') {
                    const listKey = currentKeys[i];
                    if (pk !== listKey) {
                        newList[listKey] = list[listKey];
                    }
                }
            }

            newList.pkOrdering = currentKeys.filter((x) => { return x !== pk; });

            store.set(`${this.getStorePath()}`, newList);
            this.isLoading = false;
            store.commit(`${this.storeLoadingPath()}/decrementLoading`);
            return response;
        } catch (error) {
            this.isLoading = false;
            store.commit(`${this.storeLoadingPath()}/decrementLoading`);
            throw error;
        }
    }

    async getItem(pk) {
        if (pk === null) {
            return this.createItem();
        }
        const data = _.cloneDeep(store.get(`${this.getStorePath()}@${pk}`));
        if (data !== undefined) {
            const obj = this.initModelAndInjectMethods(data, this);
            return obj;
        } else {
            throw new Error('Erreur avec la clef' + pk);
        }
    }

    getItemSync(pk) {
        if (pk === null || pk === undefined) {
            return this.createItem();
        }
        const data = _.cloneDeep(store.get(`${this.getStorePath()}@${pk}`));
        if (data !== undefined) {
            const obj = this.initModelAndInjectMethods(data, this);
            return obj;
        } else {
            throw new Error('Erreur avec la clef ' + pk);
        }
    }

    getCollection() {
        // Return the collection stored in the store
        return store.get(this.getStorePath());
    }

    initModelAndInjectMethods(rawData, collection) {
        return this._initModelAndInjectMethods(rawData, collection);
    }

    _initModelAndInjectMethods(rawData, collection) {
        const instance = this.initModel(rawData);
        instance._load = instance.load;
        instance._save = instance.save;
        instance._delete = instance.delete;
        instance.load = () => {
            // instance.load -> do nothing
        };
        instance.save = async() => {
            this.isLoading = true;
            store.commit(`${this.storeLoadingPath()}/incrementLoading`);
            instance.isLoading = true;
            try {
                const response = await instance._save();
                const newInstance = this.initModelAndInjectMethods(response);
                store.commit(`${this.storeLoadingPath()}/decrementLoading`);
                this.isLoading = false;
                store.set(`${this.getStorePath()}@${newInstance.pk}`, newInstance);
                return newInstance;
            } catch (error) {
                instance.isLoading = false;
                store.commit(`${this.storeLoadingPath()}/decrementLoading`);
                this.isLoading = false;
                throw error;
            }
        };
        instance.delete = () => {
            console.warn('BaseStoreCollection.instance.delete seems to be buggy. You should use BaseStoreCollection.deleteItem(instance) !');
            this.deleteItem(instance);
        };
        return instance;
    }

    async load(filter = null) {
        let url = this.getBaseUrl();
        const lastFilter = store.get(this.getStorePath() + 'Filter');
        /* eslint-disable-next-line no-unused-vars */
        let currentFilter = lastFilter;

        if (filter) {
            url += '?' + Object.keys(filter).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filter[k])}`).join('&');
            store.set(this.getStorePath() + 'Filter', filter);
            currentFilter = filter;
        }

        this.isLoading = true;
        store.commit(`${this.storeLoadingPath()}/incrementLoading`);
        let lastUpdate = '';
        if (
            store.get(this.getStorePath() + 'Filter') === filter &&
                store.get(this.getStorePath() + 'LastUpdate')
        ) {
            lastUpdate = store.get(this.getStorePath() + 'LastUpdate');
        }
        if (JSON.stringify(lastFilter) !== JSON.stringify(currentFilter)) {
            lastUpdate = 0;
        }
        if (this.forceReload) {
            lastUpdate = 0;
        }

        try {
            const response = await apiClient.genericStoreList(url, lastUpdate);
            const list = store.get(this.getStorePath());
            const newList = {};
            const serverKeys = response.all;

            // Delete old values by copying only
            // entries from serverKeys
            for (const i in serverKeys) {
                const key = serverKeys[i];
                newList[key] = list[key];
            }

            // Add new values
            for (const i in response.collection) {
                newList[response.collection[i].pk] = response.collection[i];
            }

            newList.pkOrdering = response.all;

            store.set(this.getStorePath(), newList);
            store.set(this.getStorePath() + 'LastUpdate', response.lastUpdatedAt);

            store.commit(`${this.storeLoadingPath()}/decrementLoading`);
            this.isLoading = false;
            return this;
        } catch (error) {
            store.commit(`${this.storeLoadingPath()}/decrementLoading`);
            this.isLoading = false;
            throw error;
        }
    }

    storeLoadingPath() {
        return this.getStorePath().split('/')[0];
    }

    asListSortedBy(sortKey, sortType = 'asc') {
        const returnBySortType = {
            asc: 1,
            desc: -1,
        };
        const list = this.asList();
        return list.sort((a, b) => {
            if (!(sortKey in a) || !(sortKey in b) || !(sortType in returnBySortType)) return 0;
            if (a[sortKey] < b[sortKey]) return returnBySortType[sortType] * (-1);
            if (a[sortKey] > b[sortKey]) return returnBySortType[sortType];
            return 0;
        });
    }
}

export {
    BaseObjectClass,
    BaseCollection,
    BaseStoreCollection,
};
