import _ from 'lodash';
import apiClient from '@/api/api';
import { make } from 'vuex-pathify';
import { getField, updateField } from 'vuex-map-fields';
import { store } from '@/store/store';
import { computeNewState, vpm } from '@/apps/ecorenover/calculus';

const defaultState = {
    /* GLOBAL VARS */
    loaded: false,
    productionSystemsOptions: [],
    annualVariation: [
        1.0, 1.0, 1.1, 0.95, 0.93, 1.09, 1.05, 0.88, 1.056, 1.08, 0.99,
        1.02, 0.98, 0.97, 1.02, 0.9, 0.91, 1.05, 0.912, 1.01, 0.9,
    ], // old name: variations_annuelles

    inputs: {
        // current situation
        annualInvoice: 2000,
        productionSystemUsed: null,
        livingSpace: 110,
        costOfEnergyIncrease: 0.04,
        // wanted situation
        productionSystemWanted: null,
        futureEnergyConsumption: 50,
        estimatedCostOfHouseWork: 55000,
        estimatedAmountOfHelps: 10000,
        // loans
        regularLoanRate: 0.025,
        ecoLoanRate: 0,
        regularLoanLength: 15,
        ecoLoanLength: 20,
        regularLoanAmount: 15000,
        ecoLoanAmount: 50000,
        regularLoanAnnualPayment: 1211,
        ecoLoanAnnualPayment: 2000,
    },

    /* COMPUTED */
    energyWanted: null,
    energyUsed: null,
    monthlySavedAmount: 0, // old name: economie_par_mois
    renovationString: 'rapporté', // old name: qualif_renovation
    earnIn20Years: 0, // old name: gain_20ans
    monthlyCost5nextYears: 0, // old name: moy_AA_situation_tendentielle_mensuelle_5ans
    monthlyEnergyEarned5nextYears: 0, // old name: moy_mensuelle_eco_combustible
    monthlyRenovationCost5nextYears: 0, // old name: moy_mensuelle_annuites
    currentEPEnergyConsumption: 189, // old name: conso_sans_travaux
    futureEPEnergyConsumption: 55, // old name: conso_avec_travaux
    differenceAgainstDoingNothing: 0, // old name: sum_AA_situation_tendentielle_annuelle
    // chart
    futureInvoicesWithoutHouseWork: [2000, 2100, 2300, 2400, 2300],
    futureInvoicesWithHouseWork: [500, 600, 550, 600, 650],
    loanRepayment: [1600, 1000, 1000, 900, 1100],
};

const state = _.cloneDeep(defaultState);
const getters = {
    ...make.getters(state),
    getField,
};

const mutations = {
    ...make.mutations(state),
    updateField,

    resetState(state) {
        state = _.cloneDeep(defaultState);
    },

    loadFromSavedState(state, savedInputs) {
        const newState = _.cloneDeep(defaultState);
        for (const savedInputKey in savedInputs) {
            newState.inputs[savedInputKey] = savedInputs[savedInputKey];
        }
        Object.assign(state, newState);
    },

    // FIXME all computations in store, not sure it's a good solution, and easy
    // to create infinite loops when modifying state here since the component is
    // watching a lot of props
    inputHaveChanged(state) {
        if (!state.loaded) {
            return;
        }
        state.inputs.regularLoanAmount = Math.max(0, Math.round(state.inputs.estimatedCostOfHouseWork - state.inputs.ecoLoanAmount));
        state.inputs.regularLoanAnnualPayment = Math.round(vpm(
            state.inputs.regularLoanRate, state.inputs.regularLoanLength, state.inputs.regularLoanAmount,
        ));
        state.inputs.ecoLoanAnnualPayment = Math.round(vpm(
            state.inputs.ecoLoanRate, state.inputs.ecoLoanLength, state.inputs.ecoLoanAmount,
        ));
        state.energyWanted = store.state.energies.energyCollectionByIdentifier[state.inputs.productionSystemWanted.energyIdentifier];
        state.energyUsed = store.state.energies.energyCollectionByIdentifier[state.inputs.productionSystemUsed.energyIdentifier];

        computeNewState(state);
    },

    setInitials(state) {
        const productionSystems = store.getters['energies/possibleProductionSystems'](
            'individual',
            'heating',
            true,
        ).map(ps => {
            ps.label = ps.name;
            ps.code = ps.identifier;
            return ps;
        });
        const defaultProductionSystemUsed = productionSystems[1];
        const defaultProductionSystemWanted = productionSystems[2];
        if (defaultState.inputs.productionSystemUsed === null) {
            defaultState.inputs.productionSystemUsed = defaultProductionSystemUsed;
        }
        if (defaultState.inputs.productionSystemWanted === null) {
            defaultState.inputs.productionSystemWanted = defaultProductionSystemWanted;
        }
        if (state.inputs.productionSystemUsed && state.inputs.productionSystemUsed.code) {
            state.inputs.productionSystemUsed = productionSystems.find(p => p.code === state.inputs.productionSystemUsed.code);
        }
        if (state.inputs.productionSystemUsed === null || state.inputs.productionSystemUsed === undefined) {
            state.inputs.productionSystemUsed = defaultProductionSystemUsed;
        }

        if (state.inputs.productionSystemWanted && state.inputs.productionSystemWanted.code) {
            state.inputs.productionSystemWanted = productionSystems.find(p => p.code === state.inputs.productionSystemWanted.code);
        }
        if (state.inputs.productionSystemWanted === null || state.inputs.productionSystemWanted === undefined) {
            state.inputs.productionSystemWanted = defaultProductionSystemWanted;
        }
        state.productionSystemsOptions = productionSystems;
        state.loaded = true;
    },
};

const actions = {
    init: ({ _ }, savedInputs) => {
        store.commit('ecorenover/resetState');
        store.commit('ecorenover/loadFromSavedState', savedInputs);
        Promise.all([
            store.dispatch('energies/loadEnergies'),
            store.dispatch('energies/loadProductionSystems'),
        ]).then(() => {
            store.commit('ecorenover/setInitials');
            store.commit('ecorenover/inputHaveChanged');
        });
    },
    getPdfReport({ state, rootState, rootGetters }) {
        const data = {
            inputs: state.inputs,
            monthlySavedAmount: state.monthlySavedAmount,
            renovationString: state.renovationString,
            earnIn20Years: state.earnIn20Years,
            monthlyCost5nextYears: state.monthlyCost5nextYears,
            monthlyEnergyEarned5nextYears: state.monthlyEnergyEarned5nextYears,
            monthlyRenovationCost5nextYears: state.monthlyRenovationCost5nextYears,
            currentEPEnergyConsumption: state.currentEPEnergyConsumption,
            futureEPEnergyConsumption: state.futureEPEnergyConsumption,
            futureInvoicesWithoutHouseWork: state.futureInvoicesWithoutHouseWork,
            futureInvoicesWithHouseWork: state.futureInvoicesWithHouseWork,
            loanRepayment: state.loanRepayment,
            differenceAgainstDoingNothing: state.differenceAgainstDoingNothing,
        };
        return new Promise(function(resolve, reject) {
            apiClient
                .getPdfSimulation({
                    data,
                    domain: rootState.whiteLabelling.domain,
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getPdfTempStore({ commit }, uuid) {
        return new Promise(function(resolve, reject) {
            apiClient
                .getPdfTempStore(uuid)
                .then(response => {
                    const data = response.data;
                    const fields = [
                        'inputs',
                        'monthlySavedAmount',
                        'renovationString',
                        'earnIn20Years',
                        'monthlyCost5nextYears',
                        'monthlyEnergyEarned5nextYears',
                        'monthlyRenovationCost5nextYears',
                        'currentEPEnergyConsumption',
                        'futureEPEnergyConsumption',
                        'futureInvoicesWithoutHouseWork',
                        'futureInvoicesWithHouseWork',
                        'loanRepayment',
                        'differenceAgainstDoingNothing',
                    ];
                    for (const path of fields) {
                        commit('updateField', { path, value: data[path] });
                    }
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
