// import { utils } from '@/utils';

const api = {
    computeResults(data) {
        return new Promise((resolve, reject) => {
            this.apiClient()
                .post('/thermix/compute_results', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    },
    getPdfReport(data) {
        return new Promise((resolve, reject) => {
            this.apiClient({ responseType: 'arraybuffer' })
                .post('/thermix/report/pdf', data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    },
};

export default api;
