import { defineStore, acceptHMRUpdate } from 'pinia';
import { computed } from 'vue';

import { collectionProperties } from './helpers';

const isActive = object => !!object.isActive;
const addSelectProperties = object => ({
    ...object,
    label: object.name,
    value: object.pk,
});

const catchmentAreasProperties = collectionProperties('catchmentAreas', '/dialogwatt/catchment-area/');
const activeCatchmentAreasList = computed(() =>
    catchmentAreasProperties.catchmentAreasList.value
        .filter(isActive)
        .map(addSelectProperties)
);

const placesProperties = collectionProperties('places', '/dialogwatt/place/');
const activePlacesList = computed(() =>
    placesProperties.placesList.value
        .filter(isActive)
        .map(addSelectProperties)
);

const reasonsProperties = collectionProperties('reasons', '/dialogwatt/reason/');
const activeReasonsList = computed(() =>
    reasonsProperties.reasonsList.value
        .filter(isActive)
        .map(addSelectProperties)
);

export const useDialogwattStore = defineStore('dialogwatt', () => ({
    ...collectionProperties('advisorDowntimes', '/dialogwatt/advisor-downtime/'),
    ...collectionProperties('appointments', '/dialogwatt/appointment/'),
    ...catchmentAreasProperties,
    activeCatchmentAreasList,
    ...collectionProperties('notifications', '/dialogwatt/notification/'),
    ...placesProperties,
    activePlacesList,
    ...reasonsProperties,
    activeReasonsList,
}));

// for HMR, see https://pinia.vuejs.org/cookbook/hot-module-replacement.html
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useDialogwattStore, import.meta.hot));
}
