const defaultState = {
    systemsLabels: [],
    solutionsResults: [],
    periodTotalCostSeries: [],
    periodCostEvolutionSeries: [],
    environmentalIndicatorsSeries: [],
    yearlyEnergiesPricesSeries: [],
    metroGrenobleRequiredEnergyProd: null,
    needs: {
        housingCategory: '',
        housingCategories: {},
    },
    metroGrenoble: {
        enabled: false,
    },
};

export default defaultState;
