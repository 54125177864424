let api = {
    getAppInfos() {
        return new Promise((resolve, reject) => {
            this.apiClient({ timeout: 1000 })
                .get(`/app_infos`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.debug(error);
                    reject(error);
                });
        });
    },
};

export default api;
