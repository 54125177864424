<template>
  <div class="grid-x grid-padding-x section-title-container">
    <div class="cell small-2 medium-1 bar"></div>
    <div class="cell medium-shrink section-title-label text-xl"><slot>{{ label }}</slot></div>
    <div class="cell small-2 medium-1 bar"></div>
  </div>
</template>

<script>
export default {
    /** Section title styling :
     * ------- SECTION TITLE -------
     * Or (small screens)
     *    -------
     * SECTION TITLE
     *    -------
     */
    name: 'SectionTitle',
    components: {},
    props: { label: { default: '', type: String, required: false } },
    mounted: function() {
        if (this.label !== '') {
            console.warn(`Use of label (${this.label}) in section_title is deprecated\nUse slot instead.`);
        }
    },
};
</script>

<style lang='stylus' scoped>
@require '../stylesheet/variables'
@require '../stylesheet/typography'

.section-title-container
    justify-content: center

.section-title-label
    text-transform: uppercase
    text-align: center

.bar
    border: 1px solid body-font-color
    height: 0px
    margin: 0.9rem 0
</style>
