<template>
<div>
  <div class="menu-bar" v-if="!searchTreeLoading">
    <div class="grid-x grid-padding-x">
      <div class="cell medium-auto"></div>
      <div class="cell medium-shrink title">Espace professionnels</div>
      <div class="cell middle-part"></div>
      <div class="cell medium-shrink buttons">
        <span class="menu-button" v-show="showConnexion">
          <font-awesome-icon class="menu-icon" :icon="['far', 'user-hard-hat']" /><a :href="$store.state.whiteLabelling.listeproAdminUrl">Connexion</a>
        </span>
        <span class="menu-button">
          <font-awesome-icon class="menu-icon" :icon="['far', 'newspaper']" /><a @click="gotoNews">Actualités</a>
        </span>
        <span class="menu-button">
          <font-awesome-icon class="menu-icon" :icon="['far', 'info-circle']" /><a @click="showModalHelpPro"><span class="help-menu">Être référencé</span></a>
        </span>
        <span class="menu-button">
          <font-awesome-icon class="menu-icon" :icon="['far', 'question-circle']" /><a :href="`mailto:${$store.state.whiteLabelling.listeproContactEmail}?subject=Demande de contact annuaire des pros`"><span class="help-menu">Contact</span></a>
        </span>
      </div>
    </div>
  </div>
  
  <spinner :active="searchTreeLoading" />
  
  <div class="content center" v-if="!(searchTreeLoading || showResults)">   
    <layout-container class="main-section">
      <div class="grid-x section-title-container">
        <div class="cell small-2 medium-1 bar"></div>
        <div class="cell medium-shrink section-title-label text-xl"><slot>Votre projet</slot></div>
        <div class="cell small-2 medium-1 bar"></div>
      </div>
      <!-- Title -->
      <div>
        Etape {{Math.min(maxStep, currentStep)}}/{{maxStep}} 
        <h2 class="title" v-if="currentStep===1">Votre projet concerne :</h2>
        <h2 class="title" v-if="currentStep>=2">Quelle prestation recherchez-vous pour votre projet ?</h2>
      </div>
      <!-- Icons and choices -->
      <div class="step step-1 grid-y" v-show="[1,2,3,4].includes(currentStep)" ref="step1">
        <div class="cell" v-if="[1,2,3,4].includes(currentStep)" >
          <div class="choices" v-bind:key=act.id v-for="act in activities">
            <a @click="setSelection(act.pk,1); ">
              <div class="pave" :class="{ 'actif': act===activity}" v-tooltip="{content: act.helpText, html:true}">
                <i class="ico_pie69" :class="act.icon"></i>
                <div>{{act.name}}</div>
              </div>
              <div >
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="step step-2 grid-y" v-show="[2,3,4].includes(currentStep)" ref="step2">
        <div class="cell" v-if="[2,3,4].includes(currentStep)" >
          <div class="choices" v-bind:key=miss.id v-for="miss in missions">
            <regular-button class="button-choice" :class="{ 'button-white': miss===mission}" @click="setSelection(miss.pk,2)"  v-tooltip="{content: miss.helpText, html:true}">
              {{miss.name}}
            </regular-button>
          </div>
        </div>
      </div>
      <div class="step step-3 grid-y" v-show="[3, 4].includes(currentStep)" ref="step3">
        <div class="cell" v-if="[3,4].includes(currentStep)" >
          <div class="choices" v-bind:key=sub.id v-for="sub in subMissions">
            <regular-button class="button-choice" :class="{'button-white': sub===subMission}" @click="setSelection(sub.pk,3)"  v-tooltip="{content: sub.helpText, html:true}">
              {{sub.name}}
            </regular-button>
          </div>
        </div>
      </div>
      <br/><br/>
      <div class="grid-x grid-margin-x">
        <div class="cell small-6">
          <regular-button v-if="currentStep>1" class="button-white" @click="goBack">
            <font-awesome-icon class="chevron" icon="chevron-left"/>Retour 
          </regular-button>
        </div>
        <div class="cell small-6">
          <regular-button class="search-button" v-if="currentStep===4" @click="showProfessionals()">
            Afficher les résultats<font-awesome-icon class="chevron" icon="chevron-right"/>
          </regular-button>
        </div>
      </div>
    </layout-container>
  </div>

  <DialogWrapper
    ref="modalHelpUser"
  >
    <div> 
      <help-user-and-pro
        @hide-modal="hideModalHelpUser"
        helpType="user"
      />
    </div>
  </DialogWrapper>
  <DialogWrapper
    ref="modalHelpPro"
    :adaptive="false"
    :width="1000"
    height="auto"
    clickToClose
    :scrollable="true"
  >
    <div> 
      <help-user-and-pro
        @hide-modal="hideModalHelpPro"
        helpType="professional"
      />
    </div>
  </DialogWrapper>
  <DialogWrapper
    ref="modalHelpMissions"
  >
    <div>
      <modal-help-missions
        @hide-modal="hideModalHelpMissions"
      />
    </div>
  </DialogWrapper>
  
  <layout-container color="white">
    <div class="grid-x">
      <div class="cell medium-auto"></div>
      <div class="cell medium-shrink text-right">
        <span class="bottom-help-icon">
          <font-awesome-icon class="icon" icon="question-circle"/>
        </span>
        
      </div>
      <div class="cell medium-shrink bottom-help-link">
        <div>
          <action-link
            label="Présentation de l'annuaire"
            icon="angle-right"
            icon-position="left"
            color="text-color"
            @click="showModalHelpUser"
            ></action-link>
        </div>
        
        <div>
          <action-link
            label="Connaître les acteurs du bâtiment"
            icon="angle-right"
            icon-position="left"
            color="text-color"
            @click="downloadKnowActors"
            ></action-link>
        </div>
        
        <div>
          <action-link
            label="Comprendre les missions"
            icon="angle-right"
            icon-position="left"
            color="text-color"
            @click="downloadHelpMissions"
            ></action-link>
        </div>
        
      </div>
    </div>
  </layout-container>
  
</div>

</template>

<script>

import ActionLink from '@/components/action_link';
import HelpUserAndPro from '@/apps/listepro/modal_help/help_user_and_pro';
import ModalHelpMissions from '@/apps/listepro/modal_help/help_missions';
import RegularButton from '@/components/regular_button';
import Spinner from '@/components/spinner';
import { searchMixin } from '@/apps/listepro/public/search/search_mixin';
import { userMixin } from '@/mixins/user';


export default {
    name: 'Search',
    mixins: [ searchMixin, userMixin ],
    props: {
        useParams: { default: false, required: false, type: Boolean },
        cref: { default: null, required: false },
    },
    provide() {
        return { parentValidator: this.$validator };
    },
    components: {
        ActionLink,
        HelpUserAndPro,
        ModalHelpMissions,
        RegularButton,
        Spinner,
    },
    data() {
        return {
            searchTree: [],
            selectedSegment: null,
            selectedActivity: null,
            selectedMission: null,
            selectedSubMission: null,
            showResults : false,
        };
    },
    computed: {
        showConnexion() {
            return !(this.userUtilities.isProfessional || this.userUtilities.isListeProAdmin);
        },
        showNews() {
            return !this.showConnexion && this.$store.get('whiteLabelling.listeproNewsIsActive') && this.$store.get('whiteLabelling.listeproNewsUrl');
        },
    },

    watch: {
        subMissions: {
            handler: function() {
                this.preSelectSubmissionIfUnique();
            },
            immediate: false,
            deep: true,
        },
        
        currentStep: {
            handler: function() {
                this.preSelectSubmissionIfUnique();
            },
            immediate: false,
        },
    },
    
    mounted: function() {
        this.loadSearchTree();
        if (this.useParams) {
            this.showResults = true;
        }
        else {
            this.$store.dispatch("listepro/clearSearchParameters");
        }
        if (this.cref) {
            this.showModalHelpPro();
        }
    },
    methods: {
        showProfessionals() {
            this.showResults = true;
            this.$router.push(
                {
                    name: 'ListeProPublicShowResults',
                    params: {
                        givenActivity: this.selectedActivity,
                        givenMission: this.selectedMission,
                        givenSubMission: this.selectedSubMission,
                    },
                }
            );
        },
        preSelectSubmissionIfUnique() {
            if (this.currentStep === 3 && this.subMissions && this.subMissions.length === 1) {
                this.selectedSubMission = this.subMissions[0].pk;
            }
        },
        selected() {
            if (this.currentStep===1 && this.activity){
                return true;
            }
            return false;
        },
        showModalHelpUser() {
            this.$refs.modalHelpUser.show();
        },
        hideModalHelpUser() {
            this.$refs.modalHelpUser.hide();
        },
        showModalHelpPro() {
            this.$refs.modalHelpPro.show();
        },
        hideModalHelpPro() {
            this.$refs.modalHelpPro.hide();
        },
        showModalHelpMissions() {
            this.$refs.modalHelpMissions.show();
        },
        hideModalHelpMissions() {
            this.$refs.modalHelpMissions.hide();
        },
        downloadKnowActors() {
            // Fiche "les acteurs et les contrats dans le batiments.pdf"
            window.open(this.$store.state.whiteLabelling.listeproHelpActorsUrl);
        },
        downloadHelpMissions() {
            // Fiche "Aide au choix des missions proposées"
            window.open(this.$store.state.whiteLabelling.listeproHelpMissionsUrl);
        },
        gotoNews() {
            this.$router.push({ name: 'ListeProPublicNews' });
        },
    },
};
</script>

<style lang="stylus" scoped>
@require '../../../../stylesheet/variables'
@require '../../../../stylesheet/typography'
@require '../../../../stylesheet/listepro_font'

.step-4
    margin-top: space-md

.main-section
    padding-top: space-xxl
    padding-bottom: space-xxl

.content
    background-color: gray-100
    min-height: 400px

.center
    text-align: center

.button
    font-color: #fff
    color: #fff

.button:hover
    background-color: primary-color-700

.button-white:hover
    background-color: primary-color-300

.section-title-container
    justify-content: center
    margin-bottom: 2rem

.section-title-label
    text-transform: uppercase
    text-align: center
    font-size: 2rem
    color: primary-color-500

.bar
    border: 1px solid primary-color-500
    height: 0px
    margin: 1.4rem 0

.instruction
    color: gray-500
    text-transform: uppercase
    margin-bottom: 2rem

.choices
    display: inline-block

.choice
    background-color: primary-color-500
    border: solid 0.2rem primary-color-500
    margin-left: 2rem
    margin-right: 2rem
    width: 8rem
    height: 8rem
    position: relative
    display: inline-block

    .icon
        position: absolute
        left: 1.3rem
        top: 1.3rem

.choice-selected
    background-color: #fff

.label-choice
    margin-left: 2rem
    margin-right: 2rem
    font-size: 1.5rem

.button-choice
    border-radius: 0.2rem
    border: 0.2rem solid primary-color-500
    margin: 0.7rem 0.5rem 0.7rem 0.5rem
    vertical-align: middle

.button-white
    background-color: #fff
    border: 0.2rem solid primary-color-500
    color: primary-color-500

.search-button
    font-size: 1.3rem

.icon
    color: #fff
    font-size: 5rem

.icon-selected
    color: primary-color-500

.chevron
    font-size: 0.8rem

.bold
    font-weight: bold

.pave
    margin: 0 1rem
    border-radius: 3px
    text-align: center
    cursor: pointer

    i
        text-align: center
        line-height: 2
        border: 3px solid primary-color-500
        padding: 1.85rem 2rem
        font-size: 4rem !important
        color: #fff
        background-color: primary-color-500
        transition: background-color 0.25s ease-out, color 0.25s ease-out

    div
        text-align: center
        font-weight: 900
        font-size: 1.5rem
        color: primary-color-500

.pave.actif
    i
        background-color: #fff
        border: 3px solid primary-color-500
        color: primary-color-500
        text-align: center

.menu-bar
    background-color: primary-color-500
    position: relative
    font-size: text-md

    .contact-menu
        margin-right: space-md

    .title
        padding-top: space-sm
        padding-bottom: space-sm
        font-weight: bold
        color: white
        padding-right: 15px

    .buttons
        padding-left: 0
        padding-top: space-sm
        padding-bottom: space-sm
        color: primary-color
        background-color: white

        .menu-button
            margin-right: space-md

            .menu-icon
                margin-right: space-xs

        .menu-button:last-child
            margin-right: 0

    .middle-part
        width: 2rem
        transform: skewX(-15deg)
        background: #fff
        margin-right: -25px
        margin-bottom: -1px

.help-mission
    color: primary-color
    font-size: 2.55rem
    vertical-align: middle

.pro-access
    color: white
    padding-top: 6px
    padding-left: space-md
    padding-right: space-md

.pro-access:hover
    background: primary-color-700

.help-menu:hover
.contact-menu:hover
    background-color: #fff

.bottom-help-icon
    .icon
        height: 2.5rem
        font-size: text-xxl
        color: primary-color
        margin-right: space-md
</style>
