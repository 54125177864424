const Thermix = () => import(/* webpackChunkName: "thermix" */ '@/apps/thermix/thermix');
const Needs = () => import(/* webpackChunkName: "thermix" */ '@/apps/thermix/needs');
const Solutions = () => import(/* webpackChunkName: "thermix" */ '@/apps/thermix/solutions');
const Results = () => import(/* webpackChunkName: "thermix_results" */ '@/apps/thermix/results');
const BasicNeeds = () => import(/* webpackChunkName: "thermix" */ '@/apps/thermix/needs_estimation/basic_needs');
const ThermalCalculation = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/thermal_calculation');
const NewBuilding = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/new_building');
const GlobalRenovation = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/global_renovation');
const SystemsRenovationBuilding = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/systems_renovation_building');
const SystemsRenovationInvoiceEuro = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/systems_renovation_invoice_euro');
const SystemsRenovationInvoiceQuantity = () => import(/* webpackChunkName: "thermix_needs_estimation" */ '@/apps/thermix/needs_estimation/systems_renovation_invoice_quantity');
const ReportPdf = () => import(/* webpackChunkName: "thermix_report_pdf" */ '@/apps/thermix/report_pdf');

const routes = [
    {
        path: '/thermix',
        name: 'Thermix',
        component: Thermix,
        redirect: { name: 'ThermixNeeds' },
        meta: {
            requiresAuth: false,
            // requiresUserType: ['administrator', 'manager', 'advisor'],
        },
        children: [
            {
                name: 'ThermixNeeds',
                path: 'mes-besoins',
                component: Needs,
                redirect: { name: 'ThermixBasicNeeds' },
                meta: {
                    tabLabel: 'Besoins',
                },
                children: [
                    {
                        name: 'ThermixBasicNeeds',
                        path: '',
                        component: BasicNeeds,
                        // meta: {
                        //     tabLabel: 'Besoins',
                        // },
                    },
                    {
                        name: 'ThermixNeedsEstimationThermalCalculation',
                        path: 'estimer-depuis-un-calcul-thermique',
                        component: ThermalCalculation,
                        // meta: {
                        //     tabLabel: 'Besoins',
                        // },
                    },
                    {
                        name: 'ThermixNeedsEstimationNewBuilding',
                        path: 'estimer-pour-une-construction-neuve',
                        component: NewBuilding,
                        // meta: {
                        //     tabLabel: 'Besoins',
                        // },
                    },
                    {
                        name: 'ThermixNeedsEstimationGlobalRenovation',
                        path: 'estimer-pour-une-renovation-globale',
                        component: GlobalRenovation,
                        // meta: {
                        //     tabLabel: 'Besoins',
                        // },
                    },
                    {
                        name: 'ThermixNeedsEstimationSystemsRenovationBuilding',
                        path: 'estimer-pour-une-renovation-des-systemes-a-partir-de-l-epoque-du-batiment',
                        component: SystemsRenovationBuilding,
                        // meta: {
                        //     tabLabel: 'Besoins',
                        // },
                    },
                    {
                        name: 'ThermixNeedsEstimationSystemsRenovationInvoiceEuro',
                        path: 'estimer-pour-une-renovation-des-systemes-a-partir-des-factures-en-euro',
                        component: SystemsRenovationInvoiceEuro,
                        // meta: {
                        //     tabLabel: 'Besoins',
                        // },
                    },
                    {
                        name: 'ThermixNeedsEstimationSystemsRenovationInvoiceQuantity',
                        path: 'estimer-pour-une-renovation-des-systemes-a-partir-quantite-de-combustible-ou-kwh',
                        component: SystemsRenovationInvoiceQuantity,
                        // meta: {
                        //     tabLabel: 'Besoins',
                        // },
                    },
                ],
            },
            {
                name: 'ThermixSolutions',
                path: 'solutions',
                component: Solutions,
                // disabled: true,
                meta: {
                    tabLabel: 'Solutions',
                },
            },
            {
                name: 'ThermixResults',
                path: 'resultats',
                component: Results,
                meta: {
                    tabLabel: 'Résultats',
                },
            },
        ],
    },
    {
        name: 'ThermixReportPdf',
        path: '/thermix/rapport/:uuid/pdf',
        component: ReportPdf,
        meta: {
            requiresAuth: false,
        },
        // beforeEnter(to, from, next) {
        //     store.dispatch('thermix/getPdfTempStore', to.params.uuid).then(response => {
        //         next();
        //     });
        // },
    },
];

export default routes;
