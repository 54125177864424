import FieldWrapper from './field_wrapper';

export default {
    components: {
        FieldWrapper,
    },
    props: {
        id: {
            type: String,
            default() {
                return (
                    '_' +
                    Math.random()
                        .toString(36)
                        .substr(2, 9)
                );
            },
        },
        // we have both in existing code, so for now this will do
        inputLabel: String,
        label: String,

        mandatory: Boolean,
        helptext: { default: '', type: String },

        // vee-validate
        scope: { type: String },
        validateRules: { default: '', type: [String, Object] },
        validationLabel: { default: '', type: String },

        // custom errors
        isError: { default: false, type: Boolean },
        customError: { type: [String, Array] },

        // tooltip
        tooltip: {
            required: false,
            type: [String, Object],
        },

    },

    computed: {
        scopedId() {
            return this.scope ? `${this.scope}.${this.id}` : this.id;
        },

        errors() {
            let customErrors = [];
            if (this.isError && this.customError) {
                customErrors = Array.isArray(this.customError) ? this.customError : [this.customError];
            } else if (this.isError) customErrors = ["Erreur, cette valeur n'est pas valide"];

            const vvErrors = this.$refs.wrapper?.$refs.validator?.errors || [];

            return [
                ...vvErrors,
                ...customErrors,
            ];
        },

        fieldData() {
            // contains all props for field-wrapper
            // components can override any of these if needed
            return {
                mandatory: this.mandatory,
                id: this.id,
                inputLabel: this.inputLabel || this.label,
                isError: this.isError,
                customError: this.customError,
                tooltipConfig: this.tooltipConfig,
                helptext: this.helptext,
                validateRules: this.validateRules,
                validationLabel: this.validationLabel,
                label: this.label,
                errors: this.errors,
            };
        },

        // tooltip configuration
        tooltipConfig() {
            const defaultConfig = {
                icon: ['far', 'question-circle'],
                iconAlign: 'left',
                html: true,
                delay: {
                    show: 500,
                    hide: 500,
                },
            };

            switch (typeof this.tooltip) {
                // If tooltip is object, return it merged with default tooltip config
                case 'object':
                    return Object.assign({}, defaultConfig, this.tooltip);

                // If tooltip is string, return default config with given content
                case 'string':
                    return Object.assign({}, defaultConfig, { content: this.tooltip });
            }

            // Else return undefined
        },
    },
};
